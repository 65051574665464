import { Injectable, signal } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AppConfig } from '@kildenconfig/app.config';
import { DialogService, DialogVariants } from '@kildencore/services/dialog.service';
import { MapService } from '@kildencore/services/map.service';
import { ThemeLayersService } from '@kildencore/services/theme-layers.service';
import { Kilden3DialogComponent } from '@kildenshared/components/dialog/dialog.component';
import { UploadFileComponent } from '@kildenshared/components/upload-file/upload-file.component';
import Collection from 'ol/Collection';
import Feature from 'ol/Feature';
import { GeoJSON, GPX, KML, TopoJSON } from 'ol/format';
import Geometry from 'ol/geom/Geometry';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Circle, Fill, Stroke, Style } from 'ol/style';
import proj4 from 'proj4';
import { ReplaySubject } from 'rxjs';
import Swal from 'sweetalert2';

// import * as JSZip from 'jszip';
// import * as JSZip from 'jszip-sync';
declare let JSZip: any;
declare let EPSGUser: any;
declare let EPSG4326: any;
declare let shpLoader: any;
declare let SHPParser: any;
declare let dbfLoader: any;
declare let DBFParser: any;

const MAX_FILE_SIZE = 20971520;

@Injectable({ providedIn: 'root' })
export class UploadService {
  private readonly _uploadedFeatures = new ReplaySubject<number>();
  readonly uploadedFeatures$ = this._uploadedFeatures.asObservable();

  // private readonly _fileFormats: { [key: string]: any } = {
  //   geojson: new GeoJSON(),
  //   gpx: new GPX(),
  //   kml: new KML(),
  //   zip: new ZIP({}),
  // };

  private _dialogRef!: MatDialogRef<Kilden3DialogComponent>;
  private _fileReaders: any[] = [];

  $uploadProgress = signal<number>(0);

  constructor(
    private readonly _dialogService: DialogService,
    private readonly _matDialog: MatDialog,
    private readonly _mapService: MapService,
    private readonly _themeLayersService: ThemeLayersService
  ) {}

  addCustomThemeLayer(features: Feature[], fileName: string): VectorLayer<VectorSource> {
    const newLayerId = this._getUniqueLayerName(AppConfig.IDBOD_USER_UPLOADED);
    const fileNameLayer = this.featuresIntoNewLayer(newLayerId, features, 'Fil: ' + fileName.replace(/\s/, '_'), true);
    this._themeLayersService.registerNewLayer(newLayerId, fileNameLayer);
    return fileNameLayer;
  }

  closeDialog = (msg: String) => this._dialogRef.close(msg);

  featuresIntoNewLayer = (
    layerId: string,
    features: Feature<Geometry>[] | Collection<Feature<Geometry>> | undefined,
    fileName: string,
    purgeExistingLayer = false
  ) => {
    const kml = fileName.endsWith('.kml');
    const style = new Style({
      fill: new Fill({
        color: kml ? [0, 0, 255, 0] : [255, 0, 0, 0],
      }),
      stroke: new Stroke({
        color: kml ? [0, 0, 255, 0.7] : [255, 0, 0, 0.7],
        width: 3,
      }),
      image: new Circle({
        radius: 5,
        fill: new Fill({
          color: [255, 0, 0, 0.7],
        }),
        stroke: new Stroke({
          color: [255, 255, 255, 1],
          width: 2,
        }),
      }),
    });

    // Properly dispose of any existing layers (and their content) using the given ID
    if (purgeExistingLayer) {
      this._themeLayersService.purgeActiveLayer(layerId);
      this._themeLayersService.purgeBankLayer(layerId);
    }

    return new VectorLayer({
      properties: {
        id: layerId,
        label: fileName,
        uploaded: true,
        altitudeMode: 'clampToGround',
      },
      source: new VectorSource({ features: features }),
      style: style,
      zIndex: AppConfig.ZINDEX_UPLOAD,
    });
  };

  openDialog(): MatDialogRef<Kilden3DialogComponent> {
    this._dialogRef = this._matDialog.open(Kilden3DialogComponent, {
      autoFocus: false,
      id: 'uploadDialog',
      minWidth: '300px',
      minHeight: '200px',
      width: '540px',
      hasBackdrop: false,
      data: {
        title: 'Last opp fil (kml, gpx, GeoJSON eller zippet shape)',
        component: UploadFileComponent,
        submitText: 'submitText',
      },
    });

    return this._dialogRef;
  }

  updateReportLayer(features: Feature[], fileName: string): VectorLayer<VectorSource> {
    if (!this._mapService.getLayer(AppConfig.IDBOD_REPORT_UPLOADED)) {
      this._mapService.addLayer(
        AppConfig.IDBOD_REPORT_UPLOADED,
        this.featuresIntoNewLayer(AppConfig.IDBOD_REPORT_UPLOADED, features, fileName)
      );
    } else {
      features.forEach((f: Feature) =>
        this._mapService.getLayer(AppConfig.IDBOD_REPORT_UPLOADED).getSource().addFeature(f)
      );
    }
    this._uploadedFeatures.next(features.length);
    return this._mapService.getLayer(AppConfig.IDBOD_REPORT_UPLOADED);
  }

  uploadFilesToMap(files: FileList): void {
    let uploadError = false;
    const promises: Array<any> = [];
    Array.from(files).forEach(file => {
      // console.log(`file`, file.name, file.size);
      this.$uploadProgress.set(0.1); // show abort button
      promises.push(this._readFile(file));
    });
    Promise.allSettled(promises)
      .then(results => {
        this.$uploadProgress.set(100);
        const uploadedLayers: any[] = [];
        results.forEach(r => {
          if (r.status === 'fulfilled') {
            const layer = r.value;
            if (layer) uploadedLayers.push(layer);
            else console.log(`upload failed:`, r);
          }
        });
        if (uploadedLayers?.length) this._mapService.zoomToUploads(uploadedLayers);
        if (uploadError) this.closeDialog('After reading files');
      })
      .catch(e => {
        uploadError = true;
        throw new Error('Kan ikke laste. Feil med en eller flere filer.');
      });
  }

  ZIP = () => new ZIP({});

  /**
   * If given fileName exists as a ThemeLayer, will increment the fileName until unique.
   * Given fileName 'line.kml' will be returned as 'line(1).kml' or 'line(2).kml' and so on.
   */
  private _getUniqueLayerName(fileName: string): string {
    const allLayers = [
      ...this._themeLayersService.getActiveThemeLayers(),
      ...(this._themeLayersService.getBankLayers() || []),
    ];
    if (!allLayers?.length) {
      return fileName;
    }

    const layerNames: string[] = allLayers.map(l => l.get('id'));
    let name = fileName;
    let counter = 0;

    while (layerNames.includes(name)) {
      const segments = name.split('.');
      let baseName = name;
      let extension = '';
      if (segments.length > 1) {
        extension = segments[segments.length - 1];
        segments.pop();
        baseName = segments.join('.');
      }

      const match = baseName.match(/\(\d+\)/g);

      if (match?.length) {
        baseName = baseName.substring(0, baseName.lastIndexOf(match[0]));
        const countSansParens = match[0].replace('(', '').replace(')', '');
        counter = parseInt(countSansParens);
      }
      counter++;

      name = baseName + '(' + counter + ')' + (extension?.length ? '.' + extension : '');
    }

    return name;
  }

  private errorPopup(text: string) {
    Swal.fire({
      title: 'Feil',
      html: text,
      icon: 'error',
      confirmButtonText: 'OK',
    });
  }

  private _displayOnMap(f: File, format: string, fileContent: string): VectorLayer<VectorSource> | undefined {
    const gpx = new GPX();
    const kml = new KML();
    const geojson = new GeoJSON();
    const topojson = new TopoJSON();
    const zip = this.ZIP();
    const ext2ol: { [key: string]: any } = { geojson, gpx, kml, topojson, zip };
    let uploadedLayer: any;
    if (fileContent) {
      if (format) {
        const ms = this._mapService;
        const fp = ms.$is3dActive() ? ms.projOn3dInit : ms.getCode();
        try {
          const readOptions: { dataProjection?: string; featureProjection?: string } = {
            featureProjection: fp,
          };
          const fileProjection = ext2ol[format].readProjection(fileContent);
          if (fileProjection) {
            readOptions['dataProjection'] = fileProjection.getCode();
          }

          const features: Feature[] = ext2ol[format].readFeatures(fileContent, readOptions);

          if (
            this._dialogService.isOpen(DialogVariants.AREA_REPORT) ||
            this._dialogService.isOpen(DialogVariants.FOREST_REPORT) ||
            this._dialogService.isOpen(DialogVariants.SOIL_REPORT)
          ) {
            // Upload as attachment for report
            uploadedLayer = this.updateReportLayer(features, f.name);
          } else {
            // Upload into separate layers and add to ActiveLayers list
            uploadedLayer = this.addCustomThemeLayer(features, f.name);
          }
        } catch (e: any) {
          this.$uploadProgress.set(0);
          throw new Error('Kan ikke laste. Feil med en eller flere filer.');
        }
      }
    }
    return uploadedLayer;
  }

  private _readFile(f: File) {
    return new Promise((resolve, reject) => {
      const format_ = f.name.split('.').pop();
      const format = format_ ? format_.toLowerCase() : '';
      const isZipFormat = format === 'zip';
      const reader = new FileReader();
      this._fileReaders.push(reader); // keep track to be able to abort them

      reader.onprogress = (p: any) => {
        if (p.lengthComputable) {
          this.$uploadProgress.set((p.loaded / p.total) * 100);
        }
      };
      reader.onerror = (e: any) => reject(`Feil ${f.name}`);
      reader.onload = (l: any) => {
        resolve(this._displayOnMap(f, format, l.target.result));
      };

      if (isZipFormat) reader.readAsArrayBuffer(f);
      else reader.readAsText(f, 'UTF-8');
    });
  }
}

function shp2geojson(buffer: any) {
  let shpData, dbfString, dbfData, prjString, geojson;

  const zip = new JSZip();
  zip.sync(() => {
    zip.loadAsync(buffer).then((zip: any) => {});
    const shpFile = zip.file(/.shp$/i)[0];
    if (shpFile) {
      shpFile.async('arraybuffer').then((result: any) => (shpData = result));
    }
    const dbfFile = zip.file(/.dbf$/i)[0];
    if (dbfFile) {
      dbfFile.async('arraybuffer').then((result: any) => (dbfData = result));
    }
    const prjFile = zip.file(/.prj$/i)[0];
    if (prjFile) {
      prjFile.async('text').then((result: any) => (prjString = result));
    }
  });

  // EPSGUser = EPSG4326;
  EPSGUser = proj4(EPSG4326);
  if (prjString) {
    proj4.defs('EPSGUSER', prjString);
    try {
      EPSGUser = proj4('EPSGUSER');
    } catch (e) {
      console.error('Unsuported Projection: ' + e);
    }
  }
  if (dbfData) {
    dbfString = new TextDecoder().decode(dbfData);
  }

  shpLoader(new SHPParser().parse(shpData, ''), () => {}, proj4);
  dbfLoader(new DBFParser().parse(dbfData, '', dbfString, 'utf-16'), (result: any) => (geojson = result), proj4);
  return geojson;
}

// const zip2shp2geojson = (buffer) => new Promise((resolve, reject) => {
//   // const zip = new JSZip(buffer);
//   // const zip = new JSZip();
//   // zip.load(buffer);
//   // const shpFile = zip.file(/.shp$/i)[0];
//   // console.log(shpFile.name, shpFile);
//   const url = URL.createObjectURL(new Blob([buffer]));
//   return loadshp({ url: url, encoding: 'utf-8' }, geojson => {
//     resolve(geojson);
//   });
// });

export type Type = 'arraybuffer' | 'json' | 'text' | 'xml';

// Define a ZIP/SHP format class by subclassing ol/format/GeoJSON:
// @Injectable()
export class ZIP extends GeoJSON {
  constructor(opt_options: any) {
    super(opt_options || {});
  }

  // needed, it will fail otherwise
  override getType() {
    return 'arraybuffer' as Type;
  }

  // asyncrously did not work:

  // // readFeature(source, options) {
  // //   zip2shp2geojson(source).then(geojson => {
  // //     return super.readFeature(geojson, options);
  // //   });
  // // }
  // readFeatures(source, options) {
  //   // return new Promise((resolve, reject) => {
  //     zip2shp2geojson(source).then(geojson => {
  //       console.log('unzipped shp as geojson', geojson);
  //       // resolve(super.readFeatures(geojson, options));
  //     });
  //   // });
  // }

  // loaded zip/buffer has to be read synchronously:
  override readFeatures(source: any, options: any) {
    const geojson = shp2geojson(source);
    return super.readFeatures(geojson, options);
  }

  // readProjection(s) { // s = source
  //   const geojson = (s instanceof ArrayBuffer) ? shp2geojson(s) : s;
  //   return super.readProjection(geojson);
  // }
}
