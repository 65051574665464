import { LocationStrategy } from '@angular/common';
import { Component, effect, HostBinding, OnInit } from '@angular/core';
import { TopicsService } from '@kildencore/services/data/topics.service';
import { KildenStateService } from '@kildencore/services/kilden-state.service';
import { MapService } from '@kildencore/services/map.service';
import { TopicConfig } from '@kildenshared/interfaces/topic-config';
import {
  // buildModuleUrl,
  // Cartesian3,
  // Cartographic,
  CesiumTerrainProvider,
  // createWorldTerrainAsync,
  // JulianDate,
  // Math as CesiumMath,
  // Matrix4,
  // Rectangle,
} from 'cesium';
import { containsXY as olExtentContainsXY } from 'ol/extent';
import { transform as olProjTransform, transformExtent as olProjTransformExtent } from 'ol/proj';
import * as olcsCore from 'olcs/core';
import OLCesium from 'olcs/OLCesium';
import {
  combineLatest,
  map as rxMap,
  Observable,
  shareReplay,
  Subject,
  take,
  takeUntil,
  takeWhile,
  tap,
  timer,
} from 'rxjs';
// import Cesium from 'cesium';
// window.Cesium = Cesium; // expose Cesium to the OL-Cesium library
// import OSM from 'ol/source/OSM';
// import TileLayer from 'ol/layer/Tile';

@Component({
  selector: 'kilden3-three-d',
  templateUrl: './three-d.component.html',
  styleUrls: ['./three-d.component.css'],
})
export class ThreeDComponent implements OnInit {
  $is3dActive = this._mapService.$is3dActive;

  @HostBinding('class.isActive3d')
  get is3dActive() {
    return this.$is3dActive();
  }

  readonly dismissPopoverAfterSeconds = 30;
  dismissPopoverPercentageRemaining = 0;
  dismissPopoverTimer$: Observable<number> = timer(0, 250).pipe(
    takeWhile(() => this.dismissPopoverPercentageRemaining > 0),
    take(1 + (this.dismissPopoverAfterSeconds * 1000) / 250),
    tap((iterationsCount: number) => {
      this.dismissPopoverPercentageRemaining = Math.ceil(
        100 - ((iterationsCount * 0.25) / this.dismissPopoverAfterSeconds) * 100
      );

      if (this.dismissPopoverPercentageRemaining <= 0) {
        this.showIntroPopover = false;
      }
    })
  );

  ol3d: any;
  showIntroPopover: boolean = false;

  private readonly _onDestroy$ = new Subject<void>();

  private signalHasEmitted = false;

  constructor(
    private readonly _kildenStateService: KildenStateService,
    private readonly _locationStrategy: LocationStrategy,
    private readonly _mapService: MapService,
    private readonly _topicsService: TopicsService
  ) {
    effect(() => {
      const isActive = this.$is3dActive();
      if (this.signalHasEmitted) {
        this.smooth3dTransition();
      }
      this.signalHasEmitted = true;
    });
  }

  ngOnInit(): void {
    /**
     * FYI: This component code works as is with Cesium v1.105.2 and olcs v2.14.0. There are newer
     * releases available for both libs but several things break after update. Things like OL drawings usually
     * error out on drawEnd, and also this component code struggles on latest olcs. The lib ol-cesium (aka olcs)
     * is seeing a lot of changes currently and will hopefully better document how to tie together OL and Cesium.
     *
     * Idea: Refactor to delay loading Cesium until 3D active. This component is always included (to show the
     * 3D-toggle button), thus its ngOnInit() should probably not load Cesium at all. That load/activation and
     * deactivation should rather be handled by the toggle3d()-method.
     */
    const map = this._mapService.getMap();
    // window.CESIUM_BASE_URL = '/assets/cesium'; // where we saved static files
    Cesium.buildModuleUrl.setBaseUrl(`${this._locationStrategy.getBaseHref()}assets/cesium/`);
    // const osm = new TileLayer({ source: new OSM() });
    // map.getLayers().insertAt(0, osm);

    // // Set good time for lighting (brightest day)
    const d = new Date(2020, 5, 21, 8, 30); // 21 June 2020, 8:30 UTC/GMT
    const jDate = Cesium.JulianDate.fromDate(d);
    const time = () => jDate;
    this.ol3d = new OLCesium({ map, time });
    this._mapService.set3d(this.ol3d); // save it in a service to be accessible
    this._mapService.saveProjOn3dInit();

    Cesium.Ion.defaultAccessToken =
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9' +
      '.eyJqdGkiOiI0MzAyNzUyYi0zY2QxLTQxZDItODRkOS1hNTA3MDU3ZTBiMDUiLCJpZ' +
      'CI6MjU0MSwiaWF0IjoxNTMzNjI1MTYwfQ.oHn1SUWJa12esu7XUUtEoc1BbEbuZpRo' +
      'cLetw6M6_AA';

    const scene = this.ol3d.getCesiumScene();
    // const camera = this.ol3d.getCamera(); // or scene.camera
    Cesium.createWorldTerrainAsync({
      // requestWaterMask: true, // required for water effects
      requestVertexNormals: true, // required for terrain lighting
    }).then((terrainProvider: CesiumTerrainProvider) => {
      scene.terrainProvider = terrainProvider;
    });
    // scene.terrainProvider = Cesium.createWorldTerrain({
    //   // requestWaterMask: true, // required for water effects
    //   requestVertexNormals: true, // required for terrain lighting
    // });
    // scene.globe.enableLighting = true;

    // // Add Cesium OSM Buildings, a global 3D buildings layer.
    // const buildingTileset = await Cesium.createOsmBuildingsAsync();
    // scene.primitives.add(buildingTileset);

    // this.ol3d.setEnabled(true);

    // Hide credits:
    const c = document.querySelector('.cesium-credit-logoContainer')?.parentElement;
    if (c) c.style.display = 'none';

    // Zoom constraint:
    scene.screenSpaceCameraController.minimumZoomDistance = 150;
    scene.screenSpaceCameraController.maximumZoomDistance = 4000000;

    // Do not render the scene at 60 FPM:
    // console.log(scene.requestRenderMode);
    scene.requestRenderMode = true;

    // Extent constraint:
    const limitCamera = () => {
      const v = map.getView();
      const p = v.getProjection();
      // Maximum extent in degrees:
      const epsg = p.getCode();
      const e = p.getExtent();
      // const extent = olProjTransformExtent(e, epsg, 'EPSG:4258');
      const extent = [4.1, 57.9, 31.7, 72]; // minimun extent / Norway's bbox
      // console.log('extent', extent);
      const c = olProjTransform(v.getCenter() || [0, 0], epsg, 'EPSG:4258');
      const cp = { lon: c[0], lat: c[1] }; // in case pickCenterPoint fails
      const cp_cartesian = olcsCore.pickCenterPoint(scene); // Cartesian3
      if (cp_cartesian) {
        const cp_radians = Cesium.Cartographic.fromCartesian(cp_cartesian);
        cp.lon = (cp_radians.longitude * 180) / Math.PI;
        cp.lat = (cp_radians.latitude * 180) / Math.PI;
      }
      // console.log('cp', cp);
      const inside = olExtentContainsXY(extent, cp.lon, cp.lat);
      if (!inside) {
        const camera = scene.camera;
        const outLon = cp.lon;
        const outLat = cp.lat;
        cp.lon = Math.max(extent[0], cp.lon);
        cp.lat = Math.max(extent[1], cp.lat);
        cp.lon = Math.min(extent[2], cp.lon);
        cp.lat = Math.min(extent[3], cp.lat);
        const deltaLon = ((outLon - cp.lon) * Math.PI) / 180;
        const deltaLat = ((outLat - cp.lat) * Math.PI) / 180;
        const cpc = camera.positionCartographic; // Lon Lat Height
        // prettier-ignore
        camera.setView({
          destination: Cesium.Cartesian3.fromRadians(
            cpc.longitude - deltaLon, cpc.latitude - deltaLat, cpc.height
          ),
          orientation: { heading: camera.heading, pitch: camera.pitch }
        });
      }
    };
    // scene.preUpdate.addEventListener(() => console.log('preUpdate'));
    // scene.preRender.addEventListener(() => console.log('preRender'));
    // scene.postUpdate.addEventListener(() => console.log('postUpdate'));
    // scene.postRender.addEventListener(() => console.log('postRender'));
    scene.postRender.addEventListener(limitCamera);

    // https://www.cesium.com/docs/cesiumjs-ref-doc/Globe.html#tileCacheSize
    // The size of the terrain tile cache, expressed as a number of tiles.
    // Any additional tiles beyond this number will be freed,
    // as long as they aren't needed for rendering this frame.
    // A larger number will consume more memory but will show detail faster
    // when, for example, zooming out and then back in.
    // Default Value: 100
    scene.globe.tileCacheSize = 50;

    // viewer.entities.suspendEvents();
    // viewer.entities.removeAll();

    // // find intersection of ray through a pixel and the globe
    // const ray = viewer.camera.getPickRay(windowCoordinates);
    // const intersection = globe.pick(ray, scene);

    // const viewer = this.ol3d.getCesiumViewer();
    // const gotCamera = scene.getCesiumCamera();
    // scene.terrainExaggeration = 2.0;
    // camera.terrainExaggeration = 2;

    this.ol3d.enableAutoRenderLoop();

    // Whenever topic is changed, check if new topic has 3D in its toolList.
    combineLatest({
      id: this._kildenStateService.topic$,
      configs: this._topicsService.topicsConfig$.pipe(shareReplay(1)),
    })
      .pipe(
        rxMap(combined => {
          return combined.configs.find(cfg => cfg.id === combined.id) as TopicConfig;
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe({
        next: (topicConfig: TopicConfig) => {
          if (this.$is3dActive() && !topicConfig.toolList.includes('3D')) {
            this.toggle3dActiveSignal();
          }
        },
      });
  }

  flyCountryExtent = () => {
    const scene = this.ol3d.getCesiumScene();
    // const camera = this.ol3d.getCamera(); // or scene.camera
    // scene.camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(
    //   -7, 56, 30, 74
    // );
    // scene.camera.flyHome(); // did not work well
    scene.camera.flyTo({
      destination: Cesium.Rectangle.fromDegrees(-7, 56, 30, 74),
    });
  };

  smooth3dTransition = () => {
    const activating3d = this.$is3dActive();
    const scene = this.ol3d?.getCesiumScene();
    // camera.setHeading(0.0);

    if (activating3d) {
      // Show the popover with intro to 3D controls
      this.showIntroPopover = true;
      this.dismissPopoverPercentageRemaining = 100;
      this.dismissPopoverTimer$.subscribe();

      // console.log('Activating 3D');
      // 'EPSG:3857', web mercator to make WMTS work
      // 2D -> 3D transition (try to open the same extent):
      this.adapt3dExtent();
      // Rotate back to north-south:
      this.initialTilt(Math.PI / 4);
      // scene.camera.zoomOut(); // zoomOut(value)
      this.toggle3d(activating3d);
    } else {
      this.showIntroPopover = false;
      // console.log('Deactivating 3D');
      // const view3d = this.ol3d.getOlView();
      // const cp = view3d.getCenter();
      const cp = olcsCore.pickBottomPoint(scene);
      // const cp = olcsCore.pickCenterPoint(scene);
      cp.z = scene.camera.position.z * 1.001;
      const target = cp ? cp : scene.camera.position;
      // camera.rotateDown/up
      // view3d.animate({ rotation: 0 }, (completed) => {
      //    switch3d(epsg2d);
      // });
      // setView and flyTo do not exist on this.ol3d.getCamera()
      scene.camera.flyTo({
        destination: target,
        // destination: scene.camera.position,
        complete: () => {
          this.toggle3d(activating3d);
          // 3D -> 2D transition (try to open the same extent):
          this.adapt2dExtent();
          // this.ms.getCurrentBackgroundLayer().getSource().refresh();
          setTimeout(() => {
            const map = this._mapService.getMap();
            map.getOverlays().forEach(o => {
              map.removeOverlay(o);
              map.addOverlay(o);
            });
          }, 200); // fixes overlays position coming back from 3D
        },
      });
      // scene.camera.zoomIn(); // zoomOut(value)
      // Rotate back to north-south:
      this._mapService.getMap().getView().animate({ rotation: 0 });
    }
  };

  toggle3dActiveSignal(): void {
    this._mapService.$is3dActive.set(!this._mapService.$is3dActive());
  }

  private adapt2dExtent = () => {
    if (!this.ol3d) return;
    const scene = this.ol3d.getCesiumScene();
    if (!scene) return;

    // const camera = this.ol3d.getCamera(); // or scene.camera
    const epsg2d = this._mapService.getMap().getView().getProjection().getCode();
    const e3d = scene.camera.computeViewRectangle(); // radians
    const west = Cesium.Math.toDegrees(e3d.west);
    const south = Cesium.Math.toDegrees(e3d.south);
    const east = Cesium.Math.toDegrees(e3d.east);
    const north = Cesium.Math.toDegrees(e3d.north);
    const width = east - west;
    const w = 0.1 * width;
    const height = north - south;
    const h = 0.1 * height;
    const e = [west + w, south + h, east - w, north - h];
    const e2d = olProjTransformExtent(e, 'EPSG:4258', epsg2d);
    this._mapService.getMap().getView().fit(e2d);
  };

  private adapt3dExtent = () => {
    if (!this.ol3d) return;
    const scene = this.ol3d.getCesiumScene();
    if (!scene) return;

    // const camera = this.ol3d.getCamera(); // or scene.camera
    const epsg2d = this._mapService.getMap().getView().getProjection().getCode();
    const e2d = this._mapService.getMap().getView().calculateExtent();
    const e = olProjTransformExtent(e2d, epsg2d, 'EPSG:4258');
    const width = e[2] - e[0];
    const w = 0.1 * width;
    const height = e[3] - e[1];
    const h = 0.1 * height;
    // prettier-ignore
    scene.camera.setView({
      // scene.camera.flyTo({
      destination: Cesium.Rectangle.fromDegrees(
        e[0] + w, e[1] + h, e[2] - w, e[3] - h
      )
    });
  };

  private initialTilt = (angle: Number) => {
    if (!this.ol3d) return;
    const scene = this.ol3d.getCesiumScene();
    if (!scene) return;

    // const camera = this.ol3d.getCamera(); // or scene.camera
    const camera = scene.camera;
    const bottom = olcsCore.pickBottomPoint(scene);
    const transform = Cesium.Matrix4.fromTranslation(bottom);
    const axis = camera.right;
    this.watchDistance();
    olcsCore.rotateAroundAxis(camera, -angle, axis, transform, {
      // callback: () => { watchDistance(); }
    });
  };

  private toggle3d = (activating3d: boolean) => {
    if (!this.ol3d) return;
    this.ol3d.setEnabled(activating3d);

    // olcs seems to hide OlMapControls such as Zoom, we need them visible
    const olcsContainerDiv = document.querySelector('.ol-overlaycontainer-stopevent') as HTMLElement;
    if (olcsContainerDiv) olcsContainerDiv.style.zIndex = '1';

    const epsg2d = this._mapService.getMap().getView().getProjection().getCode();
    const oldEpsg = !activating3d ? 'EPSG:3857' : epsg2d;
    const nextEpsg = activating3d ? 'EPSG:3857' : epsg2d;
    this._mapService.changeLayersProjection(oldEpsg, nextEpsg, activating3d ? +1 : -1);
    this._kildenStateService.changeEpsg(nextEpsg);
  };

  private watchDistance = () => {
    const scene = this.ol3d.getCesiumScene();
    // const camera = this.ol3d.getCamera(); // or scene.camera
    // prettier-ignore
    const d = Cesium.Cartesian3.distance(
      scene.camera.position, olcsCore.pickCenterPoint(scene)
    );
    // console.log('distance', d);
    const max_d = 1.0 * Math.pow(10, 6);
    if (d > max_d) {
      // console.log('move closer');
      scene.camera.moveDown(200000);
      scene.camera.moveForward(d - max_d);
    }
  };
}
