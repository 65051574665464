import { Injectable } from '@angular/core';
import { AppConfig } from '@kildenconfig/app.config';
import { PrintConfig } from '@kildenconfig/print-config';
import { BaseSerializer } from '@kildencore/serializer/base-serializer';
import { MapFishPrintV3TiledWMSSerializer } from '@kildencore/serializer/map-fish-print-v3-tiled-wmsserializer';
import { MapFishPrintV3WMSSerializer } from '@kildencore/serializer/map-fish-print-v3-wmsserializer';
import { MapFishPrintV3WMTSSerializer } from '@kildencore/serializer/map-fish-print-v3-wmtsserializer';
import { MapFishPrintVectorSerializer } from '@kildencore/serializer/map-fish-print-vector-serializer';
import { MapPrintService } from '@kildencore/services/map-print.service';
import { MapService } from '@kildencore/services/map.service';
import { ThemeLayersService } from '@kildencore/services/theme-layers.service';
import { PrintTools } from '@kildencore/tools';
import { Layer } from 'ol/layer';
import OlLayer from 'ol/layer/Layer';
import OlSourceImageWMS from 'ol/source/ImageWMS';
import OlSourceTileWMS from 'ol/source/TileWMS';
import OlSourceWMTS from 'ol/source/WMTS';

@Injectable({
  providedIn: 'root',
})
export class SpecPrintService {
  map = this._mapService.getMap();

  /**
   * A filter function that will be called before the print call. Should
   * return a Boolean whether to serialize a layer for print or not.
   *
   * @type {(layer: OlLayer) => boolean}
   */
  layerFilter = (layer: OlLayer) => true;

  /**
   * A filter function that will be called before the print call. Should
   * return a Boolean whether to serialize a legend of a layer for print or not.
   *
   * @type {(layer: OlLayer) => boolean}
   */
  legendFilter = (layer: OlLayer) => true;

  /**
   * The layer serializers to use. May be overridden or extended to obtain
   * custom functionality.
   *
   * @type {BaseSerializer[]}
   */
  serializers = [
    new MapFishPrintV3TiledWMSSerializer(),
    new MapFishPrintV3WMSSerializer(),
    new MapFishPrintV3WMTSSerializer(),
    new MapFishPrintVectorSerializer(),
  ];

  /**
   * The layer to show the actual print extent on. If not provided, a default
   * one will be created.
   */
  extentLayer = null;

  constructor(
    private readonly _mapPrintService: MapPrintService,
    private readonly _mapService: MapService,
    private readonly _themeLayersService: ThemeLayersService
  ) {}

  /**
   * Collects the payload that is required for the print call to the print
   * servlet.
   *
   * @return {Object} The print payload.
   */
  public getPrintPayload(printFormValues: any) {
    const mapProjection = this._mapService.getCode();
    const mapLayers = PrintTools.getMapLayers(this.map);

    // Sort by Layer Z-index
    mapLayers.sort((a, b) => b.getZIndex()! - a.getZIndex()!);

    const serializedLayers = mapLayers.filter(this.filterPrintableLayer.bind(this)).reduce((acc: any, layer: Layer) => {
      let serializedLayer;

      // Exception: Substitute the WFS with WMS layer for printing roads
      if (layer.getProperties()['id'] === AppConfig.IDBOD_FOREST_ROADS_WFS) {
        const substituteLayer = this._themeLayersService.findActiveLayer(AppConfig.IDBOD_FOREST_ROADS_BASIC);
        if (substituteLayer) {
          const originalVisible = substituteLayer.getVisible();
          const originalZindex = substituteLayer.getZIndex();
          const wfsZindex = layer.getZIndex() || AppConfig.ZINDEX_THEMELAYERS;

          // Temp adjustments for print
          substituteLayer.setZIndex(wfsZindex);
          substituteLayer.setVisible(true);
          serializedLayer = this.serializeLayer(substituteLayer);

          // Restore temp adjustments
          substituteLayer.setVisible(originalVisible);
          substituteLayer.setZIndex(originalZindex || wfsZindex);
        }
      } else {
        // Default serialization of the layer to be printed, finds correct serializer based on layer type.
        serializedLayer = this.serializeLayer(layer);
      }

      if (serializedLayer) {
        acc.push(serializedLayer);
      }
      return acc;
    }, []);

    // special handler for NIBIO mapfish
    const classPackageLegends = printFormValues.printLegend
      ? {
          classes: mapLayers.filter(this.filterPrintableLegend.bind(this)).reduce((acc: any, layer: any) => {
            const serializedLegend = this.serializeLegend(layer);
            if (serializedLegend) {
              acc.push(serializedLegend);
            }
            return acc;
          }, []),
        }
      : {};

    return {
      layout: printFormValues.pageLayout,
      attributes: {
        map: {
          center: this._mapPrintService.getCenterOfBoxExtent(),
          dpi: PrintConfig.DPI,
          layers: serializedLayers,
          projection: mapProjection,
          rotation: 0,
          scale: printFormValues.scale,
        },
        legend: classPackageLegends,
        title: printFormValues.title,
        coordsys: 'UTM ' + mapProjection.slice(-2),
      },
    };
  }

  /**
   * Serializes/encodes the given layer.
   *
   * @param {OlLayer} layer The layer to serialize/encode.
   *
   * @return {Object} The serialized/encoded layer.
   */
  serializeLayer(layer: any) {
    const viewResolution = this.map.getView().getResolution();
    const layerSource = layer.getSource();
    const serializer = this.serializers.find((serializer: any) => {
      return serializer.canSerialize(layerSource);
    });

    if (serializer) {
      return serializer.serialize(
        layer,
        layer.get('customPrintSerializerOpts'), // The key in the layer properties to lookup for custom serializer options.
        viewResolution
      );
    } else {
      console.info(
        'No suitable serializer for this layer/source found. ' +
          'Please check the input layer or provide an own serializer capabale ' +
          'of serializing the given layer/source to the manager. Layer ' +
          'candidate is: ',
        layer
      );
      return undefined;
    }
  }

  /**
   * Serializes/encodes the legend payload for the given layer.
   *
   * @param {OlLayer} layer The layer to serialize/encode the legend for.
   *
   * @return {Object} The serialized/encoded legend.
   */
  serializeLegend(layer: any) {
    const source = layer.getSource();
    const hasLegend = layer.getSource()?.getProperties()?.config?.hasLegend;
    if (
      (source instanceof OlSourceTileWMS || source instanceof OlSourceImageWMS || source instanceof OlSourceWMTS) &&
      hasLegend && // check propertiy from config/db
      PrintTools.getLegendGraphicUrl(layer)
    ) {
      return {
        name: layer.get('name') || (!(source instanceof OlSourceWMTS) && source.getParams().LAYERS) || '',
        icons: [PrintTools.getLegendGraphicUrl(layer)],
      };
    } else {
      console.info('No suitable instance for this legend. Candidate is: ', layer);
      return undefined;
    }
  }

  /**
   * Checks if a given layer should be printed.
   *
   * @param {OlLayer} layer The layer to check.
   *
   * @return {boolean} Whether the layer should be printed or not.
   */
  filterPrintableLayer(layer: any) {
    return (
      layer !== this._mapPrintService.getPrintBox() &&
      layer.getVisible() &&
      PrintTools.isLayerInsideResoultion(layer, this.map) &&
      this.layerFilter(layer)
    );
  }

  /**
   * Checks if the legend of a given legend should be printed.
   *
   * @param {OlLayer} layer The layer to check.
   *
   * @return {boolean} Whether the legend of the layer should be printed or not.
   */
  filterPrintableLegend(layer: any) {
    return (
      layer !== this._mapPrintService.getPrintBox() &&
      layer.getVisible() &&
      PrintTools.isLayerInsideResoultion(layer, this.map) &&
      this.legendFilter(layer)
    );
  }
}
