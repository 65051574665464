import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import '@angular/common/locales/global/nb';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NonNullableFormBuilder } from '@angular/forms';
import { DateRange, ExtractDateTypeFromSelection } from '@angular/material/datepicker';
import { ApiConfig } from '@kildenconfig/api-config';
import { AppConfig } from '@kildenconfig/app.config';
import { DateHelper } from '@kildencore/helpers/date.helper';
import { LayersConfigService } from '@kildencore/services/data/layers-config.service';
import { KildenStateService } from '@kildencore/services/kilden-state.service';
import { MapService } from '@kildencore/services/map.service';
import { allowedQueryParams, PermaLinkService } from '@kildencore/services/perma-link.service';
import { ThemeLayersService } from '@kildencore/services/theme-layers.service';
import { DeviceTypesEnum } from '@kildenshared/constants/device-types.enum';
import { LayerStylesConst } from '@kildenshared/constants/styles/layer-styles.const';
import { ApiResponseFeatureCollectionInterface } from '@kildenshared/interfaces/api-response-feature-collection.interface';
import { WildlifeApiDataListInterface } from '@kildenshared/interfaces/wildlife-api-data-list.interface';
import { WildlifeApiPostAnimalsInterface } from '@kildenshared/interfaces/wildlife-api-post-animals.interface';
import { Feature, Map as OlMap } from 'ol';
import OlFeature from 'ol/Feature';
import OlFormatGeoJSON from 'ol/format/GeoJSON';
import { Geometry, LineString, Point } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { filter, finalize, ReplaySubject, Subject, take, takeUntil, tap } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'kilden3-search-wildlife',
  templateUrl: './search-wildlife.component.html',
  styleUrls: ['./search-wildlife.component.css'],
})
export class SearchWildlifeComponent implements OnDestroy, OnInit {
  readonly VALUE_ALL = 'Alle';

  allIndividuals: string[] = [];
  dataIndividuals: string[] = [];
  dataProjects: string[] = [];
  dataQuantities: string[] = [];
  dateToday: Date = new Date();
  fetchingAnimals: boolean = false;
  formFeedback: string = '';
  frm!: FormGroup<WildlifeForm>;
  individualsByProject = new Map<string, string[]>();
  @ViewChild('individualsRef')
  individualsRef!: ElementRef<HTMLSelectElement>;
  isPeriodActive = true;
  maxDate!: Date;

  private readonly _onDestroy$ = new Subject<void>();
  private readonly _searchAnimals = new ReplaySubject<WildlifeApiPostAnimalsInterface>(1);

  private _dateYesterday!: Date;
  private _deviceType!: DeviceTypesEnum;
  private _map!: OlMap;
  private _searchParams: WildlifeApiPostAnimalsInterface = {
    projects: [this.VALUE_ALL],
    individs: [this.VALUE_ALL],
    size: 'Siste registrerte',
    bbox: [],
    maxnum: 5000,
    srid: 25833,
  };

  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _kildenStateService: KildenStateService,
    private readonly _layerConfigService: LayersConfigService,
    private readonly _mapService: MapService,
    private readonly _nfb: NonNullableFormBuilder,
    private readonly _permaLinkService: PermaLinkService,
    private readonly _themeLayersService: ThemeLayersService
  ) {}

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  ngOnInit(): void {
    this._dateYesterday = DateHelper.subtractDaysFromDate(this.dateToday, 1);
    DateHelper.setTimeStartOfDay(this._dateYesterday);
    DateHelper.setTimeEndOfDay(this.dateToday);
    this.maxDate = this.dateToday;
    this._map = this._mapService.getMap();

    this._loadParamsFromPermaLink();
    this._buildForm();
    this._fetchWildlifeDataLists();
    this._setupSubscriptions();
  }

  dateStartPicked(utcDate: ExtractDateTypeFromSelection<DateRange<Date>> | null): void {
    if (!utcDate) {
      return;
    }

    const formattedFromUTC = new Date(utcDate + ' UTC');
    this.frm.controls.periodStart.setValue(formattedFromUTC);
    this._validateDateRange();
  }

  dateEndPicked(utcDate: ExtractDateTypeFromSelection<DateRange<Date>> | null): void {
    if (!utcDate) {
      return;
    }

    const formattedFromUTC = new Date(utcDate + ' UTC');
    this.frm.controls.periodEnd.setValue(formattedFromUTC);
    this._validateDateRange();
  }

  projectSelected(): void {
    this.dataIndividuals = [];
    if (this.frm.get('projects')?.value.includes(this.VALUE_ALL)) {
      this.dataIndividuals = this.allIndividuals;
    } else {
      this.dataIndividuals = [this.VALUE_ALL];
      this.frm.get('projects')?.value.forEach(selectedProject => {
        const individs = this.individualsByProject.get(selectedProject);
        if (individs?.length) {
          this.dataIndividuals.push(...individs);
        }
      });
    }
    this.frm.get('individuals')?.setValue([this.VALUE_ALL]);
    this.individualsRef.nativeElement.scrollTo({ top: 0 });
  }

  search(): void {
    // Remove old results
    this._clearWildlifeMapFeatures();

    // Generate payload for backend
    const startDate = this.frm.get('periodStart')?.value || this._dateYesterday;
    const endDate = this.frm.get('periodEnd')?.value || this.dateToday;
    DateHelper.setTimeStartOfDay(startDate);
    DateHelper.setTimeEndOfDay(endDate);

    const requestPayload = this._searchParams;
    requestPayload.individs = this.frm.get('individuals')?.value || [this.VALUE_ALL];
    requestPayload.projects = this.frm.get('projects')?.value || [this.VALUE_ALL];
    requestPayload.size = this.frm.get('quantity')?.value || this.VALUE_ALL;
    requestPayload.startdate = startDate.toISOString();
    requestPayload.enddate = endDate.toISOString();

    // Update permalink
    this._permaLinkService.setParam(
      allowedQueryParams.WILD_ID,
      requestPayload.individs.join(AppConfig.QP_SEPARATOR_SECONDARY)
    );

    this._permaLinkService.setParam(
      allowedQueryParams.WILD_PERIOD,
      [
        formatDate(requestPayload.startdate, 'shortDate', 'nb'),
        formatDate(requestPayload.enddate, 'shortDate', 'nb'),
      ].join(AppConfig.QP_SEPARATOR_SECONDARY)
    );

    this._permaLinkService.setParam(
      allowedQueryParams.WILD_PROJECT,
      requestPayload.projects.join(AppConfig.QP_SEPARATOR_SECONDARY)
    );

    this._permaLinkService.setParamReplaceHistory(allowedQueryParams.WILD_QUANTITY, requestPayload.size);

    // Fire search
    this._fetchWildlifeAnimals(requestPayload);
  }

  private _addMapMarkers(jsonFeatures: object): void {
    const features = new OlFormatGeoJSON().readFeatures(jsonFeatures);

    this.formFeedback = '';
    if (!features?.length) {
      Swal.fire({
        title: 'Ingen treff',
        text: 'Fant ingen registreringer i dette søket.',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
      return;
    }
    if (features.length >= 5000) {
      this.formFeedback = 'Flere enn 5000 punkter funnet, henter de 5000 nyeste. Det kan ta litt tid.';
    }

    // Get the existing layers from TLS
    const pointsLayer = this._themeLayersService.findBankLayer(AppConfig.WILDLIFE_POINTSLAYER_ID);
    const tracksLayer = this._themeLayersService.findBankLayer(AppConfig.WILDLIFE_TRACKSLAYER_ID);
    if (!pointsLayer || !(pointsLayer instanceof VectorLayer)) {
      return;
    }

    // Clear old and add new features, set styles
    const pointsSrc = pointsLayer.getSource();
    if (pointsSrc) {
      pointsSrc.clear();
      pointsSrc.addFeatures(features);
      pointsLayer.setStyle(LayerStylesConst[AppConfig.WILDLIFE_POINTSLAYER_ID] || []);

      // Zoom to result extents
      let padAmount = AppConfig.ZOOM_PADDING_MOBILE;
      if (this._deviceType === DeviceTypesEnum.DESKTOP) {
        padAmount = AppConfig.ZOOM_PADDING_DESKTOP;
      }
      this._map.getView().fit(pointsSrc.getExtent(), {
        duration: 350,
        padding: padAmount,
      });
    }

    // Render tracksLayer
    if (!tracksLayer || !(tracksLayer instanceof VectorLayer)) {
      return;
    }
    this._createWildTracks(features, tracksLayer);
  }

  private _buildForm(): void {
    const startDate = this._searchParams.startdate?.length ? new Date(this._searchParams.startdate) : undefined;
    const endDate = this._searchParams.enddate?.length ? new Date(this._searchParams.enddate) : undefined;

    this.frm = this._nfb.group<WildlifeForm>({
      individuals: this._nfb.control(this._searchParams.individs),
      periodStart: this._nfb.control(startDate || this._dateYesterday),
      periodEnd: this._nfb.control(endDate || this.dateToday),
      projects: this._nfb.control(this._searchParams.projects),
      quantity: this._nfb.control(this._searchParams.size),
    });

    this._handlePeriodActive();

    this.frm.controls.quantity.valueChanges
      .pipe(
        tap(val => {
          this._handlePeriodActive();
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();
  }

  private _clearWildlifeMapFeatures(): void {
    // Get the existing layers from TLS
    const pointsLayer = this._themeLayersService.findBankLayer(AppConfig.WILDLIFE_POINTSLAYER_ID);
    if (pointsLayer instanceof VectorLayer) {
      pointsLayer.getSource()?.clear();
    }

    const tracksLayer = this._themeLayersService.findBankLayer(AppConfig.WILDLIFE_TRACKSLAYER_ID);

    if (tracksLayer instanceof VectorLayer) {
      tracksLayer.getSource()?.clear();
    }
  }

  private _createWildTracks(features: Feature<Geometry>[], tracksLayer: VectorLayer<VectorSource>): void {
    const ids: string[] = [];
    const colors: string[] = [];

    ids[0] = features[0].getProperties()['collar_id'];
    colors[0] = features[0].getProperties()['symbol_color'];

    const pointCoordinates: any[][] = [[]];

    features.forEach(feature => {
      const pointFeat = feature as Feature<Point>;
      const pointGeo = pointFeat.getGeometry();
      const pointCrd = pointGeo?.getCoordinates();

      if (!pointFeat) {
        return;
      }

      const id = pointFeat.getProperties()['collar_id'];
      const idx = ids.indexOf(id);
      if (pointGeo && pointCrd) {
        if (idx !== -1) {
          pointCoordinates[idx].push(pointCrd);
        } else {
          ids.push(pointFeat.getProperties()['collar_id']);
          colors.push(pointFeat.getProperties()['symbol_color']);
          pointCoordinates[ids.length - 1] = [];
          pointCoordinates[ids.length - 1].push(pointCrd);
        }
      }
    });

    const lineFeatures: OlFeature[] = [];
    pointCoordinates.forEach(function (lineCoords, index) {
      const viltline = new LineString(lineCoords);

      const viltLineFeature = new OlFeature({
        geometry: viltline,
        color: colors[index],
      });
      lineFeatures.push(viltLineFeature);
    });

    if (tracksLayer) {
      tracksLayer.getSource()?.clear();
      tracksLayer.getSource()?.addFeatures(lineFeatures);
      tracksLayer.setStyle(LayerStylesConst[AppConfig.WILDLIFE_TRACKSLAYER_ID] || []);
    }
  }

  private _fetchWildlifeAnimals(payload: WildlifeApiPostAnimalsInterface): void {
    const epsg = this._map.getView().getProjection().getCode().split(':');
    payload.srid = this._searchParams.srid = parseInt(epsg[1]);

    // Beware that both startdate and enddate are ignored by backend if quantity aka. size == 'Siste registrerte'
    if (!payload.startdate) {
      payload.startdate = this._dateYesterday.toISOString();
    }
    if (!payload.enddate) {
      payload.enddate = this.dateToday.toISOString();
    }

    this._searchAnimals.next(payload);
  }

  private _fetchWildlifeDataLists(): void {
    this._httpClient
      .get<WildlifeApiDataListInterface>(ApiConfig.wildlifeDataListUrl)
      .pipe(
        tap(response => {
          this.dataProjects = response.markprojects;
          this.dataQuantities = response.mengder;

          const individuals: string[] = [];
          this.individualsByProject.clear();

          // Syntax of response.idNavn is: "IndividID ProjectID"
          response.idNavn.forEach(idn => {
            const segments = idn.split(' ');
            const id = segments[0];
            const proj = segments[1];
            individuals.push(segments[0]);

            if (!proj) {
              return;
            }

            if (!this.individualsByProject.has(proj)) {
              this.individualsByProject.set(proj, [id]);
            } else {
              const existing = this.individualsByProject.get(proj);
              if (existing) {
                existing.push(id);
              }
            }
          });

          this.allIndividuals = this.dataIndividuals = individuals;
        }),
        take(1)
      )
      .subscribe();
  }

  private _handlePeriodActive(): void {
    this._setIsPeriodActive();

    if (this.isPeriodActive) {
      this.frm.controls.periodStart.enable();
      this.frm.controls.periodEnd.enable();
    } else {
      this.frm.controls.periodStart.disable();
      this.frm.controls.periodEnd.disable();
    }
  }

  private _loadParamsFromPermaLink(): void {
    const id = this._permaLinkService.getInitialValue(allowedQueryParams.WILD_ID);
    if (id !== undefined && typeof id === 'string') {
      this._searchParams.individs = id.split(AppConfig.QP_SEPARATOR_SECONDARY);
    }

    const period = this._permaLinkService.getInitialValue(allowedQueryParams.WILD_PERIOD);
    if (period !== undefined && typeof period === 'string' && period.length > 0) {
      const segments = period.split(AppConfig.QP_SEPARATOR_SECONDARY);
      if (segments.length === 2) {
        const startDate = DateHelper.parseNorwegianDate(segments[0]);
        DateHelper.setTimeStartOfDay(startDate);
        this._searchParams.startdate = startDate.toISOString();

        const endDate = DateHelper.parseNorwegianDate(segments[1]);
        DateHelper.setTimeEndOfDay(endDate);
        this._searchParams.enddate = endDate.toISOString();
      }
    }

    const project = this._permaLinkService.getInitialValue(allowedQueryParams.WILD_PROJECT);
    if (project !== undefined && typeof project === 'string') {
      this._searchParams.projects = project.split(AppConfig.QP_SEPARATOR_SECONDARY);
    }

    const quantity = this._permaLinkService.getInitialValue(allowedQueryParams.WILD_QUANTITY);
    if (quantity !== undefined && typeof quantity === 'string') {
      this._searchParams.size = quantity;
    }
  }

  /**
   * This is helper to increase efficiency and avoid using a method in the view.
   * This will allow us to write the value only when needed,
   * the rest of the time the already calculated variable value will be returned.
   */
  private _setIsPeriodActive(): void {
    this.isPeriodActive = this.frm.controls.quantity.value !== 'Siste registrerte';
  }

  private _setupSubscriptions(): void {
    this._kildenStateService.deviceType$
      .pipe(
        tap(type => {
          this._deviceType = type;
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();

    this._searchAnimals
      .pipe(
        tap(() => {
          this.fetchingAnimals = true;
        }),
        switchMap(payload => {
          return this._httpClient
            .post<ApiResponseFeatureCollectionInterface>(ApiConfig.wildlifeAnimalsUrl, payload)
            .pipe(
              filter(response => response.type === 'FeatureCollection'),
              tap(response => {
                this._addMapMarkers(response);
              }),
              finalize(() => {
                this.fetchingAnimals = false;
                setTimeout(() => {
                  this.formFeedback = '';
                }, 5000);
              }),
              take(1)
            );
        }),
        finalize(() => {
          this.fetchingAnimals = false;
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();

    this._layerConfigService.layerConfigs$
      .pipe(
        tap(layerConfigs => {
          if (layerConfigs.hasOwnProperty(AppConfig.WILDLIFE_POINTSLAYER_ID)) {
            this._fetchWildlifeAnimals(this._searchParams);
          }
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();
  }

  private _validateDateRange(): void {
    const startDate = this.frm.controls.periodStart.value;
    const endDate = this.frm.controls.periodEnd.value;
    if (!startDate || !endDate || endDate < startDate) {
      this.frm.setErrors({ date_range_invalid: true });
    }
  }
}

interface WildlifeForm {
  individuals: FormControl<string[]>;
  periodEnd: FormControl<Date>;
  periodStart: FormControl<Date>;
  projects: FormControl<string[]>;
  quantity: FormControl<string>;
}
