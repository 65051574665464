<form [formGroup]="stateForm" class="search-form">
  <div class="input-wrapper">
    <input
      (keydown)="customStopPropagation($event)"
      (input)="processSearchSubject$.next(stateForm.value)"
      [matAutocomplete]="autoGroup"
      [title]="currentTitle"
      id="search-input-field"
      class="search-input"
      type="text"
      matInput
      formControlName="searchTerm"
      required
      placeholder="Søk i kartet" />
    <button
      *ngIf="stateForm.get('searchTerm')?.value"
      (click)="clearSearch()"
      (keydown.enter)="clearSearch()"
      class="cancel-btn"
      disableRipple
      matSuffix
      mat-icon-button
      aria-label="Clear">
      <mat-icon class="cancel-icon">close</mat-icon>
    </button>
  </div>
  <mat-autocomplete
    #autoGroup="matAutocomplete"
    (optionSelected)="onChange($event.option.value)"
    [displayWith]="displayFn">
    <!-- <div class="option-group-label-background"></div> -->
    <mat-optgroup *ngFor="let group of stateGroupOptions | async">
      <mat-icon
        fontSet="material-icons-outlined"
        *ngIf="group.category === 'Fylke/kommune'"
        aria-hidden="false"
        aria-label="Fylke/kommune ikon">
        language
      </mat-icon>
      <mat-icon
        fontSet="material-icons-outlined"
        *ngIf="group.category === 'Stedsnavn'"
        aria-hidden="false"
        aria-label="Stedsnavn ikon">
        push_pin
      </mat-icon>
      <mat-icon
        fontSet="material-icons-outlined"
        *ngIf="group.category === 'Adresse/matrikkel'"
        aria-hidden="false"
        aria-label="Adresse/matrikkel ikon">
        home
      </mat-icon>
      <mat-icon
        fontSet="material-icons-outlined"
        *ngIf="group.category === 'Reinbeitedistrikt'"
        aria-hidden="false"
        aria-label="Reinbeitedistrikt ikon">
        map
      </mat-icon>
      <span class="option-group-category">{{ group.category }}</span>
      <mat-option
        *ngFor="let data of group.data"
        [value]="data"
        [title]="data.title"
        [ngClass]="group.category === 'Adresse/matrikkel' ? 'category-property' : ''">
        <button
          *ngIf="group.category === 'Adresse/matrikkel'"
          (click)="getProperties(data)"
          class="agri-btn"
          title="Søk på landbrukseiendom">
          <mat-icon aria-hidden="false" aria-label="Adresse/matrikkel ikon"> agriculture </mat-icon>
        </button>
        {{ data.title }}
      </mat-option>
    </mat-optgroup>
  </mat-autocomplete>
</form>
