import { FeatureLike } from 'ol/Feature';
import { Stroke } from 'ol/style';
import Fill from 'ol/style/Fill';
import Style, { StyleFunction } from 'ol/style/Style';
import Text from 'ol/style/Text';

export const VectorHoverStyle: StyleFunction = (feature: FeatureLike, resolution: number): Style | Style[] => {
  const origin = feature.getProperties()['origin'];

  if (origin === 'skogsbilveg' || origin === 'traktorveg_sti') {
    const veiref = feature.getProperties()['vegnummer'] + '/' + feature.getProperties()['strekningnummer'];
    var bufferMouseover = new Style({
      stroke: new Stroke({
        color: [255, 255, 255, 0.01],
        width: 50,
      }),
    });
    return [
      bufferMouseover,
      new Style({
        stroke: new Stroke({
          color: '#00F',
          width: 6,
        }),
        text: new Text({
          font: '20px helvetica,sans-serif',
          text: veiref,
          //rotation: 360 * rnd * Math.PI / 180,
          fill: new Fill({
            color: '#000',
          }),
          stroke: new Stroke({
            color: '#FFF',
            width: 8,
          }),
        }),
      }),
    ];
  } else {
    return [];
  }
};
