<button
  #tm="matMenuTrigger"
  (keyup.enter)="tm.openMenu()"
  [matMenuTriggerFor]="infomenu"
  aria-label="Åpne informasjonsmeny"
  class="show-mobile mat-menu-item navigation-style">
  <mat-icon class="black-icon" fontSet="material-icons-outlined">announcement</mat-icon>
</button>

<button
  #t="matMenuTrigger"
  (keyup.enter)="t.openMenu()"
  [class.opened]="t.menuOpen"
  [matMenuTriggerFor]="infomenu"
  aria-label="Åpne informasjonsmeny"
  class="white-icon infoButton tools-btn">
  <mat-icon class="icon-info" fontSet="material-icons-outlined">announcement</mat-icon>
  Informasjon
</button>

<mat-menu class="custom_mat_menu" #infomenu="matMenu">
  <a
    *ngFor="let link of this.sortedLinks"
    mat-menu-item
    class="button"
    draggable="false"
    href="{{ link.link }}"
    target="_blank">
    <mat-icon>navigate_next</mat-icon>
    <span>{{ link.tekst }}</span>
  </a>
</mat-menu>
