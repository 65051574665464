import { CdkDrag, CdkDragMove } from '@angular/cdk/drag-drop';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AppConfig } from '@kildenconfig/app.config';
import { KildenStateService } from '@kildencore/services/kilden-state.service';
import { MapService } from '@kildencore/services/map.service';
import { allowedQueryParams, PermaLinkService } from '@kildencore/services/perma-link.service';
import { ThemeLayersService } from '@kildencore/services/theme-layers.service';
import { Map } from 'ol';
import { EventsKey } from 'ol/events';
import ImageLayer from 'ol/layer/Image';
import VectorLayer from 'ol/layer/Vector';
import { unByKey } from 'ol/Observable';
import { getRenderPixel } from 'ol/render';
import RenderEvent from 'ol/render/Event';
import { Size } from 'ol/size';
import ImageWMS from 'ol/source/ImageWMS';
import VectorSource from 'ol/source/Vector';
import { skip, Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'kilden3-layer-swipe',
  templateUrl: './layer-swipe.component.html',
  styleUrls: ['./layer-swipe.component.css'],
})
export class LayerSwipeComponent implements OnDestroy, OnInit {
  static readonly DEFAULT_RATIO = '0.5';
  dragLineRef!: ElementRef<HTMLDivElement>;
  dragRef!: CdkDrag<HTMLDivElement>;
  swipeLabel: string | undefined;
  swipeLayer: ImageLayer<ImageWMS> | VectorLayer<VectorSource> | undefined;

  private readonly _onDestroy$ = new Subject<void>();

  private _layerListenerKeys: EventsKey[] = [];
  private _map!: Map;
  private _mapSize: Size | undefined;
  private _mapWidth: number | undefined;
  private _swipeRatio: string = LayerSwipeComponent.DEFAULT_RATIO;

  constructor(
    private readonly _kildenStateService: KildenStateService,
    private readonly _mapService: MapService,
    private readonly _permaLinkService: PermaLinkService,
    private readonly _themeLayersService: ThemeLayersService
  ) {}

  get map(): Map {
    if (!this._map) {
      this._map = this._mapService.getMap();
    }

    return this._map;
  }

  @ViewChild('dragLineRef', { static: true, read: ElementRef })
  set viewChildDragLineRef(ref: ElementRef) {
    if (ref) {
      this.dragLineRef = ref;
    }
  }

  @ViewChild('dragRef', { static: true, read: CdkDrag })
  set viewChildDragRef(ref: CdkDrag) {
    if (ref) {
      this.dragRef = ref;
    }
  }

  /**
   * Ignoring event param here since we want the position of the .line element, not other draggables like .arrows
   */
  dragMove(event: CdkDragMove): void {
    let leftPos = this.dragLineRef.nativeElement.getBoundingClientRect().x;
    const bodyElement = document.querySelector('body');
    if (bodyElement?.classList.contains('sidenav-open')) {
      // x offset includes with of sidenav, subtract it
      if (leftPos >= AppConfig.SIDENAV_WIDTH_PX) {
        leftPos = leftPos - AppConfig.SIDENAV_WIDTH_PX;
      }
    }

    this._updateDragPosition(leftPos);
  }

  exitLayerSwipe(): void {
    this._clearSwipeLayer();
    this._kildenStateService.changeLayerSwipeActive(false);
  }

  ngOnDestroy(): void {
    this.exitLayerSwipe();
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  ngOnInit() {
    const urlRatio = this._permaLinkService.getInitialValue(allowedQueryParams.COMPARE_RATIO);

    if (urlRatio) {
      // Set ratio from url
      this._swipeRatio = urlRatio.toString();
    } else {
      // Set defaults
      this._permaLinkService.setParamReplaceHistory(allowedQueryParams.COMPARE_RATIO, this._swipeRatio);
    }

    this._setSwipePositionFromRatio();
    this._initSwipeLayer();
    this._setupSubscriptions();
  }

  private _calculateRatio(xPositionLeftOffsetPx: number): string {
    let ratio = xPositionLeftOffsetPx / this._getMapWidth();
    if (ratio < 0) {
      ratio = 0;
    }
    if (ratio > 1) {
      ratio = 1;
    }

    return ratio.toFixed(4);
  }

  private _clearSwipeLayer(): void {
    // Unregister listeners bound to this.swipeLayer before unsetting this.swipeLayer
    this._layerListenerKeys.forEach(key => {
      unByKey(key);
    });
    this._layerListenerKeys = [];

    this.swipeLabel = undefined;
    this.swipeLayer = undefined;
    this.map.render();
  }

  private _getMapWidth(): number {
    this._mapSize = this.map.getSize();
    if (this._map && this._mapSize) {
      this._mapWidth = this._mapSize[0];
    }

    return this._mapWidth || 0;
  }

  private _initSwipeLayer(): void {
    const activeLayers = this._themeLayersService.getActiveThemeLayers();
    if (!activeLayers?.length) {
      return;
    }
    this.swipeLayer = activeLayers[0];
    if (!(this.swipeLayer instanceof ImageLayer)) {
      return;
    }

    const layerCfg = this.swipeLayer.getSource()?.get('config');
    this.swipeLabel = layerCfg['label'] || undefined;

    this._layerListenerKeys = [
      this.swipeLayer.on('prerender', this._preRender.bind(this)),
      this.swipeLayer.on('postrender', this._postRender.bind(this)),
    ];

    this.map.render();
  }

  private _moveDragLine(xPosition: number): void {
    // Make sure the reference to the drag-element has been set
    let timeout = 0;
    if (!this.dragRef) {
      timeout = 25;
    }
    setTimeout(() => {
      // Override the CSS transform CdkDrag sets when dragging
      this.dragRef.element.nativeElement.style.transform = 'none';

      // Set the newly calculated position as left offset
      this.dragRef.element.nativeElement.style.left = xPosition.toFixed(0) + 'px';
    }, timeout);
  }

  private _postRender(event: RenderEvent): void {
    (event.context as CanvasRenderingContext2D).restore();
  }

  private _preRender(event: RenderEvent): void {
    const width = this._getMapWidth() * parseFloat(this._swipeRatio);
    if (!this._mapSize) {
      return;
    }

    const ctx = event.context as CanvasRenderingContext2D;
    const pixelScaled = getRenderPixel(event, [width, 0]);

    ctx.save();
    ctx.beginPath();
    ctx.rect(0, 0, pixelScaled[0], ctx.canvas.height);
    ctx.closePath();
    ctx.clip();
  }

  @HostListener('window:resize')
  private _resetAndInitSwipe() {
    this._mapWidth = undefined;
    this._clearSwipeLayer();
    this._setSwipePositionFromRatio();
    this._initSwipeLayer();
  }

  private _setSwipePositionFromRatio(): void {
    const posX = this._getMapWidth() * parseFloat(this._swipeRatio);
    let roundedXPosition = Math.round(posX);

    // Set default if out of bounds
    if (roundedXPosition > this._getMapWidth() || roundedXPosition < 0) {
      this._swipeRatio = LayerSwipeComponent.DEFAULT_RATIO;
      this._kildenStateService.changeLayerSwipeRatio(this._swipeRatio);
      roundedXPosition = this._getMapWidth() * parseFloat(this._swipeRatio);
    }

    this._moveDragLine(roundedXPosition);
  }

  private _setupSubscriptions(): void {
    this._kildenStateService.deviceOrientation$
      .pipe(
        tap(() => {
          this._resetAndInitSwipe();
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();

    this._kildenStateService.sidenavOpen$
      .pipe(
        tap(() => {
          // Map area changes with sidenav, wait for animations then set updated swipe position
          setTimeout(() => {
            this._setSwipePositionFromRatio();
          }, 1); // Wait for map to finish animation to new size
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();

    this._themeLayersService.layersChange$
      .pipe(
        tap(layerChanges => {
          // Always clear and reinitialize on any relevant layer changes (active/visible). The alternative would be
          // to have lots of manual checks of many possible edge cases, like:
          // Whether the active swipelayer has been changed, or whether another themelayer with a higher zindex
          // than current swipelayer was changed etc
          if (layerChanges.some(lc => lc.change.active !== undefined || lc.change.visible !== undefined)) {
            this._clearSwipeLayer();
            if (this._themeLayersService.getActiveThemeLayers().length) {
              this._initSwipeLayer();
            }
          }
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();

    this._themeLayersService.layersReorder$
      .pipe(
        tap(() => {
          this._clearSwipeLayer();
          this._initSwipeLayer();
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();

    this._themeLayersService.layersReset$
      .pipe(
        skip(1), // skip the initial loading on fetch
        tap(() => {
          this._clearSwipeLayer();
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();
  }

  private _updateDragPosition(xPosition: number): void {
    this._swipeRatio = this._calculateRatio(xPosition);
    this._kildenStateService.changeLayerSwipeRatio(this._swipeRatio);
    this.map.render();
  }
}
