<form [formGroup]="stateForm" class="sonegrense-form">
  <div class="input-wrapper">
    <input
      id="sonegrense-input-field"
      class="sonegrense-input"
      type="text"
      matInput
      formControlName="soneSearchTerm"
      required
      [title]="currentTitle"
      placeholder="Søk kommune eller gårds- og bruksnummer/festenummer"
      [matAutocomplete]="autoGroup"
      (keydown)="customStopPropagation($event)" />
    <button
      *ngIf="stateForm.get('soneSearchTerm')?.value"
      class="cancel-btn"
      disableRipple
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="clearSoneSearch()"
      (keydown.enter)="clearSoneSearch()">
      <mat-icon class="cancel-icon">close</mat-icon>
    </button>
  </div>
  <mat-autocomplete
    #autoGroup="matAutocomplete"
    [displayWith]="displayFn"
    (optionSelected)="onChange($event.option.value)">
    <!-- <div class="option-group-label-background"></div> -->
    <mat-optgroup *ngFor="let group of stateGroupOptions | async">
      <mat-icon
        fontSet="material-icons-outlined"
        *ngIf="group.category === 'Kommune'"
        aria-hidden="false"
        aria-label="Fylke/kommune ikon">
        language
      </mat-icon>

      <mat-icon
        fontSet="material-icons-outlined"
        *ngIf="group.category === 'Adresse/matrikkel'"
        aria-hidden="false"
        aria-label="Adresse/matrikkel ikon">
        home
      </mat-icon>

      <span class="option-group-category">{{ group.category }}</span>
      <mat-option
        *ngFor="let data of group.data"
        [value]="data"
        [title]="data.title"
        [ngClass]="group.category === 'Adresse/matrikkel' ? 'category-property' : ''">
        <button
          class="agri-btn"
          *ngIf="group.category === 'Adresse/matrikkel'"
          (click)="getProperties(data)"
          title="Søk på landbrukseiendom">
          <mat-icon aria-hidden="false" aria-label="Adresse/matrikkel ikon"> agriculture </mat-icon>
        </button>
        {{ data.title }}
      </mat-option>
    </mat-optgroup>
  </mat-autocomplete>

  <div id="soneKommune" *ngIf="soneKommune !== ''">
    Soner for <b> {{ soneKommune }} </b>:
    <div id="soneResult">
      Sone(r) kjøtt: <b> {{ soneKjott }}</b> <BR /> Sone(r) melk:
      <b>{{ soneMelk }}</b>
    </div>
  </div>

  <div class="input-wrapper">
    <input
      id="orgnr-input-field"
      class="orgnr-input"
      type="text"
      matInput
      formControlName="orgnrTerm"
      required
      [title]="currentTitle"
      placeholder="Organisasjonsnummmer (9 siffer)"
      (keydown.enter)="getOrgnummer()"
      (keydown)="customStopPropagation($event)" />
    <button
      *ngIf="stateForm.get('orgnrTerm')?.value"
      class="org-cancel-btn"
      disableRipple
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="clearOrgSearch()"
      (keydown.enter)="clearOrgSearch()">
      <mat-icon class="cancel-icon">close</mat-icon>
    </button>

    <button class="mat-button mat-button-base orgnr-button pull-left" (click)="getOrgnummer()">Søk...</button>
  </div>
</form>
