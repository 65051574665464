import { Component, OnDestroy, OnInit } from '@angular/core';
import { ArrayHelper } from '@kildencore/helpers/array.helper';
import { CatalogTreeService } from '@kildencore/services/data/catalog-tree.service';
import { KildenStateService } from '@kildencore/services/kilden-state.service';
import { ThemeLayersService } from '@kildencore/services/theme-layers.service';
import { CatalogTreeItem } from '@kildenshared/components/catalog-tree/catalog-tree-item';
import { CatalogTreeRoot } from '@kildenshared/components/catalog-tree/catalog-tree-root';
import { LayerChange } from '@kildenshared/interfaces';
import { concatMap, filter, Subject, take, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'kilden3-attributions',
  templateUrl: './attributions.component.html',
  styleUrls: ['./attributions.component.css'],
})
export class AttributionsComponent implements OnInit, OnDestroy {
  attributions = new Map<string, string>();

  private readonly _onDestroy$ = new Subject<void>();

  constructor(
    private readonly _catalogTreeService: CatalogTreeService,
    private readonly _kildenStateService: KildenStateService,
    private readonly _themeLayersService: ThemeLayersService
  ) {}

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  ngOnInit(): void {
    // On change in layers we need to update this.activeLayers
    this._themeLayersService.layersChange$
      .pipe(
        concatMap(layerChanges => {
          // Concat the catalogTree to find the correct node via layerchange.layerid
          return this._catalogTreeService.catalogTree$.pipe(
            filter(catalogTree => catalogTree !== undefined && catalogTree !== null),
            take(1),
            tap((catalogTree: CatalogTreeRoot) => {
              this._handleLayerChanges(layerChanges, catalogTree);
            })
          );
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();
  }

  /**
   * Apply incoming changes for each layer
   */
  private _handleLayerChanges(layerChanges: LayerChange[], catalogTree: CatalogTreeRoot): void {
    layerChanges.forEach(lc => {
      if (lc.change.active !== undefined) {
        const layer: CatalogTreeItem = ArrayHelper.nestedFind(catalogTree.children, lc.layerid, 'idBod', 'children');
        if (layer?.category === 'layer') {
          if (!layer.config) {
            return;
          }

          const label = layer.config.attribution;
          const url = layer.config.attributionUrl;

          if (label && url) {
            const exists = this.attributions.get(label);

            if (lc.change.active) {
              if (!exists) {
                this.attributions.set(label, url);
              }
            } else {
              if (exists) {
                this.attributions.delete(label);
              }
            }
          }
        }
      }
    });
  }
}
