import { FeatureLike } from 'ol/Feature';
import { LineString, Point } from 'ol/geom';
import { Icon, Stroke } from 'ol/style';
import Style, { StyleFunction } from 'ol/style/Style';

export const WildTracksStyle: StyleFunction = (feature: FeatureLike): Style | Style[] => {
  const viltLineColor = feature.getProperties()['color'];

  const styles = [
    // The style for the line
    new Style({
      stroke: new Stroke({
        color: viltLineColor,
        width: 1,
      }),
    }),
  ];
  const geometry = feature.getGeometry() as LineString;

  geometry.forEachSegment(function (start, end) {
    const dx = end[0] - start[0];
    const dy = end[1] - start[1];
    const rotation = Math.atan2(dy, dx);

    // Add arrow to show line direction
    styles.push(
      new Style({
        geometry: new Point(start),
        image: new Icon({
          anchor: [0.065, 0.5],
          anchorOrigin: 'top-left',
          color: viltLineColor, // This will colorize svg elements that are set to white (fill="#fff")
          displacement: [0, 0],
          rotateWithView: true,
          rotation: -rotation,
          scale: [0.7, 0.7],
          src: 'assets/arrows/chevron_right.svg',
        }),
      })
    );
  });

  return styles;
};
