import { ColorsConst } from '@kildenshared/constants/draw/colors.const';
import { PointSymbolType } from '@kildenshared/types/draw/point-symbol.type';

export const PointSymbolsConst: PointSymbolType[] = [
  {
    id: 'circle',
    class: 'material-icons ',
    color: ColorsConst[0],
    src: 'circle_white_24dp.svg',
  },
  {
    id: 'circle',
    class: 'material-icons-outlined ',
    color: ColorsConst[0],
    src: 'circle_white_24dp_outline.svg',
  },
  {
    id: 'house',
    class: 'material-icons-outlined ',
    color: ColorsConst[0],
    src: 'house_white_24dp.svg',
  },
  {
    id: 'grass',
    class: 'material-icons-outlined ',
    color: ColorsConst[0],
    src: 'grass_white_24dp.svg',
  },
  {
    id: 'park',
    class: 'material-icons-outlined ',
    color: ColorsConst[0],
    src: 'park_white_24dp.svg',
  },
  {
    id: 'fence',
    class: 'material-icons-outlined ',
    color: ColorsConst[0],
    src: 'fence_white_24dp.svg',
  },
  {
    id: 'directions_walk',
    class: 'material-icons-outlined ',
    color: ColorsConst[0],
    src: 'directions_walk_white24dp.svg',
  },
  {
    id: 'place',
    class: 'material-icons ',
    color: ColorsConst[0],
    src: 'place_white_24dp.svg',
  },
  {
    id: 'place',
    class: 'material-icons-outlined ',
    color: ColorsConst[0],
    src: 'place_white_24dp_outline.svg',
  },
  {
    id: 'close',
    class: 'material-icons-outlined ',
    color: ColorsConst[0],
    src: 'close_white_24dp.svg',
  },
  {
    id: 'north',
    class: 'material-icons-outlined ',
    color: ColorsConst[0],
    src: 'north_white_24dp.svg',
  },
  {
    id: 'south',
    class: 'material-icons-outlined ',
    color: ColorsConst[0],
    src: 'south_white_24dp.svg',
  },
  {
    id: 'west',
    class: 'material-icons-outlined',
    color: ColorsConst[0],
    src: 'west_white_24dp.svg',
  },
  {
    id: 'east',
    class: 'material-icons-outlined ',
    color: ColorsConst[0],
    src: 'east_white_24dp.svg',
  },
];
