import { Component, OnInit } from '@angular/core';
import { ApiConfig } from '@kildenconfig/api-config';
import { AppConfig } from '@kildenconfig/app.config';
import { KildenStateService } from '@kildencore/services/kilden-state.service';
import { MapService } from '@kildencore/services/map.service';
import Feature from 'ol/Feature';
import Geolocation from 'ol/Geolocation';
import Point from 'ol/geom/Point';
import Polygon from 'ol/geom/Polygon';
import VectorLayer from 'ol/layer/Vector';
import Map from 'ol/Map';
import VectorSource from 'ol/source/Vector';
import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Swal from 'sweetalert2';

@Component({
  selector: 'kilden3-geolocation',
  templateUrl: './geolocation.component.html',
  styleUrls: ['./geolocation.component.css'],
})
export class GeolocationComponent implements OnInit {
  geoerr = false;
  geolocation!: Geolocation;
  tracking = false;

  private _map!: Map;

  constructor(
    private _kildenStateService: KildenStateService,
    private _mapService: MapService
  ) {}

  geolocate() {
    this.tracking = !this.tracking;
    this.geolocation.setTracking(this.tracking);
  }

  setError(): void {
    this.geoerr = true;
  }

  ngOnInit(): void {
    this._map = this._mapService.getMap();
    if (this._map === undefined) {
      return;
    }

    const mainThis = this;
    let firstActivation = true; // coordinates still undefined

    this.geolocation = new Geolocation({
      trackingOptions: {
        // https://www.w3.org/TR/geolocation-API/#position_options_interface
        enableHighAccuracy: true,
      },
      projection: this._map.getView().getProjection(),
    });

    // handle geolocation error.
    this.geolocation.on('error', error => {
      mainThis.setError();
      mainThis.tracking = false;
      this.geolocation.setTracking(false);
      Swal.fire({
        title: ApiConfig.defaultErrorHeader,
        html: 'Din posisjon er desverre ikke tilgjengelig.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      // alert(error.message);
    });

    const accuracyFeature: any = new Feature();
    accuracyFeature.setStyle(
      new Style({
        fill: new Fill({
          color: [255, 0, 0, 0.05],
        }),
        stroke: new Stroke({
          color: [255, 0, 0, 0.9],
          width: 2,
        }),
        image: new Circle({
          radius: 5,
          fill: new Fill({
            color: [255, 0, 0, 0.9],
          }),
          stroke: new Stroke({
            color: [255, 255, 255, 1],
            width: 2,
          }),
        }),
      })
    );

    this.geolocation.on('change:accuracyGeometry', () => {
      accuracyFeature.setGeometry(this.geolocation.getAccuracyGeometry() as Polygon);
    });

    const positionFeature: any = new Feature();
    positionFeature.setStyle(
      new Style({
        image: new Circle({
          radius: 6,
          fill: new Fill({
            color: '#f00',
          }),
          stroke: new Stroke({
            color: '#fff',
            width: 2,
          }),
        }),
      })
    );

    this.geolocation.on('change:position', () => {
      const coordinates = this.geolocation.getPosition();
      positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
      if (firstActivation) {
        firstActivation = false;
        mainThis.geoerr = false;
        this._map.addLayer(vectorLayer);
        this._zoomToExtent();
      } // if firstActivation
    });

    this.geolocation.on('change:tracking', () => {
      const itIsOff = !this.geolocation.getTracking();
      if (itIsOff) {
        this._map.removeLayer(vectorLayer);
        positionFeature.setGeometry(null);
        accuracyFeature.setGeometry(null);
      } else {
        firstActivation = true;
        this._zoomToExtent();
      }
    });

    const vectorLayer = new VectorLayer({
      // map: this.map, // did not work in 3D
      properties: { id: 'geolocation', altitudeMode: 'clampToGround' },
      source: new VectorSource({
        features: [accuracyFeature, positionFeature],
      }),
      zIndex: AppConfig.ZINDEX_SEARCH,
    });
    // this.map.addLayer(vectorLayer); // did not work for printing

    this._initSubscriptions();
  }

  private _initSubscriptions(): void {
    // Whenever the map projection is changed, update geolocation config to match
    this._kildenStateService.epsg$.subscribe({
      next: epsg => {
        this.geolocation.setProjection(epsg);
      },
    });
  }

  private _zoomToExtent() {
    const coord = this.geolocation.getPosition();
    if (coord) {
      const accuracy = this.geolocation.getAccuracy() || 500;
      const extent: any = [
        coord[0] - (accuracy as number),
        coord[1] - (accuracy as number),
        coord[0] + (accuracy as number),
        coord[1] + (accuracy as number),
      ];
      this._map.getView().fit(extent, { duration: 1500 });
    }
  }
}
