export class ZoomInfo {
  level: number;
  resolution: number;
  constructor(level: number = -1, resolution: number = 0) {
    this.level = level;
    this.resolution = resolution;
  }

  public hasLevel(): boolean {
    return typeof this.level !== 'undefined' && this.level !== -1;
  }

  public hasResolution(): boolean {
    return typeof this.resolution !== 'undefined' && this.resolution !== 0;
  }

  public isSet() {
    return this.hasResolution() && this.hasLevel();
  }
}
