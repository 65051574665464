import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VegaComponent } from './components/vega/vega.component';
import { SharedModule } from '@kildenshared/shared.module';

@NgModule({
  declarations: [VegaComponent],
  imports: [CommonModule, SharedModule],
})
export class ProjectVegaModule {}
