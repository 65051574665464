import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@kildenconfig/api-config';
import { AppConfig } from '@kildenconfig/app.config';
import { OrthoProjectsService } from '@kildencore/services/data/ortho-projects.service';
import { MapService } from '@kildencore/services/map.service';
import { ThemeLayersService } from '@kildencore/services/theme-layers.service';
import { CatalogTreeItem } from '@kildenshared/components/catalog-tree/catalog-tree-item';
import { CatalogLayerTimeOptionsInterface } from '@kildenshared/interfaces/catalog-layer-time-options.interface';
import { CatalogLayerVariantsInterface } from '@kildenshared/interfaces/catalog-layer-variants.interface';
import { LayerConfigsType } from '@kildenshared/interfaces/layer-configs.type';
import { WMSCapabilities } from 'ol/format';
import { Layer } from 'ol/layer';
import ImageLayer from 'ol/layer/Image';
import ImageWMS from 'ol/source/ImageWMS';
import { finalize, map, Observable, of, ReplaySubject, retry, share, take, tap, throwError, timer } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Injectable({ providedIn: 'root' })
export class LayersConfigService {
  private _layerConfigs = new ReplaySubject<LayerConfigsType>(1);
  private _fetchingVariantsForIdBod = new Set<string>();
  private _wmsCache = new Map<string, Observable<string>>();

  layerConfigs$ = this._layerConfigs.asObservable();
  layersTimeOptions = new Map<string, CatalogLayerTimeOptionsInterface>();
  layersVariants = new Map<string, CatalogLayerVariantsInterface>();

  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _mapService: MapService,
    private readonly _orthoProjectsService: OrthoProjectsService,
    private readonly _themeLayersService: ThemeLayersService
  ) {}

  /**
   * Delete the cache of variants for layer/treeItem with given idBod
   */
  clearCachedVariants(idBod: string): void {
    if (this.layersVariants.delete(idBod)) {
      // console.log(`${idBod}: cleared cached variants`);
    }
  }

  /**
   * Get layers-configuration from server and broadcast in layersConfig
   * All subscribers will receive new config
   * @return void
   */
  fetchLayerConfigsByTopic(topicId: string) {
    if (!topicId || topicId === '') {
      this._setLayerConfigsAndInitializeBackgroundLayers({});
      return;
    }
    const layersConfigUrl = ApiConfig.getLayersConfigUrl(topicId);
    return this._httpClient.get<LayerConfigsType>(layersConfigUrl).subscribe({
      next: success => {
        this._setLayerConfigsAndInitializeBackgroundLayers(success);
      },
      error: err => {
        Swal.fire({
          title: ApiConfig.defaultErrorHeader,
          html: 'Henting av nye kartlag feilet: <br> ' + err.message,
          icon: 'error',
          confirmButtonText: 'OK',
        });
      },
    });
  }

  getLayerTimeOptions(item: CatalogTreeItem): Observable<CatalogLayerTimeOptionsInterface | undefined> {
    if (!item.idBod) {
      // console.log(`early return; missing idBod`, item.idBod);
      return of(undefined);
    }

    const cached = this.layersTimeOptions.get(item.idBod);
    if (cached) {
      // console.log(`returning cached TimeOptions for ${item.idBod}`, cached);
      item.timeOptions = cached;
      return of(cached);
    }

    // console.log(`fetching TimeOptions for`, item.idBod);
    return this._fetchWmsTimeOptions(item);
  }

  getLayerVariants(treeItem: CatalogTreeItem): Observable<CatalogLayerVariantsInterface | undefined> {
    if (!treeItem.idBod) {
      console.warn(`early return: getLayerVariants !idbod`);
      return of(undefined);
    }

    const cached = this.layersVariants.get(treeItem.idBod);
    if (cached) {
      this._fetchingVariantsForIdBod.delete(treeItem.idBod);
      // console.log(`${treeItem.idBod}: returning cached variants`, cached.items?.length);
      // console.log(`${treeItem.idBod}: cached.selected`, cached.selected);
      treeItem.variants = cached;
      return of(treeItem.variants);
    }

    // console.log(`cached, fetching`, cached, this._fetchingVariantsForIdBod.has(treeItem.idBod));

    // Avoid duplicate fetch requests and race conditions
    if (!this._fetchingVariantsForIdBod.has(treeItem.idBod)) {
      // console.log(`${treeItem.idBod}: fetching variants`);
      return this._fetchVariants(treeItem).pipe(
        finalize(() => {
          // This finalize-call was part of the solution to KILDEN3-519.
          // Makes sure the next line is executed whether fetching results in success, error, or cancel by switchmap.
          this._fetchingVariantsForIdBod.delete(treeItem.idBod as string);
        })
      );
    } else {
      // console.warn(`already fetching variants for ${treeItem.idBod}`);
    }

    return of(undefined);
  }

  /**
   * Fetch given item.config.wmsUrl and parse into TimeOptions
   */
  private _fetchVariants(treeItem: CatalogTreeItem): Observable<CatalogLayerVariantsInterface | undefined> {
    this._fetchingVariantsForIdBod.add(treeItem.idBod as string);
    const themeLayer = this._themeLayersService.findBankLayer(treeItem.idBod as string);
    const variantUrl = treeItem.config?.wmsUrl;

    if (!variantUrl || !themeLayer || !(themeLayer instanceof ImageLayer)) {
      // console.warn(`early return fetchVariants`, {
      //   variantUrl: variantUrl,
      //   themeLayer: themeLayer,
      //   themeLayerInstanceOf: !(themeLayer instanceof ImageLayer),
      // });
      this._fetchingVariantsForIdBod.delete(treeItem.idBod as string);
      return of(undefined);
    }

    // Special handling for ortholayers (plane / satelite photos)
    if (AppConfig.ORTHO_EXCEPTIONS.includes(treeItem.idBod as string)) {
      // console.log(`_fetchVariants(): special handling for ortho`);
      return this._orthoProjectsService.getProjectsAsLayerVariants(treeItem).pipe(
        tap(variants => {
          this._setLayerVariants(treeItem, variants);
        }),
        take(1),
        catchError((e: Error) => {
          // console.warn(`caught err and deleted from fetching`, e);
          this._fetchingVariantsForIdBod.delete(treeItem.idBod as string);
          return throwError(() => e);
        })
      );
    }

    this._fetchingVariantsForIdBod.delete(treeItem.idBod as string);
    return of(undefined);
  }

  /**
   * Fetch given item.config.wmsUrl and parse into TimeOptions
   */
  private _fetchWmsTimeOptions(item: CatalogTreeItem): Observable<CatalogLayerTimeOptionsInterface | undefined> {
    const themeLayer = this._themeLayersService.findBankLayer(item.idBod as string);
    const wmsUrl = item.config?.wmsUrl;
    if (!wmsUrl || !themeLayer || !(themeLayer instanceof ImageLayer)) {
      // console.log(`early return; missing requirements`, themeLayer, wmsUrl);
      return of(undefined);
    }

    return this._fetchWmsUrl(wmsUrl).pipe(
      map(response => {
        if (!response.length) {
          return item.timeOptions;
        }

        return this._handleWmsTimeOptionsResponse(response, themeLayer, item);
      })
    );
  }

  /**
   * Some layers have identical wmsUrls, so we want to cache the response.
   * There are two caching mechanisms in play here:
   * 1. The rxjs share() operator handles caching the actual http response for CACHE_TIMEOUT milliseconds.
   * 2. The local _wmsCache handles the scenario where multiple layers requests the same data in parallell/simoultaneously
   */
  private _fetchWmsUrl(wmsUrl: string): Observable<string> {
    const CACHE_TIMEOUT = 60 * 1000; // In ms, (60*1000) = 1 minute

    const cachedValue = this._wmsCache.get(wmsUrl);
    if (cachedValue) {
      return cachedValue;
    }

    const wmsResponse$ = this._httpClient
      .get(wmsUrl, {
        observe: 'body',
        responseType: 'text',
      })
      .pipe(
        share({
          resetOnComplete: () => timer(CACHE_TIMEOUT), // invalidate cache on timeout
          // Replace the default Subject()-connector since we need to supply the already cached
          // value to any new subscribers.
          connector: () => new ReplaySubject(1),
        }),
        retry(2),
        catchError((e: HttpErrorResponse) => {
          return throwError(() => e);
        })
      );

    // Store for next use, also used to let app know we already have a value for that given wmsUrl
    this._wmsCache.set(wmsUrl, wmsResponse$);
    return wmsResponse$;
  }

  private _handleWmsTimeOptionsResponse(
    response: string,
    themeLayer: Layer<ImageWMS>,
    treeItem: CatalogTreeItem
  ): CatalogLayerTimeOptionsInterface | undefined {
    if (!treeItem?.config || !treeItem?.idBod) {
      return;
    }

    const layerSrc = themeLayer.getSource();
    const parser = new WMSCapabilities();
    const json = parser.read(response);
    const wmsCapabilities = json['Capability']['Layer'];
    const wmsGroups = wmsCapabilities['Layer'];

    // Find right layer:
    for (let gi = 0; gi < wmsGroups?.length; gi++) {
      // console.log(`wmsGroups[gi]`, wmsGroups[gi]);
      const groupLayers = wmsGroups[gi]?.Layer;

      for (let i = 0; i < groupLayers?.length; i++) {
        const wms = groupLayers[i];
        if (layerSrc?.getParams().LAYERS.split(',').indexOf(wms.Name) > -1 && wms.Dimension?.length) {
          const timeSettings = wms.Dimension[0];
          const [min, max] = timeSettings.values.split('/');
          // console.log('WMS name:', wms.Name);
          // console.log(min, max, timeSettings.default);

          // Fill in selector:
          const existingSelected = treeItem.timeOptions?.selected;

          treeItem.timeOptions = {
            default: parseInt(timeSettings.default),
            max: parseInt(max),
            min: parseInt(min),
            selected: existingSelected ?? parseInt(timeSettings.default),
          } as CatalogLayerTimeOptionsInterface;

          // Store in cache
          this.layersTimeOptions.set(treeItem.idBod, treeItem.timeOptions);

          return treeItem.timeOptions;
        }
      }
    }

    return treeItem.timeOptions;
  }

  /**
   * Update the layersConfig and initialize background layers
   */
  private _setLayerConfigsAndInitializeBackgroundLayers(layerConfigs: LayerConfigsType) {
    this._mapService.initializeBackgroundLayers(layerConfigs);
    this._themeLayersService.newLayerList(layerConfigs);
    this._layerConfigs.next(layerConfigs);
  }

  /**
   * Stores newly fetched variants collection into treeItem
   * If there is already a treeItem.variant.selected (like from parsing url) this will attempt to find it
   * in the new collection and keep it, or fallback to the .selected of the new collection
   */
  private _setLayerVariants(treeItem: CatalogTreeItem, variants: CatalogLayerVariantsInterface): void {
    if (!treeItem.variants) {
      treeItem.variants = variants;
    } else {
      treeItem.variants.default = variants.default;
      treeItem.variants.items = variants.items;
      if (variants.items && treeItem?.variants?.selected?.value) {
        // attempt to find preselected in new items, else fallback to cached selected
        const idx = variants.items.findIndex(
          // @ts-ignore
          ci => ci.value === treeItem.variants.selected.value
        );
        treeItem.variants.selected =
          // @ts-ignore
          idx > -1 ? treeItem.variants.items[idx] : variants.selected;
      } else {
        treeItem.variants.selected = variants.selected;
      }
    }

    // console.log(`treeItem.variants updated:`, treeItem.variants);

    // Store in cache
    this.layersVariants.set(treeItem.idBod as string, treeItem.variants);
    // No longer mark as fetching
    this._fetchingVariantsForIdBod.delete(treeItem.idBod as string);
    // Update URL
    this._themeLayersService.registerLayerChanges([
      {
        layerid: treeItem.idBod as string,
        change: {
          selectedVariant: treeItem.variants.selected,
        },
      },
    ]);
  }
}
