import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { KildenStandardComponent } from '@kildencore/components';
import { SharedModule } from '@kildenshared/shared.module';

@NgModule({
  declarations: [KildenStandardComponent],
  imports: [CommonModule, SharedModule],
})
export class CoreModule {}
