<button
  type="button"
  name="bgSelector"
  id="bgSelector"
  title="Bakgrunnskart"
  (click)="showChoices()"
  (keydown)="executeKeydown($event)">
  <p class="bg-btn-header">Bakgrunnskart</p>
  <!-- <mat-icon>expand_circle_down</mat-icon> -->
  <!-- <mat-icon class="material-icons-outlined">expand_circle_down</mat-icon> -->
  <mat-icon>chevron_right</mat-icon>
  <!-- <mat-icon>arrow_forward_ios</mat-icon> -->
</button>
<button
  *ngFor="let bg of bgLayers"
  id="{{ bg.id }}"
  class="bg-btn"
  tabindex="-1"
  [ngClass]="{ 'bg-chosen': bg.id === chosenLayerId }"
  (click)="switchTo(bg.id)"
  (keydown)="executeKeydown($event)">
  <p class="bg-btn-header">{{ bg.title }}</p>
</button>
