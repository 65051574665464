<div
  class="dialog-wrapper"
  (dragenter)="preventDrop($event)"
  (dragover)="preventDrop($event)"
  (drop)="preventDrop($event)">
  <h1
    mat-dialog-title
    cdkDrag
    (cdkDragEnded)="onDragEnded($event)"
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragBoundary=".cdk-overlay-container"
    cdkDragHandle>
    <mat-toolbar role="toolbar" class="task-header">
      <mat-icon class="drag-indicator">drag_indicator</mat-icon>
      <span class="dialog-title" [title]="data.title">{{ data.title }}</span>
      <span class="fx-spacer"></span>
      <button mat-icon-button (click)="closeDialog()">
        <mat-icon mat-list-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </h1>

  <mat-dialog-content>
    <ng-container *ngComponentOutlet="data.component"></ng-container>
  </mat-dialog-content>

  <footer *ngIf="data.footer" class="sticky-modal-footer">
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Avbryt</button>
      <button mat-button [mat-dialog-close]="true">
        {{ data.submitText }}
      </button>
    </mat-dialog-actions>
  </footer>
</div>
