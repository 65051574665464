<div class="shortcuts-grid">
  <ng-container *ngFor="let entry of shortcuts">
    <div class="key">
      <span class="keyboard-key">alt</span>&nbsp;+
      <span class="keyboard-key">{{ entry[0] }}</span>
    </div>
    <div class="desc">{{ entry[1] }}</div>
  </ng-container>
</div>

<div>
  Innad i kartlagslisten kan man bruke:
  <ul>
    <li>
      Piltaster (
      <span class="keyboard-key">↓</span>&nbsp;<span class="keyboard-key">↑</span>
      ) for å flytte markering opp og ned
    </li>
    <li>
      Piltaster (
      <span class="keyboard-key">←</span>&nbsp;<span class="keyboard-key">→</span>
      ) for å gå inn/ut av åpne kategorier.
    </li>
    <li>
      <span class="keyboard-key">Enter</span> for å:
      <ul>
        <li>åpne/lukke kategori</li>
        <li>(de)aktivere kartlag</li>
      </ul>
    </li>
    <li>
      <span class="keyboard-key">Space</span> for å:
      <ul>
        <li>(de)aktivere alle kartlag i kategorier som tillater det</li>
        <li>(de)aktivere kartlag</li>
      </ul>
    </li>
  </ul>
</div>

<div class="outro">
  Man kan flytte kartet med piltaster når knappen for geolokasjon (
  <span class="keyboard-key">alt</span>
  + <span class="keyboard-key">g</span>) er markert
</div>
