import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  MAT_LEGACY_MENU_DEFAULT_OPTIONS as MAT_MENU_DEFAULT_OPTIONS,
  MatLegacyMenuTrigger as MatMenuTrigger,
} from '@angular/material/legacy-menu';
import { ApiConfig } from '@kildenconfig/api-config';
import { TopicConfig } from '@kildenshared/interfaces/topic-config';
import { Observable, take, takeWhile, tap, timer } from 'rxjs';

const MAX_SCREEN_WIDTH = 400;

const isSmallScreen = () => {
  return window.innerWidth < MAX_SCREEN_WIDTH;
};

@Component({
  selector: 'kilden3-select-topic',
  templateUrl: './select-topic.component.html',
  styleUrls: ['./select-topic.component.css'],
  providers: [
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: { overlayPanelClass: 'overlay-select-topic' },
    },
  ],
})
export class SelectTopicComponent implements OnChanges {
  readonly autoCloseTimeout = 5;

  @Input() topics: TopicConfig[] = [];
  @Input() current: string = '';
  @Input() label: string = '';
  @Output() topicChanged = new EventEmitter<string>();

  autoClosePercentageRemaining = 0;
  autoCloseTimer$: Observable<number> = timer(0, 250).pipe(
    takeWhile(() => this.autoClosePercentageRemaining > 0),
    take(1 + (this.autoCloseTimeout * 1000) / 250),
    tap((iterationsCount: number) => {
      this.autoClosePercentageRemaining = Math.ceil(100 - ((iterationsCount * 0.25) / this.autoCloseTimeout) * 100);

      if (this.autoClosePercentageRemaining <= 0) {
        this.topicMenu.closeMenu();
        document.getElementById('header')?.focus();
      }
    })
  );

  _topics: any = [];
  _current: string = '';
  _opening: boolean = false;
  @ViewChild('topicMenuTrigger') topicMenu!: MatMenuTrigger;

  ngOnChanges(changes: SimpleChanges) {
    if (!this.current.length && this.topicMenu) {
      setTimeout(() => {
        this.topicChanged.emit(ApiConfig.getDefaultTopic());
      }, 10);

      if (!isSmallScreen()) {
        this._opening = true;
        this.topicMenu.openMenu();
        this.autoClosePercentageRemaining = 100;
        this.autoCloseTimer$.subscribe();
      }
    }

    if (changes['topics']) {
      this._topics = changes['topics'].currentValue;
      if (this._topics.length === 1) {
        this.topicMenu.closeMenu();
      }
    }

    if (changes['current']) {
      this._current = changes['current'].currentValue;
    }
  }

  /**
   * Set the current topic to selection and emit output-event to parents
   * @param  topicid               [description]
   * @return         [description]
   */
  public select(topicid: string) {
    this._current = topicid;
    this.topicChanged.emit(topicid);
  }

  /**
   * Set the current topic to selection and emit output-event to parents.
   * There is some logic to detect if this funcion is called due to pressing enter on
   * the mat-menu-trigger element.
   * @param  topicid               [description]
   * @return         [description]
   */
  public selectOnEnter(event: any, topicid: string) {
    // perform a  select of new topic caused by hitting enter.
    // This event is also triggered when opening the dialog with enter.
    // To avoid selecting when the dialog is opening due to hitting enter,
    // the _opeing flag is used to filter this.
    // Not a good solution, but it works. Did not find any safe way to detect
    // wether event was caused by hitting enter on the opening-button or on the li
    // element in the topic-list
    if (!this._opening) {
      this.select(topicid);
      this.topicMenu.closeMenu();
    }
    this._opening = false;
  }

  /**
   * Function to run when matMenu is opening
   * @return [description]
   */
  public menuOpened() {
    // set the opening-flag
    this._opening = true;
  }

  /**
   * Function to run when matMenu is opening
   * @return [description]
   */
  public menuClosed() {
    // unset the openingFlag
    this._opening = false;
    this.autoClosePercentageRemaining = 0;
  }

  /**
   * HostListener if window resizes, we close menu if it is open
   * @param  window
   * @param  []
   * @return [void]
   */
  @HostListener('window:resize', [])
  private onResize() {
    if (this.topicMenu?.menuOpen) {
      this.topicMenu.closeMenu();
    }
  }
}
