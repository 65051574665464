export const WildlifePropertyMappingsConst = {
  Acquisition_time: 'Tidspunkt',
  calf_when_marked: 'Kalv når merket',
  collar_id: 'Halsband ID',
  collarfarge: 'Farge/nr på halsband',
  ear_main: 'Hovedøre',
  id_navn: 'Individ ID',
  id_position: '7391',
  lokalitet: 'Merkelokalitet',
  markage_guessed: '',
  markdate: 'Dato merket',
  markdatetime: 'Dato/tid merket',
  markmunic: 'Merkekommune',
  markproject: 'Merkeprosjekt',
  markweight: 'Totalvekt',
  sex: 'Kjønn',
  symbol: 'Symbol',
  symbol_color: 'Farge på symbol',
  symbol_line: 'Farge på symbollinje',
} as const;
