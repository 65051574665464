import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ApiConfig } from '@kildenconfig/api-config';
import { ReportFeature } from '@kildenshared/types/report/report-feature.type';
import { ReportMapLayer } from '@kildenshared/types/report/report-map-layer.type';
import { Observable, of, Subject, timer, switchMap, retry, share, takeUntil, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';

export interface DrawLayer {
  id: number;
  layerId: string;
  source?: string;
  type?: string;
  name?: string;
  expanded?: boolean;
  bufferSize?: string;
}

export interface Report {
  srid: string;
  layers: string;
  type?: string;
  features?: ReportFeature[];
  backgroundLayerId?: string;
}

const geometryTypes: { [key: string]: any } = {
  polygon: 'flate',
  multilinestring: 'linje',
  line: 'linje',
  linestring: 'linje',
  point: 'punkt',
};

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private _drawings = new Subject<DrawLayer[]>();
  private dataStore: { drawings: DrawLayer[] } = { drawings: [] };
  readonly drawings = this._drawings.asObservable();
  private stopPolling = new Subject();

  constructor(private http: HttpClient) {}

  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  get store(): DrawLayer[] {
    return this.dataStore.drawings;
  }

  addToStore(args: DrawLayer) {
    const { id, type, source, layerId } = args;
    if (id === 0) return;
    if (this.dataStore.drawings.find(s => s.id === id)) return;
    if (!type || !type.length) return;

    const updatedSource = source ? source : 'Tegnet';

    const toolName = geometryTypes[type.toLowerCase()] || 'objekt';

    this.dataStore.drawings.push({
      id: id,
      layerId: layerId,
      name: `${updatedSource} ${toolName}`,
      type: type,
      expanded: false,
      bufferSize: type === 'polygon' ? '' : '1',
    });

    this._drawings.next(Object.assign({}, this.dataStore).drawings);
  }

  updateStoreToggle(drawingId: number) {
    this.dataStore.drawings = this.dataStore.drawings.map(d => {
      if (d.id === drawingId) {
        return { ...d, expanded: !d.expanded };
      }
      return d;
    });

    this._drawings.next(Object.assign({}, this.dataStore).drawings);
  }

  updateStoreBufferSize(drawingId: number, bufferSize: string) {
    this.dataStore.drawings = this.dataStore.drawings.map(d => {
      if (d.id === drawingId) {
        return { ...d, bufferSize: bufferSize };
      }
      return d;
    });

    this._drawings.next(Object.assign({}, this.dataStore).drawings);
  }

  removeFromStore(drawingId: number) {
    this.dataStore.drawings = this.dataStore.drawings.filter(d => d.id !== drawingId);
    this._drawings.next(Object.assign({}, this.dataStore).drawings);
  }

  private errorPopup(err: HttpErrorResponse, text: string) {
    Swal.fire({
      title: ApiConfig.defaultErrorHeader,
      html: `Henting av ${text} feilet: <BR> ${err.message}`,
      icon: 'error',
      confirmButtonText: 'OK',
    });
  }

  getReportMapLayers(id: string): Observable<ReportMapLayer[]> {
    if (!id) {
      throw new Error('no id parameter passed');
    }

    const url: { [key: string]: any } = {
      areaReport: `${ApiConfig.baseUrl}/arealrapport_ws/kartlag`,
      soilReport: `${ApiConfig.baseUrl}/arealrapport_ws/jordsmonn/kartlag`,
      forestReport: `${ApiConfig.baseUrl}/skograpport_ws/kartlag`,
    };

    return this.http.get<ReportMapLayer[]>(url[id]).pipe(
      catchError(err => {
        this.errorPopup(err, 'rapport kartlag');
        return throwError(() => 'error fetching reportMapLayers');
      })
    );
  }

  generateReport(layerId: string, reportJson: Report): Observable<any> {
    if (!layerId) return of('error');

    const url: { [key: string]: any } = {
      areaReport: '/arealrapport_ws/getReport',
      soilReport: '/arealrapport_ws/jordsmonn/getReport',
      forestReport: '/skograpport_ws/getReport',
    };

    return this.http
      .post(url[layerId], reportJson, {
        headers: this.httpHeaders,
      })
      .pipe(
        catchError(err => {
          this.errorPopup(err, 'rapport');
          return of('error');
        })
      );
  }

  fetchReport(id: string, layerId: string): Observable<any> {
    if (!layerId) return of('error');

    const url: { [key: string]: any } = {
      areaReport: `${ApiConfig.baseUrl}/arealrapport_ws/getReportStatus?jobid=${id}`,
      soilReport: `${ApiConfig.baseUrl}/arealrapport_ws/jordsmonn/getReportStatus?jobid=${id}`,
      forestReport: `${ApiConfig.baseUrl}/skograpport_ws/getReportStatus?jobid=${id}`,
    };

    return timer(0, 2000).pipe(
      switchMap(() =>
        this.http.get(url[layerId]).pipe(
          catchError(err => {
            this.errorPopup(err, 'rapport');
            return of('error');
          })
        )
      ),
      retry(),
      share(),
      takeUntil(this.stopPolling)
    );
  }

  terminatePolling() {
    this.stopPolling.next(0);
  }
}
