import {
  AfterViewInit,
  ContentChild,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[fileDropPlaceholderRef]' })
export class FileDropPlaceholderRefDirective implements OnInit {
  constructor(public readonly _elementRef: ElementRef<HTMLElement>) {}

  ngOnInit() {
    // placeholder would otherwise become new drop target and cause flickering while dragging
    this._elementRef.nativeElement.style.pointerEvents = 'none';
  }
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[fileDrop]',
})
export class FileDropDirective implements AfterViewInit, OnDestroy {
  @Output()
  readonly fileDrop = new EventEmitter<FileList>();

  @ContentChild(FileDropPlaceholderRefDirective)
  private readonly _placeholderRef?: FileDropPlaceholderRefDirective;

  constructor(
    private readonly _ngZone: NgZone,
    private readonly _elementRef: ElementRef,
    private readonly _renderer: Renderer2
  ) {}

  ngAfterViewInit(): void {
    this._ngZone.runOutsideAngular(() => {
      this._elementRef.nativeElement.addEventListener('dragenter', this._dragEnterEventHandler);
      this._elementRef.nativeElement.addEventListener('dragover', this._dragOverEventHandler);
      this._elementRef.nativeElement.addEventListener('dragleave', this._dragLeaveEventHandler);
    });
  }

  ngOnDestroy(): void {
    this._elementRef.nativeElement.removeEventListener('dragenter', this._dragEnterEventHandler);
    this._elementRef.nativeElement.removeEventListener('dragover', this._dragOverEventHandler);
    this._elementRef.nativeElement.removeEventListener('dragleave', this._dragLeaveEventHandler);
  }

  onDragEnter(event: DragEvent): void {
    // Preventing default effectively allows the custom directive code to run
    event.preventDefault();
  }

  onDragLeave(event: DragEvent): void {
    // Preventing default effectively allows the custom directive code to run
    event.preventDefault();
    this._hidePlaceholder();
  }

  onDragOver(event: DragEvent): void {
    // Preventing default effectively allows the custom directive code to run
    event.preventDefault();
    this._renderer?.addClass(this._placeholderRef?._elementRef.nativeElement, 'active');
  }

  @HostListener('drop', ['$event'])
  onDropFile(event: DragEvent): void {
    // Preventing default effectively allows the custom directive code to run
    event.preventDefault();
    if (event.dataTransfer?.files) {
      this.fileDrop.emit(event.dataTransfer.files);
    }
    this._hidePlaceholder();

    // In case of nested drop zones
    event.stopPropagation();
  }

  private readonly _dragEnterEventHandler: (event: DragEvent) => void = (event: DragEvent) => this.onDragEnter(event);

  private readonly _dragLeaveEventHandler: (event: DragEvent) => void = (event: DragEvent) => this.onDragLeave(event);

  private readonly _dragOverEventHandler: (event: DragEvent) => void = (event: DragEvent) => this.onDragOver(event);

  private _hidePlaceholder(): void {
    this._renderer?.removeClass(this._placeholderRef?._elementRef.nativeElement, 'active');
  }
}
