import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ApiConfig } from '@kildenconfig/api-config';
import { PrintConfig } from '@kildenconfig/print-config';
import { PrintService } from '@kildencore/services/data/print.service';
import { KildenStateService } from '@kildencore/services/kilden-state.service';
import { MapPrintService } from '@kildencore/services/map-print.service';
import { MapService } from '@kildencore/services/map.service';
import { SpecPrintService } from '@kildencore/services/spec-print.service';
import { PrintTools } from '@kildencore/tools';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'kilden3-printform',
  templateUrl: './printform.component.html',
  styleUrls: ['./printform.component.css'],
})
export class PrintformComponent implements OnInit, OnDestroy {
  public printForm = new UntypedFormGroup({
    pageLayout: new UntypedFormControl('Normal (A4 stående)'),
    scale: new UntypedFormControl(PrintConfig.DEFAULT_SCALE),
    format: new UntypedFormControl('pdf'),
    title: new UntypedFormControl(ApiConfig.printTitle),
    printLegend: new UntypedFormControl(false),
  });

  layouts: string[] = [];
  scales: number[] = [];
  formats: string[] = ['pdf', 'png'];
  printLegend: boolean = false;
  mapScale: string = '' + PrintConfig.DEFAULT_SCALE;
  subscriptions = new Subscription();
  showLoader: boolean = false;

  constructor(
    private printService: PrintService,
    private mapService: MapService,
    private mapPrintService: MapPrintService,
    private specPrintService: SpecPrintService,
    private appState: KildenStateService
  ) {}

  ngOnInit(): void {
    this.printService.getPrintObj();
    const subscriptionPrintConfigFromMapfish$ = this.printService.printObj$.subscribe(printConfigMapfish => {
      if (printConfigMapfish) {
        printConfigMapfish.layouts.forEach((value: any, key: string) => {
          this.layouts.push(value['name']);
        });

        const layoutInfo = this.getLayoutInfo(printConfigMapfish.layouts[0]);
        this.scales = [];
        if (layoutInfo) {
          this.scales = layoutInfo['scales'];
          this.scales = this.scales.reverse();
        }
        this.calculatePrintScale();
      }
    });

    const subscriptionUtm$ = this.appState.epsg$.subscribe(newUtm => {
      newUtm && newUtm !== '' ? this.calculatePrintScale() : null;
    });

    this.subscriptions.add(subscriptionPrintConfigFromMapfish$);
    this.subscriptions.add(subscriptionUtm$);
  }

  getLayoutInfo(layout: any) {
    const mapAttribute = { info: null };
    const attributes = layout.attributes;
    for (let i = 0; i < attributes.length; i++) {
      const a = attributes[i];
      if (a.name === 'map') {
        mapAttribute.info = a.clientInfo;
      }
    }
    return mapAttribute.info;
  }

  onSubmit(): void {
    const printFormFormat = this.printForm.value.format;
    const printFormAllValue = this.printForm.value;
    this.showLoader = true;
    this.printService
      .postPrintSpec(this.specPrintService.getPrintPayload(printFormAllValue), printFormFormat)
      .subscribe(
        res => {
          const statusUrl = res.statusURL;
          this.printService
            .pollUntilDone(statusUrl, PrintConfig.INTERVAL, PrintConfig.TIMEOUT)
            .then((val: any) => {
              this.showLoader = false;
              window.location.assign(val);
            })
            .catch((err: any) => {
              this.showLoader = false;
              this.alert(err);
            });
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            this.showLoader = false;
            this.alert(err.error.message);
          } else {
            //Backend returns unsuccessful response codes such as 404, 500 etc.
            this.showLoader = false;
            this.alert(err.error);
          }
          this.showLoader = false;
          this.alert(err);
        }
      );
  }

  /**
   * [Common alertbox for only print]
   * @param  err               [descrition of error]
   * @return     [show alertbox]
   */
  alert(err: any) {
    Swal.fire({
      title: ApiConfig.defaultErrorHeader,
      html: 'Lage kartbildet feilet: <BR> ' + err.message,
      icon: 'error',
      confirmButtonText: 'OK',
    });
  }

  /**
   * Calculate the print scale based on the current map view
   * this.mapScale is set to the calculated value, defaults to this.mapScales[0].value
   * if scale logic fails to detect scale.
   */
  calculatePrintScale(): void {
    const mapExtent = this.mapService.getViewExtent();
    const mapExtentSize = PrintTools.getSizeOfExtent(mapExtent);
    const goal = mapExtentSize / 2;
    if (this.scales && this.scales.length > 0) {
      this.mapScale =
        '' +
        this.scales.reduce((prev, curr) =>
          Math.abs(curr / PrintConfig.MAP_SCALE_CONVERSION - goal) <
          Math.abs(prev / PrintConfig.MAP_SCALE_CONVERSION - goal)
            ? curr
            : prev
        );
    } else return;

    this.printForm.controls['scale'].setValue(+this.mapScale);
    this.setPrintScale(this.mapScale);
    this.handleNewLayouts();
    this.mapPrintService.updatePrintBox(false);
  }

  /**
   * Set the print scale in the map service
   * @param {string}          scale           new print scale
   * * @param {boolean = true}  updatePrintBox  [optional] whether or not we
   *                                                     need to update the
   *                                                     printBox after change
   */
  setPrintScale(scale: string, updatePrintBox: boolean = true): void {
    this.mapPrintService.setPrintScale(parseInt(scale, 10));
    if (updatePrintBox) {
      this.mapPrintService.updatePrintBox(true);
    }
  }

  /**
   * Set the paper type in the map service
   * @param {string}          layouts           new print-layouts eks: "A4 liggende"
   * @param {boolean = true}  updatePrintBox  [optional] whether or not we
   *                                                     need to update the
   *                                                     printBox after change
   */
  setPrintPaperAndLayout(layouts: string, updatePrintBox: boolean = true): void {
    const paper = layouts[0];
    const layout = layouts[1] === 'liggende' ? 'LANDSCAPE' : 'PORTRAIT';
    this.mapPrintService.setPrintPaper(paper);
    this.mapPrintService.setPrintLayout(layout);
    if (updatePrintBox) {
      this.mapPrintService.updatePrintBox(true);
    }
  }

  /**
   * Do the stuff that has to be done when scale of print-box is changed
   */
  public handleNewScale(): void {
    const scale = this.printForm.controls['scale'].value;
    this.setPrintScale(scale);
  }

  /**
   * Do the stuff that has to be done when layouts of print-box is changed
   */
  public handleNewLayouts(): void {
    const layouts = this.printForm.controls['pageLayout'].value
      .match(/\((.*)\)/)
      .pop()
      .split(' ');
    this.setPrintPaperAndLayout(layouts);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
