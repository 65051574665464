<h1 mat-dialog-title>
  <mat-toolbar role="toolbar" class="task-header">
    <span> {{ data.title }}</span>
  </mat-toolbar>
</h1>
<div class="buttons-wrapper">
  <div>
    <a
      class="dialog-btn"
      mat-button
      [href]="data.reportData.pdfDownloadUrl"
      target="_blank"
      aria-label="Lenke til pdf-rapport">
      Hent pdf-rapport
    </a>
    <a class="dialog-btn" mat-button [href]="getCsv()" aria-label="Lenke til csv-fil" download> Hent csv-fil </a>
  </div>
  <button class="dialog-btn" mat-button mat-dialog-close>Lukk</button>
</div>
