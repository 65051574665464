import { CdkDragEnd } from '@angular/cdk/drag-drop';
import { Component, Inject, ViewContainerRef } from '@angular/core';
import {
  _MatLegacyDialogContainerBase as _MatDialogContainerBase,
  LegacyDialogPosition as DialogPosition,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { AppConfig } from '@kildenconfig/app.config';
import { DialogType } from '@kildencore/services/dialog.service';
import { ObjectinfoService } from '@kildencore/services/objectinfo.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'kilden3-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css'],
})
export class Kilden3DialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private readonly _dialogRef: MatDialogRef<Kilden3DialogComponent>,
    private readonly _objectinfoService: ObjectinfoService,
    private readonly _viewContainerRef: ViewContainerRef
  ) {}

  /**
   * Expose method for use when typed as DialogType
   * @see DialogType
   */
  afterClosed(): Observable<void> {
    return this._dialogRef.afterClosed();
  }

  /**
   * Expose method for use when typed as DialogType
   * @see DialogType
   */
  afterOpened(): Observable<void> {
    return this._dialogRef.afterOpened();
  }

  beforeClosed(): Observable<void> {
    return this._dialogRef.beforeClosed();
  }

  /**
   * Expose method for use when typed as DialogType
   * @see DialogType
   */
  close(): void {
    this._dialogRef.close();
  }

  closeDialog(): void {
    this._dialogRef.close();
  }

  getContainer(): _MatDialogContainerBase {
    return this._dialogRef._containerInstance;
  }

  getNativeElement(): HTMLElement {
    return this._viewContainerRef.element.nativeElement;
  }

  getSize(): { height?: number; width?: number } {
    const featureInfoOverlay = document.getElementsByClassName(AppConfig.ID_DIALOG_FEATURE_INFO);
    const containers = featureInfoOverlay[0].getElementsByClassName('mat-dialog-container');

    if (!containers[0]) {
      return { height: undefined, width: undefined };
    }

    const rect = containers[0].getBoundingClientRect();
    return { height: rect.height, width: rect.width };
  }

  getViewContainer(): ViewContainerRef {
    return this._viewContainerRef;
  }

  /**
   * Store the x,y position in objService
   */
  onDragEnded(event: CdkDragEnd): void {
    const { x, y } = event.source.getRootElement().getBoundingClientRect();
    this._objectinfoService.setStoredDialogPosition(x, y);
  }

  preventDrop(e: any) {
    e.preventDefault();
    e.dataTransfer.effectAllowed = 'none';
    e.dataTransfer.dropEffect = 'none';
  }

  /**
   * Expose method for use when typed as DialogType
   * @see DialogType
   */
  updatePosition(position?: DialogPosition): DialogType {
    this._dialogRef.updatePosition(position);
    return this;
  }
}
