import { formatNumber } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppConfig } from '@kildenconfig/app.config';
import { ArrayHelper } from '@kildencore/helpers/array.helper';
import { LayerHelper } from '@kildencore/helpers/layer.helper';
import { ZoomInfo } from '@kildencore/models';
import { CatalogTreeService } from '@kildencore/services/data/catalog-tree.service';
import { LayersConfigService } from '@kildencore/services/data/layers-config.service';
import { KildenStateService } from '@kildencore/services/kilden-state.service';
import { allowedQueryParams, PermaLinkService } from '@kildencore/services/perma-link.service';
import { ThemeLayersService } from '@kildencore/services/theme-layers.service';
import { LayerChange } from '@kildenshared/interfaces';
import { CatalogLayerTimeOptionsInterface } from '@kildenshared/interfaces/catalog-layer-time-options.interface';
import { CatalogLayerVariantInterface } from '@kildenshared/interfaces/catalog-layer-variant.interface';
import { CatalogLayerVariantsInterface } from '@kildenshared/interfaces/catalog-layer-variants.interface';
import { LayerConfigInterface } from '@kildenshared/interfaces/layer-config.interface';
import { LayerConfigsType } from '@kildenshared/interfaces/layer-configs.type';
import { filter, Subscription, tap } from 'rxjs';
import { CatalogTreeItem } from './catalog-tree-item';

@Component({
  selector: 'kilden3-catalog-tree',
  templateUrl: './catalog-tree.component.html',
  styleUrls: ['./catalog-tree.component.css'],
})
export class CatalogTreeComponent implements OnInit, OnDestroy {
  catalogTree: CatalogTreeItem[] = [];
  layerConfigs!: LayerConfigsType;
  topic!: string;
  zoom!: ZoomInfo;

  private _startupLayers: Partial<CatalogTreeItem>[] = [];
  // Flag to detect the initialization of catalogTree
  private initDone = false;
  private subscriptions = new Subscription();
  private checkedLayers: CatalogTreeItem[] = [];

  constructor(
    private appState: KildenStateService,
    private cts: CatalogTreeService,
    private lc: LayersConfigService,
    private permaLink: PermaLinkService,
    private tls: ThemeLayersService
  ) {}

  /**
   * executeKeydown catch keybordevent and do som action
   * catch: enter, space, all arrows
   * @param  event
   */
  public executeKeydown(event: KeyboardEvent): void {
    const target = event.target as HTMLButtonElement;
    const id = target.getAttribute('id') as string;

    switch (event.key) {
      case 'Enter':
        this.navigate(this.catalogTree, id, 'root', 'toggleLayer', event);
        break;
      case 'Space':
      case ' ': // Some browsers report simply ' ' when hitting the spacebar
        event.preventDefault();
        this.navigate(this.catalogTree, id, 'root', 'toggleLayerChild', event);
        break;
      case 'ArrowUp':
        event.preventDefault();
        target.previousElementSibling
          ? (target.previousElementSibling as any).focus()
          : (target.parentElement?.lastElementChild as any).focus();
        break;
      case 'ArrowDown':
        event.preventDefault();
        target.nextElementSibling
          ? (target.nextElementSibling as any).focus()
          : (target.parentElement?.firstElementChild as any).focus();
        break;
      case 'ArrowLeft':
        this.navigate(this.catalogTree, id, 'root', 'goToParent', event);
        break;
      case 'ArrowRight':
        this.navigate(this.catalogTree, id, 'root', 'goToFirstChild', event);
        break;
      case 'Tab':
        event.preventDefault();
        if (event.shiftKey) {
          document.getElementById('mat-tab-label-0-0')?.focus();
        } else {
          document.getElementById('sidenavToggle')?.focus();
        }
        break;
    }
  }

  /**
   * Recursive function for marking the layers listed in the selectedIds array
   * if selectedIds are  ['jm_dekning', 'jordkvalitet', 'jm_erosjonsrisiko_flateerosjon']
   * the layers with these bodIds will have their selectedOpen = true when this function
   * has traversed the complete catalogTree.
   * Will change the input object item.
   *
   * @param  item                      CatalogTreeItem
   * @param  selectedIds               array of strings with bodIds
   * @param  parentCatalogs            array with item's parantcatalogs
   * @return
   */
  public markSelected(
    item: CatalogTreeItem,
    selectedIds: string[],
    parentCatalogs: CatalogTreeItem[],
    holdUpdate?: boolean
  ) {
    if (item.category === 'layer' && typeof item.idBod !== 'undefined') {
      this.toggleLayerItem(item, selectedIds.includes(item.idBod), holdUpdate);

      if (item.selectedOpen) {
        parentCatalogs.forEach(p => {
          if (typeof p === 'object' && p.category === 'folder') {
            p.expanded = true;
          }
        });
      }
    } else if (item.category === 'folder' && typeof item.children !== 'undefined') {
      // Only add as parent if it has nested children, or it is the direct parent of a selectedId.
      // We don't want to add siblings of parents, only direct parents/ancestors. Ref. Task KILDEN3-380.
      if (
        item.children.some(child => {
          return (
            (child.idBod && selectedIds.includes(child.idBod)) ||
            (child.category === 'folder' && child.children !== undefined)
          );
        })
      ) {
        parentCatalogs.push(item);
      }

      for (const i of item.children) {
        this.markSelected(i, selectedIds, parentCatalogs, holdUpdate);
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    const subscriptionTopic$ = this.appState.topic$.subscribe(newTopic => {
      if (newTopic !== this.topic) {
        this.topic = newTopic;
        if (this.topic?.length) {
          this.cts.refreshLayersList(this.topic);
        }
      }
    });

    const subscriptionCatalogTree$ = this.cts.catalogTree$
      .pipe(
        filter(catalogTree => catalogTree !== undefined),
        tap(catalogTree => {
          this.catalogTree = [];

          if (catalogTree) {
            this.catalogTree = catalogTree?.children as CatalogTreeItem[];
            // Check if urlParams should be used to initialize checked layers
            if (!this.initDone) {
              // This is the first time we run this code
              this.initializeFromUrlParams(this.catalogTree);
              this.initDone = true;
            } else {
              // reset layers on topic change
              this._startupLayers = [];
            }
            this.lc.fetchLayerConfigsByTopic(this.topic);
          }
        })
      )
      .subscribe();

    const subscriptionLayerConfig$ = this.lc.layerConfigs$.subscribe(layerConfig => {
      if (layerConfig) {
        this.layerConfigs = layerConfig;

        this.markFirstLevelLayer(this.catalogTree);
        this.updateTreeWithConfigInfo(this.catalogTree, this.layerConfigs);
        this.updateFromConfig(this.catalogTree);

        // Remove user drawn layer from startup, as it would always be empty and should always be added on top later
        const drawIdx = this._startupLayers.findIndex(sl => sl.idBod === AppConfig.IDBOD_USER_DRAWN);
        if (drawIdx > -1) {
          this._startupLayers.splice(drawIdx, 1);
        }

        if (!this._startupLayers?.length) {
          // StartupLayers has not been set when layersConfig$ is received,
          // hence any checked layers in catalogTree decide active layers
          this.checkedLayers = [];
          this._startupLayers = this._getCheckedLayers(this.catalogTree);
        }

        // Notify service which layers should be active
        if (this._startupLayers.length) {
          this.tls.registerLayerChanges(
            this._startupLayers.map(layer => {
              return {
                layerid: layer.idBod,
                change: {
                  active: true,
                  opacity: layer.opacity,
                  selectedTimeOption: layer.timeOptions?.selected,
                  visible: layer.visible,
                },
              } as LayerChange;
            })
          );
          if (drawIdx > -1) {
            this._startupLayers.splice(drawIdx, 1);
          }

          if (!this._startupLayers?.length) {
            // StartupLayers has not been set when layersConfig$ is received,
            // hence any checked layers in catalogTree decide active layers
            this._startupLayers = this._getCheckedLayers(this.catalogTree);
          }

          // Notify service which layers should be active
          if (this._startupLayers.length) {
            this.tls.registerLayerChanges(
              this._startupLayers.map(layer => {
                return {
                  layerid: layer.idBod,
                  change: {
                    active: true,
                    opacity: layer.opacity ?? layer.config?.opacity,
                    selectedTimeOption: layer.timeOptions?.selected,
                    visible: layer.visible,
                  },
                } as LayerChange;
              })
            );
          }
        }
      }
    });

    const subscriptionCatalogTreeCategoryExpand$ = this.cts.categoryExpand$
      .pipe(
        filter(treeItem => treeItem !== undefined),
        tap((treeItem: CatalogTreeItem) => {
          treeItem.expanded = true;
        })
      )
      .subscribe();

    this.subscriptions.add(subscriptionTopic$);
    this.subscriptions.add(subscriptionCatalogTree$);
    this.subscriptions.add(subscriptionCatalogTreeCategoryExpand$);
    this.subscriptions.add(subscriptionLayerConfig$);
  }

  /**
   * Toggle whether given item (folder) is expanded
   */
  toggleFolderExpanded(item: CatalogTreeItem): void {
    item.expanded = !item.expanded;
  }

  /**
   * Get parent and children and toggler checked on each child
   */
  toggleLayerChild(parent: CatalogTreeItem, children: CatalogTreeItem[], event: Event): void {
    event.stopPropagation(); // stop bubbling click-event to parent
    this.toggleLayerItem(parent);
    const layerIds: string[] = [];

    children.forEach(c => {
      c.selectedOpen = parent.selectedOpen;
      if (c.idBod) {
        layerIds.push(c.idBod);
      }
    });

    if (layerIds.length) {
      this.tls.registerLayerChanges(
        LayerHelper.generateLayerChanges(layerIds.join(','), 'active', parent.selectedOpen ?? true)
      );
    }
  }

  /**
   * Toggle given layer active or not
   */
  toggleLayerItem(item: CatalogTreeItem, enableLayer?: boolean, holdUpdate?: boolean): void {
    if (enableLayer !== undefined) {
      item.selectedOpen = enableLayer;
    } else {
      item.selectedOpen = !item?.selectedOpen;
    }

    item.visible = item.selectedOpen;

    // Notify service of the change in layers
    if (item.idBod) {
      this.tls.registerLayerChanges([
        {
          layerid: item.idBod,
          change: { active: item.selectedOpen },
          holdUpdate,
        } as LayerChange,
        {
          layerid: item.idBod,
          change: { visible: item.selectedOpen },
          holdUpdate,
        } as LayerChange,
      ]);
    }
  }

  private _buildResolutionText(layerConfig: Partial<LayerConfigInterface>) {
    const texts = {
      available_at_scale: 'Tilgjengelig i målestokk',
      available_at_scale_gt: 'Tilgjengelig i målestokk større enn',
      available_at_scale_lt: 'Tilgjengelig i målestokk mindre enn',
    };
    const min = layerConfig.minScale || 0;
    const max = layerConfig.maxScale || Infinity;
    const maxStr = formatNumber(max, 'nb');
    const minStr = formatNumber(min, 'nb');
    if (typeof min === 'undefined' || min === 0) {
      return texts.available_at_scale_gt + ' 1:' + maxStr;
    } else if (max === 0) {
      return texts.available_at_scale_lt + ' 1:' + minStr;
    }
    return texts.available_at_scale + ' 1:' + maxStr + ' til 1:' + minStr;
  }

  /**
   * Get all checked layers from LayerTree
   */
  private _getCheckedLayers(treeItems: CatalogTreeItem[]): CatalogTreeItem[] {
    //const checkedLayers: CatalogTreeItem[] = [];
    treeItems.forEach(item => {
      if (item.category === 'layer' && item.selectedOpen && !this.checkedLayers.includes(item)) {
        this.checkedLayers.push(item);
      } else if (item.children) {
        this._getCheckedLayers(item.children);
      }
    });
    return this.checkedLayers;
  }

  /**
   * Let urlParameters override which layers are shown by default.
   * Reads urlParams, fills this.startupLayers and marks items in CatalogTree.
   */
  private initializeFromUrlParams(catalogTree: CatalogTreeItem[]) {
    const urlLayers = this.permaLink.getInitialValue(allowedQueryParams.LAYERS)?.toString();
    const urlLayersOpacity = this.permaLink.getInitialValue(allowedQueryParams.LAYERS_OPACITY)?.toString();
    const urlLayersVisibility = this.permaLink.getInitialValue(allowedQueryParams.LAYERS_VISIBILITY)?.toString();

    if (typeof urlLayers !== 'undefined' && urlLayers.trim() !== '') {
      // There are layers set in the initial permalink, and this must
      // override the default settings of selectedOpen
      const opacityArr = urlLayersOpacity?.split(AppConfig.QP_SEPARATOR_PRIMARY);
      const visibilityArr = urlLayersVisibility?.split(AppConfig.QP_SEPARATOR_PRIMARY);

      const layerTimes = this._parseLayerTimesUrl();
      const layerVariants = this._parseLayerVariantsUrl();

      // For each item in layers urlParam, check layers_opacity and _visibility
      urlLayers.split(AppConfig.QP_SEPARATOR_PRIMARY).forEach((l, idx) => {
        if (l.length) {
          const itemFromUrlParams = {
            idBod: l,
            selectedOpen: true,
            visible: AppConfig.DEFAULT_LAYER_VISIBILITY,
          } as Partial<CatalogTreeItem>;

          if (opacityArr && opacityArr[idx] !== undefined) {
            const parsed = parseFloat(opacityArr[idx]);
            if (parsed >= 0 && parsed <= 1) {
              // use value from url if within bounds
              itemFromUrlParams.opacity = parsed;
            }
          }

          if (visibilityArr && visibilityArr[idx] !== undefined) {
            itemFromUrlParams.visible = visibilityArr[idx] === 'true';
          }

          // TimeOptions
          const layerTime = layerTimes.get(l);
          // Check undefined as 0 or other falsey could be valid values
          if (layerTime !== undefined) {
            if (itemFromUrlParams.timeOptions) {
              itemFromUrlParams.timeOptions.selected = layerTime;
            } else {
              itemFromUrlParams.timeOptions = {
                selected: layerTime,
              } as CatalogLayerTimeOptionsInterface;
            }
          }

          // Variants (WMS param "Layers")
          const layerVariant = layerVariants.get(l);
          // Check undefined as 0 or other falsey could be valid values
          if (layerVariant !== undefined) {
            let variant = {
              label: layerVariant,
              value: layerVariant,
            } as CatalogLayerVariantInterface;

            if (itemFromUrlParams.variants) {
              if (itemFromUrlParams.variants.items?.length) {
                const foundItem = itemFromUrlParams.variants.items.find(it => it.value === layerVariant);
                if (foundItem) {
                  variant = foundItem;
                }
              }

              itemFromUrlParams.variants.selected = variant;
            } else {
              itemFromUrlParams.variants = {
                default: variant,
                items: [variant],
                selected: variant,
              } as CatalogLayerVariantsInterface;
            }
          }

          // Look in our CatalogTree for the layerId we found in the URL
          const treeItem: CatalogTreeItem = ArrayHelper.nestedFind(catalogTree, l, 'idBod', 'children');

          if (treeItem) {
            // Match found, merge in the new layerAttributes from URL
            // TODO: Does this need nestedAssign?
            Object.assign(treeItem, itemFromUrlParams);
            this._startupLayers.push(treeItem);
          } else {
            this._startupLayers.push(itemFromUrlParams);
          }
        }
      });

      for (const i of catalogTree) {
        this.markSelected(i, urlLayers.split(AppConfig.QP_SEPARATOR_PRIMARY), [catalogTree], true);
      }
      this.permaLink._updateQueryParamsForLayers();
    }
  }

  /**
   * we need to now who is on first level
   * @param arr  CatalogTreeItem
   */
  private markFirstLevelLayer(arr: CatalogTreeItem): void {
    for (const child of arr) {
      child.firstLevel = true;
    }
  }

  /**
   * Take params, find item do some action described by handler
   * @param  layerTree               [layerslist]
   * @param  layerId                 [layer.id + layer.label]
   * @param  parentId                [parentid]
   * @param  handler                 [describe which action]
   * @param  event                   [event from dom, after each keybordnavigation]
   */
  private navigate(layerTree: CatalogTreeItem[], layerId: string, parentId: string, handler: string, event: any): void {
    for (const layer of layerTree) {
      if (layer.id && layer.label) {
        const concatenatedId = layer.id + layer.label;
        if (layer.id && concatenatedId === layerId) {
          if (handler === 'toggleLayer') {
            layer.category === 'layer' ? this.toggleLayerItem(layer) : null;
            layer.category === 'folder' ? this.toggleFolderExpanded(layer) : null;
          } else if (handler === 'toggleLayerChild') {
            if (layer.category === 'folder' && layer.parentCheckbox && layer.children) {
              this.toggleLayerChild(layer, layer.children, event);
            }
            if (layer.category === 'layer') {
              this.toggleLayerItem(layer);
            }
          } else if (
            handler === 'goToFirstChild' &&
            layer.children &&
            layer.children[0].id &&
            layer.children[0].label
          ) {
            const firstChild = layer.children[0].id + layer.children[0].label;
            const element = document.getElementById(firstChild);
            element ? element.focus() : event.target.focus(); // if element exist give focus or stay were you are
          } else if (handler === 'goToParent') {
            const element = document.getElementById(parentId);
            element ? element.focus() : event.target.focus();
          }
        } else {
          if (layer.children) {
            this.navigate(layer.children, layerId, concatenatedId, handler, event);
          }
        }
      }
    }
  }

  /*
            max       |  min          |  what?        | return
            ---------------------------------------------------
            0         |  0,n,undefined| not posible   | false
            undefined |  0            | always visible| false
            undefined |  undefined    | not possible  | false
            undefined |  n            | limitations   | resolution > min
            n         |  0,undef      |limitations    | resolution < max
            n         |  n            |limitations    | resolution < max
                                                        resolution > min
          */
  private outsideResolutionInterval(currentResolution: number, max: number, min: number) {
    if (typeof max === 'undefined') {
      if (typeof min === 'undefined') {
        return false;
      }
      if (min === 0) {
        return false;
      }
      return currentResolution > min;
    }
    // not possible, max should never be 0
    if (max === 0) {
      return false;
    }
    if (typeof min === 'undefined') {
      min = 0;
    }
    if (max < min) {
      return false;
    }
    return currentResolution > max || currentResolution < min;
  }

  /**
   * Recursive function that padds the catalogTree-structure with
   * information from the layerConfig structure. Max and min resolution are
   * added to the structure.
   */
  private updateFromConfig(tree: CatalogTreeItem[]): void {
    if (!this.layerConfigs || Object.keys(this.layerConfigs).length < 1) {
      return;
    }

    type LayerConfigKey = keyof typeof this.layerConfigs;
    for (const i of tree) {
      if (i.category === 'layer') {
        const key = i.idBod as LayerConfigKey;
        if (this.layerConfigs[key]) {
          const config = this.layerConfigs[key];
          i.maxResolution = (config?.maxResolution || 5000) as number;
          i.minResolution = (config?.minResolution || 0) as number;
        }
      } else {
        if (i.children) {
          this.updateFromConfig(i.children);
        }
      }
    }
  }

  private updateTreeWithConfigInfo(layerTree: CatalogTreeItem, layerConfig: LayerConfigsType) {
    type LayerConfigKey = keyof typeof layerConfig;
    for (const layer of layerTree) {
      if (layer.category === 'layer') {
        const key = layer.idBod as LayerConfigKey;
        if (layerConfig[key]) {
          layer.config = layerConfig[key];
          layerConfig[key].idBod = key as string;
          layer.resolutionMessage = this._buildResolutionText(layerConfig[key]);
        }
      } else {
        if (layer.children) {
          this.updateTreeWithConfigInfo(layer.children, layerConfig);
        }
      }
    }
  }

  /**
   * Fetch and parse any specified layers_time url params
   * @example &layers_time=vekstsesongslengde:1983,helling:2001&layers=vekstsesongslengde,helling
   */
  private _parseLayerTimesUrl(): Map<string, number> {
    const response = new Map<string, number>();
    const urlLayersTime = this.permaLink.getInitialValue(allowedQueryParams.LAYERS_TIME)?.toString();
    if (!urlLayersTime) {
      return response;
    }

    urlLayersTime.split(AppConfig.QP_SEPARATOR_PRIMARY).forEach(layerParam => {
      const segments = layerParam.split(AppConfig.QP_SEPARATOR_SECONDARY);
      if (segments.length === 2) {
        response.set(segments[0], parseInt(segments[1]));
      }
    });
    return response;
  }

  /**
   * Fetch and parse any specified layers_variant url params
   * @example &layers_variant=norgeibilder_2:Porsgrunn2013,norgeibilder_satelite:Skien1966&layers=norgeibilder_2,norgeibilder_satelite
   */
  private _parseLayerVariantsUrl(): Map<string, string> {
    const response = new Map<string, string>();
    const urlLayersVariant = this.permaLink.getInitialValue(allowedQueryParams.LAYERS_VARIANT)?.toString();
    if (!urlLayersVariant) {
      return response;
    }

    urlLayersVariant.split(AppConfig.QP_SEPARATOR_PRIMARY).forEach(layerParam => {
      const segments = layerParam.split(AppConfig.QP_SEPARATOR_SECONDARY);
      if (segments.length === 2) {
        response.set(segments[0], segments[1]);
      }
    });
    return response;
  }
}
