import { ProjectConfigType } from '@kildenshared/types/project-config.type';

export class VegaInitConfig {
  public static readonly config = VegaInitConfig.getConfig();
  private static getConfig() {
    const configVega: ProjectConfigType = {
      name: 'vega',
      backend: 'vegakilden_ws',
      mapfishApp: 'vegakilden',
      printTitle: 'Kart fra Vegakilden',
      backgroundLayer: 'farger',
      title: 'Vegakilden',
      logo: 'assets/vega/vega_logo_black_60px.png',
      logoUrl: 'https://www.verdensarvvega.no/',
      logo2: 'assets/vega/landbruksdirektoratet_logo_60px.png',
      logo3: 'assets/vega/nordland_60px.png',
      logo4: 'assets/vega/stiftelsen_60px.png',
      logo5: 'assets/vega/nn_logo_vega_verneomraadestyre_60px.png',
      layerSearch: false,
      threeD: false,
      startCoordinates: { x: 7294849, y: 360272, z: 6 },
      zoomExtentController: [329900, 7276010, 390650, 7313650],
    };
    return configVega;
  }
}
