declare var require: any; // hack for jsts

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
} from '@angular/material/legacy-dialog';
import { ApiConfig } from '@kildenconfig/api-config';
import { AppConfig } from '@kildenconfig/app.config';
import { DrawHelper } from '@kildencore/helpers/draw.helper';
import { DrawLayer, Report, ReportService } from '@kildencore/services/data/report.service';
import { DialogService, DialogVariants } from '@kildencore/services/dialog.service';
import { DrawService } from '@kildencore/services/draw.service';
import { KeyboardService } from '@kildencore/services/keyboard.service';
import { KildenStateService } from '@kildencore/services/kilden-state.service';
import { MapService } from '@kildencore/services/map.service';
import { PermaLinkService } from '@kildencore/services/perma-link.service';
import { UploadService } from '@kildencore/services/upload.service';
import { ReportDialogComponent } from '@kildenshared/components/report/report-dialog/report-dialog.component';
import { ColorsConst } from '@kildenshared/constants/draw/colors.const';
import { ToolIdsEnum } from '@kildenshared/constants/tool-ids.enum';
import { BackgroundLayerInterface } from '@kildenshared/interfaces/background-layer.interface';
import { DrawType } from '@kildenshared/types/draw/draw.type';
import { ReportFeature } from '@kildenshared/types/report/report-feature.type';
import { ReportMapLayer } from '@kildenshared/types/report/report-map-layer.type';
import { ReportStatus } from '@kildenshared/types/report/report-status.type';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import { LinearRing, LineString, MultiLineString, MultiPoint, MultiPolygon, Point, Polygon } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import Overlay from 'ol/Overlay';
import VectorSource from 'ol/source/Vector';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import { map, Subject, take, takeUntil, tap } from 'rxjs';
import Swal from 'sweetalert2';

const jstsParser = require('jsts/org/locationtech/jts/io').OL3Parser;
const BufferOp = require('jsts/org/locationtech/jts/operation/buffer').BufferOp;

type Tools = {
  id: DrawType;
  name: string;
  iconClass: string;
  hideMobile: boolean;
};

type BufferItem = {
  id: number;
  size: string;
};

const MAX_NUMBER_DRAWINGS = 10;
const MAX_AREA = 100;
const MAX_LINE = 50;

const parser: any = new jstsParser();
parser.inject(Point, LineString, LinearRing, Polygon, MultiPoint, MultiLineString, MultiPolygon);

@Component({
  selector: 'kilden3-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css'],
})
export class ReportComponent implements OnInit, OnDestroy {
  bufferItems: BufferItem[] = [];
  chosenTool = 'None';
  drawItems: DrawLayer[] | undefined;
  geoTypesAutoBuffer = AppConfig.GEOTYPES_REPORT_AUTO_BUFFER;
  loadingReport = false;
  reportMapLayers: ReportMapLayer[] = [];

  private readonly _onDestroy$ = new Subject<void>();

  private backgroundList: BackgroundLayerInterface[] | undefined;
  private currentDrawNumber = 0;
  private drawBufferId: string;
  private drawId: string;

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: any,
    private readonly _dialogService: DialogService,
    private readonly _drawService: DrawService,
    private readonly _keyboardService: KeyboardService,
    private readonly _kildenStateService: KildenStateService,
    private readonly _mapService: MapService,
    private readonly _permaLinkService: PermaLinkService,
    private readonly _reportService: ReportService,
    private readonly _uploadService: UploadService
  ) {
    this.drawId = dialogData.reportType;
    this.drawBufferId = `${dialogData.reportType}Buffer`;
    this._mapService.bgListReceived$.subscribe(bg => (this.backgroundList = bg.list));
  }

  showTips: boolean = false;
  tools: Tools[] = [
    { id: 'LineString', name: 'Linje', iconClass: 'icon-ga-line', hideMobile: false },
    {
      id: 'Polygon',
      name: 'Flate',
      iconClass: 'icon-ga-polygon',
      hideMobile: false,
    },
    { id: 'File', name: 'Fil', iconClass: 'icon-upload', hideMobile: false },
  ];

  ngOnInit(): void {
    this.fetchReportMapLayers();
    this.initBufferLayer();
    this.initUploadedGeometries();
    this.initDraw();
    this.initDrawingSubscription();
    setTimeout(() => {
      this.initDrawItemsList();
      this.initAreaControl();
      this.initLineControl();
      this.chooseDrawTool('Modify');
      this.setDrawLayersVisibility(true);
      this.setOverlaysVisibility(true);
    }, 1);
  }

  ngOnDestroy() {
    // console.log(`ondestroy report`);

    this._kildenStateService.tool$.pipe(take(1)).subscribe(tool => {
      if (tool === ToolIdsEnum.REPORT) {
        this._kildenStateService.changeTool(ToolIdsEnum.INFO);
      }
    });
    this.terminatePolling();
    this._onDestroy$.next();
    this._onDestroy$.complete();

    this.setDrawLayersVisibility(false);
    this.setOverlaysVisibility(false);
    this._drawService.endDrawInteractions();
  }

  private fetchReportMapLayers() {
    const processForForestReport = (e: ReportMapLayer[]) => {
      return e.map(l => {
        if (l.id === 'skogressurs_volum_v') {
          return { ...l, checked: false };
        }
        return l;
      });
    };

    this._reportService
      .getReportMapLayers(this.drawId)
      .pipe(
        map((e: ReportMapLayer[]) => {
          return e.map(l => ({
            ...l,
            checked: true,
          }));
        })
      )
      .subscribe(e => {
        this.reportMapLayers = processForForestReport(e);
      });
  }

  private initBufferLayer() {
    if (!this._mapService.getLayer(this.drawBufferId)) {
      this.addBufferLayer(this.drawBufferId);
    }
  }

  private initUploadedGeometries() {
    this._uploadService.uploadedFeatures$.pipe(takeUntil(this._onDestroy$)).subscribe(() => {
      const uploaded = this._mapService.getLayer(AppConfig.IDBOD_REPORT_UPLOADED);
      if (uploaded) {
        uploaded
          .getSource()
          .getFeatures()
          .forEach((f: any) => {
            const geoType = f.getGeometry().getType();
            f.setId(f.ol_uid);
            this._reportService.addToStore({
              id: f.ol_uid,
              type: geoType,
              layerId: AppConfig.IDBOD_REPORT_UPLOADED,
              source: 'Opplastet',
            });

            // Add buffer to geoTypes lacking an area, as only features with an area will be included in reports
            if (AppConfig.GEOTYPES_REPORT_AUTO_BUFFER.includes(geoType)) {
              this.initBufferSize();
              this.setBuffer(f.ol_uid);
            }
          });
      }
    });
  }

  private initDraw() {
    this._kildenStateService.changeTool(ToolIdsEnum.REPORT);
    this._drawService.startDraw(this.drawId);
    this._drawService.changeDrawColor(ColorsConst[0].color);
    this._drawService.changeDrawStrokeWidth(3);
    // this.chooseDrawTool('LineString');
    // this._kildenStateService.changeTool('draw');
  }

  private addBufferLayer(id: string) {
    const bufferLayer = new VectorLayer({
      properties: { altitudeMode: 'clampToGround' },
      source: new VectorSource(),
      style: new Style({
        fill: new Fill({
          color: [75, 124, 164, 0.2],
        }),
        stroke: new Stroke({
          color: [75, 124, 164, 1],
          width: 2,
        }),
      }),
    });
    bufferLayer.setZIndex(AppConfig.ZINDEX_UPLOADED_DRAWING);
    this._mapService.addLayer(id, bufferLayer);
  }

  private initDrawingSubscription() {
    this._drawService.onDrawAbort$
      .pipe(
        tap(() => {
          this._unselectTool();
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();

    this._drawService.onDrawEnd$
      .pipe(
        tap(() => {
          this._unselectTool();
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();

    this._drawService.drawing$.pipe(takeUntil(this._onDestroy$)).subscribe(draw => {
      if (draw.layerId !== this.drawId) return;
      this.currentDrawNumber = draw.id;

      if (this._reportService.store.length >= MAX_NUMBER_DRAWINGS) {
        if (this._drawService.interactionDraw) {
          this._drawService.interactionDraw.setActive(false);
          this.warningPopup(`Kan ikke tegne mer enn ${MAX_NUMBER_DRAWINGS} objekter`);
        }
      } else {
        this._reportService.addToStore({
          id: draw.id,
          type: this.chosenTool,
          layerId: this.drawId,
        });
        this.initBufferSize();
      }
    });
  }

  private initDrawItemsList() {
    this.drawItems = this._reportService.store.filter(
      s => s.layerId === this.drawId || s.layerId === AppConfig.IDBOD_REPORT_UPLOADED
    );

    this._reportService.drawings.subscribe(
      d => (this.drawItems = d.filter(e => e.layerId === this.drawId || e.layerId === AppConfig.IDBOD_REPORT_UPLOADED))
    );
  }

  /**
   * TODO: This should ideally be implemented using a geometryFunction
   *  so we can undo the last draw-operation instead of deleting entire object/feature
   *  https://stackoverflow.com/a/56796064/504075
   */
  private initAreaControl() {
    this._drawService.drawArea$.pipe(takeUntil(this._onDestroy$)).subscribe(area => {
      if (area.id !== this.drawId) return;
      if (this.formatArea(area.size) >= MAX_AREA) {
        this._drawService.resetArea();
        setTimeout(() => {
          this.clearDrawObjects();
        }, 10);
        this.warningPopup(
          `Tegnet område er for stort, området kan maks være 100km<sup>2</sup>. Inntegnet område er ${this.formatArea(
            area.size
          )} km<sup>2</sup>`
        );
      } else {
        setTimeout(() => {
          this._drawService.chooseDrawTool('Modify', this.drawId);
        }, 10);
      }
    });
  }

  /**
   * TODO: This should ideally be implemented using a geometryFunction
   *  so we can undo the last draw-operation instead of deleting entire object/feature
   *  https://stackoverflow.com/a/56796064/504075
   */
  private initLineControl() {
    this._drawService.drawLine$.pipe(takeUntil(this._onDestroy$)).subscribe(line => {
      if (line.id !== this.drawId) return;
      if (this.formatLine(line.size) > MAX_LINE) {
        this._drawService.resetLine();
        setTimeout(() => {
          this.clearDrawObjects();
        }, 10);
        this.warningPopup(
          `Tegnet linje er for lang, linjen kan maks være 50km. Inntegnet linje er ${this.formatLine(line.size)} km`
        );
      } else {
        if (this.currentDrawNumber === 0) return;
        setTimeout(() => {
          this._drawService.chooseDrawTool('Modify', this.drawId);
        }, 10);

        setTimeout(() => {
          this.setBuffer(this.currentDrawNumber);
        }, 10);
      }
    });
  }

  private setDrawLayersVisibility(visible = true) {
    this._mapService.getLayer(this.drawId)?.setVisible(visible);
    this._mapService.getLayer(this.drawBufferId)?.setVisible(visible);
  }

  private setOverlaysVisibility(visible = true) {
    this.getActiveOverlays()?.forEach(o => {
      if (visible) {
        o.getElement()?.parentElement?.removeAttribute('hidden');
      } else {
        o.getElement()?.parentElement?.setAttribute('hidden', 'true');
      }
    });
  }

  private initBufferSize() {
    this._reportService.store.forEach(e => this.bufferItems.push({ id: e.id, size: e.bufferSize || '' }));
  }

  private clearDrawObjects() {
    this.removeFeature(this.currentDrawNumber, this.drawId);
    this.removeFeature(this.currentDrawNumber, this.drawBufferId);
    this.removeOverlay(this.currentDrawNumber);
    this._reportService.removeFromStore(this.currentDrawNumber);
  }

  mouseOver(id: number) {
    const feature = this.findFeatureInLayers(id);
    if (!feature) return;
    const geoType = feature.getGeometry()?.getType();
    if (geoType === 'Point') return;

    feature.setStyle(
      new Style({
        stroke: new Stroke({ color: '#ff0000', width: 5 }),
        fill: new Fill({ color: DrawHelper.setTransparency('#ff0000', 0.2) }),
      })
    );
  }

  mouseOut(id: number) {
    const feature = this.findFeatureInLayers(id);
    if (!feature) return;
    const geoType = feature.getGeometry()?.getType();
    if (geoType === 'Point') return;

    feature.setStyle(
      new Style({
        stroke: new Stroke({ color: '#ff0000', width: 3 }),
        fill: new Fill({ color: DrawHelper.setTransparency('#ff0000', 0.2) }),
      })
    );
  }

  private findFeatureInLayers(id: number): Feature | undefined {
    let feature: Feature | undefined;
    [this.drawId, AppConfig.IDBOD_REPORT_UPLOADED].forEach(layerId => {
      if (this.findFeatureById(id, layerId)) {
        feature = this.findFeatureById(id, layerId);
      }
    });

    return feature;
  }

  private findFeatureById(id: number, layerId: string): Feature | undefined {
    let foundFeature = undefined;

    this._mapService
      .getLayer(layerId)
      ?.getSource()
      .getFeatures()
      .forEach((f: Feature) => {
        if (f.getId() === id) {
          foundFeature = f;
        }
      });

    return foundFeature;
  }

  private removeFeature(id: number, layerId: string) {
    this._mapService.getLayer(layerId)?.getSource().removeFeature(this.findFeatureById(id, layerId));
  }

  private removeOverlay(id: number) {
    const overlayToRemove = this._mapService
      .getMap()
      ?.getOverlays()
      .getArray()
      .find(o => (o.getId() as string) == `${this.drawId}${id}`);

    if (overlayToRemove) {
      this._mapService.getMap().removeOverlay(overlayToRemove);
    }
  }

  private formatArea(area: number): number {
    return Math.round((area / 1000000) * 100) / 100;
  }

  private formatLine(line: number): number {
    return Math.round((line / 1000) * 100) / 100;
  }

  private warningPopup(text: string) {
    Swal.fire({
      allowOutsideClick: false,
      title: 'Advarsel',
      html: text,
      icon: 'warning',
      confirmButtonText: 'OK',
    });
  }

  private getActiveOverlays(): Overlay[] {
    return this._mapService
      .getMap()
      ?.getOverlays()
      .getArray()
      .filter(o => (o.getId() as string).includes(this.drawId));
  }

  /**
   * [Choose draw tool and set right geometryType for respective tool]
   * @param  inTool [name of tool]
   */
  chooseDrawTool(inTool: DrawType): void {
    this.chosenTool = inTool;
    if (inTool !== 'File') this._drawService.chooseDrawTool(inTool, this.drawId);
    else this.openUploadDialog();
  }

  deleteDrawObject(id: number) {
    this.removeFeature(id, this.drawId);
    this.removeFeature(id, this.drawBufferId);
    this.removeFeature(id, AppConfig.IDBOD_REPORT_UPLOADED);
    this.removeOverlay(id);
    this._reportService.removeFromStore(id);
    if (this._drawService.interactionDraw) {
      this._drawService.interactionDraw.setActive(true);
    }
  }

  toggleDraw(id: number) {
    this._reportService.updateStoreToggle(id);
  }

  updateBuffer(e: Event, id: number, type: string | undefined) {
    let bufferSize = (e.target as HTMLInputElement).value;
    if (
      type &&
      (type.toLowerCase() === 'line' || type.toLowerCase() === 'multilinestring') &&
      (bufferSize === '0' || bufferSize === '')
    ) {
      bufferSize = '1';
    }

    this.bufferItems = [...this.bufferItems.filter((b: BufferItem) => b.id !== id), { id: id, size: bufferSize }];
  }

  setBuffer(id: number) {
    let currentBufferSize = this.bufferItems.find(b => b.id === id)?.size;

    if (currentBufferSize === undefined) {
      currentBufferSize = '1';
      this._reportService.updateStoreBufferSize(id, currentBufferSize);
    }

    if (currentBufferSize === '0' || this.findFeatureById(id, this.drawBufferId)) {
      this.removeFeature(id, this.drawBufferId);

      if (currentBufferSize === '0') {
        return;
      }
    }

    const feature = this.findFeatureInLayers(id);

    if (!feature) {
      return;
    }

    const jstsGeom = parser.read(feature?.getGeometry());
    const buffered = BufferOp.bufferOp(jstsGeom, currentBufferSize);
    const bufferedGeometry = parser.write(buffered);

    if (this.formatArea(bufferedGeometry.getArea()) >= MAX_AREA) {
      return this.warningPopup('Tegnet område er for stort, området kan maks være 100km<sup>2</sup>.');
    }

    const bf = new Feature(bufferedGeometry);
    bf.setId(id);
    this._mapService.getLayer(this.drawBufferId).getSource().addFeature(bf);
  }

  mapLayerChange(id: number | string) {
    this.reportMapLayers = this.reportMapLayers.map((s: ReportMapLayer) => {
      if (s.id === id) {
        return {
          ...s,
          checked: !s.checked,
        };
      }
      return s;
    });
  }

  getBackgroundLayerId() {
    const currentLayer = this._permaLinkService.getInitialValue('bgLayer')?.toString();
    const layerObject = this.backgroundList?.find(b => b.id === currentLayer);
    const originalId = layerObject?.originalId || 'string';

    return originalId;
  }

  generateReport() {
    const drawFeatures = this._mapService.getLayer(this.drawId)?.getSource().getFeatures();
    const uploadedFeatures = this._mapService.getLayer(AppConfig.IDBOD_REPORT_UPLOADED)?.getSource().getFeatures();

    if (!drawFeatures?.length && !uploadedFeatures?.length) {
      return this.warningPopup('Du må tegne objekter eller laste opp fil for å kunne generere rapport.');
    }

    if (!this.reportMapLayers.filter(sr => sr.checked).length) {
      return this.warningPopup('Du må velge minst et kartlag for å kunne generere rapport.');
    }

    if ([...(drawFeatures || []), ...(uploadedFeatures || [])].length > MAX_NUMBER_DRAWINGS) {
      return this.warningPopup(
        `Rapporten tillater maks ${MAX_NUMBER_DRAWINGS} objekter, noen objekter må slettes før rapport kan genereres.`
      );
    }

    const reportJson: Report = {
      srid: this.getEpsg().split(':')[1],
      layers: this.getActiveMapLayers().toString(),
      type: 'FeatureCollection',
      backgroundLayerId: this.getBackgroundLayerId(),
      features: this.generateFeatures(),
    };

    const startTime = new Date().getTime();

    this._reportService.generateReport(this.drawId, reportJson).subscribe(r => {
      this.loadingReport = true;

      if (typeof r === 'string') {
        this.loadingReport = false;
        return;
      }

      if (typeof r !== 'string' && r.jobid) {
        this._reportService.fetchReport(r.jobid, this.drawId).subscribe((rs: ReportStatus) => {
          if (!rs) {
            this.terminatePolling();
            return this.warningPopup(
              'Utskrift feilet, om dette gjentar seg vennligst gi beskjed til gisdrift@nibio.no'
            );
          }

          if (new Date().getTime() - startTime > 6000000) {
            this.terminatePolling();
            return this.warningPopup(
              'Utskrift feilet, har ventet over 100 min på data, om dette gjentar seg vennligst gi beskjed til gisdrift@nibio.no'
            );
          }

          if (rs.statusCsv === 'failed' && rs.statusPdf === 'failed') {
            this.terminatePolling();
            return this.warningPopup(
              'Utskrift feilet, om dette gjentar seg vennligst gi beskjed til gisdrift@nibio.no'
            );
          }

          if (rs.statusCsv === 'ok' && rs.statusPdf === 'ok') {
            this.terminatePolling();
            this.showDownloadDialog(rs);
          }
        });
      }
    });
  }

  terminatePolling() {
    this.loadingReport = false;
    this._reportService.terminatePolling();
  }

  showDownloadDialog(rs: ReportStatus) {
    this.dialog.open(ReportDialogComponent, {
      width: '500px',
      height: 'auto',
      data: {
        title: 'Rapporten er ferdig',
        reportData: rs,
      },
      hasBackdrop: true,
      panelClass: 'report-download-dialog',
    });
  }

  private _unselectTool(): void {
    this.chosenTool = 'None';
  }

  private getEpsg() {
    return this._mapService.getMap().getView().getProjection().getCode();
  }

  private getActiveMapLayers() {
    return this.reportMapLayers.filter((e: ReportMapLayer) => e.checked).map(e => e.id);
  }

  private generateFeatures() {
    const features: ReportFeature[] = [];
    const format = new GeoJSON();

    const geoJsonStrDraw = JSON.parse(
      format.writeFeatures([
        ...(this._mapService.getLayer(this.drawId)?.getSource().getFeatures() || []),
        ...(this._mapService.getLayer(AppConfig.IDBOD_REPORT_UPLOADED)?.getSource().getFeatures() || []),
      ])
    );

    const geoJsonStrBuffer = JSON.parse(
      format.writeFeatures(this._mapService.getLayer(this.drawBufferId).getSource().getFeatures())
    );

    geoJsonStrDraw.features.forEach((f: ReportFeature) => {
      const bufferFeature = geoJsonStrBuffer.features.find((b: ReportFeature) => b.id === f.id);
      if (bufferFeature) {
        features.push(bufferFeature);
      } else {
        features.push(f);
      }
    });

    return features;
  }

  deleteAllDrawings() {
    this.drawItems?.forEach(i => this.deleteDrawObject(i.id));
  }

  isForestReport(): boolean {
    return this.drawId === 'forestReport';
  }

  private openUploadDialog(): void {
    if (this._dialogService.isOpen(DialogVariants.UPLOAD)) {
      Swal.fire({
        title: ApiConfig.defaultWarnHeader,
        text: 'Opplastingsdialogen er allerede åpen',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
      return;
    }

    this._dialogService.openDialog({
      variant: DialogVariants.UPLOAD,
      dialogHandle: this._uploadService.openDialog(),
      keyboardService: this._keyboardService,
    });
  }

  zoomToContents(db: DrawLayer): void {
    this._mapService.zoomToLayer(this._mapService.getLayer(db.layerId));
  }
}
