import { Layer } from 'ol/layer';
import { Source } from 'ol/source';

/**
 * The BaseSerializer.
 *
 * @class
 */
export class BaseSerializer {
  /**
   * Determines if this Serializer can serialize the given source
   *
   * @abstract
   * @param {Source} source
   * @return {boolean}
   */
  canSerialize(source: Source): boolean {
    // eslint-disable-line no-unused-vars
    throw new Error('abstract method');
  }

  /**
   * Serializes/Encodes the given layer.
   *
   * @abstract
   * @param {Layer} layer The layer to serialize/encode.
   * @param {Object} opts Additional properties to pass to the serialized
   *   layer object that can't be obtained by the layer itself. It can also be
   *   used to override all generated layer values, e.g. the image format. Only
   *   used in V3.
   * @param {number} viewResolution The resolution to calculate the styles for.
   * @return {Object} The serialized/encoded layer.
   */
  serialize(layer: Layer, opts: any, viewResolution: any) {
    // eslint-disable-line no-unused-vars
    throw new Error('abstract method');
  }

  /**
   * Validates if the given ol source is compatible with the serializer. Usally
   * called by subclasses.
   *
   * @param {Source} source The source to validate.
   * @return {boolean} Whether it is a valid source or not.
   */
  validateSource(source: Source): boolean {
    const isValidSource = this.canSerialize(source);
    return isValidSource as boolean;
  }
}

export default BaseSerializer;
