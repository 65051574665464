import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { WindowRefHelper } from '@kildencore/helpers/window-ref.helper';
import { KeyboardService } from '@kildencore/services/keyboard.service';
import { KildenStateService } from '@kildencore/services/kilden-state.service';
import { BodyStyleClassesEnum } from '@kildenshared/constants/body-style-classes.enum';
import { DeviceOrientationsEnum } from '@kildenshared/constants/device-orientations.enum';
import { DeviceTypesEnum } from '@kildenshared/constants/device-types.enum';
import { Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'kilden3-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnDestroy, OnInit {
  title = 'Kilden';

  private readonly QUERY_SCREEN_SMALL = '(max-width: 1025px)';
  private readonly QUERY_ORIENTATION_PORTRAIT = '(orientation: portrait)';
  private readonly _onDestroy$ = new Subject<void>();

  private bodyElement!: HTMLElement;
  private window: Window = WindowRefHelper.getNativeWindow();

  constructor(
    @Inject(DOCUMENT)
    private readonly document: Document,
    private readonly _keyboardService: KeyboardService,
    private readonly kildenStateService: KildenStateService,
    @Inject(LOCALE_ID)
    private readonly _locale: string,
    private readonly titleService: Title
  ) {
    this.document.documentElement.lang = _locale;
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();

    this.window.removeEventListener('change', this.onMatchedMediaOrientationChange.bind(this));

    this.window.removeEventListener('change', this.onMatchedMediaScreensize.bind(this));
  }

  ngOnInit(): void {
    this.bodyElement = this.document.body;

    // Set initial values on load
    this.setDeviceSize();
    this.setDeviceType(
      this.window.matchMedia(this.QUERY_SCREEN_SMALL).matches ? DeviceTypesEnum.MOBILE : DeviceTypesEnum.DESKTOP
    );
    this.setDeviceOrientation(
      this.window.matchMedia(this.QUERY_ORIENTATION_PORTRAIT).matches
        ? DeviceOrientationsEnum.PORTRAIT
        : DeviceOrientationsEnum.LANDSCAPE
    );

    // Listen for changes to media match queries
    this.window
      .matchMedia(this.QUERY_SCREEN_SMALL)
      .addEventListener('change', this.onMatchedMediaScreensize.bind(this));

    this.window
      .matchMedia(this.QUERY_ORIENTATION_PORTRAIT)
      .addEventListener('change', this.onMatchedMediaOrientationChange.bind(this));

    // Update body classlist when sidenav toggles
    this.kildenStateService.sidenavOpen$
      .pipe(
        tap((isOpen: boolean) => {
          if (isOpen) {
            this.addClass(BodyStyleClassesEnum.SIDENAV_OPEN);
          } else {
            this.delClass(BodyStyleClassesEnum.SIDENAV_OPEN);
          }
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();
  }

  @HostListener('keyup', ['$event'])
  handleKeyup(e: KeyboardEvent): void {
    this._keyboardService.handleGlobalShortcuts(e);
  }

  /**
   * Add a given classname to the body element
   */
  private addClass(className: BodyStyleClassesEnum): void {
    this.bodyElement.classList.add(className);
  }

  /**
   * Remove a given classname from the body element
   */
  private delClass(className: BodyStyleClassesEnum): void {
    this.bodyElement.classList.remove(className);
  }

  /**
   * Callback for when a media match query for device orientation triggers
   */
  private onMatchedMediaOrientationChange(m: Event): void {
    if (!(m instanceof MediaQueryListEvent)) {
      return;
    }

    this.setDeviceOrientation(m.matches ? DeviceOrientationsEnum.PORTRAIT : DeviceOrientationsEnum.LANDSCAPE);
  }

  /**
   * Callback for when a media match query for screensize triggers
   */
  private onMatchedMediaScreensize(m: Event): void {
    if (!(m instanceof MediaQueryListEvent)) {
      return;
    }

    this.setDeviceSize();
    this.setDeviceType(m.matches ? DeviceTypesEnum.MOBILE : DeviceTypesEnum.DESKTOP);
  }

  /**
   * Update body classlist and notify state service when device orientation changes
   */
  private setDeviceOrientation(orientation: DeviceOrientationsEnum): void {
    if (orientation === DeviceOrientationsEnum.PORTRAIT) {
      this.addClass(BodyStyleClassesEnum.ORIENTATION_PORTRAIT);
      this.delClass(BodyStyleClassesEnum.ORIENTATION_LANDSCAPE);
      this.kildenStateService.deviceOrientation = DeviceOrientationsEnum.PORTRAIT;
    } else {
      this.addClass(BodyStyleClassesEnum.ORIENTATION_LANDSCAPE);
      this.delClass(BodyStyleClassesEnum.ORIENTATION_PORTRAIT);
      this.kildenStateService.deviceOrientation = DeviceOrientationsEnum.LANDSCAPE;
    }
  }

  /**
   * Notify state service about device size
   */
  private setDeviceSize(): void {
    this.kildenStateService.deviceSize = {
      height: this.window.innerHeight,
      width: this.window.innerWidth,
    };
  }

  /**
   * Update body classlist and notify state service when device type changes
   */
  private setDeviceType(device: DeviceTypesEnum): void {
    if (device === DeviceTypesEnum.MOBILE) {
      this.addClass(BodyStyleClassesEnum.SCREEN_SMALL);
      this.delClass(BodyStyleClassesEnum.SCREEN_LARGE);
      this.kildenStateService.deviceType = DeviceTypesEnum.MOBILE;
    } else {
      this.addClass(BodyStyleClassesEnum.SCREEN_LARGE);
      this.delClass(BodyStyleClassesEnum.SCREEN_SMALL);
      this.kildenStateService.deviceType = DeviceTypesEnum.DESKTOP;
    }
  }
}
