import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'wildlifeLegend',
})
export class WildlifeLegendPipe implements PipeTransform {
  constructor(private readonly _domSanitizer: DomSanitizer) {}

  public transform(
    input: {
      fill: string;
      shape: 'circle' | 'square' | 'triangle';
      stroke: string;
    } = {
      fill: '#666',
      shape: 'square',
      stroke: '#222',
    },
    options: Partial<{ size: string }> = { size: '16' }
  ): string | SafeHtml {
    if (input.shape && input.fill) {
      // First sanitize any data from external sources (api)
      const fill = this._domSanitizer.sanitize(SecurityContext.HTML, input.fill || null);
      const size = this._domSanitizer.sanitize(SecurityContext.HTML, options.size || null);
      const stroke = this._domSanitizer.sanitize(SecurityContext.HTML, input.stroke || null);

      // Build response
      let response = `<svg
        fill="${fill}"
        height="${size}"
        stroke="${stroke}"
        viewBox="0 0 90 90"
        width="${size}"
        xmlns="http://www.w3.org/2000/svg"
        >`;

      switch (input.shape) {
        case 'circle':
          response += `<circle cx="45" cy="45" r="42"></circle>`;
          break;
        case 'square':
          response += `<rect height="84" width="84" x="3" y="3"></rect>`;
          break;
        case 'triangle':
          response += `<path d="M3 87 45 3 L 87 87"/>`;
          break;
        default:
          return '';
      }
      response += '</svg>';

      // Return as HTML, this is why the initial sanitize is important
      return this._domSanitizer.bypassSecurityTrustHtml(response);
    }

    return '';
  }
}
