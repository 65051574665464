import { AppConfig } from '@kildenconfig/app.config';
import { DrawType } from '@kildenshared/types/draw/draw.type';
import { asArray, asString, Color } from 'ol/color';
import Feature from 'ol/Feature';
import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Icon, { Options as IconOptions } from 'ol/style/Icon';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';

export class DrawHelper {
  static readonly CACHED_STYLE_KEY = 'cached_style';
  static readonly DEFAULT_FILL_TRANSPARENCY: number = 0.2;
  static readonly DEFAULT_POINT_TRANSPARENCY: number = 1;
  static readonly DEFAULT_STROKE_TRANSPARENCY: number = 1;
  static readonly COLOR_FILL: string = 'rgba(255, 0, 0, ' + DrawHelper.DEFAULT_FILL_TRANSPARENCY + ')';
  static readonly COLOR_POINT: string = 'rgba(255, 0, 0, ' + DrawHelper.DEFAULT_POINT_TRANSPARENCY + ')';
  static readonly COLOR_STROKE: string = 'rgba(255, 0, 0, ' + DrawHelper.DEFAULT_STROKE_TRANSPARENCY + ')';
  static readonly ICON_SIZE = 0.5;
  static readonly ICON_SRC = 'assets/draw/circle_white_24dp.svg';
  static readonly SELECT_FILL_COLOR: string = 'rgba(102, 255, 204, 0.4)';
  static readonly SELECT_STROKE_COLOR: string = 'rgba(102, 255, 204, 0.8)';
  static readonly STROKE_WIDTH = 3;

  static STYLE_SELECTED: Style = new Style({
    image: new Circle({
      radius: 15,
      fill: new Fill({
        color: DrawHelper.SELECT_FILL_COLOR,
      }),
      stroke: new Stroke({
        color: DrawHelper.SELECT_STROKE_COLOR,
      }),
    }),
    stroke: new Stroke({
      color: DrawHelper.SELECT_STROKE_COLOR,
      lineDash: [50, 5],
      width: 5,
    }),
  });

  /**
   * Takes an argument like rgba(255, 0, 0, 0.5).
   * Removes the last transparency param and returns in rgb(r, g, b) string format
   */
  static convertRgbaToRgb(rgbaStr: string): string {
    const clrNumbers = rgbaStr.replace(/rgb?a\(/, '').replace(')', '');
    const segments = clrNumbers.split(',');
    return 'rgb(' + [segments[0].trim(), segments[1].trim(), segments[2].trim()].join(', ') + ')';
  }

  /**
   * Return a style which is applied to the OpenLayers DrawInteraction, which is typically used
   * when drawing new features, or modifying existing features
   */
  static getDrawStyle(isCurrentlyDrawing: boolean, options: DrawStyleOptions): Style {
    let returnStyle!: Style;

    // While drawing linestring or poly, show a semitransparent circle for the cursor
    const cursorCircle = isCurrentlyDrawing
      ? new Circle({
          radius: 4 + (options.strokeWidth || DrawHelper.STROKE_WIDTH),
          fill: new Fill({
            color: 'rgba(255, 255, 255, 0.5)',
          }),
          stroke: new Stroke({
            color: options.color || DrawHelper.COLOR_STROKE,
          }),
        })
      : undefined;

    switch (options.chosenTool) {
      case 'LineString':
        returnStyle = new Style({
          image: cursorCircle,
          stroke: new Stroke({
            color: options.color || DrawHelper.COLOR_STROKE,
            width: options.strokeWidth || DrawHelper.STROKE_WIDTH,
          }),
        });
        if (cursorCircle) {
          returnStyle.setImage(cursorCircle);
        }
        break;

      case 'Point':
        returnStyle = new Style({
          fill: new Fill({
            color: options.color || DrawHelper.COLOR_POINT,
          }),
          stroke: new Stroke({
            // Hack to get icon
            width: options.strokeWidth || DrawHelper.ICON_SIZE,
          }),
          image: new Icon({
            color: options.color || DrawHelper.COLOR_POINT,
            scale: options.iconSize || DrawHelper.ICON_SIZE,
            src: options.iconSrc || DrawHelper.ICON_SRC,
          } as IconOptions),
        });
        break;

      case 'Polygon':
        const fillPoly = options.color || DrawHelper.COLOR_FILL;
        const polyFill = this.setTransparency(
          fillPoly,
          options.fillTransparency || DrawHelper.DEFAULT_FILL_TRANSPARENCY
        );

        returnStyle = new Style({
          stroke: new Stroke({
            color: options.color || DrawHelper.COLOR_STROKE,
            width: options.strokeWidth || DrawHelper.STROKE_WIDTH,
          }),
        });
        if (options.useFillColor) {
          returnStyle.setFill(
            new Fill({
              color: polyFill,
            })
          );
        }
        if (cursorCircle) {
          returnStyle.setImage(cursorCircle);
        }
        break;

      case 'Text':
        let outLineColor = 'white';

        // Count color content, if above 500 total, deemed to be a light enough color that it needs a dark outline
        if (options.color) {
          let clrSum = 0;
          const clrNumbers = options.color.replace(/rgb?a\(/, '').replace(')', '');
          const segments = clrNumbers.split(',');
          let i = segments.length;
          while (i--) {
            clrSum += parseInt(segments[i]);
          }
          if (clrSum > 500) {
            outLineColor = 'black';
          }
        }

        returnStyle = new Style({
          text: new Text({
            font: '100 normal 16px helvetica',
            text: options.inputString?.length ? options.inputString : 'Legg til tekst',
            fill: new Fill({
              color: options.color || DrawHelper.COLOR_STROKE,
            }),
            stroke: new Stroke({
              color: outLineColor,
              width: 4,
            }),
          }),
        });
        break;

      default:
        returnStyle = new Style();
        break;
    }

    return returnStyle;
  }

  /**
   * Return the styling for a selected existing OpenLayers Feature
   */
  static getSelectionStyle(
    feature: Feature,
    resolution: number = 1,
    cacheKey = DrawHelper.CACHED_STYLE_KEY
  ): Style | Style[] {
    const geoType = feature.getGeometry()?.getType();
    const featStyleLike = feature.getStyle();
    let featStyle: Style;
    let selectStyle: Style;

    if (Array.isArray(featStyleLike)) {
      featStyle = featStyleLike[0];
    } else if (typeof featStyleLike === 'function') {
      featStyle = featStyleLike.call(this, feature, resolution) as Style;
    } else {
      featStyle = featStyleLike as Style;
    }

    if (!featStyle) {
      console.log(`featStyle undefined, return default selection style`);
      return DrawHelper.STYLE_SELECTED;
    }

    if (!geoType) {
      console.log(`geoType undefined, return default selection style`);
      return DrawHelper.STYLE_SELECTED;
    }

    feature.set(cacheKey, featStyle.clone()); // make a copy
    // eslint-disable-next-line prefer-const
    selectStyle = featStyle.clone();

    // Make sure selection style is below
    featStyle.setZIndex(AppConfig.ZINDEX_DRAW_ORIGIN);
    selectStyle.setZIndex(AppConfig.ZINDEX_DRAW_SELECT);

    // Add the "selected"-stroke
    if (selectStyle.getStroke()?.getWidth()) {
      selectStyle.getStroke().setColor(DrawHelper.SELECT_STROKE_COLOR);
      selectStyle.getStroke().setWidth(8 + (featStyle.getStroke().getWidth() || 0));
    }

    // We don't want any fill on selectStyle, set it totally transparent as OpenLayers currently does not provide
    // a way to remove existing fill. Their stance seems to be "just don't add Fill in the first place".
    if (selectStyle.getFill()) {
      selectStyle.setFill(new Fill({ color: 'rgba(0, 0, 0, 0)' }));
    }

    // Special handling for specific geo types
    if (geoType === 'Point' && selectStyle.getImage() instanceof Icon && !selectStyle.getText()?.getText()?.length) {
      let scale = featStyle.getImage().getScale();
      if (Array.isArray(scale)) {
        scale = scale[0];
      }

      const selectImage = new Circle({
        radius: 24 * scale,
        fill: new Fill({
          color: DrawHelper.SELECT_FILL_COLOR,
        }),
        stroke: new Stroke({
          color: DrawHelper.SELECT_STROKE_COLOR,
        }),
      });
      selectStyle.setImage(selectImage);
    } else if (selectStyle.getText()) {
      selectStyle.getText().getStroke().setColor(DrawHelper.SELECT_STROKE_COLOR);
      selectStyle
        .getText()
        .getStroke()
        .setWidth(2 * (featStyle.getText().getStroke().getWidth() || DrawHelper.STROKE_WIDTH));
    }

    return [featStyle, selectStyle];
  }

  static setTransparency<T extends Color | string>(
    inColor: T,
    inTransparency: number,
    returnAsString: boolean = true
  ): T {
    const color = asArray(inColor);
    const c = color.slice();
    c[3] = inTransparency;

    return returnAsString ? (asString(c) as T) : (c as T);
  }
}

export type DrawStyleOptions = {
  chosenTool: DrawType;
  color?: string;
  fillTransparency?: number;
  iconSrc?: string;
  iconSize?: number;
  inputString?: string;
  strokeWidth?: number;
  useFillColor?: boolean;
};
