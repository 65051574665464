<mat-tab-group
  #matTabGroup
  (selectedTabChange)="resetAllIframeLoaded()"
  [@.disabled]="true"
  [selectedIndex]="(wildlifeInfo || forestRoad ? 1 : 0) + getFeatureInfo.length"
  class="kilden3-feature-info-content mat-tab-fill-height">
  <mat-tab label="Koordinater">
    <mat-list>
      <mat-list-item mat-list-item *ngFor="let coordinate of coordinates">
        <div mat-line>
          <b>{{ coordinate.name }}</b>
        </div>
        <div mat-line>{{ coordinate.value }}</div>
      </mat-list-item>
    </mat-list>
  </mat-tab>

  <mat-tab *ngIf="wildlifeInfo" [label]="wildlifeInfo.heading">
    <div *ngFor="let item of wildlifeInfo.features" class="wildlife">
      <h3>
        <span
          *ngIf="item.mapSymbol?.shape"
          [innerHTML]="item.mapSymbol | wildlifeLegend"
          class="symbol"
          title="Kartsymbol for {{ item.name }}"></span>
        {{ item.name }}
      </h3>
      <table class="wildlife">
        <tr *ngFor="let prop of item.properties">
          <td class="label">{{ prop.label }}:</td>
          <td>{{ prop.value }}</td>
        </tr>
      </table>
    </div>
  </mat-tab>

  <mat-tab *ngIf="forestRoad" [label]="forestRoad.heading">
    <kilden3-forest-road-info [id]="forestRoad.id"></kilden3-forest-road-info>
  </mat-tab>

  <mat-tab *ngFor="let feature of getFeatureInfo" [label]="feature.name">
    <mat-spinner *ngIf="!feature.iframeLoaded" color="primary" diameter="50" value="50" strokeWidth="3"></mat-spinner>

    <iframe
      *ngIf="feature.url && feature.url.length > 1"
      (load)="feature.iframeLoaded = true"
      [src]="feature.url | safe: 'resourceUrl'"
      title="feature.name"
      width="100%"
      height="100%"
      frameborder="0"
      class="tab-content">
    </iframe>
  </mat-tab>

  <mat-tab *ngIf="tabsLoading" label="Henter data">
    <ng-template mat-tab-label>
      Henter
      <mat-spinner class="inline-spinner" color="primary" diameter="20" strokeWidth="2" value="50"></mat-spinner>
    </ng-template>
  </mat-tab>
</mat-tab-group>
