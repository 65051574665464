import { Component } from '@angular/core';
import { DialogService, DialogVariants } from '@kildencore/services/dialog.service';
import { MapService } from '@kildencore/services/map.service';
import { UploadService } from '@kildencore/services/upload.service';
import { formatFileSize as formatSize } from '@kildencore/tools';
import { GeoJSON, GPX, KML, TopoJSON } from 'ol/format';

const UPLOADED_ID = 'uploadedVectorLayer';

@Component({
  selector: 'kilden3-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css'],
})
export class UploadFileComponent {
  public fileContent = 'Content';
  public fileNames = '';
  public progress = 0;
  public uploadErrorMessage = '';
  public userMessage = '';
  public validFile = false;

  private files: Array<File> = [];
  private readers: Array<any> = [];
  private uploadError = false;

  constructor(
    private readonly _dialogService: DialogService,
    private readonly _mapService: MapService,
    private readonly _uploadService: UploadService
  ) {}

  public openFileBrowser = () => document.getElementById('fileUpload')?.click();

  public chosen = (e: any) => {
    this.reset();
    const n = e.target.files.length;
    const allowed = e.target.accept.split(',').map((p: string) => p.slice(1));
    this.fileNames = '';
    for (let i = 0; i < n; i++) {
      const f = e.target.files[i];
      if (this.isValidFile(f, allowed)) {
        this.files.push(f);
        if (this.fileNames) this.fileNames += '; ';
        this.fileNames += `${f.name}, ${formatSize(f.size)}`;
      }
    }
  };

  // prettier-ignore
  private readFile = (f: File) => new Promise((resolve, reject) => {
    const format_ = f.name.split('.').pop();
    const format = format_ ? format_.toLowerCase() : '';
    // prettier-ignore
    const zip = (format === 'zip');
    const reader = new FileReader();
    this.readers.push(reader); // keep track to be able to abort them

    reader.onprogress = (p: any) => {
      if (p.lengthComputable) this.progress = (p.loaded / p.total) * 100;
    };
    reader.onerror = (e: any) => {
      reject(`Feil ${f.name}`);
    };
    reader.onload = (l: any) => {
      this.fileContent = l.target.result;
      resolve(this.displayOnMap(f, format, l.target.result));
    };

    if (!zip) reader.readAsText(f, 'UTF-8'); else reader.readAsArrayBuffer(f);
  });

  public uploadFilesToMap = () => {
    this.fileContent = '';
    const promises: Array<any> = [];
    this.files.forEach((f: File) => {
      this.progress = 0.1; // show abort button
      promises.push(this.readFile(f));
    });
    Promise.allSettled(promises)
      .then(results => {
        this.progress = 100;
        const uploadedLayers: Array<any> = [];
        results.forEach(r => {
          if (r.status === 'fulfilled') {
            const layer = r.value;
            if (layer) uploadedLayers.push(layer);
          }
        });
        this._mapService.zoomToUploads(uploadedLayers);
        if (!this.uploadError) {
          this._uploadService.closeDialog('After reading files');
        }
      })
      .catch(e => {
        this.uploadError = true;
        this.uploadErrorMessage = 'Kan ikke laste. Feil med en eller flere filer.';
      });
  };

  public cancel = () => {
    this.userMessage = 'Avbrytet';
    this.progress = 0;
    // Abort file reader processes
    this.readers.forEach((r: any) => {
      if (r.readyState == 1) r.abort();
    });
  };

  private reset = () => {
    this.validFile = false;
    this.uploadError = false;
    this.userMessage = '';
    this.uploadErrorMessage = '';
    this.files.length = 0;
    this.fileContent = '';
    this.readers.length = 0;
  };

  private isValidFile = (f: File, allowed: Array<string>) => {
    const format_ = f.name.split('.').pop();
    const format = format_ ? format_.toLowerCase() : '';
    if (allowed.indexOf(format) < 0) {
      this.userMessage = 'Ulovlig format';
      this.validFile = false;
      return false;
    }
    // prettier-ignore
    const maxSize = 1048576 * ((format === 'zip') ? 1 : 20);
    if (f.size > maxSize) {
      this.userMessage = `Må være mindre enn ${formatSize(maxSize)}`;
      this.validFile = false;
      return false;
    }

    this.validFile = true;
    return true;
  };

  private displayOnMap = (f: File, format: string, fileContent: string) => {
    const gpx = new GPX();
    const kml = new KML();
    const geojson = new GeoJSON();
    const topojson = new TopoJSON();
    const zip = this._uploadService.ZIP();
    const ext2ol: { [key: string]: any } = { geojson, gpx, kml, topojson, zip };
    let uploadedLayer: any;
    if (fileContent) {
      if (format) {
        const ms = this._mapService;
        const fp = ms.$is3dActive() ? ms.projOn3dInit : ms.getCode();
        try {
          const features = ext2ol[format].readFeatures(fileContent, {
            featureProjection: fp,
          });

          if (
            this._dialogService.isOpen(DialogVariants.AREA_REPORT) ||
            this._dialogService.isOpen(DialogVariants.FOREST_REPORT) ||
            this._dialogService.isOpen(DialogVariants.SOIL_REPORT)
          ) {
            // Upload as attachment for report
            uploadedLayer = this._uploadService.updateReportLayer(features, f.name);
          } else {
            // Upload into separate layers and add to ActiveLayers list
            uploadedLayer = this._uploadService.addCustomThemeLayer(features, f.name);
          }

          this.userMessage = 'Lastet opp på kartet';
        } catch (e: any) {
          this.uploadError = true;
          this.uploadErrorMessage = 'Kan ikke laste. Feil med en eller flere filer.';
          this.progress = 0;
        }
      }
    }
    return uploadedLayer;
  };

  preventDrop(e: any) {
    e.preventDefault();
    e.dataTransfer.effectAllowed = 'none';
    e.dataTransfer.dropEffect = 'none';
  }
}
