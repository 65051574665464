import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable()
export class ApiConfig {
  private static project = 'kilden';
  public static backendPath = '/kilden_ws/';
  public static defaultBackgroundLayer = 'graatone';
  public static restPrintServerUrl = environment.printHost + '/print-servlet/print/kilden/capabilities.json?';
  public static restPrintServerUrlReport = environment.printHost + '/print-servlet/print/kilden/report.';
  public static printTitle = environment.printTitle;

  public static readonly proxyUrl = environment.proxyHost;
  public static readonly baseUrl = environment.backendHost;
  public static readonly guiUrl = environment.guiHost;
  public static readonly svgUrl = environment.svgHost;
  public static readonly restCatalogServerUrl = '/catalogServer';
  public static readonly restLinksServerUrl = '/links';

  public static readonly getTokenUrl = '/map/token_json.jsp';
  public static readonly open_url = 'https://opencache.statkart.no/gatekeeper/gk/gk.open_wmts';

  public static readonly defaultErrorHeader = 'Oops! Noe gikk galt!';
  public static readonly defaultWarnHeader = 'Oops! Her ble det feil!';
  public static readonly orthoProjectsUrl = '/nib2/rest/projectMetadata.ashx';
  public static readonly ticket_nib = '/map/ticket_nib_json.jsp';
  public static readonly ticket_nib_project = '/map/ticket_nib_project_json.jsp';
  public static readonly ticket_lantmeteriet = '/map/ticket_json.jsp?id=wms.lantmeteriet_topo';

  /**
   * Urls for skogsbilveger
   */
  static readonly forestRoadsRestServicesUrl = environment.backendHost + '/skogsbilveger_ws/';
  static readonly forestRoadInfoUrl = ApiConfig?.forestRoadsRestServicesUrl + 'skogsbilveiInfo';

  /**
   * Urls for Viltkart
   * @example https://kart13utv.int.nibio.no/viltkart_ws/rest/services/dataList
   */
  static readonly wildLifeRestServicesUrl = environment.backendHost + '/viltkart_ws/rest/services/';
  static readonly wildlifeDataListUrl = ApiConfig?.wildLifeRestServicesUrl + 'dataList';
  static readonly wildlifeAnimalsUrl = ApiConfig?.wildLifeRestServicesUrl + 'animals';

  static readonly URL_GEONORGE_WMS_NIB = 'https://wms.geonorge.no/skwms1/wms.nib';
  static readonly URL_GEONORGE_WMS_NIB_PROJECTS = ApiConfig?.URL_GEONORGE_WMS_NIB + '-prosjekter';

  public static getDefaultTopic() {
    return ApiConfig.project === 'vega' ? 'vega' : environment.defaultTopic;
  }

  public static getLayersConfigUrl(topicid: any) {
    return (
      environment.backendHost +
      ApiConfig.backendPath +
      'rest/services' +
      '/' +
      topicid +
      '/MapServer/layersConfig?lang=nb'
    );
  }

  public static getServiceUrl() {
    return environment.backendHost + ApiConfig.backendPath + 'rest/services';
  }

  /**
   *
   * @param routerInfo Object with routerInfo exsample:
   *  name: 'vega',
      backend: 'vegakilden_ws',
      backgroundLayer: 'farger',
      title: 'Vegakilden',
      logo: 'assets/vega/vega_logo_black_60px.png',
      logoUrl: 'https://www.verdensarvvega.no/',
      logo2: 'assets/vega/landbruksdirektoratet_logo_60px.png',
      logo3: 'assets/vega/nordland_60px.png',
      logo4: 'assets/vega/nn_logo_vega_verneomraadestyre_60px.png',
      logo5: 'assets/vega/stiftelsen_60px.png',
      layerSearch: false,
      startCoordinates: { x: 7294849, y: 360272, z: 9 },
      zoomExtentController: [329900, 7276010, 390650, 7313650],
   */
  public static setBackendInfo(routerInfo: any) {
    ApiConfig.project = routerInfo.name ? routerInfo.name : ApiConfig.project;
    ApiConfig.backendPath = routerInfo.backend ? '/' + routerInfo.backend + '/' : ApiConfig.backendPath;
    ApiConfig.defaultBackgroundLayer = routerInfo.backgroundLayer
      ? routerInfo.backgroundLayer
      : ApiConfig.defaultBackgroundLayer;
    const name = routerInfo.mapfishApp ? routerInfo.mapfishApp : 'kilden';
    ApiConfig.restPrintServerUrl = environment.printHost + '/print-servlet/print/' + name + '/capabilities.json?';
    ApiConfig.restPrintServerUrlReport = environment.printHost + '/print-servlet/print/' + name + '/report.';
    ApiConfig.printTitle = routerInfo.printTitle ? routerInfo.printTitle : ApiConfig.printTitle;
  }
}
