import { BaseSerializer } from '@kildencore/serializer/base-serializer';
import { defaultsDeep } from 'lodash';
import ImageLayer from 'ol/layer/Image';
import TileLayer from 'ol/layer/Tile';
import { Source, TileWMS } from 'ol/source';
import ImageWMS from 'ol/source/ImageWMS';

export class MapFishPrintV3WMSSerializer extends BaseSerializer {
  static TYPE_WMS = 'tiledWMS'; // because of A0 size, mapfish need tiles
  static TILE_SIZE = [1024, 1024];

  constructor() {
    super();
  }

  override canSerialize(source: Source) {
    return source instanceof ImageWMS || source instanceof TileWMS;
  }

  /**
   * Serializes/Encodes the given layer.
   *
   * @abstract
   * @param {OlLayer} layer The layer to serialize/encode.
   * @param {Object} opts Additional properties to pass to the serialized
   *   layer object that can't be obtained by the layer itself. It can also be
   *   used to override all generated layer values, e.g. the image format. Only
   *   used in V3.
   * @param {number} viewResolution The resolution to calculate the styles for.
   * @return {Object} The serialized/encoded layer.
   */
  override serialize(layer: ImageLayer<ImageWMS> | TileLayer<TileWMS>, opts: any = {}, viewResolution: any) {
    // eslint-disable-line no-unused-vars
    defaultsDeep(opts, {
      failOnError: false,
      mergeableParams: [],
      method: 'GET',
      rasterStyle: undefined,
      // One of MAPSERVER, GEOSERVER, QGISSERVER
      serverType: undefined,
      useNativeAngle: false,
    });

    const source = layer.getSource();

    if (!source || !this.validateSource(source)) {
      return;
    }

    const layers = source.getParams().LAYERS;
    const layersArray = layers ? layers.split(',') : [''];
    const styles = source.getParams().STYLES;
    const stylesArray = styles ? styles.split(',') : [''];

    const { LAYERS, STYLES, VERSION, WIDTH, HEIGHT, FORMAT, BBOX, CRS, SRS, ...customParams } = source.getParams();

    let baseUrl = '';
    if (source) {
      if (source instanceof ImageWMS) {
        baseUrl = source.getUrl() as string;
      } else if (source instanceof TileWMS) {
        const urls = source.getUrls();
        baseUrl = urls?.length ? urls[0] : '';
      }
    }

    return {
      ...{
        baseURL: baseUrl,
        customParams: { TRANSPARENT: 'true' },
        imageFormat: source.getParams().FORMAT || 'image/png',
        layers: layersArray,
        name: layer.get('name'),
        opacity: layer.getOpacity(),
        styles: stylesArray,
        version: source.getParams().VERSION || '1.1.0',
        type: MapFishPrintV3WMSSerializer.TYPE_WMS,
        tileSize: MapFishPrintV3WMSSerializer.TILE_SIZE,
      },
      ...opts,
    };
  }
}

export default MapFishPrintV3WMSSerializer;
