<button
  #topicMenuTrigger="matMenuTrigger"
  (menuClosed)="menuClosed()"
  (menuOpened)="menuOpened()"
  [matMenuTriggerFor]="topicMenu"
  aria-label="Velg fagområde"
  class="topic-btn"
  [attr.disabled]="_topics?.length === 1 ? true : false"
  mat-button
  id="topic">
  <div *ngIf="_topics.length > 1" class="topic-btn-flex-wrapper">
    <span class="topic-label">Fagområde:</span>
    <span class="label-wrapper">
      <span class="topic-name" [title]="label">{{ label }}</span>
      <mat-icon>chevron_right</mat-icon>
    </span>
  </div>
  <div *ngIf="_topics.length === 1" class="topic-btn-flex-wrapper">
    <span class="topic-label">Fagområde:</span>
    <span class="label-wrapper">
      <span class="topic-name" [title]="label">{{ label }}</span>
    </span>
  </div>
</button>

<mat-menu
  class="topic-select-menu"
  #topicMenu="matMenu"
  aria-label="Velg fagområde"
  hasBackdrop="false"
  overlapTrigger="true"
  xPosition="after"
  yPosition="above">
  <div class="topic-toolbar">
    <h3>Velg fagområde</h3>
    <div class="countdown-and-close">
      <button (click)="menuClosed()" aria-label="Lukk fagområdevelger" class="close-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>

      <mat-progress-spinner
        *ngIf="autoClosePercentageRemaining"
        class="timer"
        color="primary"
        diameter="29"
        [value]="autoClosePercentageRemaining">
      </mat-progress-spinner>
    </div>
  </div>

  <ul class="topic-flex-container">
    <li
      *ngFor="let topic of _topics"
      (click)="select(topic.id)"
      (keyup.enter)="selectOnEnter($event, topic.id)"
      [class.current]="_current === topic.id"
      mat-menu-item
      class="topic-select"
      draggable="false">
      <p draggable="false">{{ topic.label }}</p>
      <img draggable="false" src="assets/topics/{{ topic.id }}.png" alt="{{ topic.label }}" />
    </li>
  </ul>
</mat-menu>
