import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@kildenconfig/api-config';
import { InformationLinkInterface } from '@kildenshared/interfaces/information-link.interface';
import { Observable, ReplaySubject } from 'rxjs';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class LinksService {
  private _informationLinks$ = new ReplaySubject<InformationLinkInterface[]>(1);

  get informationLinks$(): Observable<InformationLinkInterface[]> {
    return this._informationLinks$.asObservable();
  }

  constructor(private readonly _httpClient: HttpClient) {}

  /**
   * Get links-configuration from server
   * @param  topicId arealinformasjon, skogportal....
   * @return Observable for linksdata
   */
  fetchInformationLinksByTopic(topicId: string) {
    if (topicId) {
      return this._httpClient
        .get<InformationLinkInterface[]>(ApiConfig.getServiceUrl() + '/' + topicId + ApiConfig.restLinksServerUrl)
        .subscribe({
          next: success => this.parseAndEmitNewLinks(success),
          error: err => {
            Swal.fire({
              title: ApiConfig.defaultErrorHeader,
              html: 'Henting av nye linker feilet: <BR> ' + err.message,
              icon: 'error',
              confirmButtonText: 'OK',
            });
          },
        });
    }
    return null;
  }

  parseAndEmitNewLinks(links: InformationLinkInterface[]) {
    this._informationLinks$.next(
      links.map(l => {
        return {
          ...l,
          // order_key is string and will sort wrong (1, 10, 2), parse to int
          order_integer: parseInt(l.order_key, 10),
        } as InformationLinkInterface;
      })
    );
  }
}
