export enum KeyboardShortcutsEnum {
  b = 'Bakgrunnskart',
  g = 'Geolokasjon (din posisjon)',
  h = 'Hjelp (vis snarveier)',
  i = 'Inn/ut sidemeny',
  k = 'Kartlag',
  o = 'Fagområde',
  p = 'Popup - bytt mellom åpne dialogbokser (popups)',
  s = 'Søk',
  t = 'Tegnforklaring',
  v = 'Velg kartlag (søk)',
}
