<button id="zoomCountryExtent3d" title="Vis hele Norge" (click)="flyCountryExtent()"></button>

<div class="popover-container">
  <button [class.active]="$is3dActive()" id="toggle3d" title="3D-visning" (click)="toggle3dActiveSignal()">
    <span class="circle">
      <mat-icon>3d_rotation</mat-icon>
    </span>
  </button>

  <div id="info3dIntro" class="intro-3d-popover-content" [class.active]="showIntroPopover" [hidden]="!$is3dActive()">
    <div class="countdown-wrapper">
      <div class="countdown-and-close">
        <button (click)="showIntroPopover = false" aria-label="Lukk" class="close-button" mat-icon-button title="Lukk">
          <mat-icon>close</mat-icon>
        </button>

        <mat-progress-spinner
          *ngIf="dismissPopoverPercentageRemaining"
          class="timer"
          color="primary"
          diameter="29"
          [value]="dismissPopoverPercentageRemaining">
        </mat-progress-spinner>
      </div>
    </div>

    Skru av/på 3D ved å trykke på ikonet.
    <br /><br />
    For å rotere og skråstille kartbildet kan du:<br />
    <ul>
      <li>Holde nede scrollhjulet og bevege musen.</li>
      <li>eller:</li>
      <li>Holde nede <span class="keyboard-key">CTRL</span>-tasten, klikk og dra med musen</li>
    </ul>
  </div>
</div>
