<div class="ga-draw-buttons">
  <button
    (click)="chooseDrawTool(tool.id)"
    *ngFor="let tool of drawTools"
    title="{{ tool.name }}"
    class="draw_tool_button {{ tool.iconClass }}"
    [class.hidden-mobile]="tool.hideMobile"
    [class.highlight]="tool.id === activeTab">
    <div class="draw_tool_text">{{ tool.name }}</div>
  </button>
</div>

<div *ngIf="!is3dActive()" class="ga-draw-modify">
  <div class="flex-wrapper">
    <div
      class="flex-item hidden-mobile"
      *ngIf="!(chosenTool === 'Delete' || chosenTool === 'Modify' || chosenTool === 'None')">
      <label>
        Farge:
        <select class="form-control" #selClr (change)="chooseColor(selClr.value)">
          <option *ngFor="let c of colors" [value]="c.value" [selected]="c === optionColor">
            {{ c.name }}
          </option>
        </select>
      </label>
    </div>

    <ng-container *ngIf="chosenTool === 'Text'">
      <div class="flex-item text-field">
        <label>
          Tekst:
          <input
            class="textInput"
            [(ngModel)]="optionText"
            placeholder="Legg til tekst"
            (ngModelChange)="changeText()"
            type="text" />
        </label>
      </div>
    </ng-container>

    <div class="flex-item hidden-mobile" *ngIf="chosenTool === 'Point'">
      <label>
        Størrelse:
        <select class="form-control" [(ngModel)]="optionSymbolSize" (change)="chooseSize()">
          <option *ngFor="let s of pointSizes" [ngValue]="s.scale" [selected]="s.scale === optionSymbolSize">
            {{ s.label }}
          </option>
        </select>
      </label>
    </div>

    <div class="symbols hidden-mobile" *ngIf="chosenTool === 'Point'">
      <label>Symbol:</label>
      <div class="ga-choose-icon">
        <span
          (click)="chooseIcon(i)"
          *ngFor="let item of pointSymbols; let i = index"
          [ngClass]="[item.class, optionColor.value]">
          {{ item.id }}
        </span>
      </div>
    </div>

    <div class="flex-item hidden-mobile" *ngIf="chosenTool === 'LineString' || chosenTool === 'Polygon'">
      <label>
        Bredde:
        <select class="form-control" #lw (change)="chooseLinewidth(lw.value)">
          <option *ngFor="let line of strokeWidths" [value]="line.value" [selected]="line.value === optionStrokeWidth">
            {{ line.label }}
          </option>
        </select>
      </label>
    </div>

    <ng-container *ngIf="chosenTool === 'LineString' || chosenTool === 'Polygon'">
      <div class="symbols flex-item" *ngIf="chosenTool === 'Polygon' || chosenTool === 'LineString'">
        <label class="checkbox">
          <input
            #measureCheckBox
            id="measureCheckBox"
            type="checkbox"
            [checked]="showMeasurement"
            (change)="changeShowMeasurement()" />
          <span *ngIf="chosenTool === 'Polygon'"> Vis areal i kartet</span>
          <span *ngIf="chosenTool === 'LineString'"> Vis lengde i kartet</span>
        </label>
      </div>

      <div class="symbols flex-item" *ngIf="chosenTool === 'Polygon'">
        <label class="checkbox">
          <input
            #useFillColorRef
            id="polygonFillCheckBox"
            type="checkbox"
            [checked]="optionUseFill"
            (change)="changeUseFillColor(useFillColorRef.checked)" />
          Fyll med farge
        </label>
      </div>
    </ng-container>
  </div>

  <div class="tips hidden-mobile">
    <div *ngIf="activeTab === 'Polygon' || activeTab === 'LineString'">
      <strong>Tips:</strong><br />
      Dobbeltklikk for å fullføre tegning. Trykk <span class="keyboard-key">esc</span> for å angre siste tegnesteg. Hold
      <span class="keyboard-key">shift</span> for å tegne frihånd.
    </div>

    <ng-container *ngIf="activeTab === 'Modify'">
      <div *ngIf="chosenTool === 'Modify'">
        Velg et objekt for å endre farge, linjetykkelse, symbol og tekst.<br />
        <br />
      </div>

      <div *ngIf="chosenTool === 'Point' || chosenTool === 'Text'">
        <strong>Tips:</strong><br />
        Klikk og dra objektet for å flytte det i kartet
      </div>

      <div *ngIf="chosenTool === 'LineString' || chosenTool === 'Polygon'">
        <strong>Tips:</strong><br />
        Dra blått punkt for å endre form på linje/flate.<br />
        Hold nede <span class="keyboard-key">alt</span> og trykk for å fjerne knekkpunkt.<br />
        Hold <span class="keyboard-key">shift</span> for å flytte hele objektet.
      </div>
    </ng-container>

    <ng-container *ngIf="activeTab === 'Delete'">
      <div>
        Velg objekt og trykk «Slett valgt(e)».<br />
        Hold <span class="keyboard-key">shift</span> for å markere flere.
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="activeTab === 'Delete'">
    <div class="flex-wrapper buttons">
      <button mat-button class="deleteButton flex-item" (click)="deleteAllFeatures()">
        <span>Slett alle objekter</span>
      </button>
      <button mat-button class="deleteButton flex-item" (click)="deleteFeatures()">
        <span
          >Slett {{ nrSelectedFeatures === 0 ? '' : nrSelectedFeatures }} valgt<span
            *ngIf="nrSelectedFeatures !== 1"
            class="hidden-mobile"
            >e</span
          >
        </span>
      </button>
    </div>
  </ng-container>
</div>

<div *ngIf="is3dActive()" class="ga-draw-modify">
  Tegning er ikke tilgjengelig i 3D-modus. Gå til 2D-visning for å tegne.
</div>
