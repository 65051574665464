export class PrintConfig {
  public static readonly PRINT_BOX_COLOR_LINE = 'rgba(74, 123, 164, 1)';
  public static readonly PRINT_BOX_COLOR_FILL = 'rgba(74, 123, 164, 0.3)';
  public static readonly PRINT_BOX_COLOR_FILL_ACTIVE = 'rgba(74, 123, 164, 0.4)';
  public static readonly UPPER_X = 2;
  public static readonly UPPER_Y = 3;
  public static readonly LOWER_X = 0;
  public static readonly LOWER_Y = 1;
  public static readonly DEFAULT_SCALE = 5000;
  public static readonly MAP_SCALE_CONVERSION = 6;
  public static readonly MOVE_DOWN = 30; // => header is 60px / 2
  public static readonly PORTRAIT = 'PORTRAIT';
  public static readonly LANDSCAPE = 'LANDSCAPE';
  public static readonly A4 = 'A4';
  public static readonly A3 = 'A3';
  public static readonly SIZE_A4_PORTRAIT = {
    height: 719,
    width: 555,
  };
  public static readonly SIZE_A4_LANDSCAPE = {
    height: 469,
    width: 800,
  };
  public static readonly SIZE_A3_PORTRAIT = {
    height: 1069,
    width: 800,
  };
  public static readonly SIZE_A3_LANDSCAPE = {
    height: 719,
    width: 1100,
  };
  public static readonly SIZE_A0_PORTRAIT = {
    height: 3245,
    width: 2340,
  };
  public static readonly SIZE_A0_LANDSCAPE = {
    height: 2254,
    width: 3328,
  };
  public static readonly DPI = 130;
  public static readonly MM_PER_INCHES = 46;
  public static readonly UNITS_RATIO = 39.37;

  // polling params ms
  public static readonly INTERVAL = 1000; // 1 sec
  public static readonly TIMEOUT = 300000; // 5 min
}
