import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@kildenconfig/api-config';
import { AppConfig } from '@kildenconfig/app.config';
import { LayerHelper } from '@kildencore/helpers/layer.helper';
import { TicketService } from '@kildencore/services/ticket.service';
import { CatalogTreeItem } from '@kildenshared/components/catalog-tree/catalog-tree-item';
import { LayerChange } from '@kildenshared/interfaces';
import { LayerConfigsType } from '@kildenshared/interfaces/layer-configs.type';
import { ThemeLayers } from '@kildenshared/models';
import { Map as OlMap } from 'ol';
import LayerGroup from 'ol/layer/Group';
import ImageLayer from 'ol/layer/Image';
import VectorLayer from 'ol/layer/Vector';
import ImageWMS from 'ol/source/ImageWMS';
import VectorSource from 'ol/source/Vector';
import { ReplaySubject, shareReplay, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ThemeLayersService {
  private readonly _zIndexExceptionIds: string[] = [
    AppConfig.IDBOD_USER_DRAWN,
    'areaReport',
    'forestReport',
    'soilReport',
  ];

  // The config currently in play
  private _currentLayersConfig?: LayerConfigsType;

  // Stores all available ThemeLayers fetched from backend. Usually 300+ items.
  private _layerBank?: ThemeLayers;
  private _map?: OlMap;

  // Holds all currently active (visible) themeLayers in the map
  private _themeLayersGroup = new LayerGroup({
    zIndex: AppConfig.ZINDEX_THEMELAYERS,
    properties: { type: 'themes' },
  });

  private _layersChange = new Subject<LayerChange[]>();
  private _layersReorder = new ReplaySubject<CatalogTreeItem[]>(1);
  private _layersReset = new Subject<void>();

  // Emits when a layer has been toggled or opacity changed
  layersChange$ = this._layersChange.asObservable();

  // Emits when active layers have been reordered
  layersReorder$ = this._layersReorder.asObservable().pipe(shareReplay(1));

  // Emits when all active layers have been cleared/reset
  layersReset$ = this._layersReset.asObservable().pipe(shareReplay(1));

  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _ticketService: TicketService
  ) {}

  /**
   * find layer in themeLayersGroup,
   * optionally fallback to layerBank
   */
  findActiveLayer(
    id: string,
    fallbackToLayerBank: boolean = true
  ): ImageLayer<ImageWMS> | VectorLayer<VectorSource> | undefined {
    let foundThemeLayer = (
      this._themeLayersGroup.getLayers().getArray() as Array<ImageLayer<ImageWMS> | VectorLayer<VectorSource>>
    ).find(tl => tl.get('id') === id);

    if (!foundThemeLayer && fallbackToLayerBank) {
      const foundBankLayer = this.findBankLayer(id);
      if (foundBankLayer) {
        foundBankLayer.setVisible(true);
        this._themeLayersGroup?.getLayers().extend([foundBankLayer]);
        foundThemeLayer = (
          this._themeLayersGroup.getLayers().getArray() as Array<ImageLayer<ImageWMS> | VectorLayer<VectorSource>>
        ).find(tl => tl.get('id') === id);
      }
    }

    if (foundThemeLayer instanceof ImageLayer) {
      return foundThemeLayer as ImageLayer<ImageWMS>;
    } else if (foundThemeLayer instanceof VectorLayer) {
      return foundThemeLayer as VectorLayer<VectorSource>;
    }

    return foundThemeLayer;
  }

  /**
   * find layer in the layerBank where all available ThemeLayers are kept
   */
  findBankLayer(id: string): ImageLayer<ImageWMS> | VectorLayer<VectorSource> | undefined | null {
    return this._layerBank?.find(id);
  }

  /**
   * Get array of themeLayers currently visible in Map - sorted by zIndex, filter out user_drawn as it's not really a ThemeLayer
   */
  getActiveThemeLayers(): Array<ImageLayer<ImageWMS> | VectorLayer<VectorSource>> {
    return this._themeLayersGroup
      .getLayers()
      .getArray()
      .filter(tl => tl.getVisible() && tl.get('id') !== AppConfig.IDBOD_USER_DRAWN)
      .sort((a, b) => b.getZIndex()! - a.getZIndex()!) as Array<ImageLayer<ImageWMS> | VectorLayer<VectorSource>>;
  }

  getHighestExistingZIndex(): number {
    const allZs: number[] = [0];
    this._layerBank?.themeLayersList.forEach(l => {
      const id = l.get('id');
      if ((!id && l.getZIndex()! >= 1000) || this._zIndexExceptionIds.includes(id)) {
        // Early return if id in exceptions. These exceptions have static Z-indexes from config.
        return;
      }

      if (l.getZIndex()! !== undefined && typeof l.getZIndex()! === 'number') {
        allZs.push(l.getZIndex()!);
      }
    });
    return Math.max(...allZs);
  }

  getBankLayers(): (ImageLayer<ImageWMS> | VectorLayer<VectorSource>)[] | undefined {
    return this._layerBank?.themeLayersList;
  }

  /**
   * Set the layerList that is source for all theme layers available
   */
  newLayerList(layerConfigs: LayerConfigsType) {
    // Clear the layerGroup as we are changing layerList
    this.resetLayers();
    this._currentLayersConfig = layerConfigs;
    this._layerBank = new ThemeLayers({
      layerConfigs: layerConfigs,
      map: this._map,
      httpClient: this._httpClient,
    });
  }

  // Dispose of layer and source, and remove from _themeLayersGroup
  purgeActiveLayer(layerId: string) {
    const activeLayers = this.getActiveThemeLayers();
    const oldThemeLayerIdx = activeLayers.findIndex(l => l.get('id') === layerId);
    if (oldThemeLayerIdx > -1) {
      const oldThemeLayer = activeLayers[oldThemeLayerIdx];
      oldThemeLayer.getSource()?.dispose();
      oldThemeLayer.dispose();
      activeLayers.splice(oldThemeLayerIdx, 1);
    }
  }

  // Dispose of layer and source, and remove from _layerBank
  purgeBankLayer(layerId: string) {
    const bankLayers = this.getBankLayers();
    const oldBankLayerIdx = bankLayers?.findIndex(l => l.get('id') === layerId);
    if (oldBankLayerIdx && oldBankLayerIdx > -1 && bankLayers) {
      const oldBankLayer = bankLayers[oldBankLayerIdx];
      oldBankLayer.getSource()?.dispose();
      oldBankLayer.dispose();
      bankLayers.splice(oldBankLayerIdx, 1);
    }
  }

  /**
   * Make changes to layers in this service, then broadcast changes
   */
  registerLayerChanges(changes: LayerChange[]) {
    if (!changes?.length) {
      return;
    }

    changes.forEach(lc => {
      // user_drawn is handled by DrawService, skip it
      if (lc.layerid === AppConfig.IDBOD_USER_DRAWN) {
        return;
      }

      // Handle change to active state (layer added/removed)
      if (lc.change.active !== undefined) {
        if (lc.change.active) {
          this._addLayer(lc.layerid);
        } else {
          this._removeLayer(lc.layerid);
        }
      }

      // Unless layer was deactivated, fetch ThemeLayer to apply changes to it
      const themeLayer = this.findActiveLayer(lc.layerid, false);
      if (!themeLayer || lc.change.active === false) {
        return;
      }

      // Override exceptions for opacity
      if (lc.change.active && AppConfig.OPAQUE_EXCEPTIONS.includes(lc.layerid)) {
        themeLayer.setOpacity(1);
      }

      // Handle changes to visibility (on/off)
      if (lc.change.visible !== undefined) {
        themeLayer.setVisible(lc.change.visible);
      }

      // Handle changes to opacity (float 0.00 - 1.00)
      if (lc.change.opacity !== undefined) {
        themeLayer.setOpacity(lc.change.opacity);
      }

      let layerSrc = undefined;
      if (themeLayer) {
        layerSrc = themeLayer.getSource();
      }

      // Handle layer time change
      if (lc.change.selectedTimeOption !== undefined) {
        if (layerSrc && layerSrc instanceof ImageWMS) {
          layerSrc.updateParams({
            TIME: lc.change.selectedTimeOption,
          });
        }
      }

      // Handle layer variant change
      if (lc.change.selectedVariant !== undefined) {
        if (layerSrc) {
          const params: { [key: string]: unknown } = {
            LAYERS: lc.change.selectedVariant.value,
            TRANSPARENT: true,
            VISIBLE: true,
          };

          // Exclusive to ortho
          if (AppConfig.ORTHO_EXCEPTIONS.includes(lc.layerid)) {
            // Fetch tickets which acts as tokens to query Geonorge WMS endpoints
            const tickets = this._ticketService.getProjectTickets();
            let ticket: string | undefined = tickets[1]; // token for fetching 'wms.geonorge.no/skwms1/wms.nib-prosjekter'

            if (lc.layerid === AppConfig.IDBOD_NIB_2) {
              // flyPhotos without variant selected uses its own url/endpoint (ApiConfig.URL_GEONORGE_NIB_WMS)
              if (lc.change.selectedVariant.value === 'ortofoto') {
                layerSrc.setUrl(ApiConfig.URL_GEONORGE_WMS_NIB);
                ticket = tickets[0]; // token for fetching 'wms.geonorge.no/skwms1/wms.nib'
              } else {
                layerSrc.setUrl(ApiConfig.URL_GEONORGE_WMS_NIB_PROJECTS);
              }
            }
            params['TICKET'] = ticket;
          }

          if (layerSrc && layerSrc instanceof ImageWMS) {
            layerSrc.updateParams(params);
          }
        }
      }

      if (lc.layerid === 'basis_lantmeteriet') {
        const params: { [key: string]: unknown } = {
          LAYERS:
            'mark,hydrografi_ytor,kurvor,hydrografi,kommunikation,jarnvag,fjallinformation,kraftledningar,anlaggningar,bebyggelse,bestammelser,administrativ_indelning,adresser,text',
          TRANSPARENT: true,
          VISIBLE: true,
        };
        const ticket = this._ticketService.getLantmeterietTicket();
        // console.log('ticket: ', ticket);
        params['TICKET'] = ticket;
        if (layerSrc && layerSrc instanceof ImageWMS) {
          layerSrc.updateParams(params);
        }
      }
    });
    this._layersChange.next(changes);
  }

  /**
   * Reorder layers in this service, then broadcast changes
   */
  registerLayersReorder(layers: CatalogTreeItem[]): void {
    this._themeLayersGroup
      .getLayers()
      .getArray()
      .slice()
      .forEach(tl => {
        const newIdx = layers.findIndex(l => l.idBod === tl.get('id'));
        if (newIdx < 0) {
          this._removeLayer(tl.get('id'));
          return;
        }

        tl.setZIndex(AppConfig.ZINDEX_THEMELAYERS + newIdx); // idx 0 is reserved for bgLayer in Kilden, start at 1
      });
    this._layersReorder.next(layers);
  }

  /**
   * Register target map
   */
  registerMap(map: OlMap) {
    this._map = map;
    this._map.addLayer(this._themeLayersGroup);
  }

  /**
   * Provides a way to register a new layer into _layerBank
   */
  registerNewLayer<T extends VectorLayer<VectorSource> | ImageLayer<ImageWMS>>(id: string, newLayer: T): T | undefined {
    if (!this._layerBank) {
      return;
    }

    if (!newLayer.get('id')) {
      newLayer.set('id', id);
    }

    // Add to bank if not exists
    let themeLayer = this.findBankLayer(id);
    if (!themeLayer) {
      themeLayer = this._layerBank.addLayer<T>(newLayer);
    }
    themeLayer.setVisible(true);

    // If given ID is found as active layer, return that
    const existingActiveLayer = this.findActiveLayer(id, false);
    if (existingActiveLayer) {
      themeLayer = existingActiveLayer as T;
    } else {
      let zIndex = undefined;
      if (id === AppConfig.IDBOD_USER_DRAWN) {
        zIndex = AppConfig.ZINDEX_USER_DRAWN;
      }
      this._addLayer(id, zIndex);
    }

    // Emit to subscribers like LeftComponent
    this.registerLayerChanges([{ layerid: id, change: { active: true, label: newLayer.get('label') } } as LayerChange]);

    return themeLayer as T;
  }

  resetLayers(): void {
    this._clearLayers();
    this._layersReset.next();
  }

  /**
   * Will look up _layerBank by id and add as layer to the _themeLayersGroup
   * If id is not found in _layerBank, nothing happens.
   */
  private _addLayer(id: string, overrideZIndex?: number): boolean {
    const existing = this.findActiveLayer(id, false);
    if (existing) {
      existing.setVisible(true);
      // console.warn(
      //   `layer with id: ${id} already exists in ThemeLayersGroup. TLG size`,
      //   this._themeLayersGroup.getLayers().getLength()
      // );
      return true;
    }

    const bankLayer = this.findBankLayer(id);
    if (bankLayer && this._layerBank) {
      try {
        let zIndex = overrideZIndex;
        if (zIndex === undefined) {
          if (id === AppConfig.IDBOD_NIB_2) {
            zIndex = AppConfig.ZINDEX_ORTHOPHOTO;
          } else if (id === AppConfig.IDBOD_NIB_SATELLITE) {
            zIndex = AppConfig.ZINDEX_SATELLITE;
          } else {
            zIndex = this.getHighestExistingZIndex() + 1;
          }
        }

        bankLayer.setVisible(true);
        bankLayer.setZIndex(zIndex);
        this._themeLayersGroup?.getLayers().extend([bankLayer]);
        return true;
      } catch (error) {
        return false;
      }
    }

    return false;
  }

  /**
   * Remove layer from map.
   * Removes from _themeLayersgroup, and set as not visible in _layerBank.
   */
  private _removeLayer(id: string, deleteFromLayerBank?: boolean) {
    const activeLayer = this.findActiveLayer(id, id === AppConfig.IDBOD_USER_DRAWN);

    if (activeLayer) {
      this._themeLayersGroup?.getLayers().remove(activeLayer);

      const idx = this._layerBank?.themeLayersList.findIndex(tl => tl.get('id') === id);
      if (idx && idx > -1) {
        this._layerBank?.themeLayersList[idx].setVisible(false);
        if (deleteFromLayerBank || LayerHelper.isUserProvided(id)) {
          this._layerBank?.themeLayersList.splice(idx, 1);
        }
      }

      // ThemeLayers class has errorHandling integrated, and in order to clear potentially detected errors on this layer,
      // remove the layer as erroneous. If the layer is still in error when turned on, it will be detected again
      this._layerBank?.clearFromErrorList(id);
    }
  }

  private _clearLayers(): void {
    this._themeLayersGroup
      ?.getLayers()
      .getArray()
      .slice()
      .forEach((layer, index: number) => {
        if (layer && layer.get('id')?.length && !LayerHelper.isUserProvided(layer.get('id'))) {
          this._removeLayer(layer.get('id'));
        }
      });
  }
}
