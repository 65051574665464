import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { DefaultInitConfig } from '@kildenconfig/default-init-config';
import { KildenStandardComponent } from '@kildencore/components';
import { VegaComponent } from '@projectvega/components';
import { VegaInitConfig } from '@projectvega/config/vega-init-config';
import { environment } from 'src/environments/environment';

const routes: Routes = [
  {
    path: '**',
    redirectTo: environment.route ? environment.route : '',
    pathMatch: 'full',
  },
];

const kilden = {
  path: 'kilden',
  component: KildenStandardComponent,
  resolve: {
    config: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => configDefault,
  },
};

const vega = {
  path: 'vega',
  component: VegaComponent,
  resolve: {
    config: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => configVega,
  },
};

//routes.unshift(kilden);

if (environment.addOtherRoutes) {
  // only on utv add all
  routes.unshift(vega) && routes.unshift(kilden);
} else if (environment.route === 'vega') {
  routes.unshift(vega);
} else {
  routes.unshift(kilden);
}

// console.log('routes', routes);

// Custom config for default Kilden or a project
export const configVega: Object = VegaInitConfig.config;
export const configDefault: Object = DefaultInitConfig.config;

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
