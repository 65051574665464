import { FeatureLike } from 'ol/Feature';
import { Stroke } from 'ol/style';
import Fill from 'ol/style/Fill';
import Style, { StyleFunction } from 'ol/style/Style';
import Text from 'ol/style/Text';

export const ForestRoadsStyle: StyleFunction = (feature: FeatureLike, resolution: number): Style | Style[] => {
  const color = '#FF00FF';
  const vNr = feature.getProperties()['vegnummer'];
  // console.log('Strekningnummer',feature.get('strekningnummer') );

  let parsell = '';
  if (feature.get('strekningnummer')) {
    parsell = feature.get('strekningnummer').toString();

    const vRef = vNr + '/' + parsell;
    let textScale = 0.01;
    if (resolution < 20) {
      textScale = 1;
    }
    if (resolution < 5) {
      textScale = 1.5;
    }
    if (vNr !== '0') {
      var bufferDefaultColor = new Style({
        stroke: new Stroke({
          color: [255, 255, 255, 0.01],
          width: 50,
        }),
      });
      return [
        bufferDefaultColor,
        new Style({
          stroke: new Stroke({
            color: color,
            width: 4,
          }),
          text: new Text({
            font: '10px helvetica,sans-serif',
            scale: textScale,
            text: vRef,
            //rotation: 360 * rnd * Math.PI / 180,
            fill: new Fill({
              color: '#000',
            }),
            stroke: new Stroke({
              color: '#FFF',
              width: 4,
            }),
          }),
        }),
      ];
    } else {
      return [];
    }
  } else {
    return [];
  }
};
