import { WildlifePropertyMappingsConst } from '@kildenshared/constants/wildlife-property-mappings.const';
import { FeatureLike } from 'ol/Feature';
import { Stroke } from 'ol/style';
import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import RegularShape from 'ol/style/RegularShape';
import Style, { StyleFunction } from 'ol/style/Style';
import Text from 'ol/style/Text';

export const WildPointsStyle: StyleFunction = (feature: FeatureLike, resolution: number): Style | Style[] => {
  const properties: {
    [key in keyof typeof WildlifePropertyMappingsConst]: string | any;
  } = Object.assign(WildlifePropertyMappingsConst, feature.getProperties());

  const sex: string = feature.get('sex').toString();

  const olStroke = new Stroke({
    color: properties['symbol_line'] || [0, 0, 0, 1],
    width: 1,
  });

  const olFill = new Fill({
    color: properties['symbol_color'] || 'blue',
  });

  const triangle = new RegularShape({
    fill: olFill,
    stroke: olStroke,
    points: 3,
    radius: 7,
    //rotation: Math.PI / 4,
    angle: 0,
  });

  const circle = new Circle({
    radius: 7,
    fill: olFill,
    stroke: olStroke,
  });

  const square = new RegularShape({
    fill: olFill,
    stroke: olStroke,
    points: 4,
    radius: 7,
    rotation: Math.PI / 4,
    angle: 0,
  });

  let symbolShape = triangle;
  if (properties['symbol'] === 'circle') {
    symbolShape = circle;
  } else if (properties['symbol'] === 'square') {
    symbolShape = square;
  }

  let textScale = 0.01;
  if (resolution < 2) {
    textScale = 1;
  }

  if (properties['collar_id'] !== '0') {
    const bufferDefaultColor = new Style({
      stroke: new Stroke({
        color: [0, 0, 0, 0.7],
        width: 50,
      }),
    });
    return [
      bufferDefaultColor,
      new Style({
        image: symbolShape,
        text: new Text({
          font: '10px helvetica,sans-serif',
          scale: textScale,
          text: properties['Acquisition_time'],
          offsetY: -20,
          //rotation: 360 * rnd * Math.PI / 180,
          fill: new Fill({
            color: '#000',
          }),
          stroke: new Stroke({
            color: '#fff',
            width: 4,
          }),
        }),
      }),
    ];
  } else {
    return [];
  }
};
