import { Component } from '@angular/core';
import { KeyboardShortcutsEnum } from '@kildenshared/constants/keyboard-shortcuts.enum';

@Component({
  selector: 'kilden3-keyboard-tips',
  templateUrl: './keyboard-tips.component.html',
  styleUrls: ['./keyboard-tips.component.css'],
})
export class KeyboardTipsComponent {
  shortcuts = Object.entries(KeyboardShortcutsEnum);
}
