import { Injectable } from '@angular/core';
import { AppConfig } from '@kildenconfig/app.config';
import { ProjectConfigType } from '@kildenshared/types/project-config.type';

@Injectable()
export class DefaultInitConfig {
  public static readonly config = DefaultInitConfig.getConfig();

  public static getConfig() {
    const configKilden: ProjectConfigType = {
      logo: 'assets/NIBIO_logo_hvit_ikketittel.png',
      logoUrl: 'https://nibio.no',
      layerSearch: true,
      threeD: true,
      startCoordinates: { x: 7219344, y: 383375, z: AppConfig.MAP_ZOOM_START },
      zoomExtentController: AppConfig.EXTENT_NORWAY_MAINLAND,
    };
    return configKilden;
  }
}
