import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DialogService, DialogVariants } from '@kildencore/services/dialog.service';
import { Kilden3DialogComponent } from '@kildenshared/components/dialog/dialog.component';
import { KeyboardTipsComponent } from '@kildenshared/components/keyboard-tips/keyboard-tips.component';

@Injectable({
  providedIn: 'root',
})
export class KeyboardService {
  constructor(
    private readonly _dialog: MatDialog,
    private readonly _dialogService: DialogService,
    @Inject(DOCUMENT)
    private readonly document: Document
  ) {}

  /**
   * Shortcuts that should be available everywhere in the app.
   * There should be a matching case for each item in KeyboardShortcutsEnum
   * @see KeyboardShortcutsEnum
   */
  handleGlobalShortcuts(e: KeyboardEvent): void {
    if (e.altKey && e.key.toLowerCase() === 'b') {
      document.getElementById('bgSelector')?.focus();
    }
    if (e.altKey && e.key.toLowerCase() === 'g') {
      document.getElementById('geolocation')?.focus();
    }
    if (e.altKey && e.key.toLowerCase() === 'h') {
      this._showKeyboardTips();
    }
    if (e.altKey && e.key.toLowerCase() === 'i') {
      document.getElementById('sidenavToggle')?.focus();
    }
    if (e.altKey && e.key.toLowerCase() === 'k') {
      document.getElementById('mat-tab-label-0-0')?.focus();
    }
    if (e.altKey && e.key.toLowerCase() === 'o') {
      document.getElementById('topic')?.focus();
    }
    if (e.altKey && e.key.toLowerCase() === 'p') {
      this._dialogsFocusCycle();
    }
    if (e.altKey && e.key.toLowerCase() === 's') {
      document.getElementById('search-input-field')?.focus();
    }
    if (e.altKey && e.key.toLowerCase() === 't') {
      document.getElementById('legend')?.focus();
    }
    if (e.altKey && e.key.toLowerCase() === 'v') {
      document.getElementById('search-layer-input-field')?.focus();
    }
  }

  private _showKeyboardTips(): void {
    if (this._dialogService.isOpen(DialogVariants.KEYBOARD_HINTS)) {
      this._dialogService.focusDialog(DialogVariants.KEYBOARD_HINTS);

      // this._dialogService.dismissDialog(DialogVariants.KEYBOARD_HINTS);
      // const handle = this._dialogService.getHandle(
      //   DialogVariants.KEYBOARD_HINTS
      // ) as MatDialogRef<Kilden3DialogComponent>;
      // handle._containerInstance._recaptureFocus();
      // handle.
    } else {
      this._dialogService.openDialog({
        variant: DialogVariants.KEYBOARD_HINTS,
        dialogHandle: this._dialog.open(Kilden3DialogComponent, {
          autoFocus: false,
          id: 'keyboardTips',
          maxWidth: '750px',
          minHeight: '320px',
          minWidth: '320px',
          hasBackdrop: false,
          data: {
            title: 'Tastatursnarveier for Kilden',
            component: KeyboardTipsComponent,
          },
        }),
        keyboardService: this,
      });
    }
  }

  /**
   * This exists to help a keyboard user cycle through open dialogs.
   * When this method is called, keyboard focus should be moved to next opened dialog
   */
  private _dialogsFocusCycle(): void {
    const dialogHtmlIds: string[] = [];

    // Get new list of open dialogs every time shortcut is used since it's likely some dialogs were opened/closed.
    this._dialogService.getOpenDialogs().forEach(d => {
      if (d instanceof MatDialogRef && d.hasOwnProperty('id')) {
        dialogHtmlIds.push(d.id);
      }
    });

    // Early return if no open dialogs
    if (!dialogHtmlIds.length) {
      return;
    }

    // Focus the first item by default
    let currentFocusIdx: number = 0;
    // Check if the currently focused browser element (document.activeElement) is inside one of the open dialogs
    for (let i = 0; i < dialogHtmlIds.length; i++) {
      const dialogEl = document.getElementById(dialogHtmlIds[i]);
      if (dialogEl?.contains(document.activeElement)) {
        // Match found, store which dialog is in focus
        currentFocusIdx = i;
      }
    }

    let nextFocusHtmlId: string = dialogHtmlIds[currentFocusIdx + 1];
    // Loop back to top if current focused was last in list, so +1 would be out of bounds
    if (currentFocusIdx + 1 === dialogHtmlIds.length) {
      nextFocusHtmlId = dialogHtmlIds[0];
    }

    document.getElementById(nextFocusHtmlId)?.focus();
  }
}
