<div *ngIf="environment.production === false && isDevMode" class="debug">
  <!-- Start debug area -->

  <h5 *ngIf="debugToolService.data.length">DebugTool.data:</h5>
  <ol *ngFor="let item of debugToolService.data">
    <li>{{ item | json }}</li>
  </ol>

  <!--  <div *ngIf="dialogRef">-->
  <!--    ObjInfo Dialog ({{ dialogRef.id }}):-->
  <!--    {{ dialogRef.getState() === 0 ? 'Open' : 'Not open' }}-->
  <!--  </div>-->

  <!--  <ng-container-->
  <!--    *ngIf="-->
  <!--      objectinfoService.getStoredDialogSize()?.width ||-->
  <!--      objectinfoService.getStoredDialogSize()?.height-->
  <!--    ">-->
  <!--    <h5>objSvc.dialogSize:</h5>-->
  <!--    {{ objectinfoService.getStoredDialogSize() | json }}-->
  <!--  </ng-container>-->

  <!-- / End debug area-->
</div>
