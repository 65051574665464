import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { TreeItem } from './tree-item';

@Component({
  selector: 'kilden3-catalog-item',
  templateUrl: './catalog-item.component.html',
  styleUrls: ['./catalog-item.component.css'],
})
export class CatalogItemComponent<T extends TreeItem> {
  // description  https://javascript.plainenglish.io/angular-tree-control-8896e63f04a
  // Here comes each level of catalogtree in
  @Input()
  catalogTree: T[] = [];

  @ContentChild(TemplateRef)
  nodeTemplate: TemplateRef<any> | null = null;
}
