import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

// Generated with ng generate component --module core --project kilden3  core/components/kildenStandard
@Component({
  selector: 'kilden3-standard',
  templateUrl: './kilden-standard.component.html',
  styleUrls: ['./kilden-standard.component.css'],
})
export class KildenStandardComponent {
  constructor(
    private meta: Meta,
    private title: Title
  ) {
    this.title.setTitle('Kilden');
    this.meta.addTags([
      {
        name: 'description',
        content:
          'Kilden er NIBIOs hovedkartløsning. Her er det meste av instituttets ' +
          ' data samlet på ett sted sammen med et utvalg andre sentrale, ' +
          ' nasjonale datasett. Løsningen er delt inn i flere fagområder, ' +
          ' og tilbyr funksjonalitet som innsyn, utskrift, 3D, sammenligning ' +
          ' av kartlag, lenker til informasjon om kartlagene, ' +
          ' tegne- og måle areal/lengde, opplasting av filer ' +
          ' for visning i kartet og forskjellige arealberegninger.',
      },
      { name: 'author', content: 'Line Larsen' },
      { name: 'robots', content: 'index, follow' },
      {
        name: 'keword',
        content:
          'AR5, AR50, AR250, Arealtype, Skogbonitet, Treslag, Grunnforhold,' +
          'Dyrkbarjord, Myr, Jordbruksareal, Skog, Utmarksbeite, Vegetasjon,' +
          'Beitebruk, Arealressurser, Snaumark, Jordsmonn, Erosjonsrisiko,' +
          'Landskap,Skogressurskart, Miljøregistreringer i skog, Skogbruksplan,' +
          'Markfuktighetskart, Verdiklasser, Arealinformasjon, Skogportalen,' +
          'Reindrift, Dyreportalen, Jordkvalitet, Jordressurs, Barkbilleovervåking,' +
          'SR16, Viltkart',
      },
    ]);
  }

  jumpToTopic(event: Event): void {
    event.preventDefault();
    document.getElementById('topic')?.focus();
  }
}
