import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { MapService } from '@kildencore/services/map.service';
import { ThemeLayersService } from '@kildencore/services/theme-layers.service';
import { BackgroundLayerIdsEnum } from '@kildenshared/constants/background-layer-ids.enum';
import { LayerChange } from '@kildenshared/interfaces';
import { BackgroundLayerInterface } from '@kildenshared/interfaces/background-layer.interface';
import { BackgroundLayersListInterface } from '@kildenshared/interfaces/background-layers-list.interface';
import * as _ from 'lodash';
import { filter, Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'kilden3-background-layer-selector',
  templateUrl: './background-layer-selector.component.html',
  styleUrls: ['./background-layer-selector.component.css'],
})
export class BackgroundLayerSelectorComponent implements OnDestroy, OnInit {
  bgLayers: BackgroundLayerInterface[] = [];
  chosenLayerId: BackgroundLayerIdsEnum | undefined;

  private readonly layersRequiringPlanePhoto = ['ar5_flater', 'ar5_flater_minfo', 'ar5_usikre_flater'];
  private readonly _onDestroy$ = new Subject<void>();

  constructor(
    private readonly _elRef: ElementRef,
    private readonly _mapService: MapService,
    private readonly _themeLayersService: ThemeLayersService
  ) {}

  executeKeydown($event: any): void {
    const keyCode = $event.which;
    const target = $event.target;
    const collapsed = !target.parentNode.classList.contains('opened');
    if (target.id === 'bgSelector' && collapsed) return;

    switch (keyCode) {
      case 9: // tab
        $event.preventDefault();
        const bs = document.getElementById('bgSelector');
        bs ? bs.focus() : null;
        bs ? bs.click() : null;
        break;
      case 37: // leftArrow
        target.nextElementSibling ? target.nextElementSibling.focus() : target.focus();
        break;
      case 39: // RightArrow
        target.previousElementSibling ? target.previousElementSibling.focus() : target.focus();
        break;
    }
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  ngOnInit(): void {
    this._mapService.bgListReceived$
      .pipe(
        tap((bg: BackgroundLayersListInterface) => {
          const order = [
            BackgroundLayerIdsEnum.GRAYSCALE,
            BackgroundLayerIdsEnum.COLORS,
            BackgroundLayerIdsEnum.BASIC,
            BackgroundLayerIdsEnum.RASTER,
            BackgroundLayerIdsEnum.NORWAY_PHOTOS,
            BackgroundLayerIdsEnum.NONE,
          ];
          this.bgLayers = _.sortBy(bg.list, function (obj) {
            return _.indexOf(order, obj.id);
          });
          this.chosenLayerId = bg.initial;
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();

    this._themeLayersService.layersChange$
      .pipe(
        filter((changes: LayerChange[]) => changes?.length > 0),
        tap(changes => {
          const needsPlanePhotoBg = changes.some(lc => {
            return (
              lc.change.active !== undefined && lc.change.active && this.layersRequiringPlanePhoto.includes(lc.layerid)
            );
          });

          if (needsPlanePhotoBg) {
            this.highlight(BackgroundLayerIdsEnum.NORWAY_PHOTOS);
            this._mapService.changeBackgroundLayer(BackgroundLayerIdsEnum.NORWAY_PHOTOS);
            (this._elRef.nativeElement as HTMLElement).classList.remove('opened');
          }
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();
  }

  showChoices(): void {
    this._elRef.nativeElement.classList.toggle('opened');
  }

  switchTo(layerId: BackgroundLayerIdsEnum): void {
    this.highlight(layerId);
    this._mapService.changeBackgroundLayer(layerId);
    this._elRef.nativeElement.classList.toggle('opened');
  }

  private highlight(layerId: BackgroundLayerIdsEnum): void {
    const hs = document.getElementsByClassName('bg-chosen');
    const hsl = hs.length;
    for (let i = 0; i < hsl; i++) hs[i].classList.remove('bg-chosen');
    document.getElementById(layerId)?.classList.add('bg-chosen');
  }
}
