<div [formGroup]="frm" class="flex-wrapper project">
  <div class="flex-item">
    <label for="search-wildlife-filter-project">Valg av prosjekt:</label>
    <select
      (change)="projectSelected()"
      [formControl]="frm.controls.projects"
      id="search-wildlife-filter-project"
      multiple
      size="4">
      <option *ngFor="let val of dataProjects" [value]="val">{{ val }}</option>
    </select>
  </div>

  <div class="flex-item individual">
    <label for="search-wildlife-filter-individual">Valg av individ:</label>
    <select
      #individualsRef
      [formControl]="frm.controls.individuals"
      id="search-wildlife-filter-individual"
      multiple
      size="4">
      <option *ngFor="let val of dataIndividuals" [value]="val">
        {{ val }}
      </option>
    </select>
  </div>

  <div class="flex-item period" [class.inactive]="!isPeriodActive">
    <label for="search-wildlife-filter-period">Valg av periode:</label>

    <div *ngIf="!isPeriodActive">Ikke relevant for datamengde "Siste&nbsp;registrerte"</div>

    <div *ngIf="isPeriodActive" class="period-picker">
      <mat-date-range-input [max]="maxDate" [rangePicker]="picker" separator="">
        <input
          matStartDate
          (dateChange)="dateStartPicked($event.value)"
          [formControl]="frm.controls.periodStart"
          [max]="dateToday"
          id="search-wildlife-filter-period"
          placeholder="Fra dato" />
        <input
          matEndDate
          (dateChange)="dateEndPicked($event.value)"
          [formControl]="frm.controls.periodEnd"
          [max]="dateToday"
          placeholder="Til dato" />
      </mat-date-range-input>
      <mat-date-range-picker #picker></mat-date-range-picker>
      <button (click)="picker.open()" [disabled]="!isPeriodActive" class="btn--rangepicker mat-flat-button">
        <mat-icon>event</mat-icon>
        Velg periode
      </button>
    </div>
  </div>

  <div class="flex-item quantity">
    <label for="search-wildlife-filter-dataset">Valg av datamengde:</label>
    <select [formControl]="frm.controls.quantity" id="search-wildlife-filter-dataset">
      <option *ngFor="let val of dataQuantities" [value]="val">
        {{ val }}
      </option>
    </select>
    <button (click)="search()" (keyup.enter)="search()" class="search mat-flat-button">
      <mat-spinner
        *ngIf="fetchingAnimals"
        [diameter]="19"
        color="primary"
        class="loading"
        mode="indeterminate"></mat-spinner>
      <span>Søk{{ fetchingAnimals ? 'er..' : '' }}</span>
    </button>

    <div *ngIf="formFeedback?.length && !fetchingAnimals" class="feedback">
      {{ formFeedback }}
    </div>

    <div class="feedback">
      <span *ngIf="frm.controls.periodStart.hasError('matDatepickerMax')"
        >Startdato kan ikke være høyere enn dagens dato.</span
      >
      <span *ngIf="frm.controls.periodEnd.hasError('matDatepickerMax')"
        >Sluttdato kan ikke være høyere enn dagens dato.</span
      >
      <span *ngIf="frm.hasError('date_range_invalid')">Sluttdato må være nyere enn startdato.</span>
    </div>
  </div>
</div>
