import { Location } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppConfig } from '@kildenconfig/app.config';
import { LayerHelper } from '@kildencore/helpers/layer.helper';
import { SearchService } from '@kildencore/services/data/search.service';
import { KildenStateService } from '@kildencore/services/kilden-state.service';
import { ThemeLayersService } from '@kildencore/services/theme-layers.service';
import { CatalogTreeItem } from '@kildenshared/components/catalog-tree/catalog-tree-item';
import { ToolIdsEnum } from '@kildenshared/constants/tool-ids.enum';
import { TopicIdsEnum } from '@kildenshared/constants/topic-ids.enum';
import { LayerChange } from '@kildenshared/interfaces';
import { filter, skip, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PermaLinkService {
  private _params = new HttpParams();
  private _paramLayers: CatalogTreeItem[] = [];

  constructor(
    public readonly location: Location,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _kildenStateService: KildenStateService,
    private readonly _searchService: SearchService,
    private readonly _themeLayersService: ThemeLayersService
  ) {
    // Read the original routing parameters
    this._saveInitialParams();
    this._setupSubscriptions();
  }

  /**
   * Fetch value from URL parameter. This is the initial value on page loading.
   * @param key name of the parameter we are looking for
   * @return value from URL
   */
  getInitialValue(key: allowedQueryParams | string): string | number | boolean | null {
    // let v = this.route.snapshot.queryParams[key]; // did not work for capital
    let v = this._params.get(key);
    // Becomes array when multiple times:
    if (key !== 'layers' && Array.isArray(v)) {
      v = v[0];
    }
    return v;
  }

  /**
   * Provide outside with an easy way to find the permalink values of a given layer
   */
  getParamLayerById(idBod: string): CatalogTreeItem | undefined {
    return this._paramLayers.find(pl => pl.idBod === idBod);
  }

  /**
   * Check if key is allowed and value is an actual change and update the
   * params variable.
   */
  setParam(key: allowedQueryParams | string, value: string | number | boolean | undefined): boolean {
    if (isAllowedQueryParam(key) && (!this._params.has(key) || this._params.get(key) !== value)) {
      this._params = value === undefined ? this._params.delete(key) : this._params.set(key, value);
      return true;
    }
    return false;
  }

  /**
   * Update the query parameter with value if key is an allowed query parameter
   * and the value changes. Add to  browser history.
   * @param  key                         key of the query parameter to set
   * @param  value                       value of the parameter
   * @return boolean                     true if url is updated with new value,
   *                                     false if the value does not change or the
   *                                     key is not allowed
   */
  setParamNewHistory(key: allowedQueryParams | string, value: string | number | boolean) {
    if (this.setParam(key, value)) {
      this.location.go('', this._params.toString());
    }
  }

  /**
   * Update the query parameter with value if key is an allowed query parameter
   * Replace the current browser history.
   * @param  key                         key of the query parameter to set
   * @param  value                       value of the parameter
   * @return               void
   */
  setParamReplaceHistory(key: allowedQueryParams | string, value: string | number | boolean | undefined): void {
    if (this.setParam(key, value)) {
      this.location.replaceState('', this._params.toString());
    }
  }

  /**
   * Updates one property on given paramLayer, then updates URL query params
   */
  updateLayerParam(idBod: string, property: 'opacity' | 'visible', value: number | boolean): void {
    const layer = this.getParamLayerById(idBod);
    if (layer) {
      if (property === 'opacity') {
        layer.opacity = value as number;
      } else if (property === 'visible') {
        layer.visible = !!value;
      }
      this._updateQueryParamsForLayers();
    }
  }

  private _clearLayers(): void {
    this._paramLayers = [];
  }

  /**
   * Callback for the layerChanges$ observable. Makes sure URL is in sync with layers
   */
  private _handleLayerChanges(layerChanges: LayerChange[]): void {
    if (!layerChanges?.length) {
      return;
    }

    layerChanges.forEach(lc => {
      if (!layerChanges?.length) {
        return;
      }

      // Handle adding or removing a layer
      if (lc.change.active !== undefined) {
        if (lc.change.active) {
          this._layerAddActive(lc);
        } else {
          this._layerDeleteActive(lc);
        }
      } else {
        // Handle changes to an existing layer
        const layerIdx = this._paramLayers.findIndex(l => l.idBod === lc.layerid);
        LayerHelper.applyAttributeChanges(this._paramLayers[layerIdx], lc);
      }
    });

    // Update URL based on paramLayers cache
    if (!layerChanges[0].holdUpdate) this._updateQueryParamsForLayers();
  }

  /**
   * Add layer changes to Permalink paramLayers cache
   */
  private _layerAddActive(lc: LayerChange): void {
    let addLayer = LayerHelper.getLayerDefaults(undefined);
    addLayer = LayerHelper.applyAttributeChanges(addLayer, lc);

    if (!this._paramLayers.some(pl => pl.idBod === addLayer.idBod)) {
      this._paramLayers.push(addLayer);
    }
  }

  /**
   * Remove layer from paramLayers cache
   */
  private _layerDeleteActive(lc: LayerChange): void {
    const layerIdx = this._paramLayers.findIndex(l => l.idBod === lc.layerid);
    if (layerIdx > -1) {
      this._paramLayers.splice(layerIdx, 1);
    }
  }

  /**
   * Register all given layers in paramLayers cache
   */
  private _registerLayers(layers: CatalogTreeItem[], clearExisting: true): void {
    if (clearExisting) {
      this._clearLayers();
    }

    if (layers?.length) {
      this._paramLayers.push(...layers);
    }
  }

  /**
   * Filter all initial query parameters, store the allowed ones in this._params cache
   */
  private _saveInitialParams() {
    if (this._activatedRoute.snapshot.queryParams) {
      // eslint-disable-next-line prefer-const
      for (let [key, val] of Object.entries(this._activatedRoute.snapshot.queryParams)) {
        key = key.length === 1 ? key.toLowerCase() : key;
        if (isAllowedQueryParam(key) && val) {
          this._params = this._params.set(key, val);
        }
      }
    }
  }

  /**
   * Config and start all listeners
   */
  private _setupSubscriptions(): void {
    this._kildenStateService.epsg$.pipe(skip(1)).subscribe({
      next: nextEpsg => {
        const newVal = nextEpsg?.length ? nextEpsg.replace('EPSG:', '') : undefined;
        this.setParamReplaceHistory('epsg', newVal);
      },
    });

    this._kildenStateService.layerSwipeActive$
      .pipe(
        tap(active => {
          if (!active) {
            this.setParamReplaceHistory(allowedQueryParams.COMPARE_RATIO, undefined);
          }
        })
      )
      .subscribe();

    this._kildenStateService.layerSwipeRatio$
      .pipe(
        tap(layerSwipeRatio => {
          this.setParamReplaceHistory(allowedQueryParams.COMPARE_RATIO, layerSwipeRatio || undefined);
        })
      )
      .subscribe();

    // On topic change, clear out all topic-specific url params
    this._kildenStateService.topic$
      .pipe(
        filter(newTopicId => newTopicId.length > 0),
        tap((newTopicId: TopicIdsEnum | string) => {
          if (newTopicId !== TopicIdsEnum.WILDLIFE) {
            this.setParam(allowedQueryParams.WILD_ID, undefined);
            this.setParam(allowedQueryParams.WILD_PERIOD, undefined);
            this.setParam(allowedQueryParams.WILD_PROJECT, undefined);
            this.setParam(allowedQueryParams.WILD_QUANTITY, undefined);

            this.location.replaceState('', this._params.toString());
          }
        })
      )
      .subscribe();

    // Update URL on tool change
    this._kildenStateService.tool$
      .pipe(
        skip(1), // BehaviourSubject already has val
        filter(newToolId => newToolId.length > 0),
        tap((newToolId: ToolIdsEnum) => {
          this.setParamReplaceHistory(allowedQueryParams.TOOL, newToolId === ToolIdsEnum.INFO ? undefined : newToolId);
        })
      )
      .subscribe();

    // Remove qparams on search cleared
    this._searchService.searchCleared$
      .pipe(
        tap(() => {
          this.setParamReplaceHistory('komnr', undefined);
        })
      )
      .subscribe();

    // When layer order changes we must update the layers_* params
    this._themeLayersService.layersReorder$
      .pipe(
        tap(layers => {
          this._registerLayers(layers, true);
          this._updateQueryParamsForLayers();
        })
      )
      .subscribe();

    // When layers are reset we must update the layers_* params
    this._themeLayersService.layersReset$
      .pipe(
        tap(() => {
          this._clearLayers();
          this._updateQueryParamsForLayers();
        })
      )
      .subscribe();

    // When a layer is toggled, we might need to update permalink
    this._themeLayersService.layersChange$.pipe(tap(changes => this._handleLayerChanges(changes))).subscribe();
  }

  /**
   * Update URL based on paramLayers cache
   */
  public _updateQueryParamsForLayers(): void {
    this.setParam(
      allowedQueryParams.LAYERS,
      this._paramLayers.map(l => l.idBod).join(AppConfig.QP_SEPARATOR_PRIMARY) || undefined
    );

    this.setParam(
      allowedQueryParams.LAYERS_OPACITY,
      this._paramLayers.map(l => l.opacity).join(AppConfig.QP_SEPARATOR_PRIMARY) || undefined
    );

    this.setParam(
      allowedQueryParams.LAYERS_VISIBILITY,
      this._paramLayers.map(l => l.visible).join(AppConfig.QP_SEPARATOR_PRIMARY) || undefined
    );

    // Handle layer versions/time
    const timeParamSegments: string[] = [];
    if (this._paramLayers.some(pl => pl.timeOptions?.selected)) {
      this._paramLayers.forEach(pl => {
        if (pl.timeOptions?.selected) {
          timeParamSegments.push(`${pl.idBod}${AppConfig.QP_SEPARATOR_SECONDARY}${pl.timeOptions.selected}`);
        }
      });
    }
    this.setParam(allowedQueryParams.LAYERS_TIME, timeParamSegments.join(AppConfig.QP_SEPARATOR_PRIMARY) || undefined);

    // Handle layer variants
    const variantParamSegments: string[] = [];
    if (this._paramLayers.some(pl => pl.variants?.selected)) {
      this._paramLayers.forEach(pl => {
        if (pl.variants?.selected) {
          variantParamSegments.push(`${pl.idBod}${AppConfig.QP_SEPARATOR_SECONDARY}${pl.variants.selected.value}`);
        }
      });
    }
    this.setParam(
      allowedQueryParams.LAYERS_VARIANT,
      variantParamSegments.join(AppConfig.QP_SEPARATOR_PRIMARY) || undefined
    );

    // Finally replace the location state with the updates params. Call only once to prevent browser throttling.
    this.location.replaceState('', this._params.toString());
  }
}

export enum allowedQueryParams {
  BG_LAYER = 'bgLayer',
  COMPARE_RATIO = 'compare_ratio',
  EPSG = 'epsg',
  KOMNR = 'komnr',
  LAYERS = 'layers',
  LAYERS_OPACITY = 'layers_opacity',
  LAYERS_TIME = 'layers_time',
  LAYERS_VARIANT = 'layers_variant',
  LAYERS_VISIBILITY = 'layers_visibility',
  TOOL = 'tool',
  TOPIC = 'topic',
  WILD_ID = 'wild_id',
  WILD_PERIOD = 'wild_period',
  WILD_PROJECT = 'wild_proj',
  WILD_QUANTITY = 'wild_quantity',
  X = 'x',
  Y = 'y',
  ZOOM = 'zoom',
}

export function isAllowedQueryParam(myStr: allowedQueryParams | string): myStr is allowedQueryParams {
  return (Object as any).values(allowedQueryParams).includes(myStr);
}
