import MapFishPrintV3WMSSerializer from '@kildencore/serializer/map-fish-print-v3-wmsserializer';
import TileLayer from 'ol/layer/Tile';
import { Source, TileWMS } from 'ol/source';
import OlSourceTileWMS from 'ol/source/TileWMS';

export class MapFishPrintV3TiledWMSSerializer extends MapFishPrintV3WMSSerializer {
  static override TYPE_WMS = 'tiledwms';

  constructor() {
    super();
  }

  override canSerialize(source: Source) {
    return source instanceof OlSourceTileWMS;
  }

  /**
   * Serializes/Encodes the given layer.
   *
   * @abstract
   * @param {TileLayer<TileWMS>} layer The layer to serialize/encode.
   * @param {Object} opts Additional properties to pass to the serialized
   *   layer object that can't be obtained by the layer itself. It can also be
   *   used to override all generated layer values, e.g. the image format. Only
   *   used in V3.
   * @param {number} viewResolution The resolution to calculate the styles for.
   * @return {Object} The serialized/encoded layer.
   */
  override serialize(layer: TileLayer<TileWMS>, opts: any = {}, viewResolution: any) {
    // defaultsDeep(opts, {  // no need because we set this in MapFishPrintV3WMSSerializer
    //   tileSize: [512, 512],
    // });

    const source = layer.getSource();

    if (!source || !this.validateSource(source)) {
      return;
    }

    return {
      ...super.serialize(layer, opts, viewResolution),
      ...{
        type: MapFishPrintV3TiledWMSSerializer.TYPE_WMS,
      },
      ...opts,
    };
  }
}

export default MapFishPrintV3TiledWMSSerializer;
