<div class="mapPanel" role="main">
  <kilden3-layer-swipe *ngIf="isLayerSwipeActive"></kilden3-layer-swipe>

  <div (fileDrop)="filesDropped($event)" id="map" class="map" tabindex="0">
    <div id="fileDropPlaceholderRef" fileDropPlaceholderRef>Slipp filen her for å laste inn i kartet</div>
    <kilden3-geolocation></kilden3-geolocation>
  </div>

  <kilden3-three-d *ngIf="isConfig3dEnabled && isTopic3dEnabled"></kilden3-three-d>

  <kilden3-maalestokk></kilden3-maalestokk>

  <kilden3-attributions></kilden3-attributions>

  <kilden3-debug-tool></kilden3-debug-tool>

  <kilden3-background-layer-selector></kilden3-background-layer-selector>

  <select id="epsgSelector" title="Bytt projisering / UTM sone">
    <option value="EPSG:25832">UTM 32</option>
    <option value="EPSG:25833">UTM 33</option>
    <option value="EPSG:25835">UTM 35</option>
    <option value="EPSG:3857">EPSG:3857</option>
  </select>

  <kilden3-feature-info></kilden3-feature-info>
</div>

<div id="popup" class="ol-popup">
  <a href="#" id="popup-closer" class="ol-popup-closer"></a>
  <div id="popup-content"></div>
</div>
