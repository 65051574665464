import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ApiConfig } from '@kildenconfig/api-config';
import { AppConfig } from '@kildenconfig/app.config';
import { ForestRoadInfoType } from '@kildenshared/components/forest-road-info/forest-road-info.type';
import { finalize, take } from 'rxjs';

@Component({
  selector: 'kilden3-forest-road-info',
  templateUrl: './forest-road-info.component.html',
  styleUrls: ['./forest-road-info.component.css'],
})
export class ForestRoadInfoComponent implements OnChanges, OnInit {
  @Input()
  id!: string;

  details: ForestRoadInfoType[] = [];
  processing: boolean = true;
  response!: ForestRoadInfoType[];
  roadNumber!: string;
  roadObjects: ForestRoadInfoType[] = [];
  summary: ForestRoadInfoType[] = [];

  protected readonly AppConfig = AppConfig;

  constructor(private readonly _httpClient: HttpClient) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['id'].isFirstChange() && changes['id'].currentValue) {
      this._fetchRoadInfo();
    }
  }

  ngOnInit(): void {
    this._fetchRoadInfo();
  }

  asPropArray(prop: any): ForestRoadInfoType[] {
    return prop as ForestRoadInfoType[];
  }

  isArray(prop: any): boolean {
    return Array.isArray(prop);
  }

  private _fetchRoadInfo(): void {
    this.processing = true;

    this._httpClient
      .get<[ForestRoadInfoType[]]>(ApiConfig.forestRoadInfoUrl + '?sveiid=' + this.id)
      .pipe(
        finalize(() => {
          // @ts-ignore
          this.processing = false;
        }),
        take(1)
      )
      .subscribe(response => {
        this.response = response[0];
        this.details = [];
        this.roadObjects = [];

        // assign extended data which for some reason is nested under the first array item's "value" property
        if (Array.isArray(this.response[0].value)) {
          this.details = this.response[0].value as ForestRoadInfoType[];

          // prevent duplicate "Veinummer" in both ingress and details
          if (this.details[0].navn.toLowerCase() === 'veinummer') {
            this.roadNumber = this.details[0].value as string;
            this.details.shift();
          }
        }

        // special handling for veiobjekter
        if (
          this.response.length > 2 &&
          this.response[this.response.length - 1]['navn'].toLowerCase() === 'veiobjekter'
        ) {
          // First response item contains road-details, last contains road-objects. For the summary, we want all in-betweens.
          this.summary = this.response.slice(1, -1);

          // Now assign all roadObjects into its own variable with typecasted properties
          const sliced = this.response.slice(-1);
          const popped = sliced.pop();
          if (popped?.value) {
            this.roadObjects = popped.value as ForestRoadInfoType[];
            this.roadObjects.forEach(roadObject => {
              // cast to array type and filter invalid records like: {navn: "Navn", value: null}
              roadObject.value = roadObject.value as ForestRoadInfoType[];
              roadObject.value = roadObject.value.filter(item => item.value);
            });
          }
        } else {
          // No roadObjects, skip the details in response[0] and use the rest for summary.
          this.summary = this.response.slice(1);
        }
      });
  }
}
