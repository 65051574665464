import { Injectable, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiConfig } from '@kildenconfig/api-config';
import { AppConfig } from '@kildenconfig/app.config';
import { LayerHelper } from '@kildencore/helpers/layer.helper';
import { ZoomInfo } from '@kildencore/models';
import { KeyboardService } from '@kildencore/services/keyboard.service';
import { KildenStateService } from '@kildencore/services/kilden-state.service';
import { PermaLinkService } from '@kildencore/services/perma-link.service';
import { ThemeLayersService } from '@kildencore/services/theme-layers.service';
import { KeyboardEventInteraction } from '@kildenshared/components/map/keyboard-event.interaction';
import { LayerStylesConst } from '@kildenshared/constants/styles/layer-styles.const';
import { BackgroundLayersListInterface } from '@kildenshared/interfaces/background-layers-list.interface';
import { ClickedCoordinateType } from '@kildenshared/interfaces/clicked-coordinate.type';
import { LayerConfigsType } from '@kildenshared/interfaces/layer-configs.type';
import { ProjectConfigType } from '@kildenshared/types/project-config.type';
import { MousePosition, Zoom, ZoomToExtent } from 'ol/control';
import { Coordinate, format } from 'ol/coordinate';
import { EventsKey } from 'ol/events';
import { pointerMove } from 'ol/events/condition';
import { buffer, extend, Extent, getTopLeft, getWidth, intersects } from 'ol/extent';
import Feature from 'ol/Feature';
import { defaults as defaultInteractions, PinchZoom } from 'ol/interaction';
import Interaction from 'ol/interaction/Interaction';
import Select from 'ol/interaction/Select';
import LayerGroup from 'ol/layer/Group';
import Layer from 'ol/layer/Layer';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import Map from 'ol/Map';
import MapEvent from 'ol/MapEvent';
import { unByKey } from 'ol/Observable';
import Overlay from 'ol/Overlay';
import { get as getProjection, getPointResolution, transform, transformExtent } from 'ol/proj';
import { register } from 'ol/proj/proj4';
import Projection from 'ol/proj/Projection';
import VectorSource from 'ol/source/Vector';
import WMTS from 'ol/source/WMTS';
import WMTSTileGrid from 'ol/tilegrid/WMTS';
import View, { FitOptions } from 'ol/View';
import proj4 from 'proj4';
import { Observable, ReplaySubject, Subject } from 'rxjs';

// Setup projections for map
// EUREF89 (Norway)
proj4.defs('EPSG:25832', '+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs');
proj4.defs('EPSG:25833', '+proj=utm +zone=33 +ellps=GRS80 +units=m +no_defs');
proj4.defs('EPSG:25835', '+proj=utm +zone=35 +ellps=GRS80 +units=m +no_defs');
proj4.defs('EPSG:4258', '+proj=longlat +ellps=GRS80 +no_defs');

register(proj4);

@Injectable({ providedIn: 'root' })
export class MapService {
  private readonly _coordinateClicked = new ReplaySubject<ClickedCoordinateType>(1);

  private _mapMoveSub = new Subject<MapEvent>();
  private _ol3d: any;

  private bg: any = {
    // graatone: { 32: null, 33: null, 35: null },
    // farger: { 32: null, 33: null, 35: null },
    // grunnkart: { 32: null, 33: null, 35: null },
    // raster: { 32: null, 33: null, 35: null },
    // norgeibilder: { 32: null, 33: null, 35: null },
    // ingen: { 32: null, 33: null, 35: null }
  };
  // Own group for backgroun layers
  private bgLayers = new LayerGroup({
    // zIndex: 0,
    properties: { type: 'background' },
  });
  private constrainedExtent: { [key: string]: Extent } = {
    'EPSG:25832': transformExtent(AppConfig.MAP_DEFAULT_EXTENT, 'EPSG:4258', 'EPSG:25832'),
    'EPSG:25833': transformExtent(AppConfig.MAP_DEFAULT_EXTENT, 'EPSG:4258', 'EPSG:25833'),
    'EPSG:25835': transformExtent(AppConfig.MAP_DEFAULT_EXTENT, 'EPSG:4258', 'EPSG:25835'),
    'EPSG:3857': transformExtent(AppConfig.MAP_DEFAULT_EXTENT, 'EPSG:4258', 'EPSG:3857'),
  };
  private defaultBg!: any;
  private map!: Map;
  private mapMoveKey: EventsKey | undefined;
  private mousePositionControl = new MousePosition({
    coordinateFormat: (coord: any) => {
      const template = '{x}N, {y}Ø';
      return format(coord.reverse(), template, 0);
    },
    className: 'kilden-mouse-position',
    // placeholder: false, // retain last position when mouse leaves viewport
    placeholder: '', // or it will be a whitespace with white background
  });
  private token: string = '';
  private zoomInfo = new ZoomInfo();

  coordinateClicked$ = this._coordinateClicked.asObservable();
  mapMove$ = this._mapMoveSub.asObservable();
  vectorHoverInteraction = new Select({
    condition: pointerMove,
    hitTolerance: 0,
    multi: false,
    style: LayerStylesConst['vector_hover'],
    layers: layer => layer.get('id') === AppConfig.IDBOD_FOREST_ROADS_WFS,
  });
  overlay!: any;

  $is3dActive = signal<boolean>(false);
  projOn3dInit!: string;
  saveProjOn3dInit = () => (this.projOn3dInit = this.getCode());

  constructor(
    private readonly _keyboardService: KeyboardService,
    private readonly _kildenStateService: KildenStateService,
    private readonly _permaLinkService: PermaLinkService,
    private readonly _themeLayersService: ThemeLayersService
  ) {
    getProjection('EPSG:25832')!.setExtent(this.mapExtent['EPSG:25832']);
    getProjection('EPSG:25833')!.setExtent(this.mapExtent['EPSG:25833']);
    getProjection('EPSG:25835')!.setExtent(this.mapExtent['EPSG:25835']);
  }

  // taken from http://www.kartverket.no/kart/gratis-kartdata/wms-tjenester/
  private mapExtent: any = {
    'EPSG:25832': [-2000000.0, 3500000.0, 3545984.0, 9045984.0],
    'EPSG:25833': [-2500000.0, 3500000.0, 3045984.0, 9045984.0],
    'EPSG:25835': [-3500000.0, 3500000.0, 2045984.0, 9045984.0],
  };
  // // Geonorge token to access cached WMTSes:
  // const requestGeonorgeToken = () => new Promise((resolve, reject) => {
  //   resolve(
  //     fetch('/geonorge/token.jsp')
  //     .then(response => response.json())
  //     .then(json => json.ticket)
  //     .catch(() => console.log('Token fra Geonorge kunne ikke hentes.'))
  //   );
  // });
  // requestGeonorgeToken().then(gToken => updateWMTSes(gToken));

  private nameMap: any = {
    'EPSG:25832': 'UTM32_EUREF89',
    'EPSG:25833': 'UTM33_EUREF89',
    'EPSG:25835': 'UTM35_EUREF89',
    'EPSG:3857': 'web_mercator',
  };
  private urlMap: any = {
    'EPSG:25832': 'utm32',
    'EPSG:25833': 'utm33',
    'EPSG:25835': 'utm35',
    'EPSG:3857': 'web_mercator',
  };

  private createWMTS(meta: any, utm: number) {
    // eslint-disable-next-line prettier/prettier
    const nib = meta.id === 'norgeibilder';
    const epsg = `EPSG:${utm > 99 ? utm : 25800 + utm}`;
    const layerName = meta.name.replace('UTM33_EUREF89', this.nameMap[epsg]);
    const utmUrl = meta.url.replace('utm33', this.urlMap[epsg]);
    const token = this.token;
    let url = token ? `${utmUrl}?` : ApiConfig.open_url;
    if (nib) {
      url = token ? `${utmUrl}?gkt=${token}` : ApiConfig.open_url;
    }
    const properties = {
      id: meta.id,
      title: meta.title,
      type: 'base',
      url: utmUrl,
    };

    const projection = getProjection(epsg);
    const projectionExtent = projection?.getExtent();
    const origin = getTopLeft(projectionExtent || [0, 0, 0, 0]);

    const size = getWidth(projectionExtent || [0, 0, 0, 0]) / 256;
    // const resolutions = AppConfig.MAP_WMTS_RESOLUTIONS;
    const resolutions = new Array(18);
    const rl = resolutions.length;
    const matrixIds = new Array(rl);
    for (let z = 0; z < rl; ++z) {
      resolutions[z] = size / Math.pow(2, z);
      matrixIds[z] = nib ? z : `${z}`;
    }

    const matrixSet: any = {
      '32': 'utm32n',
      '33': 'utm33n',
      '35': 'utm35n',
      '3857': 'webmercator',
    };

    const source = new WMTS({
      // crossOrigin: 'anonymous', // did not help
      url: url,
      layer: layerName,
      matrixSet: nib ? 'default028mm' : matrixSet[utm.toString()],
      //matrixSet: nib ? 'default028mm' : epsg,
      format: `image/${meta.format}`,
      projection: projection || epsg,
      // 'olcs.projection': 'EPSG:3857', // it does not work if defined here
      tileGrid: new WMTSTileGrid({
        origin,
        // Resolutions added in TileGrid define the image url query, whereas resolutions in View only define the view resolutions.
        // If they match (at some zooms), then images will be queries else the zoom will only be digital in nature.
        resolutions,
        matrixIds,
      }),
      style: 'default',
    });
    // source.set('olcs.projection', getProjection('EPSG:3857')); // not needed

    return new TileLayer({ properties, source });
  }

  private createBackgroundLayers(metadata: any) {
    metadata.forEach((meta: any) => {
      // console.log(meta.id);
      const empty = new TileLayer({
        properties: {
          id: meta.id,
          title: meta.title,
          type: 'base',
          url: meta.url,
        },
      });
      this.bg[meta.id] = { 32: empty, 33: empty, 35: empty, 3857: empty };
      if (meta.url) {
        const bg = this.bg[meta.id];
        bg['32'] = this.createWMTS(meta, 32);
        bg['33'] = this.createWMTS(meta, 33);
        bg['35'] = this.createWMTS(meta, 35);
        bg['3857'] = this.createWMTS(meta, 3857);
      }
    });
    return this.bg;
  }

  /**
   * Create and return Openlayers map
   * @return ol/Map
   */
  public createMap(x: number, y: number, z: number, newExtentForZoom: Extent) {
    const epsgFromUrl = this._permaLinkService.getInitialValue('epsg')?.toString();
    const epsg = `EPSG:${epsgFromUrl || '25833'}`;

    // If EPSG is set to non-default via URL, we should let the rest of the application know via state service
    if (epsg !== 'EPSG:25833') {
      this._kildenStateService.changeEpsg(epsg);
    }

    const extentForZoomController = newExtentForZoom.length
      ? transformExtent(newExtentForZoom, 'EPSG:25833', epsg)
      : this.constrainedExtent[epsg];
    const tipLabelForZoomController = newExtentForZoom.length ? 'Zoom til extent' : 'Vis hele Norge';

    const map = new Map({
      controls: [
        new Zoom({
          className: 'kilden-zoom',
          zoomInClassName: 'kilden-zoom-in',
          zoomOutClassName: 'kilden-zoom-out',
        }),
        new ZoomToExtent({
          className: 'kilden-zoom-extent',
          extent: extentForZoomController,
          tipLabel: tipLabelForZoomController,
        }),
        this.mousePositionControl,
      ],
      interactions: defaultInteractions().extend([
        new PinchZoom(),
        this.vectorHoverInteraction,
        new KeyboardEventInteraction(this._keyboardService),
      ]),
      layers: [this.bgLayers],
      target: 'map',
      view: new View({
        center: [x, y],
        constrainResolution: AppConfig.MAP_CONSTRAIN_RESOLUTION,
        enableRotation: false,
        extent: this.constrainedExtent[epsg],
        maxResolution: AppConfig.MAP_VIEW_RESOLUTION_MAX,
        minResolution: AppConfig.MAP_VIEW_RESOLUTION_MIN,
        projection: epsg,
        resolutions: AppConfig.MAP_VIEW_RESOLUTIONS,
        zoom: z,
        zoomFactor: AppConfig.MAP_ZOOM_FACTOR,
      }),
    });

    map.on('moveend', () => {
      const v = map.getView();
      const z = v.getZoom() ?? 0;
      if (this.zoomInfo.level !== z) {
        // Broadcast a new zoom level
        this.zoomInfo.level = z;
        const newRes = v.getResolution();
        if (typeof newRes !== 'undefined') {
          this.zoomInfo.resolution = newRes;
        }
        this._kildenStateService.changeZoom(this.zoomInfo);
      }
      this._permaLinkService.setParam('zoom', Math.trunc(z * 10) / 10);
      const c = v.getCenter() ?? [0, 0];
      const east = +c[0].toFixed(2);
      const north = +c[1].toFixed(2);
      this._permaLinkService.setParam('x', north);
      this._permaLinkService.setParamReplaceHistory('y', east);
    });

    this._themeLayersService.registerMap(map);

    this.map = map;

    return map;
  }

  public getMap(): Map {
    return this.map;
  }

  public createPopup() {
    const container = document.getElementById('popup');
    const closer = document.getElementById('popup-closer');

    /**
     * Create an overlay to anchor the popup to the map.
     */
    this.overlay = new Overlay({
      element: container!,
      autoPan: {
        animation: {
          duration: 250,
        },
      },
    });

    this.map?.addOverlay(this.overlay);

    /**
     * Add a click handler to hide the popup.
     * @return {boolean} Don't follow the href.
     */
    if (closer) {
      closer!.onclick = () => {
        container?.classList.remove('active');
        this.overlay.setPosition(undefined);
        closer?.blur();
        return false;
      };
    }
  }

  public updatePopup(position: any, contentText: string) {
    const container = document.getElementById('popup');
    container?.classList.add('active');
    const content = document.getElementById('popup-content');
    content!.innerHTML = contentText;
    this.overlay!.setPosition(position);
  }

  public removePopup() {
    const container = document.getElementById('popup');
    container?.classList.remove('active');
    this.overlay!.setPosition(undefined);
  }

  public getBackgroundLayerById(id: string) {
    const epsg = this.getCode(); // wrong when 3D
    const oldBg = this.bgLayers.getLayers().item(0);
    const e = oldBg && oldBg.get('source') ? oldBg.get('source').getProjection().getCode() : epsg;
    const utm = e === 'EPSG:3857' ? e.slice(-4) : e.slice(-2);
    return this.bg[id] ? this.bg[id][utm] : null;
  }

  public updateBackgroundLayerUrl(token: string) {
    if (Object.keys(this.bg).length < 1) this.token = token;
    for (const [id, zones] of Object.entries(this.bg)) {
      // console.log(`${id}: ${zones}`);
      // console.log(zones['33'].getProperties());
      const zones_ = this.bg[id];
      // console.log(zones_['33'].getProperties());
      for (const [z, layer] of Object.entries(zones_)) {
        // console.log(`${z}: ${layer}`);
        const layer_ = zones_[z];
        if (!layer_ || layer_.get('type') !== 'base') continue;
        else {
          const url = layer_.get('url');
          //const fullUrl = token ? `${url}?gkt=${token}` : ApiConfig.open_url;
          const fullUrl = token ? `${url}?` : ApiConfig.open_url;
          layer_.getSource()?.setUrl(fullUrl);
          // console.log(layer_.getSource().getUrls());
        }
      }
    }
  }

  // Observable string sources
  private bgListReceived_source = new ReplaySubject<BackgroundLayersListInterface>(1);
  // Observable string streams
  public bgListReceived$: Observable<BackgroundLayersListInterface> = this.bgListReceived_source.asObservable();

  public initializeBackgroundLayers(all: LayerConfigsType) {
    const filtered = [];
    for (const id of Object.keys(all)) {
      const p = all[id];

      if (p.background) {
        // // prettier-ignore
        // p.wmsUrl = p.wmsUrl?.replace( // to avoid cors errors on 3D WMTS
        //   'gatekeeper{1-3}.geonorge.no/BaatGatekeeper/gk', '/gk{1-3}'
        // );
        const https = p.wmsUrl?.indexOf('https://') === 0;
        const proxy = p.wmsUrl?.indexOf('/') === 0;
        filtered.push({
          originalId: id,
          id: id.split('_')[0],
          format: p.format,
          name: p.serverLayerName,
          title: p.label,
          url: https || proxy ? p.wmsUrl : `https://${p.wmsUrl}`,
        });
      }
    }
    filtered.push({ id: 'ingen', title: 'Ingen bakgrunn', url: '' });
    const idFromUrl = this._permaLinkService.getInitialValue('bgLayer')?.toString();
    const defaultBgId = ApiConfig.defaultBackgroundLayer;
    const initialBgId = idFromUrl ? LayerHelper.convertFromLegacyName(idFromUrl) : defaultBgId;
    this.bgListReceived_source.next({
      list: filtered,
      initial: initialBgId,
    } as BackgroundLayersListInterface); // tell bg-selector component
    const bg = this.createBackgroundLayers(filtered);
    this._permaLinkService.setParamReplaceHistory('bgLayer', initialBgId);
    const bgFromUrl = this.getBackgroundLayerById(initialBgId);
    const epsg = this.getCode();
    const utm = epsg === 'EPSG:3857' ? epsg.slice(-4) : epsg.slice(-2);
    this.defaultBg = bgFromUrl ?? bg[filtered[0].id][utm];
    this.defaultBg.setZIndex(0);
    this.bgLayers.getLayers().clear();
    this.bgLayers?.getLayers().extend([this.defaultBg]);
  }

  public changeBackgroundLayer(id: string) {
    const bgLayer = this.getBackgroundLayerById(id);
    if (bgLayer) {
      bgLayer.setZIndex(0);
      this.bgLayers.getLayers().clear();
      this.bgLayers?.getLayers().extend([bgLayer]);
    }
    this._permaLinkService.setParamReplaceHistory('bgLayer', id);
  }

  // start add code for print
  /**
   * Get extent of the current view
   * @return {Array<any>}   extent of current view
   */
  public getViewExtent() {
    if (this.map === undefined) {
      return;
    }
    return this.map.getView().calculateExtent(this.map.getSize());
  }

  // Get current UTM code
  public getCode(): string {
    return this.map.getView().getProjection().getCode();
  }

  /**
   * Add map interaction
   * @param {Interaction} interaction interaction to add
   */
  public addInteraction(interaction: Interaction): void {
    this.map.addInteraction(interaction);
  }

  /**
   * Get the amount of layers in the current map
   * @return {number} Number of layers
   */
  public getNumberOfLayers(): number {
    if (this.map === undefined) {
      return 0;
    }
    return this.map.getLayers().getLength();
  }

  /**
   * Add layer to map
   * @param  {string}  id         id of layer
   * @param  {any}     inputLayer layer to add
   * @return {boolean}            true if operation was successful
   */
  public addLayer(id: string, inputLayer: any): boolean {
    const pos = this.getNumberOfLayers();
    return this.addLayerAt(id, inputLayer, pos);
  }
  /**
   * Add a layer at a certain position
   * @param {string} id id for layer
   * @param {any} inputLayer layer
   * @param {number} pos position
   */
  public addLayerAt(id: string, inputLayer: any, pos: number): boolean {
    // Check that id is not represented already
    if (typeof id === 'undefined' || typeof inputLayer === 'undefined') {
      return false;
    }

    // Add to map if map is created
    if (this.map !== null && typeof this.map !== 'undefined') {
      inputLayer.set('id', id);
      this.map.getLayers().insertAt(pos, inputLayer);
    }

    return false;
  }

  /**
   * Remove map interaction
   * @param {Interaction} interaction interaction to remove
   */
  public removeInteraction(interaction: Interaction): void {
    if (this.map !== undefined) {
      // to run tests
      this.map.removeInteraction(interaction);
    }
  }
  /**
   * Check is layer inside extent, have a buffer on -100m
   * @param {any} reference to layer
   * @return {Boolean}   true/false
   */
  public isLayerInViewExtent(printBox: any) {
    if (this.map === undefined) {
      return;
    }
    const pBoxExtent = printBox.getSource().getExtent();
    const viewExtent: any = this.getViewExtent();
    return intersects(viewExtent, buffer(pBoxExtent, -100));
  }
  /**
   * Get layer from map
   * @param  {string} id  id of layer to fetch from map
   * @return {any}        reference to layer
   */
  public getLayer(id: string): any {
    if (!this.map) {
      return null;
    }

    let response = null;
    this.map.getLayers().forEach(layer => {
      if (layer.get('id') === id) {
        response = layer;
      }
    });
    return response;
  }

  /**
   * Remove layer from map
   * @param  {string}  id   id of layer
   * @return {boolean}      true if operation was successful
   */
  public removeLayer(id: string): boolean {
    if (typeof id === 'undefined' || this.map === undefined) {
      return false;
    }
    const remove = this.map
      .getLayers()
      .getArray()
      .find(ml => ml.get('id') === id);

    if (remove) {
      this.map.removeLayer(remove);
      return true;
    }

    return false;
  }

  /**
   * Remove overlay from map
   */
  public removeOverlay(overlayId: string): void {
    const overlay = this.map.getOverlayById(overlayId);
    if (overlay) {
      this.map.removeOverlay(overlay);
    }
  }

  /**
   * zoom to supplied extent
   */
  public zoomToExtent(extent: Extent, fitOptions: FitOptions = { padding: AppConfig.ZOOM_PADDING_DESKTOP }) {
    if (extent) this.map.getView().fit(extent, fitOptions);
  }

  private flyToExtent = (e: Extent) => {
    const view = this.map.getView();
    const viewExtent = view.calculateExtent();
    const z = view.getZoom()!;

    // transformExtent fixes: change proj --> 3d --> change proj --> upload
    const te = transformExtent(e, this.$is3dActive() ? this.projOn3dInit : this.getCode(), this.getCode());

    const fit = () => view.fit(te, { duration: AppConfig.ZOOM_DURATION_MS, padding: AppConfig.ZOOM_PADDING_DESKTOP });
    const visibleDestination = viewExtent && te ? intersects(viewExtent, te) : false;
    if (z < 8 || visibleDestination) fit();
    else view.animate({ zoom: 7 }, fit);
  };

  public zoomToFeature = (feature: Feature) => {
    // this.map.getView().fit(feature.getGeometry());
    // @ts-ignore
    this.flyToExtent(feature.getGeometry().getExtent());
  };

  public zoomToLayer = (layer: Layer) => {
    // @ts-ignore
    this.flyToExtent(layer.getSource().getExtent());
  };

  public zoomToUploads(layers: VectorLayer<VectorSource>[]) {
    let ext!: Extent;

    layers.forEach(layer => {
      const currentExtent = (layer.getSource()?.getExtent() || layer.getExtent()) as Extent;
      // prettier-ignore
      if (currentExtent) {
        if (!ext) ext = currentExtent;
        else extend(ext, currentExtent);
      } else console.error(
        'ZoomToUploads found no extent/source for uploaded layer',
        layer.getSource(),
        layer.get('id')
      );
    });

    if (ext) this.flyToExtent(ext);
  }

  private changeBackgroundLayerProjection = (epsg: string) => {
    // update background layer
    const utm = epsg === 'EPSG:3857' ? epsg.slice(-4) : epsg.slice(-2);
    const bgGroup = this.bgLayers?.getLayers();
    const bgId = bgGroup.item(0).get('id');
    // const bg = this.getBackgroundLayerById(bgId); // or
    const bg = this.bg[bgId][utm];
    bgGroup.setAt(0, bg);
  };

  private changeVectorLayerProjection = (oldEpsg: string, newEpsg: string, dimensionChange: number) => {
    // update all features (draw, reports, search and uploadfile)
    this.map.getLayers().forEach(layer => {
      layer.getLayersArray().forEach(layer => {
        const source = layer?.getSource();
        if (source instanceof VectorSource) {
          // console.log(layer.get('id'), layer.get('label'));

          const from2Dto3D = dimensionChange > 0;
          const from3Dto2D = dimensionChange < 0;
          // if (from2Dto3D) console.log('2D --> 3D');
          // if (from3Dto2D) console.log('3D --> 2D');

          // Kilden default           EPSG:25833
          // Ol-Cesium 3d default     EPSG:3857
          // OL.readFeatures default  EPSG:4326

          const fromEpsg = from3Dto2D ? this.projOn3dInit : oldEpsg;
          const toEpsg = from2Dto3D ? this.projOn3dInit : newEpsg;

          if (fromEpsg !== toEpsg) {
            // console.log(`transforming from ${fromEpsg} to ${toEpsg}.`);
            source.getFeatures().forEach(feature => {
              feature.getGeometry()?.transform(fromEpsg, toEpsg);
            });
          } // else console.log(`identical from/to epsg`, fromEpsg);
        }
      });
    });
  };

  private changeOverlayProjection = (oldEpsg: string, epsg: string) => {
    // console.log('Update overlays position from', oldEpsg, 'to', epsg);
    // console.log(this.map.getOverlays().getLength(), 'overlay(s) in the map.');
    // update all overlays (coordinates, draw and search)
    this.map?.getOverlays().forEach(overlay => {
      const oldPosition = overlay.getPosition();
      // console.log(overlay.getId(), overlay.get('id'), oldPosition);
      if (oldPosition) overlay.setPosition(transform(oldPosition, oldEpsg, epsg));
    });
  };

  public changeLayersProjection(oldEpsg: string, epsg: string, dimensionChange: number): void {
    // console.log(`changeLayersProjection from ${oldEpsg} to ${epsg}, dimensionChange ${dimensionChange}`);
    if (dimensionChange !== 0 || !this.$is3dActive()) {
      this.changeBackgroundLayerProjection(epsg);
      this.changeVectorLayerProjection(oldEpsg, epsg, dimensionChange);
    }
    if (dimensionChange === 0) {
      this.changeOverlayProjection(oldEpsg, epsg);
      // Fix: search sted --> change proj --> same sted
      const searchField = document.getElementById('search-input-field');
      searchField?.dispatchEvent(new Event('input'));
    }
  }

  // Change map projection
  public setProjection(epsg: string): void {
    const oldView = this.map.getView();
    const oldResolution = oldView.getResolution()!;
    const oldCenter = oldView.getCenter() as Coordinate;
    const oldProjection = oldView.getProjection();

    const oldEpsg = oldProjection?.getCode();
    const oldMPU = oldProjection?.getMetersPerUnit()!;
    const oldPointResolution = getPointResolution(oldProjection, 1 / oldMPU, oldCenter, 'm') * oldMPU;

    const newProjection = getProjection(epsg) as Projection;
    const newCenter = transform(oldCenter, oldProjection, newProjection);
    const newMPU = newProjection.getMetersPerUnit()!;
    const newPointResolution = getPointResolution(newProjection, 1 / newMPU, newCenter, 'm') * newMPU;
    const newResolution = (oldResolution * oldPointResolution) / newPointResolution;
    const newExtent = this.constrainedExtent[epsg];
    if (!newProjection.getExtent()) newProjection.setExtent(newExtent);

    const newView = new View({
      center: newCenter,
      constrainResolution: AppConfig.MAP_CONSTRAIN_RESOLUTION,
      extent: newExtent,
      maxResolution: AppConfig.MAP_VIEW_RESOLUTION_MAX,
      minResolution: AppConfig.MAP_VIEW_RESOLUTION_MIN,
      projection: newProjection,
      resolution: newResolution,
      // resolution: oldView.getResolution(),
      // Resolutions added in TileGrid define the image url query, whereas resolutions in View only define the view resolutions.
      // If they match (at some zooms), then images will be queries else the zoom will only be digital in nature.
      resolutions: AppConfig.MAP_VIEW_RESOLUTIONS,
      // rotation: currentRotation,
      zoomFactor: AppConfig.MAP_ZOOM_FACTOR,
    });
    this.map.setView(newView);

    // const bg = this.bgLayers.getLayers().item(0);
    // const on3d = bg?.get('source')?.getProjection()?.getCode() === 'EPSG:3857';
    // if (!this.on3d()) this.changeLayersProjection(oldEpsg, epsg, 0);
    this.changeLayersProjection(oldEpsg, epsg, 0);
    // if (!this.$is3dActive()) this.changeLayersProjection(oldEpsg, epsg, 0);
    // else console.log('ikke changeLayersProjection on 3d');

    // update url
    this._permaLinkService.setParamReplaceHistory('epsg', epsg.replace('EPSG:', ''));
    this._kildenStateService.changeEpsg(epsg);
  }

  /**
   * Remove all features from layer
   * @param {object} layer layer
   * @param {int[]} ids array of feature id to remove
   */
  public removeFeaturesById(layer: any, ids: any) {
    if (layer && ids) {
      const features = layer.getSource().getFeatures();
      ids.forEach((id: any) => {
        const feature = features.find((f: any) => f.getId() === id);
        this.removeFeature(layer, feature);
      });
    }
  }

  /**
   * Remove feature from layer
   * @param {object} layer layer
   * @param {Feature} feature feature
   */
  public removeFeature(layer: any, feature: any) {
    if (layer && feature) {
      const source = layer.getSource();
      source.removeFeature(feature);
    }
  }

  /**
   *
   * @param epsg f.eks EPSG:25833
   * @param toPro f.eks EPSG:4258
   * @param coordinate f.eks [260269.37144752074, 6615182.140671089]
   * @returns [10.752374104497544, 59.60421915294734]
   */
  public transformCoordinate(epsg: string, toPro: string, coordinate: Array<number>) {
    const result = transform(coordinate, epsg, toPro);
    return result;
  }

  // Start listen to the maps movend and update observable
  public startMapChanged() {
    this.mapMoveKey = this.map.on('moveend', (evt: MapEvent) => {
      this._mapMoveSub.next(evt);
    });
  }

  // Stop listen to the maps moveend
  public stopMapChanged() {
    if (this.mapMoveKey) {
      unByKey(this.mapMoveKey);
    }
  }

  registerCoordinateClicked(coord: Coordinate): void {
    this._coordinateClicked.next({
      coordinate: coord,
      resolution: this.getMap().getView().getResolution() || 1,
      projection: this.getCode(),
    });
  }

  restoreDefaultView(activatedRoute: ActivatedRoute): void {
    const config: ProjectConfigType = activatedRoute.snapshot.data['config'];
    // prettier-ignore
    this.getMap().getView().fit(
      transformExtent(config.zoomExtentController, 'EPSG:25833', this.getCode())
    );
  }

  set3d: any = (ol3d: any) => (this._ol3d = ol3d);
  get3d: any = () => this._ol3d;

  getCurrentBackgroundLayer: any = () => this.bgLayers.getLayers().item(0);
}
