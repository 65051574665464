<div
  [class.show]="isVisible"
  [ngClass]="openLeftPanel ? 'openLeftPanelClass' : 'closedLeftPanelClass'"
  draggable="false"
  id="legend-wrapper">
  <button id="legend" class="handle" draggable="false" (click)="isVisible = !isVisible">
    <span class="vtext">Tegnforklaring</span>
  </button>
  <div class="card">
    <div *ngFor="let legend of legendArray">
      <div class="header" *ngIf="legend.header">{{ legend.name }}</div>
      <img
        *ngIf="legend.type === 'Image'"
        alt="Tegnforklaring for kartlag: {{ legend.header }}"
        draggable="false"
        src="{{ legend.url }}" />
    </div>
  </div>
</div>
