<form class="search-form">
  <div class="input-wrapper">
    <input
      id="search-layer-input-field"
      class="search-input"
      type="text"
      matInput
      [formControl]="searchFormControl"
      [title]="currentTitle"
      placeholder="Søk kartlag"
      [matAutocomplete]="auto"
      (keydown)="customStopPropagation($event)" />
    <button
      type="button"
      *ngIf="searchFormControl.value"
      class="cancel-btn"
      disableRipple
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="clearSearch()"
      (keydown.enter)="clearSearch()">
      <mat-icon class="cancel-icon">close</mat-icon>
    </button>
  </div>
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onChange($event.option.value)">
    <mat-option *ngFor="let layer of stateGroupOptions" [value]="layer">
      {{ layer.label }} &mdash; {{ layer.parentLabel }}
    </mat-option>
  </mat-autocomplete>
</form>
