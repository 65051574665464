<div class="flex-wrapper kilden3-report">
  <p class="intro">Her kan du generere en rapport med arealtall for valgte karttema innenfor aktuelt område.</p>
  <a
    aria-label="Lenke til veiledning arealrapport"
    class="help-btn-report"
    draggable="false"
    disableRipple="true"
    href="https://www.nibio.no/tjenester/kilden/veiledning-arealrapport"
    mat-icon-button
    target="_blank"
    title="Veiledning arealrapport">
    <mat-icon fontSet="material-icons-outlined">help</mat-icon>
  </a>
</div>

<div class="block flex-wrapper">
  <div class="flex-item number-col"><p>1</p></div>
  <div class="flex-item content-col">
    <!-- prettier-ignore -->
    <p>
      Angi område ved å hente inn en fil, eller tegne en flate eller linje.
      Det er en begrensning på maks 10 objekter for å lage rapport.
    </p>
    <div class="ga-draw-buttons">
      <button
        *ngFor="let tool of tools"
        title="{{ tool.name }}"
        class="draw_tool_button {{ tool.iconClass }} {{ tool.id === chosenTool ? 'chosen-tool' : '' }}"
        [class.hidden-mobile]="tool.hideMobile"
        (click)="chooseDrawTool(tool.id)">
        <mat-icon fontSet="material-icons-outlined" *ngIf="tool.id === 'File'"> file_upload </mat-icon>
        <div class="draw_tool_text">{{ tool.name }}</div>
      </button>
    </div>
    <div class="tips">
      <div (click)="showTips = !showTips" class="trigger">
        <span class="icon material-icons-outlined">{{ showTips ? 'expand_less' : 'expand_more' }}</span>
        Vis tips for tegning
      </div>
      <div *ngIf="showTips" class="tips-details">
        <div>
          <br />Under tegning:<br />
          Dobbeltklikk for å fullføre tegning. Trykk <span class="keyboard-key">esc</span> for å angre siste tegnesteg.
          Hold <span class="keyboard-key">shift</span> for å tegne frihånd.
        </div>
        <div>
          <br />Endre tegninger:<br />
          Dra blått punkt for å endre form på linje/flate.<br />
          Marker objekt og hold nede <span class="keyboard-key">alt</span> og trykk for å fjerne knekkpunkt.<br />
          Marker objekt og hold <span class="keyboard-key">shift</span> for å flytte hele objektet.
        </div>
      </div>
    </div>
    <ul>
      <li
        class="drawing-item"
        *ngFor="let db of drawItems; let i = index"
        [id]="db.id"
        (click)="zoomToContents(db)"
        (mouseover)="mouseOver(db.id)"
        (mouseout)="mouseOut(db.id)">
        <div class="flex-wrapper">
          <span class="item-title">{{ i + 1 }}&nbsp;&nbsp;{{ db.name }}</span>
          <div>
            <button title="Lag buffer" mat-icon-button aria-label="Åpne/lukk buffer" (click)="toggleDraw(db.id)">
              <mat-icon fontSet="material-icons-outlined" class="expand-icon"> open_in_full </mat-icon>
            </button>
            <button title="Slett" mat-icon-button aria-label="Slette tegning" (click)="deleteDrawObject(db.id)">
              <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
            </button>
          </div>
        </div>
        <div *ngIf="db.expanded" class="buffer-panel">
          <hr />
          <p>Lag buffer rundt objektet, i meter.</p>
          <div class="flex-wrapper-wrap">
            <input
              type="number"
              class="buffer-input"
              [min]="db.type ? (geoTypesAutoBuffer.includes(db.type) ? 1 : 0) : 0"
              [(ngModel)]="db.bufferSize"
              (change)="updateBuffer($event, db.id, db.type)" />
            <button mat-button class="buffer-btn dialog-btn" (click)="setBuffer(db.id)">Lag buffer</button>
          </div>
        </div>
      </li>
    </ul>
    <div *ngIf="drawItems?.length" class="flex-wrapper delete-all">
      <button mat-button class="dialog-flat-btn" (click)="deleteAllDrawings()">Slett alle objekter</button>
    </div>
  </div>
</div>

<div class="block flex-wrapper">
  <div class="flex-item number-col"><p>2</p></div>
  <div class="flex-item content-col">
    <p>
      Velg hvilke tema det skal gjøres analyse mot.
      <span *ngIf="!isForestReport()">Som standard er alle tema valgt.</span>
    </p>
    <ul>
      <li *ngFor="let map of reportMapLayers">
        <mat-label [title]="map.navn" class="flex-wrapper">
          <input type="checkbox" [checked]="map.checked" (change)="mapLayerChange(map.id)" />
          <span class="input-label">{{ map.navn }}</span>
        </mat-label>
      </li>
    </ul>
  </div>
</div>

<footer class="buttons-wrapper">
  <button class="dialog-btn" mat-button mat-dialog-close>Avbryt</button>
  <button *ngIf="!loadingReport" class="dialog-btn" (click)="generateReport()" mat-button cdkFocusInitial>
    Lag rapport
  </button>
  <button *ngIf="loadingReport" class="dialog-btn" (click)="terminatePolling()" mat-button>
    <div class="btn-content-wrapper">
      <span>Avbryt rapport</span>
      <mat-spinner diameter="20" class="spinner"></mat-spinner>
    </div>
  </button>
</footer>
