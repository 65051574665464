import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ArrayHelper } from '@kildencore/helpers/array.helper';
import { LinksService } from '@kildencore/services/data/links.service';
import { InformationLinkInterface } from '@kildenshared/interfaces/information-link.interface';
import { Subject, takeUntil, tap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'kilden3-infolinks',
  templateUrl: './infolinks.component.html',
  styleUrls: ['./infolinks.component.css'],
})
export class InfolinksComponent implements OnDestroy, OnInit {
  sortedLinks: InformationLinkInterface[] = [];

  private readonly _onDestroy$ = new Subject<void>();

  constructor(private readonly _linksService: LinksService) {}

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  ngOnInit(): void {
    this._linksService.informationLinks$
      .pipe(
        tap(links => {
          this.sortedLinks = links.sort(ArrayHelper.fieldSorter(['order_integer', 'tekst']));
        }),
        catchError((e: HttpErrorResponse) => {
          this.sortedLinks = [];
          return throwError(() => e);
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();
  }
}
