import { BaseSerializer } from '@kildencore/serializer/base-serializer';
import TileLayer from 'ol/layer/Tile';
import { Source } from 'ol/source';
import WMTS from 'ol/source/WMTS';
import WMTSTileGrid from 'ol/tilegrid/WMTS';

/**
 * The MapFishPrintV3WMTSSerializer.
 * Documentation on http://mapfish.github.io/mapfish-print-doc/layers.html
 */
export class MapFishPrintV3WMTSSerializer extends BaseSerializer {
  static TYPE_WMTS = 'wmts';

  constructor() {
    super();
  }

  /**
   * @param {Source} source
   * @return {boolean}
   */
  public override canSerialize(source: Source) {
    return source instanceof WMTS;
  }

  /**
   * Serializes/Encodes the given layer.
   *
   * @abstract
   * @param {TileLayer<WMTS>} layer The layer to serialize/encode.
   * @param {Object} opts Additional properties to pass to the serialized
   *   layer object that can't be obtained by the layer itself. It can also be
   *   used to override all generated layer values, e.g. the image format. Only
   *   used in V3.
   * @param {number} viewResolution The resolution to calculate the styles for.
   * @return {Object} The serialized/encoded layer.
   */
  override serialize(layer: TileLayer<WMTS>, opts = {}, viewResolution: any) {
    // eslint-disable-line no-unused-vars
    const source = layer.getSource();

    if (!source || !this.validateSource(source)) {
      return;
    }

    let baseUrl = '';
    if (source) {
      if (source instanceof WMTS) {
        const urls = source.getUrls();
        baseUrl = urls?.length ? urls[0] : '';
      }
    }

    // MapFish Print replaces {style}
    // https://mapfish.github.io/mapfish-print-doc/layers.html#WMTS%20Layer
    if (baseUrl.indexOf('{Style}') > -1) {
      baseUrl = baseUrl.replace('{Style}', '{style}');
    }

    const tileGrid = source.getTileGrid() as WMTSTileGrid;
    const matrixSizes = source.get('matrixSizes');

    if (!tileGrid) {
      return;
    }
    // 28mm is the pixel size
    const scaleDenominators = tileGrid.getResolutions().map((resolution: any) => resolution / 0.00028);
    return {
      ...opts,
      baseURL: baseUrl,
      customParams: undefined,
      dimensionParams: undefined,
      dimensions: source.getDimensions(),
      //  failOnError: opts?.failOnError || true,
      imageFormat: source.getFormat() || 'image/png',
      layer: source.getLayer(),
      matrices: tileGrid.getMatrixIds().map((matrixId: any, index: any) => ({
        identifier: matrixId,
        matrixSize: matrixSizes
          ? [matrixSizes[index][0], matrixSizes[index][1]]
          : [Math.pow(2, index), Math.pow(2, index)],
        scaleDenominator: scaleDenominators[index],
        tileSize: [tileGrid.getTileSize(index), tileGrid.getTileSize(index)],
        topLeftCorner: tileGrid.getOrigin(index) || tileGrid.getOrigin(0),
      })),
      matrixSet: source.getMatrixSet(),
      mergeableParams: undefined,
      name: layer.get('name'),
      opacity: layer.getOpacity(),
      rasterStyle: '',
      requestEncoding: source.getRequestEncoding(),
      style: source.getStyle(),
      version: source.getVersion() || '1.1.0',
      type: MapFishPrintV3WMTSSerializer.TYPE_WMTS,
    };
  }
}

export default MapFishPrintV3WMTSSerializer;
