import { Component, isDevMode } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DialogService } from '@kildencore/services/dialog.service';
import { ObjectinfoService } from '@kildencore/services/objectinfo.service';
import { DebugToolService } from '@kildenshared/components/debug-tool/debug-tool.service';
import { Kilden3DialogComponent } from '@kildenshared/components/dialog/dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'kilden3-debug-tool',
  templateUrl: './debug-tool.component.html',
  styleUrls: ['./debug-tool.component.css'],
})
export class DebugToolComponent {
  protected readonly isDevMode = isDevMode;
  protected readonly environment = environment;

  protected dialogRef: MatDialogRef<Kilden3DialogComponent> | undefined;

  constructor(
    readonly debugToolService: DebugToolService,
    readonly dialogService: DialogService,
    readonly objectinfoService: ObjectinfoService
  ) {
    // setTimeout(() => {
    //   this.dialogRef = this.dialogService.getHandle(
    //     DialogVariants.OBJECT_INFO
    //   ) as MatDialogRef<Kilden3DialogComponent>;
    // }, 15000);
  }
}
