import { DrawType } from '@kildenshared/types/draw/draw.type';

export const DrawToolsConst: {
  id: DrawType;
  name: string;
  iconClass: string;
  hideMobile: boolean;
}[] = [
  {
    id: 'Point',
    name: 'Punkt',
    iconClass: 'icon-ga-point',
    hideMobile: false,
  },
  { id: 'LineString', name: 'Linje', iconClass: 'icon-ga-line', hideMobile: false },
  {
    id: 'Polygon',
    name: 'Flate',
    iconClass: 'icon-ga-polygon',
    hideMobile: false,
  },
  { id: 'Text', name: 'Tekst', iconClass: 'icon-ga-text', hideMobile: true },
  {
    id: 'Modify',
    name: 'Endre',
    iconClass: 'icon-ga-edit',
    hideMobile: true,
  },
  {
    id: 'Delete',
    name: 'Slett',
    iconClass: 'icon-ga-delete',
    hideMobile: false,
  },
];
