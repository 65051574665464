import { Component, OnInit } from '@angular/core';
import { TopicsService } from '@kildencore/services/data/topics.service';
import { KildenStateService } from '@kildencore/services/kilden-state.service';
import { PermaLinkService } from '@kildencore/services/perma-link.service';
import { ThemeLayersService } from '@kildencore/services/theme-layers.service';
import { TopicIdsEnum } from '@kildenshared/constants/topic-ids.enum';
import { TopicConfig } from '@kildenshared/interfaces/topic-config';

@Component({
  selector: 'kilden3-topic-panel',
  templateUrl: './topic-panel.component.html',
  styleUrls: ['./topic-panel.component.css'],
})
export class TopicPanelComponent implements OnInit {
  // Id of the current topic configuration. Set to default from ApiConfig
  public currentTopicId = '';
  // Config object for all available topics
  public topicsConfig: TopicConfig[] = [];
  // Config for the current topic
  public currentTopicConfig: any;

  constructor(
    private readonly _kildenStateService: KildenStateService,
    private readonly _permaLinkService: PermaLinkService,
    private readonly _topicsService: TopicsService
  ) {}

  ngOnInit(): void {
    this._topicsService.topicsConfig$.subscribe(topics => {
      if (topics.length === 0) return;
      this.topicsConfig = topics;
      if (this.currentTopicId === '') {
        // determine the initial value of topic. If topic is found in url. Use that useValue
        // if it is valid. If topic is not set in url, use the default value from ApiConfig.
        // If value set in url is not a valid key, the default value from apiConfig is used
        let initialTopic = this._permaLinkService.getInitialValue('topic');
        if (!initialTopic || !this.validTopic(initialTopic.toString())) {
          initialTopic = '';

          // If direct link with &layers but &topic omitted, set default topic.
          const initialLayers = this._permaLinkService.getInitialValue('layers');
          if (initialLayers?.toString().length) {
            initialTopic = TopicIdsEnum.AREA;
          }
        }
        this.setTopic(initialTopic.toString());
        this._permaLinkService.setParamReplaceHistory('topic', initialTopic);
      }
    });
  }

  /**
   * called when new topic selected
   */
  topicChanged(newTopic: TopicIdsEnum | string) {
    if (newTopic !== this.currentTopicId) {
      this.setTopic(newTopic);
      this._permaLinkService.setParamReplaceHistory('topic', newTopic);
    }
  }

  private setTopic(newTopic: TopicIdsEnum | string) {
    this.currentTopicId = newTopic;
    this.currentTopicConfig = this.topicsConfig.find((t: any) => t.id === this.currentTopicId);
    // Notify rest of application about the current topic
    this._kildenStateService.changeTopic(this.currentTopicId);
  }

  private validTopic(checkMe: string): boolean {
    const found = this.topicsConfig.find((t: any) => t.id === checkMe);
    return typeof found !== 'undefined';
  }
}
