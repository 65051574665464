import { Injectable } from '@angular/core';
import { ZoomInfo } from '@kildencore/models';
import { DeviceOrientationsEnum } from '@kildenshared/constants/device-orientations.enum';
import { DeviceTypesEnum } from '@kildenshared/constants/device-types.enum';
import { ToolIdsEnum } from '@kildenshared/constants/tool-ids.enum';
import { TopicIdsEnum } from '@kildenshared/constants/topic-ids.enum';
import { BehaviorSubject, ReplaySubject, shareReplay } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class KildenStateService {
  private readonly _activeTool = new BehaviorSubject<ToolIdsEnum>(ToolIdsEnum.INFO);
  private readonly _deviceOrientation = new ReplaySubject<DeviceOrientationsEnum>(1);
  private readonly _deviceSize = new ReplaySubject<{
    height: number;
    width: number;
  }>(1);
  private readonly _deviceType = new ReplaySubject<DeviceTypesEnum>(1);
  private readonly _epsg = new BehaviorSubject<string>('EPSG:25833');
  private readonly _layerSwipeActive = new ReplaySubject<boolean>(1);
  private readonly _layerSwipeRatio = new ReplaySubject<string>(1);
  private readonly _sidenavOpen = new BehaviorSubject<boolean>(false);
  private readonly _topic = new ReplaySubject<TopicIdsEnum | string>(1);
  private readonly _utm = new BehaviorSubject<string>('');
  private readonly _zoomChanges = new BehaviorSubject<ZoomInfo>(new ZoomInfo());

  readonly deviceOrientation$ = this._deviceOrientation.asObservable();
  readonly deviceSize$ = this._deviceSize.asObservable();
  readonly deviceType$ = this._deviceType.asObservable();
  readonly epsg$ = this._epsg.asObservable();
  readonly layerSwipeActive$ = this._layerSwipeActive.asObservable().pipe(distinctUntilChanged());
  // Use shareReplay() to cache value AND share the same observable to all subscribers.
  // A getter which returns MySubject.asObservable() actually yields a "new observable()" copy to each subscriber,
  // which causes unneccessary additional memory usage. Complete example at https://www.gushiciku.cn/pl/2dLx
  readonly layerSwipeRatio$ = this._layerSwipeRatio.asObservable().pipe(shareReplay(1));
  readonly sidenavOpen$ = this._sidenavOpen.asObservable();
  readonly tool$ = this._activeTool.asObservable();
  readonly topic$ = this._topic.asObservable();
  readonly zoomChanges$ = this._zoomChanges.asObservable();

  set deviceOrientation(value: DeviceOrientationsEnum) {
    this._deviceOrientation.next(value);
  }

  set deviceSize(value: { height: number; width: number }) {
    this._deviceSize.next(value);
  }

  set deviceType(value: DeviceTypesEnum) {
    this._deviceType.next(value);
  }

  /**
   * Register change in projection (epsg/utm) and broadcast to subscribers
   */
  changeEpsg(newEpsg: string) {
    this._epsg.next(newEpsg);
  }

  changeLayerSwipeActive(active: boolean): void {
    this._layerSwipeActive.next(active);
  }

  changeLayerSwipeRatio(ratio: string): void {
    this._layerSwipeActive.next(true);
    this._layerSwipeRatio.next(ratio);
  }

  /**
   * Notify of changes in LeftPanel. Will broadcast new status to subscribers.
   */
  changeSidenavState(isOpen: boolean) {
    this._sidenavOpen.next(isOpen);
  }

  /**
   * Notify of changes in active tool. Will broadcast new tool to subscribers.
   */
  changeTool(newTool: ToolIdsEnum) {
    this._activeTool.next(newTool);
  }

  /**
   * Broadcast changes in topic to subscribers
   * @param  newTopic              String id for the new topic.
   * @return         void.
   */
  changeTopic(newTopic: TopicIdsEnum | string) {
    this.changeLayerSwipeActive(false);
    this._topic.next(newTopic);
  }

  /**
   * Notify of changes in zoom level. Will broadcast new level to subscribers.
   */
  changeZoom(newZoom: ZoomInfo) {
    this._zoomChanges.next(newZoom);
  }
}
