export class AppConfig {
  static readonly DEFAULT_LAYER_OPACITY = 0.75;
  static readonly DEFAULT_LAYER_VISIBILITY = true;

  static readonly EXTENT_NORWAY_MAINLAND = [-100033, 6422999, 1123456, 7964321];

  // How do we want dates formatted/displayed in app
  static readonly FORMAT_DATE = 'dd.MM.yyyy';
  static readonly FORMAT_DATE_TIME = 'dd.MM.yyyy HH:mm';

  // The geometry types that in report context should receive a buffer automatically after upload
  static readonly GEOTYPES_REPORT_AUTO_BUFFER = ['Line', 'LineString', 'MultiLineString', 'Point'];

  // Special layer IDs
  static readonly ID_DIALOG_FEATURE_INFO = 'feature-info-dialogbox';
  static readonly IDBOD_FOREST_ROADS_BASIC = 'basis_skogsbilveier';
  static readonly IDBOD_FOREST_ROADS_WFS = 'skogsveier_wfs';
  static readonly IDBOD_NIB_2 = 'norgeibilder_2';
  static readonly IDBOD_NIB_SATELLITE = 'norgeibilder_satelite';
  static readonly IDBOD_REPORT_UPLOADED = 'report_uploaded';
  static readonly IDBOD_USER_UPLOADED = 'user_uploaded';
  static readonly IDBOD_USER_DRAWN = 'user_drawn';

  // Layer IDs that contain content which is lost on reload, typically user provided content (uploads/drawings).
  static readonly IMPERSISTENT_LAYERS = [AppConfig.IDBOD_USER_DRAWN, AppConfig.IDBOD_USER_UPLOADED];

  // Settings for the OpenLayers Map / View
  static readonly MAP_CONSTRAIN_RESOLUTION = false; // Allow zoom level with decimals, or constrain to integers?
  static readonly MAP_DEFAULT_EXTENT = [-22, 48, 46, 75];
  // These values define the view resolutions
  static readonly MAP_VIEW_RESOLUTIONS = [
    2708, 1354, 677, 338.5, 169.25, 84.625, 42.3125, 21.15625, 10.578125, 5.2890625, 2.64453125, 1.322265625,
    0.6611328125, 0.33056640625, 0.165283203125,
  ];
  // These resolutions are used for constructing the WMTS queries and should match whatever backend/geonorge caches exist.
  static readonly MAP_WMTS_RESOLUTIONS = [
    21664, 10832, 5416, 2708, 1354, 677, 338.5, 169.25, 84.625, 42.3125, 21.15625, 10.578125, 5.2890625, 2.64453125,
    1.322265625, 0.6611328125, 0.33056640625, 0.165283203125,
  ];
  static readonly MAP_VIEW_RESOLUTION_MAX = AppConfig.MAP_VIEW_RESOLUTIONS[0];
  static readonly MAP_VIEW_RESOLUTION_MIN = AppConfig.MAP_VIEW_RESOLUTIONS[AppConfig.MAP_VIEW_RESOLUTIONS.length - 1];
  static readonly MAP_ZOOM_FACTOR = 2.0;
  static readonly MAP_ZOOM_START = 0;

  /**
   * Formats for use with MatDatePicker etc
   * @see NorwegianDateAdapter
   * @see AppModule
   */
  static readonly MAT_DATE_FORMATS = {
    parse: {
      dateInput: ['dd.MM.yyyy', 'dd/MM/yyyy'], // to accept different input styles from user
    },
    display: {
      dateInput: 'dd.MM.yyyy', // display format in input field
      monthYearLabel: 'yyyy MMMM',
      dateA11yLabel: 'MMMM d, y', //'LL',
      monthYearA11yLabel: 'MMMM yyyy',
    },
  };

  // Special collections
  static readonly OPAQUE_EXCEPTIONS = [
    AppConfig.IDBOD_NIB_2,
    AppConfig.IDBOD_NIB_SATELLITE,
    AppConfig.IDBOD_USER_DRAWN,
  ];
  static readonly ORTHO_EXCEPTIONS = [AppConfig.IDBOD_NIB_2, AppConfig.IDBOD_NIB_SATELLITE];

  static readonly SIDENAV_WIDTH_PX = 300;

  // Make sure we use same query param separators throughout app
  static readonly QP_SEPARATOR_PRIMARY = ',';
  static readonly QP_SEPARATOR_SECONDARY = ':';

  // Layer IDs used for wildlife layers (viltkart)
  static readonly WILDLIFE_POINTSLAYER_ID = 'viltkart_geojson';
  static readonly WILDLIFE_TRACKSLAYER_ID = 'viltkart_spor';

  // Z-indexes to use in OpenLayers
  static readonly ZINDEX_BACKGROUND = 0;
  static readonly ZINDEX_SATELLITE = 4;
  static readonly ZINDEX_ORTHOPHOTO = 5;
  static readonly ZINDEX_THEMELAYERS = 6;
  static readonly ZINDEX_DRAW_ORIGIN = 90;
  static readonly ZINDEX_DRAW_SELECT = 80;
  static readonly ZINDEX_SEARCH = 800;
  static readonly ZINDEX_UPLOAD = 900;
  static readonly ZINDEX_UPLOADED_DRAWING = 920;
  static readonly ZINDEX_REPORT_DRAWINGS = 1000;
  static readonly ZINDEX_USER_DRAWN = 1100;

  static readonly ZOOM_DURATION_MS = 500;
  // Padding to add as option when using map.getView().fit(layer.getSource().getExtent(), {padding: xxx})
  // Clockwise values (t r b l). Top = 100 + header height. Left = 100 + sidebar width.
  static readonly ZOOM_PADDING_MOBILE = [60, 10, 10, 50];
  static readonly ZOOM_PADDING_DESKTOP = [80, 20, 20, 20];
}
