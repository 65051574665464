import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';
import { AppConfig } from '@kildenconfig/app.config';
import { DateHelper } from '@kildencore/helpers/date.helper';

@Injectable()
export class NorwegianDateAdapter extends NativeDateAdapter {
  override format(date: Date, displayFormat: Object): string {
    return formatDate(date, AppConfig.MAT_DATE_FORMATS.display.dateInput, 'nb');
  }

  override parse(value: any): Date | null {
    return DateHelper.parseNorwegianDate(value);
  }
}
