import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { AppConfig } from '@kildenconfig/app.config';
import { WindowRefHelper } from '@kildencore/helpers/window-ref.helper';
import { DialogService, DialogVariants } from '@kildencore/services/dialog.service';
import { KeyboardService } from '@kildencore/services/keyboard.service';
import { KildenStateService } from '@kildencore/services/kilden-state.service';
import { MapService } from '@kildencore/services/map.service';
import { ObjectinfoService } from '@kildencore/services/objectinfo.service';
import { Kilden3DialogComponent } from '@kildenshared/components/dialog/dialog.component';
import { FeatureInfoContentComponent } from '@kildenshared/components/feature-info-content/feature-info-content.component';
import { ToolIdsEnum } from '@kildenshared/constants/tool-ids.enum';
import { AppTouchEventType } from '@kildenshared/types/app-touch-event.type';

import { Coordinate } from 'ol/coordinate';
import OlFeature from 'ol/Feature';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import Map from 'ol/Map';
import MapBrowserEvent from 'ol/MapBrowserEvent';
// import Overlay from 'ol/Overlay';
import { transform as olProjTransform } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import OlStyleIcon from 'ol/style/Icon';
import OlStyleStyle from 'ol/style/Style';
import { filter, Subject, take, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'kilden3-feature-info',
  template: '',
})
export class FeatureInfoComponent implements OnInit, OnDestroy {
  private readonly _onDestroy$ = new Subject<void>();

  private dialogContainerRef: HTMLElement | null = null;
  private featureInfoBoxRef: HTMLElement | null = null;

  private dialogRef: MatDialogRef<Kilden3DialogComponent> | undefined;
  private isSidenavOpen: boolean = false;
  private map!: Map;

  constructor(
    private readonly _cdr: ChangeDetectorRef,
    private readonly _dialogService: DialogService,
    private readonly _keyboardService: KeyboardService,
    private readonly _kildenStateService: KildenStateService,
    private readonly _mapService: MapService,
    public matDialog: MatDialog,
    private readonly _objectinfoService: ObjectinfoService
  ) {}

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  ngOnInit(): void {
    this._setupSubscriptions();

    this.map = this._mapService.getMap();
    this.map?.on('singleclick', (evt: MapBrowserEvent<UIEvent>) => {
      this._kildenStateService.tool$
        .pipe(
          take(1),
          filter(tool => tool === ToolIdsEnum.INFO)
        )
        .subscribe(tool => {
          const d3 = this._mapService.$is3dActive();
          const coordinates = d3 ? this.coordinate3d(evt.coordinate) : evt.coordinate;
          // Show marker on map
          this.showMarkerOnMap(coordinates);
          // Open object info dialog
          this.showDialog(coordinates);
        });
    });
  }

  /**
   *
   */
  resetObjInfoDialog(): void {
    this._objectinfoService.resetDialogPosition();
    this._objectinfoService.resetDialogSize();

    // Override the css 'transform' that AngularMaterial for some reason sets as inline styles
    const overlayEl = document.querySelector('.feature-info-dialogbox');
    if (overlayEl) {
      overlayEl.setAttribute('style', 'transform:translate3d(0,0,0)');
    }

    // Here we would have to reset dragPosition (Cdk.setFreeDragPosition({x:0,y:0}) if we were
    // to keep dialog open, for now just close dialog which also resets dragPos.
    this.dialogRef?.close();
  }

  /**
   * Show the FeatureInfo dialog, init new if needed
   */
  showDialog(coordinate: Coordinate) {
    if (!this._dialogService.isOpen(DialogVariants.OBJECT_INFO)) {
      this._initDialogWithCallbacks();
    }
    if (!this.dialogRef) {
      this.dialogRef = this._dialogService.getHandle(
        DialogVariants.OBJECT_INFO
      ) as MatDialogRef<Kilden3DialogComponent>;
    }
    this._mapService.registerCoordinateClicked(coordinate);
  }

  /**
   * Sets config for the dialog, opens and returns the reference handle
   */
  private openDialogDetails(): MatDialogRef<Kilden3DialogComponent> | undefined {
    const defaultXPos = this.isSidenavOpen ? AppConfig.SIDENAV_WIDTH_PX : 0;
    const position = this._objectinfoService.getStoredDialogPosition();
    const size = this._objectinfoService.getStoredDialogSize();

    const panelClasses = [AppConfig.ID_DIALOG_FEATURE_INFO];
    if (position !== undefined) {
      panelClasses.push('repositioned');
    }
    if (size !== undefined && (size?.height || size?.width)) {
      panelClasses.push('resized');
    }

    if (this._dialogService.isOpen(DialogVariants.OBJECT_INFO)) {
      return this._dialogService.getHandle(DialogVariants.OBJECT_INFO) as MatDialogRef<Kilden3DialogComponent>;
    }

    this._dialogService.openDialog({
      variant: DialogVariants.OBJECT_INFO,
      dialogHandle: this.matDialog.open(Kilden3DialogComponent, {
        autoFocus: false, // also auto scrolls
        data: {
          component: FeatureInfoContentComponent,
          infoFormat: 'text/html',
          title: 'Objektinformasjon',
        },
        hasBackdrop: false,
        maxHeight: '98vh',
        maxWidth: '98vw',
        panelClass: panelClasses,
        position: {
          left: (position?.x || defaultXPos) + 'px',
          top: (position?.y || 0) + 'px',
        },
      }),
      keyboardService: this._keyboardService,
    });

    return this._dialogService.getHandle(DialogVariants.OBJECT_INFO) as MatDialogRef<Kilden3DialogComponent>;
  }

  private coordinate3d(c: Array<number>): Array<number> {
    const pixelPositionArray = this.map.getPixelFromCoordinate(c);
    const pixelPosition = { x: pixelPositionArray[0], y: pixelPositionArray[1] };
    // console.log(c, pixelPositionArray, pixelPosition);
    const scene = this._mapService.get3d().getCesiumScene();
    const ray = scene.camera.getPickRay(pixelPosition);
    const cartesian = scene.globe.pick(ray, scene);
    if (!cartesian) return c;
    const cartographic = scene.globe.ellipsoid.cartesianToCartographic(cartesian);
    // prettier-ignore
    const coords = [
      Cesium.Math.toDegrees(cartographic.longitude),
      Cesium.Math.toDegrees(cartographic.latitude)
    ];

    const height = scene.globe.getHeight(cartographic);
    if (height) coords.push(height);

    return olProjTransform(coords, 'EPSG:4326', this._mapService.projOn3dInit);
  }

  private showMarkerOnMap = (coordinates: Coordinate) => {
    const id = 'featureInfoMarker';
    const marker = this._mapService.getLayer(id);
    // // prettier-ignore
    // this.marker = this.map.getOverlayById(id) ?? new Overlay({
    //   id: id,
    //   className: 'location-icon blue',
    //   position: coordinates,
    //   positioning: 'bottom-center',
    // });
    // this.map.addOverlay(this.marker);

    // Use layer instead of overlay to:
    //   - avoid error "parent_ is null" in console
    //   - make it work in 3D
    const p = new Point(coordinates);
    if (marker) marker.getSource().getFeatures()[0].setGeometry(p);
    else {
      const pFeature = new OlFeature({ geometry: p });
      // prettier-ignore
      pFeature.setStyle(new OlStyleStyle({
        image: new OlStyleIcon({
          anchor: [0.5, 1],
          src: './assets/location/kldn_pin_blue.svg'
        }),
      }));
      const pLayer = new VectorLayer({
        properties: { id: 'featureInfoMarker', altitudeMode: 'clampToGround' },
        source: new VectorSource({ features: [pFeature] }),
        zIndex: AppConfig.ZINDEX_SEARCH,
      });
      this._mapService.getMap().addLayer(pLayer);
    }
  };

  private _initDialogWithCallbacks(): MatDialogRef<Kilden3DialogComponent> | undefined {
    this.dialogRef = this.openDialogDetails();

    // This dialog overlay
    this.featureInfoBoxRef = document.querySelector('.feature-info-dialogbox');

    // This dialog container
    this.dialogContainerRef = document.querySelector('.feature-info-dialogbox > mat-dialog-container');

    // Restore cached size after opened
    this.dialogRef?.afterOpened().subscribe(() => {
      setTimeout(() => {
        const restoreSize = this._objectinfoService.getStoredDialogSize();

        if (this.dialogContainerRef) {
          if (restoreSize?.width) {
            if (this.featureInfoBoxRef) {
              this.featureInfoBoxRef.classList.add('resized');
            }
            this.dialogContainerRef.style.width = `${restoreSize.width}px`;
          }
          if (restoreSize?.height) {
            if (this.featureInfoBoxRef) {
              this.featureInfoBoxRef.classList.add('resized');
            }
            this.dialogContainerRef.style.height = `${restoreSize.height}px`;
          }
        }
      }, 10);
    });

    // Remove map marker when dialog closed
    this.dialogRef?.beforeClosed().subscribe(() => {
      const marker = this._mapService.getLayer('featureInfoMarker');
      if (marker) this.map.removeLayer(marker);
    });

    if (this.dialogContainerRef) {
      this.dialogContainerRef.appendChild(resizerHandle());
      const resizeHandle = document.querySelector('.resizer-handle');
      const el = this.dialogContainerRef as HTMLElement;
      let startX: number, startY: number, startWidth: number, startHeight: number;

      // resize the element
      const doResize = (e: MouseEvent | AppTouchEventType) => {
        if (el) {
          const eventOrigin = 'touches' in e ? e.touches[0] : e;
          el.style.width = startWidth + eventOrigin.clientX - startX + 'px';
          el.style.height = startHeight + eventOrigin.clientY - startY + 'px';
        }
      };

      const stopResize = (e: MouseEvent | AppTouchEventType) => {
        if (this.dialogContainerRef) {
          this.dialogContainerRef.children[0].classList.remove('resize-dragging');
          const height = parseInt(this.dialogContainerRef.style.height);
          const width = parseInt(this.dialogContainerRef.style.width);
          if (
            this.dialogContainerRef.style.height &&
            this.dialogContainerRef.style.width &&
            !isNaN(height) &&
            !isNaN(width)
          ) {
            this._objectinfoService.setStoredDialogSize(height, width);
          }
        }
        if ('touches' in e) {
          // Remove touch event listeners
          WindowRefHelper.getNativeWindow().removeEventListener('touchmove', doResize, false);
          WindowRefHelper.getNativeWindow().removeEventListener('touchend', stopResize, false);
        } else {
          // on mouseup remove windows functions mousemove & mouseup
          WindowRefHelper.getNativeWindow().removeEventListener('mousemove', doResize, false);
          WindowRefHelper.getNativeWindow().removeEventListener('mouseup', stopResize, false);
        }
      };

      // Window funtion mousemove & mouseup
      const initResize = (e: MouseEvent | AppTouchEventType) => {
        // Stop possible native browser drag of e.target
        e.preventDefault();
        // Dont bubble up to cdkDrag listener
        e.stopPropagation();
        // "Clear" browser selection to avoid user effectively dragging a selection onto map canvas
        getSelection()?.setPosition(null);

        const eventOrigin = 'touches' in e ? e.touches[0] : e;
        startX = eventOrigin.clientX;
        startY = eventOrigin.clientY;

        const style = document.defaultView?.getComputedStyle(el);

        // check if resizehandle is target, to not resize when dragging title
        if (style && e.target === resizeHandle) {
          startWidth = parseInt(style.width, 10);
          startHeight = parseInt(style.height, 10);
          this.dialogContainerRef?.children[0].classList.add('resize-dragging');

          if ('touches' in e) {
            WindowRefHelper.getNativeWindow().addEventListener('touchmove', doResize, false);
            WindowRefHelper.getNativeWindow().addEventListener('touchend', stopResize, false);
          } else {
            WindowRefHelper.getNativeWindow().addEventListener('mousemove', doResize, false);
            WindowRefHelper.getNativeWindow().addEventListener('mouseup', stopResize, false);
          }
          return true;
        }

        return undefined;
      };

      document.getElementById('feature-info-resizer-handle')?.addEventListener('mousedown', initResize, false);
      document.getElementById('feature-info-resizer-handle')?.addEventListener('touchstart', initResize, false);
    }

    return this.dialogRef;
  }

  private _setupSubscriptions(): void {
    this._kildenStateService.deviceOrientation$
      .pipe(
        filter(a => this.dialogRef !== undefined),
        tap(() => {
          this.resetObjInfoDialog();
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();

    this._kildenStateService.deviceSize$
      .pipe(
        filter(a => this.dialogRef !== undefined),
        tap(a => {
          this.resetObjInfoDialog();
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();

    this._kildenStateService.sidenavOpen$
      .pipe(
        tap(status => {
          this.isSidenavOpen = status;
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();

    this._kildenStateService.topic$
      .pipe(
        tap(() => {
          this.resetObjInfoDialog();
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();
  }
}

export const resizerHandle = () => {
  const resizer = document.createElement('div');
  resizer.id = 'feature-info-resizer-handle';
  resizer.classList.add('resizer-handle');
  resizer.setAttribute('draggable', 'false');
  resizer.style.backgroundColor = 'transparent';
  resizer.style.backgroundImage =
    'url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iMjRweCIgZmlsbD0iIzk0OTQ5NCI+PHBhdGggZD0iTTAgMGgyNHYyNEgwVjB6IiBmaWxsPSJub25lIi8+PHBhdGggZD0iTTE5IDEyaC0ydjNoLTN2Mmg1di01ek03IDloM1Y3SDV2NWgyVjl6bTE0LTZIM2MtMS4xIDAtMiAuOS0yIDJ2MTRjMCAxLjEuOSAyIDIgMmgxOGMxLjEgMCAyLS45IDItMlY1YzAtMS4xLS45LTItMi0yem0wIDE2LjAxSDNWNC45OWgxOHYxNC4wMnoiLz48L3N2Zz4=")';
  resizer.style.bottom = '0';
  resizer.style.cursor = 'se-resize';
  resizer.style.margin = '5px';
  resizer.style.position = 'absolute';
  resizer.style.right = '0';
  resizer.style.height = '21px';
  resizer.style.width = '23px';
  resizer.style.zIndex = '999999';
  return resizer;
};
