<div
  [class.compact]="!showExpandedView"
  [class.expanded]="showExpandedView"
  [class.additional-controls]="layer.selectedOpen && layer.config?.timeEnabled"
  [class.outside-resolution]="isOutsideResolution"
  class="layer {{ layer.idBod }}"
  draggable="false">
  <input
    (click)="showExpandedView ? toggleLayerVisibility() : toggleLayerItem(layer)"
    (keyup.enter)="showExpandedView ? toggleLayerVisibility() : toggleLayerItem(layer)"
    (keyup.space)="showExpandedView ? toggleLayerVisibility() : toggleLayerItem(layer)"
    [checked]="showExpandedView ? layer.visible : layer.selectedOpen"
    [tabindex]="showExpandedView ? 0 : -1"
    [title]="
      (showExpandedView ? 'Vis/skjul' : 'Slå av/på') + ' kartlaget «' + layer.label + '». ' + layer.resolutionMessage
    "
    class="cbox"
    draggable="false"
    type="checkbox" />

  <div (mouseleave)="mouseOn = false" class="layer-details-container" draggable="false">
    <div
      (click)="showExpandedView ? $event.preventDefault() : toggleLayerItem(layer)"
      (keyup.enter)="showExpandedView ? $event.preventDefault() : toggleLayerItem(layer)"
      (mouseenter)="mouseOn = true"
      (touchend)="mouseOn = true"
      [hidden]="!isOutsideResolution && !showExpandedView && layer.selectedOpen && mouseOn"
      [title]="isOutsideResolution ? layer.resolutionMessage : layer.label"
      [tabindex]="-1"
      class="layer-content-container">
      <img
        *ngIf="layer.iconUrl && layer.iconUrl !== 'blank.png'"
        [src]="'assets/layericon/' + layer.iconUrl"
        [tabindex]="-1"
        [title]="layer.config?.attribution"
        alt=""
        class="layer-icon"
        draggable="false" />
      <span class="label-text">
        {{ layer.label }}
        <a
          *ngIf="showExpandedView && layer.infolink && !layer.children?.length"
          (click)="$event.stopPropagation()"
          [href]="layer.infolink"
          class="label-link link-info"
          draggable="false"
          target="_blank"
          title="Informasjon om kartlaget: {{ layer.label }}">
          <mat-icon draggable="false" fontSet="material-icons-outlined">info</mat-icon>
        </a>
        <a
          *ngIf="showExpandedView && canBeZoomed"
          (click)="zoomToLayerExtent()"
          class="label-link link-zoom"
          draggable="false"
          title="Vis innhold i kartet">
          <mat-icon draggable="false" fontSet="material-icons-outlined">location_on</mat-icon>
        </a>
      </span>
    </div>

    <span class="slider-container">
      <input
        (focusout)="mouseOn = false"
        (input)="opacityChange(layer, $event)"
        (change)="opacityChangeBroadcast(layer, $event)"
        [hidden]="isOutsideResolution || (!showExpandedView && (!layer.selectedOpen || !mouseOn))"
        [value]="layerOpacity"
        title="Velg gjennomsiktighet for: {{ layer.label }}"
        type="range"
        name="{{ layer.id }}-opacity"
        class="slider"
        min="0"
        max="1"
        step="0.01" />
    </span>

    <div *ngIf="layer.selectedOpen && layer.config && layer.config.timeEnabled && layer.timeOptions" class="controls">
      <select
        #timeSelectRef
        (change)="selectTimeOption(timeSelectRef.value)"
        class="time-enabled"
        name="time-select"
        [(ngModel)]="layer.timeOptions.selected">
        <option *ngFor="let timeOpt of timeSeries" [value]="timeOpt">
          {{ timeOpt.toString() }}
        </option>
      </select>
    </div>

    <div
      *ngIf="layer.selectedOpen && layer.config && this.layer.variants && layer.variants.items?.length"
      class="controls">
      <mat-spinner
        *ngIf="layer.variants.fetching"
        class="inline-spinner"
        color="primary"
        diameter="20"
        strokeWidth="2"
        value="75"></mat-spinner>
      <select
        *ngIf="layer.variants.items?.length || layer.variants.default"
        (ngModelChange)="selectVariant($event)"
        [(ngModel)]="layer.variants.selected"
        class="variant"
        name="variant-select">
        <option *ngFor="let variant of layer.variants.items" [ngValue]="variant">
          {{ variant.label?.toString() }}
        </option>

        <option
          *ngIf="!layer.variants.items && layer.variants.default"
          [ngValue]="layer.variants.default.value"
          [title]="layer.variants.default.label?.toString()">
          {{ layer.variants.default.label?.toString() }}
        </option>
      </select>
    </div>
  </div>

  <a
    *ngIf="!showExpandedView && layer.infolink && !layer.children?.length"
    [href]="layer.infolink"
    [tabindex]="-1"
    class="info-icon"
    draggable="false"
    mat-icon-button
    target="_blank"
    title="Informasjon om kartlaget: {{ layer.label }}">
    <mat-icon draggable="false" fontSet="material-icons-outlined">info</mat-icon>
  </a>

  <div *ngIf="showExpandedView" class="layer-action-buttons">
    <div
      (click)="toggleLayerItem(layer, false)"
      (keyup.enter)="toggleLayerItem(layer, false)"
      class="action toggle-layer"
      title="Fjern kartlaget fra aktive lag"
      tabindex="0">
      <mat-icon fontSet="material-icons-outlined">close</mat-icon>
    </div>
  </div>
</div>
