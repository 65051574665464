<div class="layer-swipe-boundary">
  <div
    #dragRef
    (cdkDragMoved)="dragMove($event)"
    id="layer-swipe"
    class="layer-swipe"
    cdkDragBoundary=".layer-swipe-boundary"
    cdkDragLockAxis="x"
    cdkDrag>
    <div #dragLineRef class="line"></div>

    <div *ngIf="swipeLabel?.length" class="label">
      {{ swipeLabel }}
    </div>

    <div class="arrows line-icon" title="Flytt sammenlikningsgrensen">
      <img alt="Flytt sammenlikningsgrensen" src="assets/arrows/swap_horizontal.svg" />
    </div>

    <div
      (click)="exitLayerSwipe()"
      (keyup.enter)="exitLayerSwipe()"
      class="close line-icon"
      tabindex="0"
      title="Avslutt sammenlikning">
      <img alt="Avslutt sammenlikning" src="assets/draw/close_white_24dp.svg" />
    </div>
  </div>
</div>
