import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeNb from '@angular/common/locales/nb';
import { LOCALE_ID, NgModule } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MatNativeDateModule } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiConfig } from '@kildenconfig/api-config';
import { AppConfig } from '@kildenconfig/app.config';
import { CoreModule } from '@kildencore/core.module';
import { NorwegianDateAdapter } from '@kildencore/helpers/norwegian-date-adapter.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProjectVegaModule } from '@projectvega/project-vega.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CoreModule,
    HttpClientModule,
    MatNativeDateModule,
    ProjectVegaModule,
  ],
  providers: [
    ApiConfig,
    { provide: LOCALE_ID, useValue: 'nb' },
    { provide: MAT_DATE_FORMATS, useValue: AppConfig.MAT_DATE_FORMATS },
    { provide: DateAdapter, useClass: NorwegianDateAdapter },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    registerLocaleData(localeNb);
  }
}
