import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AppConfig } from '@kildenconfig/app.config';
import { ArrayHelper } from '@kildencore/helpers/array.helper';
import { CatalogTreeService } from '@kildencore/services/data/catalog-tree.service';
import { SearchService } from '@kildencore/services/data/search.service';
import { KildenStateService } from '@kildencore/services/kilden-state.service';
import { PermaLinkService } from '@kildencore/services/perma-link.service';
import { liveSearch, moveCursorToStart } from '@kildencore/tools';
import { CatalogTreeItem } from '@kildenshared/components/catalog-tree/catalog-tree-item';
import { CatalogTreeRoot } from '@kildenshared/components/catalog-tree/catalog-tree-root';
import { CatalogTreeComponent } from '@kildenshared/components/catalog-tree/catalog-tree.component';
import { map, Observable } from 'rxjs';

@Component({
  providers: [CatalogTreeComponent],
  selector: 'kilden3-layer-search',
  templateUrl: './layer-search.component.html',
  styleUrls: ['./layer-search.component.css'],
})
export class LayerSearchComponent implements OnInit {
  public topic = '';
  currentTitle = '';
  searchFormControl = new FormControl('');
  filteredLayers: Observable<string[]> | undefined;
  stateGroupOptions: CatalogTreeItem[] | undefined;
  private catalogTree: CatalogTreeRoot | undefined;

  constructor(
    private ss: SearchService,
    private kss: KildenStateService,
    private ctc: CatalogTreeComponent,
    private cts: CatalogTreeService,
    private permaLink: PermaLinkService
  ) {}

  ngOnInit() {
    this.searchFormControl.valueChanges
      .pipe(
        liveSearch((value: string | null) => {
          this.getTopic();
          return this.ss.getMapLayers(value, this.topic);
        })
      )
      .pipe(
        map((data: CatalogTreeItem[]) => {
          this.stateGroupOptions = data;
        })
      )
      .subscribe();

    this.cts.catalogTree$.subscribe(t => {
      this.catalogTree = t;
    });
  }

  private getTopic() {
    this.kss.topic$.subscribe(d => (this.topic = d));
  }

  customStopPropagation(e: KeyboardEvent) {
    if (e.key === ' ') {
      e.stopPropagation();
    }
  }

  clearSearch() {
    this.searchFormControl.setValue('');
    this.currentTitle = '';
    moveCursorToStart('search-layer-input-field');
  }

  displayFn(option: CatalogTreeItem): string {
    return option && option.label && option.parentLabel ? `${option.label} - ${option.parentLabel}` : '';
  }

  onChange(selectedLayer: CatalogTreeItem) {
    this.currentTitle = `${selectedLayer.label} - ${selectedLayer.parentLabel}` || '';
    moveCursorToStart('search-layer-input-field');
    this.ctc.toggleLayerItem(selectedLayer, true);

    const catalogTree = this.catalogTree?.children;
    if (!catalogTree) return;

    // Find the layer in the actual CatalogTree and overwrite variable if found
    const treeItem: CatalogTreeItem = ArrayHelper.nestedFind(catalogTree, selectedLayer.id, 'id');
    if (typeof treeItem === 'object' && !Array.isArray(treeItem)) {
      selectedLayer = treeItem;
      this.ctc.toggleLayerItem(selectedLayer, true);
    }

    // Get layerIds from children
    let childrenIds = this._getNestedIdsFromLayer(selectedLayer);

    // Append layerIds from url
    const permaLinkLayersValue = this.permaLink.getInitialValue('layers')?.toString();
    if (permaLinkLayersValue && permaLinkLayersValue.trim() !== '') {
      const ids = permaLinkLayersValue.split(AppConfig.QP_SEPARATOR_PRIMARY);
      childrenIds = [...ids, ...childrenIds];
    }

    // Mark selected
    for (const i of catalogTree) {
      this.ctc.markSelected(i, childrenIds, [catalogTree]);
    }
  }

  private _getNestedIdsFromLayer(selectedLayer: CatalogTreeItem): string[] {
    const ids = selectedLayer.idBod?.length ? [selectedLayer.idBod] : [];
    if (selectedLayer.children?.length) {
      selectedLayer.children?.forEach((child: CatalogTreeItem) => {
        if (child.idBod?.length) {
          ids.push(child.idBod);
        }
      });
    }
    return ids;
  }
}
