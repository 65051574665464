<form [formGroup]="printForm">
  <mat-form-field class="form-group">
    <mat-label>Sideoppsett</mat-label>
    <mat-select id="pageLayout" formControlName="pageLayout" ngDefaultControl (selectionChange)="handleNewLayouts()">
      <mat-option *ngFor="let layout of layouts" [value]="layout">
        {{ layout }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="form-group">
    <mat-label>Målestokk</mat-label>
    <mat-select id="scale" formControlName="scale" ngDefaultControl (selectionChange)="handleNewScale()">
      <mat-option *ngFor="let scale of scales" [value]="scale">
        {{ scale }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="form-group">
    <mat-label>Format</mat-label>
    <mat-select id="format" formControlName="format" ngDefaultControl>
      <mat-option *ngFor="let format of formats" [value]="format">
        {{ format }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="form-group">
    <mat-label>Tittel</mat-label>
    <input id="title" formControlName="title" ngDefaultControl matInput value="Kart fra Kilden" />
  </mat-form-field>

  <br />
  <span
    ><input
      MatCheckbox
      #printLegend
      (keyup.enter)="printLegend.click()"
      type="checkbox"
      value=""
      formControlName="printLegend" />

    <mat-label>Tegnforklaring</mat-label>
  </span>
</form>

<footer class="sticky-modal-footer">
  <button class="printButton" mat-button mat-dialog-close>Avbryt</button>
  <button id="submitPrint" class="printButton" (click)="onSubmit()" mat-button cdkFocusInitial>
    <span *ngIf="showLoader" id="loading"></span>
    <span *ngIf="!showLoader">Skriv ut</span>
  </button>
</footer>
