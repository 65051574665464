<mat-spinner *ngIf="processing" color="primary" diameter="50" value="50" strokeWidth="3"></mat-spinner>

<ng-container *ngIf="!processing">
  <table class="road-info">
    <tr>
      <td>Veinummer:</td>
      <td>{{ roadNumber || id }}</td>
    </tr>
    <tr *ngFor="let item of summary; let idx = index">
      <td>{{ item.navn }}:</td>
      <td>
        {{ item.value || 'Ingen data' }}
        <span *ngIf="item.date" class="date"
          >(<span>{{ item.date | date: AppConfig.FORMAT_DATE }}</span
          >)</span
        >
      </td>
    </tr>
  </table>

  <mat-accordion *ngIf="details.length > 0" [multi]="true">
    <mat-expansion-panel>
      <mat-expansion-panel-header>Detaljer</mat-expansion-panel-header>

      <mat-panel-description>
        <table class="road-details">
          <tr *ngFor="let item of details">
            <td>{{ item['navn'] }}:</td>
            <td>{{ item['value'] || 'Ingen data' }}</td>
          </tr>
        </table>
      </mat-panel-description>
    </mat-expansion-panel>

    <mat-expansion-panel *ngFor="let roadObject of roadObjects">
      <mat-expansion-panel-header>{{ roadObject.navn }}</mat-expansion-panel-header>

      <mat-panel-description>
        <span *ngIf="!isArray(roadObject.value)">{{ roadObject.value }}</span>

        <table *ngIf="isArray(roadObject.value)" class="road-details">
          <tr *ngFor="let item of asPropArray(roadObject.value)">
            <td class="descriptor">{{ item['navn'] }}:</td>
            <td>
              {{ item['value'] || 'Ingen data' }}
              <span *ngIf="item.date" class="date"
                >(<span>{{ item.date | date: AppConfig.FORMAT_DATE }}</span
                >)</span
              >
            </td>
          </tr>
        </table>
      </mat-panel-description>
    </mat-expansion-panel>
  </mat-accordion>

  <div *ngIf="!roadObjects?.length" class="no-road-objects">Ingen veiobjekter er registrert</div>
</ng-container>
