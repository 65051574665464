import { Component, OnDestroy } from '@angular/core';
import { KildenStateService } from '@kildencore/services/kilden-state.service';
import { LegendService } from '@kildencore/services/legend.service';
import { Subject, Subscription, take, takeUntil, tap } from 'rxjs';

type legendObject = {
  id: string;
  url: string;
  prop: any;
  header: boolean;
  type: string;
  name: string;
};

@Component({
  selector: 'kilden3-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.css'],
})
export class LegendComponent implements OnDestroy {
  isVisible = false;
  openLeftPanel = true;
  legendArray: Array<legendObject> = [];

  private readonly _onDestroy$ = new Subject<void>();

  constructor(
    private readonly _kildenStateService: KildenStateService,
    private readonly _legendService: LegendService
  ) {
    this._legendService.legendArray$
      .pipe(
        tap(legendArray => {
          this.legendArray = legendArray; // Get new array when it is changed
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();

    this._kildenStateService.sidenavOpen$
      .pipe(
        take(1),
        tap((state: boolean) => {
          this.openLeftPanel = state;
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  toggleSidenav(): void {
    this.openLeftPanel = !this.openLeftPanel;
  }
}
