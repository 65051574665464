import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@kildenconfig/api-config';
import { AppConfig } from '@kildenconfig/app.config';
import { CatalogTreeItem } from '@kildenshared/components/catalog-tree/catalog-tree-item';
import { CatalogLayerVariantInterface } from '@kildenshared/interfaces/catalog-layer-variant.interface';
import { CatalogLayerVariantsInterface } from '@kildenshared/interfaces/catalog-layer-variants.interface';
import { catchError, map, Observable, retry, take, throwError } from 'rxjs';
import Swal from 'sweetalert2';
import { MapService } from '../map.service';

@Injectable({
  providedIn: 'root',
})
export class OrthoProjectsService {
  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _mapService: MapService
  ) {}

  getProjects(type: 'flyPhotos' | 'satPhotos' = 'flyPhotos'): Observable<ProjectMetadataInterface> {
    let projects = '1,2,3,4,5,8,9,12';

    if (type === 'satPhotos') {
      projects = '6';
    }

    const mapExtent = this._mapService.getViewExtent();
    const srId = this._mapService.getMap().getView().getProjection().getCode().split(':')[1] || '25832';

    if (!mapExtent) {
      throw new Error('Could not get map extent for fetching project meta data');
    }

    const top = mapExtent[1];
    const bottom = mapExtent[3];
    const left = mapExtent[0];
    const right = mapExtent[2];

    const NIBextent = `${left},${top};${left},${bottom};${right},${bottom};${right},${top}`;
    const ortoParam = `?request={"Filter": "ortofototype%20in%20(${projects})", "Coordinates":"${NIBextent}", "InputWKID":${srId}, "StopOnCover":false}`;

    return this._httpClient
      .get<ProjectMetadataInterface>(`${ApiConfig.baseUrl}${ApiConfig.orthoProjectsUrl}${ortoParam}`)
      .pipe(
        take(1),
        retry({ count: 3, delay: 500, resetOnSuccess: true }),
        catchError((err: Error) => {
          this._errorPopup(err as HttpErrorResponse);
          console.error(`caught err`, err);
          return throwError(() => err);
        })
      );
  }

  getProjectsAsLayerVariants(treeItem: CatalogTreeItem): Observable<CatalogLayerVariantsInterface> {
    const itemList: CatalogLayerVariantInterface[] = [];
    let orthoType: 'flyPhotos' | 'satPhotos' = 'satPhotos';
    let preselectLabel = treeItem.config?.wmsLayers;

    // Exclusive to flyPhotos
    if ((treeItem.idBod as string) === AppConfig.IDBOD_NIB_2) {
      orthoType = 'flyPhotos';
      preselectLabel = 'Heldekkende nyeste';
      itemList.push({
        label: preselectLabel,
        value: 'ortofoto',
      } as CatalogLayerVariantInterface);
    }

    let variants = {
      default: itemList[0],
      items: itemList,
      selected: itemList[0],
    } as CatalogLayerVariantsInterface;

    // console.log(`${treeItem.idBod}: getProjects(${orthoType})`);

    return this.getProjects(orthoType).pipe(
      map(response => {
        if (!response || !treeItem?.config || !treeItem?.idBod) {
          return variants;
        }

        if (!response.Success || response.ProjectList.length < 1) {
          return variants;
        }

        // Populate list
        response.ProjectList.forEach(item => {
          itemList.push({
            label: item,
            value: item,
          } as CatalogLayerVariantInterface);
        });

        // Set default
        let defaultItem = itemList[0];
        if (orthoType === 'satPhotos') {
          const matchIdx = itemList.findIndex(list => list.label === preselectLabel);
          if (matchIdx > -1) {
            defaultItem = itemList[matchIdx];
          }
        }

        // Save to tree/catalog
        variants = {
          default: defaultItem,
          items: itemList,
          selected: defaultItem,
        } as CatalogLayerVariantsInterface;

        // console.log(itemList.length + ` new orthoprojects, last one:`, itemList[itemList.length - 1].label);

        return variants;
      }),
      take(1),
      catchError((e: Error) => {
        console.error(`caught err`, e);
        return throwError(() => e);
      })
    );
  }

  private _errorPopup(err: HttpErrorResponse) {
    Swal.fire({
      title: ApiConfig.defaultErrorHeader,
      html: `Henting av flyfoto-data feilet: <BR> ${err.message}`,
      icon: 'error',
      confirmButtonText: 'OK',
    });
  }
}

export interface ProjectMetadataInterface {
  Success: boolean;
  ErrorMessage?: string;
  ProjectList: string[];
  ProjectMetadata?: string;
}
