import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@kildenconfig/api-config';
import { CatalogTreeItem } from '@kildenshared/components/catalog-tree/catalog-tree-item';
import { CatalogTreeRoot } from '@kildenshared/components/catalog-tree/catalog-tree-root';
import { TopicIdsEnum } from '@kildenshared/constants/topic-ids.enum';
import { ReplaySubject, shareReplay, Subject } from 'rxjs';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class CatalogTreeService {
  private catalogTree = new ReplaySubject<CatalogTreeRoot>(1);
  private categoryExpand = new Subject<CatalogTreeItem>();

  readonly catalogTree$ = this.catalogTree.asObservable().pipe(shareReplay(1));
  readonly categoryExpand$ = this.categoryExpand.asObservable().pipe(shareReplay(1));

  // We have not any topic first time, layers.component set topic when topic-state have broadcastet
  constructor(private http: HttpClient) {}

  public expandCategory(category: CatalogTreeItem): void {
    this.categoryExpand.next(category);
  }

  /**
   * Get layer-configuration from server
   * @param  topic arealinformasjon, skogportal....
   * @return Observable for layerdata
   */
  public refreshLayersList(topic: TopicIdsEnum | string) {
    if (topic) {
      return this.http.get<any>(ApiConfig.getServiceUrl() + '/' + topic + ApiConfig.restCatalogServerUrl).subscribe({
        next: success => {
          this.setNewLayersList(success.results.root);
        },
        error: err => {
          Swal.fire({
            title: ApiConfig.defaultErrorHeader,
            html: 'Henting av nye kartlagsliste feilet: <BR> ' + err.message,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        },
      });
    }
    return null;
  }

  public setNewLayersList(tree: CatalogTreeRoot) {
    this.catalogTree.next(tree);
  }
}
