import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterOutlet } from '@angular/router';
import { AttributionsComponent } from '@kildenshared/components/attributions/attributions.component';
import { BackgroundLayerSelectorComponent } from '@kildenshared/components/background-layer-selector/background-layer-selector.component';
import { CatalogItemComponent } from '@kildenshared/components/catalog-item/catalog-item.component';
import { CatalogLayerComponent } from '@kildenshared/components/catalog-layer/catalog-layer.component';
import { CatalogTreeComponent } from '@kildenshared/components/catalog-tree/catalog-tree.component';
import { DebugToolComponent } from '@kildenshared/components/debug-tool/debug-tool.component';
import { Kilden3DialogComponent } from '@kildenshared/components/dialog/dialog.component';
import { DrawComponent } from '@kildenshared/components/draw/draw.component';
import { FeatureInfoContentComponent } from '@kildenshared/components/feature-info-content/feature-info-content.component';
import { FeatureInfoComponent } from '@kildenshared/components/feature-info/feature-info.component';
import { ForestRoadInfoComponent } from '@kildenshared/components/forest-road-info/forest-road-info.component';
import { GeolocationComponent } from '@kildenshared/components/geolocation/geolocation.component';
import { InfolinksComponent } from '@kildenshared/components/infolinks/infolinks.component';
import { KeyboardTipsComponent } from '@kildenshared/components/keyboard-tips/keyboard-tips.component';
import { KildenHeaderComponent } from '@kildenshared/components/kilden-header/kilden-header.component';
import { LayerSearchComponent } from '@kildenshared/components/layer-search/layer-search.component';
import { LayerSwipeComponent } from '@kildenshared/components/layer-swipe/layer-swipe.component';
import { LeftComponent } from '@kildenshared/components/left/left.component';
import { LegendComponent } from '@kildenshared/components/legend/legend.component';
import { MaalestokkComponent } from '@kildenshared/components/maalestokk/maalestokk.component';
import { MapComponent } from '@kildenshared/components/map/map.component';
import { PrintformComponent } from '@kildenshared/components/printform/printform.component';
import { ReportDialogComponent } from '@kildenshared/components/report/report-dialog/report-dialog.component';
import { ReportComponent } from '@kildenshared/components/report/report.component';
import { SearchWildlifeComponent } from '@kildenshared/components/search-wildlife/search-wildlife.component';
import { SearchComponent } from '@kildenshared/components/search/search.component';
import { SelectTopicComponent } from '@kildenshared/components/select-topic/select-topic.component';
import { SonegrenseComponent } from '@kildenshared/components/sonegrense/sonegrense.component';
import { ThreeDComponent } from '@kildenshared/components/three-d/three-d.component';
import { TopicPanelComponent } from '@kildenshared/components/topic-panel/topic-panel.component';
import { UploadFileComponent } from '@kildenshared/components/upload-file/upload-file.component';
import { FileDropPlaceholderRefDirective, FileDropDirective } from '@kildenshared/directives/file-drop.directive';
import { SafePipe } from '@kildenshared/pipes';
import { WildlifeLegendPipe } from '@kildenshared/pipes/wildlife-legend.pipe';

@NgModule({
  declarations: [
    AttributionsComponent,
    BackgroundLayerSelectorComponent,
    CatalogItemComponent,
    CatalogLayerComponent,
    CatalogTreeComponent,
    DebugToolComponent,
    FileDropDirective,
    FileDropPlaceholderRefDirective,
    DrawComponent,
    FeatureInfoComponent,
    FeatureInfoContentComponent,
    ForestRoadInfoComponent,
    GeolocationComponent,
    InfolinksComponent,
    KeyboardTipsComponent,
    Kilden3DialogComponent,
    KildenHeaderComponent,
    LayerSearchComponent,
    LayerSwipeComponent,
    LeftComponent,
    LegendComponent,
    MaalestokkComponent,
    MapComponent,
    PrintformComponent,
    ReportComponent,
    ReportDialogComponent,
    SafePipe,
    SearchComponent,
    SearchWildlifeComponent,
    SelectTopicComponent,
    SonegrenseComponent,
    ThreeDComponent,
    TopicPanelComponent,
    UploadFileComponent,
    WildlifeLegendPipe,
  ],
  imports: [
    CommonModule,
    DragDropModule,
    FormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSidenavModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    ReactiveFormsModule,
    RouterOutlet,
  ],
  exports: [FileDropDirective, KeyboardTipsComponent, KildenHeaderComponent, LeftComponent, MapComponent],
})
export class SharedModule {}
