import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppConfig } from '@kildenconfig/app.config';
import { TopicsService } from '@kildencore/services/data/topics.service';
import { KildenStateService } from '@kildencore/services/kilden-state.service';
import { MapService } from '@kildencore/services/map.service';
import { PermaLinkService } from '@kildencore/services/perma-link.service';
import { TicketService } from '@kildencore/services/ticket.service';
import { UploadService } from '@kildencore/services/upload.service';
import { TopicConfig } from '@kildenshared/interfaces/topic-config';
import { Extent } from 'ol/extent';
import { combineLatest, map as rxMap, shareReplay, skip, Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'kilden3-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
})
export class MapComponent implements OnDestroy, OnInit {
  isConfig3dEnabled: boolean = false;
  isTopic3dEnabled: boolean = false;
  isLayerSwipeActive: boolean = false;

  private startCoordinateX = 7219344;
  private startCoordinateY = 383375;
  private startZoom = AppConfig.MAP_ZOOM_START;
  private startZoomExtentController = [];

  private readonly _onDestroy$ = new Subject<void>();

  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _elementRef: ElementRef,
    private readonly _kildenStateService: KildenStateService,
    private readonly _mapService: MapService,
    private readonly _permaLinkService: PermaLinkService,
    private readonly _ticketService: TicketService,
    private readonly _topicsService: TopicsService,
    private readonly _uploadService: UploadService
  ) {
    this._activatedRoute.data
      ?.pipe(
        tap(data => {
          this.startCoordinateX = data['config']['startCoordinates'].x;
          this.startCoordinateY = data['config']['startCoordinates'].y;
          this.startZoom = data['config']['startCoordinates'].z;
          this.startZoomExtentController = data['config'].zoomExtentController;
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  ngOnInit(): void {
    // START initvalues routeprovider from routing.module.ts ref kildenproject
    this.isConfig3dEnabled = this._activatedRoute.snapshot.data['config']?.threeD === true;

    const north: number = +(this._permaLinkService.getInitialValue('x') ?? this.startCoordinateX);
    const east: number = +(this._permaLinkService.getInitialValue('y') ?? this.startCoordinateY);
    const z: number = +(this._permaLinkService.getInitialValue('zoom') ?? this.startZoom);
    const extent: Extent = this.startZoomExtentController ?? [];
    const map = this._mapService.createMap(east, north, z, extent);

    // Remove white stripe from right side of the screen:
    map.once('postrender', map.updateSize);

    this._ticketService.getTickets().subscribe({
      next: tickets => {
        if (!tickets) return;
        else this._mapService.updateBackgroundLayerUrl(tickets[0]);
        // setTimeout(() => {
        //   console.log('timed out');
        //   this._mapService.updateBackgroundLayerUrl('');
        // }, 2000);
      },
      error: err => {
        // Could not get a token, create map with opencache
        // console.log('Token Error:', err);
        this._mapService.updateBackgroundLayerUrl('');
      },
    });

    // const epsgSelector = document.getElementById('utmSelector');
    const epsgSelector = this._elementRef.nativeElement.querySelector('#epsgSelector');
    epsgSelector.value = this._mapService.getCode(); // initialize with current projection
    epsgSelector.onchange = () => this._mapService.setProjection(epsgSelector.value);

    // Keep selector synced with (programmatic) epsgSelector changes
    this._kildenStateService.epsg$
      .pipe(
        skip(1),
        tap(nextEpsg => {
          epsgSelector.value = nextEpsg;
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();

    this._kildenStateService.layerSwipeActive$
      .pipe(
        tap(active => {
          this.isLayerSwipeActive = active;
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();

    this._kildenStateService.sidenavOpen$
      .pipe(
        tap(active => {
          // When sidenav closes we have a larger map area to render, set new size once animations done
          setTimeout(() => {
            const mapEl = document.getElementById('map');

            if (mapEl) {
              map.setSize([mapEl.clientWidth, mapEl.clientHeight]);
            }
          }, 1); // Wait until animations are done
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();

    // Whenever topic is changed, check if new topic has 3D in its toolList.
    combineLatest({
      id: this._kildenStateService.topic$,
      configs: this._topicsService.topicsConfig$.pipe(shareReplay(1)),
    })
      .pipe(
        rxMap(combined => {
          return combined.configs.find(cfg => cfg.id === combined.id) as TopicConfig;
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe({
        next: (topicConfig?: TopicConfig) => {
          if (topicConfig?.toolList) {
            this.isTopic3dEnabled = topicConfig.toolList.includes('3D');
          }
        },
      });
  }

  filesDropped(fileList: FileList): void {
    this._uploadService.uploadFilesToMap(fileList);
  }
}
