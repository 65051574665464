import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@kildenconfig/api-config';
import { TopicIdsEnum } from '@kildenshared/constants/topic-ids.enum';
import { SearchResponseCountyMunicipalityCommonType } from '@kildenshared/types/search/search-response-county-municipality-common.type';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import Swal from 'sweetalert2';

const utm = [
  { epsg: '25832', name: 'utm32' },
  { epsg: '25833', name: 'utm33' },
  { epsg: '25835', name: 'utm35' },
];

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private driftsSenterApiUrl = `${ApiConfig.baseUrl}/sonegrenser_ws/hentEiendom`;
  private fylkeKommuneApiUrl = `${ApiConfig.baseUrl}/kilden_ws/rest/services/searchfylkekommune`;
  private locationsApiURL = `${ApiConfig.baseUrl}/stedsnavn/v1/sted`;
  private municipalityListApiUrl = `${ApiConfig.baseUrl}/kilden_ws/rest/services/kommuner`;
  private orgNrApiUrl = `${ApiConfig.baseUrl}/sonegrenser_ws/hentDriftsenterpunkt`;
  private propertyApiUrl = `${ApiConfig.baseUrl}/kilden_ws/rest/services/search`;
  private reindeerApiUrl = `${ApiConfig.baseUrl}/kilden_ws/rest/services/searchReinbeitedistrikt`;
  private sonegrenseApiUrl = `${ApiConfig.baseUrl}/sonegrenser_ws/kommuneSoner`;

  private readonly _searchCleared$ = new Subject<void>();

  searchCleared$ = this._searchCleared$.asObservable();

  constructor(private http: HttpClient) {}

  /**
   * Let app know selected search result was cleared (removed)
   */
  clearSearch(): void {
    this._searchCleared$.next();
  }

  errorPopup(err: HttpErrorResponse, text: string) {
    Swal.fire({
      title: ApiConfig.defaultErrorHeader,
      html: `Henting av ${text} feilet: <BR> ${err.message}`,
      icon: 'error',
      confirmButtonText: 'OK',
    });
  }

  getAll(searchString: string | null | undefined, epsg: string, topic: TopicIdsEnum | string): Observable<any> {
    if (!searchString || !searchString.length) return of({});

    const epsgCode = epsg.split(':')[1];
    const currentUtm = utm.find(e => e.epsg === epsgCode)?.name || 'utm33';

    const locationApiUrl = `${this.locationsApiURL}?sok=${searchString}*&treffPerSide=10&utkoordsys=${epsgCode}&side=1&fuzzy=true`;
    const fylkeKommuneApiUrl = `${this.fylkeKommuneApiUrl}?search=${searchString}&count=10`;
    const propertyApiUrl = `${this.propertyApiUrl}?src=${currentUtm}&search=${searchString}`;
    const reindeerAPIUrl = `${this.reindeerApiUrl}?&search=${searchString}&count=10`;

    let sources = {
      location: this.http.get(locationApiUrl),
      fylkeKommune: this.http.get(fylkeKommuneApiUrl),
      property: this.http.get(propertyApiUrl),
    };

    if (topic === TopicIdsEnum.REIN_HERDING) {
      sources = { ...sources, ...{ reindeer: this.http.get(reindeerAPIUrl) } };
    }

    return forkJoin(sources).pipe(
      catchError(err => {
        this.errorPopup(err, 'søk resultat');
        return of('error');
      })
    );
  }

  /**
   * Fetches name for the provided municipality number
   */
  getCountyName(countyNumber: string): Observable<string> {
    if (!countyNumber || !countyNumber.length) {
      return of('');
    }

    return this.http
      .get<SearchResponseCountyMunicipalityCommonType>(
        `${ApiConfig.proxyUrl}/municipality_cache_ws/fylke/${countyNumber}`
      )
      .pipe(
        map(response => {
          return response.fylkenavn;
        }),
        catchError(err => {
          this.errorPopup(err, 'fylkenavn');
          return of('');
        })
      );
  }

  getMunicipalities(): Observable<any> {
    return this.http.get(this.municipalityListApiUrl).pipe(
      catchError(err => {
        this.errorPopup(err, 'kommuneliste');
        return of('error');
      })
    );
  }

  /**
   * Fetches border layer for the provided municipality or county
   */
  getMunicipalityCountyBorder(epsg: string, id: string, type: 'fylke' | 'kommune' | string): Observable<any> {
    if (!type || !type.length) return of([]);

    let apiUrl = '';

    if (type.toLowerCase() === 'fylke') {
      apiUrl = `${ApiConfig.proxyUrl}/municipality_cache_ws/fylkegrense?fylkeid=${id}&src=${
        epsg.split(':')[1]
      }&decimals=4`;
    }

    if (type.toLowerCase() === 'kommune') {
      apiUrl = `${ApiConfig.proxyUrl}/municipality_cache_ws/kommunegrense?komid=${id}&src=${
        epsg.split(':')[1]
      }&decimals=4`;
    }

    if (!apiUrl.length) of([]);

    return this.http.get(apiUrl).pipe(
      catchError(err => {
        this.errorPopup(err, 'fylke-/kommunegrense');
        return of('error');
      })
    );
  }

  /**
   * Fetches name for the provided municipality number
   */
  getMunicipalityName(muniNumber: string): Observable<string> {
    if (!muniNumber || !muniNumber.length) {
      return of('');
    }

    return this.http
      .get<SearchResponseCountyMunicipalityCommonType>(
        `${ApiConfig.proxyUrl}/municipality_cache_ws/kommune/${muniNumber}`
      )
      .pipe(
        map(response => {
          return response?.komnavn || '';
        }),
        catchError(err => {
          this.errorPopup(err, 'kommunenavn');
          return of('');
        })
      );
  }

  getProperty(municipalityNumb: string, gnr: number, bnr: number, fnr: number, epsg: string): Observable<any> {
    const epsgCode = epsg.split(':')[1];
    const apiUrl = `${ApiConfig.baseUrl}/matinfojson/${municipalityNumb},${gnr},${bnr},${fnr},${epsgCode}`;

    return this.http.get(apiUrl).pipe(
      catchError(err => {
        this.errorPopup(err, 'eiendom/matrikkel-grense');
        return of('error');
      })
    );
  }

  getProperties(municipalityNumb: string, gnr: number, bnr: number, fnr: number): Observable<any> {
    const apiUrl = `${ApiConfig.baseUrl}/kilden_ws/rest/services/hentEiendommer?knr=${municipalityNumb}&gnr=${gnr}&bnr=${bnr}&fnr=${fnr}`;

    return this.http.get(apiUrl).pipe(
      catchError(err => {
        this.errorPopup(err, 'eiendommer/matrikkel-grenser');
        return of('error');
      })
    );
  }

  getReindeerDistrict(kode: string, epsg: string): Observable<any> {
    const apiUrl = `${ApiConfig.baseUrl}/kilden_ws/rest/services/reinbeitedistrikt?distkode=${kode}&src=${
      epsg.split(':')[1]
    }&decimals=4`;

    return this.http.get(apiUrl).pipe(
      catchError(err => {
        this.errorPopup(err, 'reinbeitedistrikt grense');
        return of('error');
      })
    );
  }

  getMapLayers(searchString: string | null | undefined, topic: string): Observable<any> {
    if (!searchString || !searchString.length) return of([]);

    const apiUrl = `${ApiConfig.getServiceUrl()}/searchLayer?search=${searchString}&topic=${topic}&count=20`;
    return this.http.get(apiUrl).pipe(
      catchError(err => {
        this.errorPopup(err, 'kartlag');
        return of([]);
      })
    );
  }

  getSonerKommune(soneKomNr: number | null | undefined): Observable<any> {
    if (!soneKomNr || soneKomNr.toString().length !== 4) return of([]);

    const apiUrl = `${this.sonegrenseApiUrl}?knr=${soneKomNr}`;
    return this.http.get(apiUrl).pipe(
      catchError(err => {
        this.errorPopup(err, 'sonegrenser: ');
        return of('error');
      })
    );
  }

  getOrgnummer(orgNr: string | null | undefined): Observable<any> {
    if (!orgNr || orgNr.length !== 9) return of([]);

    const apiUrl = `${this.orgNrApiUrl}?orgnr=${orgNr}`;
    return this.http.get(apiUrl).pipe(
      catchError(err => {
        this.errorPopup(err, 'orgnummer: ');
        return of('error');
      })
    );
  }

  driftsSenter(property: any): Observable<any> {
    const apiUrl = `${this.driftsSenterApiUrl}?bnr=${property.bnr}&fnr=${property.fnr}&gnr=${property.gnr}&knr=${property.municipalityNumb}`;
    return this.http.get(apiUrl).pipe(
      catchError(err => {
        this.errorPopup(err, 'driftsSenter: ');
        return of('error');
      })
    );
  }
}
