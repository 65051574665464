export const environment = {
  production: true,
  proxyHost: 'https://proxy1.nibio.no',
  backendHost: 'https://kilden.nibio.no',
  printHost: 'https://kilden.nibio.no',
  guiHost: 'https://kart13.nibio.no/kilden3',
  svgHost: 'https://kart13.nibio.no/svg_color',
  route: 'kilden',
  addOtherRoutes: false,
  title: 'Kilden',
  defaultTopic: 'arealinformasjon',
  printTitle: 'Kart fra Kilden',
};
