<a
  (click)="jumpToTopic($event)"
  (keydown.enter)="jumpToTopic($event)"
  aria-label="Hopp til valg av fagområde"
  class="skiplink header-prefix"
  href="#topic">
  <h2>Tastaturnavigasjon</h2>
  <p>Trykk Enter for å hoppe til valg av fagområde, eller Tab for å bla videre til søk.</p>
  <p>Følgende snarveier kan brukes i Kilden:</p>
  <kilden3-keyboard-tips></kilden3-keyboard-tips>
</a>

<kilden3-header> </kilden3-header>
<div class="content-container">
  <kilden3-left class="left-container"> </kilden3-left>
  <kilden3-map class="map-container"> </kilden3-map>
</div>
