import { Injectable } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ApiConfig } from '@kildenconfig/api-config';
import { KeyboardService } from '@kildencore/services/keyboard.service';
import { Kilden3DialogComponent } from '@kildenshared/components/dialog/dialog.component';
import { finalize, take } from 'rxjs';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private _openDialogs = new Map<DialogVariants, MatDialogRef<any> | Kilden3DialogComponent>();

  dismissAllDialogs(
    exceptions: DialogVariants[] = [DialogVariants.OBJECT_INFO],
    runCloseHandler: boolean = true
  ): void {
    // console.log(`dissmissAllDialogs`);

    this._openDialogs.forEach((ref, key) => {
      if (!exceptions.includes(key)) {
        this.dismissDialog(key, runCloseHandler);
      }
    });
  }

  dismissDialog(variant: DialogVariants, runCloseHandler: boolean = true): boolean {
    let handle = this.getHandle(variant);
    if (handle) {
      // console.log('dismissing', variant, handle);
      if (runCloseHandler) {
        handle.close();
      }
      // Set undefined to free up handle and run the ngOnDestroy() of the component instance
      handle = undefined;
    }

    return this._openDialogs.delete(variant);
  }

  focusDialog(variant: DialogVariants) {
    const dialogRef = this.getHandle(variant) as MatDialogRef<Kilden3DialogComponent>;
    if (!dialogRef) {
      return;
    }

    const cdkOverlayWrappers = document.getElementsByClassName('cdk-global-overlay-wrapper');

    for (let i = 0; i < cdkOverlayWrappers.length; i++) {
      const wrapper = cdkOverlayWrappers[i];

      if (wrapper.contains(dialogRef.componentInstance.getNativeElement())) {
        const parent = wrapper.parentNode!;
        const last = parent.lastChild;
        if (last !== wrapper) {
          last!.parentNode!.insertBefore(wrapper, last!.nextSibling);
        }
        break;
      }
    }
  }

  getHandle(variant: DialogVariants): DialogType | undefined {
    return this._openDialogs.get(variant);
  }

  getOpenDialogs(): Map<DialogVariants, MatDialogRef<any> | Kilden3DialogComponent> {
    return this._openDialogs;
  }

  isOpen(variant: DialogVariants): boolean {
    return this._openDialogs.has(variant);
  }

  openDialog(params: OpenDialog): boolean {
    if (this.isOpen(params.variant)) {
      Swal.fire({
        title: ApiConfig.defaultWarnHeader,
        text: `Dialogen for «${params.variant}» er allerede åpen`,
        icon: 'warning',
        confirmButtonText: 'OK',
      });
      return false;
    }

    this._openDialogs.set(params.variant, params.dialogHandle);

    if (params.keyboardService !== undefined) {
      (params.dialogHandle as MatDialogRef<any>).keydownEvents().subscribe({
        next: e => {
          params.keyboardService?.handleGlobalShortcuts(e);
        },
      });
    }

    if (!params.closeManually) {
      params.dialogHandle
        .afterClosed()
        .pipe(
          take(1),
          finalize(() => {
            // console.log(`autodismiss dialog`);
            this.dismissDialog(params.variant);
          })
        )
        .subscribe();
    }

    return true;
  }
}

export type DialogType = MatDialogRef<any> | Kilden3DialogComponent;

export type OpenDialog = {
  closeManually?: boolean;
  dialogHandle: DialogType;
  keyboardService?: KeyboardService;
  variant: DialogVariants;
};

export enum DialogVariants {
  AREA_REPORT = 'Arealrapport',
  COMPARE_LAYERS = 'Sammenlikne kartlag',
  DRAW = 'Tegne / måle',
  FIND_COORDINATE = 'Finn/søk koordinat',
  FOREST_REPORT = 'Skograpport',
  GRANT_ZONES = 'Finn sone for distriktstilskudd',
  KEYBOARD_HINTS = 'Tastaturtips',
  OBJECT_INFO = 'Objektinformasjon',
  PRINT = 'Utskrift',
  REPORT = 'Rapport',
  SOIL_REPORT = 'Arealrapport jordsmonn',
  UPLOAD = 'Opplasting',
  WILDLIFE = 'Dyreportalen',
}
