import { AppConfig } from '@kildenconfig/app.config';
import { ForestRoadsStyle } from '@kildenshared/constants/styles/forest-roads.style';
import { VectorHoverStyle } from '@kildenshared/constants/styles/vector-hover.style';
import { WildPointsStyle } from '@kildenshared/constants/styles/wild-points.style';
import { WildTracksStyle } from '@kildenshared/constants/styles/wild-tracks.style';
import { StyleLike } from 'ol/style/Style';

export const LayerStylesConst: { [id: string]: StyleLike } = {
  [AppConfig.IDBOD_FOREST_ROADS_WFS]: ForestRoadsStyle,
  vector_hover: VectorHoverStyle,
  [AppConfig.WILDLIFE_POINTSLAYER_ID]: WildPointsStyle,
  [AppConfig.WILDLIFE_TRACKSLAYER_ID]: WildTracksStyle,
};
