import { Injectable } from '@angular/core';
import { KildenStateService } from '@kildencore/services/kilden-state.service';
import { ThemeLayersService } from '@kildencore/services/theme-layers.service';
import ImageLayer from 'ol/layer/Image';
import VectorLayer from 'ol/layer/Vector';
import { ImageWMS } from 'ol/source';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LegendService {
  legends: Array<any> = [];
  private legendArray = new BehaviorSubject<any>(this.legends);
  legendArray$ = this.legendArray.asObservable();

  constructor(
    private readonly _kildenStateService: KildenStateService,
    private readonly _themeLayersService: ThemeLayersService
  ) {
    this._themeLayersService.layersChange$.subscribe(changes => {
      if (!changes?.length) {
        return;
      }

      changes.forEach(lc => {
        if (lc.change.visible !== undefined) {
          if (lc.change.visible) {
            this.addLayer(lc.layerid);
          } else {
            this.removeLayer(lc.layerid);
          }
        }
        if (lc.change.active !== undefined) {
          if (lc.change.active) {
            this.addLayer(lc.layerid);
          } else {
            this.removeLayer(lc.layerid);
          }
        }
      });
      this.legendArray.next(this.legends);
    });
    this._kildenStateService.topic$.subscribe(changeTopic => {
      if (changeTopic) {
        this.legends = [];
        this.legendArray.next(this.legends);
      }
    });
  }

  /**
   * Find layer and add legendobject to legendsArray
   * @param id
   */
  private addLayer(id: string) {
    const layer = this._themeLayersService.findBankLayer(id);
    const hasLegend = layer?.getSource()?.getProperties()?.['config']?.hasLegend;
    if (hasLegend && !this.isLegendInList(id) && layer) {
      if (layer instanceof ImageLayer) {
        this.getWMSLegends(layer);
      } else if (layer instanceof VectorLayer) {
        this.getWFSLegends(layer);
      }
    }
  }

  /**
   * Remove alle object in legendarray with this id/name
   * @param id
   */
  private removeLayer(id: string) {
    while (this.isLegendInList(id)) {
      const pos = this.legends.map(e => e.id).indexOf(id);
      if (pos > -1) {
        this.legends.splice(pos, 1);
      }
    }
  }

  /**
   * Make check for ? in baseUrl
   * @param baseUrl
   * @param layer
   * @returns valid lengendurl
   */
  private getLayerStandardLegendUrl(baseUrl: any, layer: any) {
    let standardParams = '?VERSION=1.1.1&service=WMS&' + 'request=GetLegendGraphic&format=image/png&layer=';
    if (baseUrl.includes('?')) {
      standardParams = '&VERSION=1.1.1&service=WMS&' + 'request=GetLegendGraphic&format=image/png&layer=';
    }
    standardParams += layer;
    return baseUrl + standardParams;
  }

  /**
   * Make a standard legend call for layer
   * and add for legendsarray
   * @param layer
   */
  private getWFSLegends(layer: VectorLayer<any>) {
    const src = layer.getSource();
    let layerHeader = false;
    const exceptionUrl = src?.getProperties()?.config.legendUrl;
    const pattern = /^((http|https):\/\/)/;
    if (pattern.test(exceptionUrl)) {
      layerHeader = true;
      const arrObj = this.makeObject(layer, exceptionUrl, layerHeader);
      this.legends.unshift(arrObj);
    }
  }

  /**
   * Make a standard legend call for layer
   * and add for legendsarray
   * @param layer
   */
  private getWMSLegends(layer: ImageLayer<ImageWMS>) {
    const baseUrl = layer.getSource()?.getUrl();
    const p = layer.getSource()?.getParams();
    let layerHeader = false;
    var spesialUrl = layer.getSource()?.getProperties()?.['config'].legendUrl;
    var pattern = /^((http|https):\/\/)/;
    if (pattern.test(spesialUrl)) {
      layerHeader = true;
      const arrObj = this.makeObject(layer, spesialUrl, layerHeader);
      this.legends.unshift(arrObj);
    } else {
      // Standard legend
      let splitLayers = p.LAYERS.split(',');
      if (splitLayers instanceof Array && splitLayers.length > 1) {
        splitLayers = p.LAYERS.split(','); // More than one layer in wms
      } else {
        splitLayers[0] = p.LAYERS; // One layer in wms
      }

      for (let i = 0; i < splitLayers.length; i++) {
        const inputUrl = this.getLayerStandardLegendUrl(baseUrl, splitLayers[i]);
        if (i === splitLayers.length - 1) {
          // Only header for last wms layer
          layerHeader = true;
        }
        const arrObj = this.makeObject(layer, inputUrl, layerHeader);
        this.legends.unshift(arrObj);
      }
    }
  }

  makeObject(layer: any, inputUrl: string, layerHeader: boolean) {
    return {
      id: layer.getProperties().id,
      name: layer.getSource()?.getProperties()?.config.label,
      url: inputUrl,
      type: 'Image',
      header: layerHeader,
      layer: layer,
      queryable: layer.getSource()?.getProperties()?.config.queryable,
    };
  }

  /**
   * [isLayerInListe description]
   * @param  {[string]} id [id for one legends]
   * @return {[boolean]}  [Check if legends id exist already]
   */
  private isLegendInList(id: string): boolean {
    const pos = this.legends.map(e => e.id).indexOf(id);
    if (pos > -1) {
      return true;
    } else return false;
  }
}
