import { Injectable } from '@angular/core';
import { TopicConfig } from '@kildenshared/interfaces/topic-config';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '@kildenconfig/api-config';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class TopicsService {
  private topicsConfig = new BehaviorSubject<TopicConfig[]>([]);

  topicsConfig$: Observable<TopicConfig[]> = this.topicsConfig.asObservable();

  constructor(private readonly _httpClient: HttpClient) {
    this.refreshtopicsConfig();
  }

  /**
   * Get topic-configuration from server and broadcast to topicsConfig$
   */
  public refreshtopicsConfig() {
    return this._httpClient.get<any>(ApiConfig.getServiceUrl()).subscribe({
      next: success => this.setTopicsConfig(success.topics),
      error: err => {
        Swal.fire({
          title: ApiConfig.defaultErrorHeader,
          html: 'Henting av nye fagområder feilet: <BR> ' + err.message,
          icon: 'error',
          confirmButtonText: 'OK',
        });
      },
    });
  }

  private setTopicsConfig(topics: TopicConfig[]) {
    this.topicsConfig.next(topics);
  }
}
