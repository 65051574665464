import { Injectable } from '@angular/core';
import { MapService } from '@kildencore/services/map.service';
import {
  DoubleClickZoom,
  DragAndDrop,
  DragPan,
  DragRotate,
  DragZoom,
  KeyboardPan,
  KeyboardZoom,
  MouseWheelZoom,
  PinchRotate,
  PinchZoom,
} from 'ol/interaction';
import BaseLayer from 'ol/layer/Base';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

@Injectable({
  providedIn: 'root',
})
export class DebugToolService {
  data: any[] = [];

  constructor(readonly mapService: MapService) {}

  logMapInteractionsToConsole(text?: string) {
    console.log(
      text,
      this.mapService
        .getMap()
        .getInteractions()
        .getArray()
        .filter(int => {
          return (
            int.getActive() &&
            !(
              int instanceof DragRotate ||
              int instanceof DoubleClickZoom ||
              int instanceof DragPan ||
              int instanceof PinchRotate ||
              int instanceof PinchZoom ||
              int instanceof KeyboardPan ||
              int instanceof KeyboardZoom ||
              int instanceof MouseWheelZoom ||
              int instanceof DragZoom ||
              int instanceof PinchZoom ||
              int instanceof DragAndDrop
            )
          );
        })
    );
  }

  logMapLayersToConsole(text?: string) {
    console.log(
      text,
      this.mapService
        .getMap()
        .getLayers()
        .getArray()
        .map(l => {
          if (!l.get('id')) {
            return l
              .get('layers')
              .getArray()
              .map((sl: any) => {
                return {
                  parentVis: l.getVisible(),
                  parentZ: l.getZIndex(),
                  ...this.logLayer(sl),
                };
              });
          }

          return this.logLayer(l);
        })
    );
  }

  logLayer(layer: BaseLayer) {
    let nrFeatures = '-';

    if (layer instanceof VectorLayer) {
      nrFeatures = (layer as VectorLayer<VectorSource>).getSource()?.getFeatures()?.length.toString() || '0';
    }

    return {
      id: layer.get('id'),
      group: layer.getLayersArray(),
      keys: JSON.stringify(structuredClone(layer.getKeys())),
      nrFeatures,
      srcKeys: JSON.stringify(structuredClone((layer as VectorLayer<VectorSource>).getSource()?.getKeys())),
      visible: layer.getVisible(),
      z: layer.getZIndex(),
    };
  }
}
