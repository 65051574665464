import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import { AppConfig } from '@kildenconfig/app.config';
import { MapService } from '@kildencore/services/map.service';
import { ObjectinfoService } from '@kildencore/services/objectinfo.service';
import { ClickedCoordinateType } from '@kildenshared/interfaces/clicked-coordinate.type';
import { FeatureInfoInterface } from '@kildenshared/interfaces/feature-info.interface';
import { WildlifeFeatureInfoInterface } from '@kildenshared/interfaces/wildlife-feature-info.interface';
import { toStringHDMS } from 'ol/coordinate';
import ImageLayer from 'ol/layer/Image';
import { ImageWMS } from 'ol/source';
import { combineLatest, concatMap, filter, finalize, Subject, take, takeUntil, tap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export interface Section {
  name: string;
  value: string;
}

@Component({
  selector: 'kilden3-feature-info-content',
  templateUrl: './feature-info-content.component.html',
  styleUrls: ['./feature-info-content.component.css'],
})
export class FeatureInfoContentComponent implements OnInit, OnDestroy, AfterViewInit {
  coordinateData!: ClickedCoordinateType;
  coordinates: Section[] = [];
  forestRoad: { heading: string; id: string } | undefined;
  getFeatureInfo: FeatureInfoInterface[] = [];
  tabsLoading = false;
  wildlifeInfo: { heading: string; features: WildlifeFeatureInfoInterface[] } | undefined;

  @ViewChild('matTabGroup', { static: false })
  matTabGroup!: MatTabGroup;

  private readonly _onDestroy$ = new Subject<void>();

  private _exceptionIds: string[] = [
    AppConfig.IDBOD_FOREST_ROADS_WFS,
    AppConfig.WILDLIFE_POINTSLAYER_ID,
    AppConfig.WILDLIFE_TRACKSLAYER_ID,
  ];
  private _nextTabListener = (event: any) => {
    this._goToNextTabIndex(this.matTabGroup);
  };
  private _prevTabListener = (event: any) => {
    this._goToPreviousTabIndex(this.matTabGroup);
  };
  private _wmsFeatures: FeatureInfoInterface[] = [];

  private matTabHeaderPaginationAfter: any = [];
  private matTabHeaderPaginationBefore: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData: any,
    private readonly _httpClient: HttpClient,
    private readonly _mapService: MapService,
    private readonly _objectinfoService: ObjectinfoService
  ) {}

  ngAfterViewInit(): void {
    this.matTabHeaderPaginationBefore = document.querySelectorAll('.mat-tab-header-pagination-before');
    this.matTabHeaderPaginationAfter = document.querySelectorAll('.mat-tab-header-pagination-after');

    this.matTabHeaderPaginationBefore.forEach((item: Element) => {
      item.addEventListener('click', this._prevTabListener);
    });

    this.matTabHeaderPaginationAfter.forEach((item: Element) => {
      item.addEventListener('click', this._nextTabListener);
    });
  }

  ngOnDestroy(): void {
    this.matTabHeaderPaginationBefore.forEach((item: Element) => {
      item.removeEventListener('click', this._prevTabListener);
    });

    this.matTabHeaderPaginationAfter.forEach((item: Element) => {
      item.addEventListener('click', this._nextTabListener);
    });

    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  ngOnInit(): void {
    this._mapService.coordinateClicked$
      .pipe(
        tap(data => {
          this.coordinateData = data;
          this._lookupCoordinate();
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();
  }

  makeCoordinateArray(data: ClickedCoordinateType) {
    const utm32 = this._mapService.transformCoordinate(data.projection, 'EPSG:25832', data.coordinate);
    const utm32_formatted = utm32[1].toFixed(0) + 'N' + ' , ' + utm32[0].toFixed(0) + 'Ø';

    const utm33 = this._mapService.transformCoordinate(data.projection, 'EPSG:25833', data.coordinate);
    const utm33_formatted = utm33[1].toFixed(0) + 'N' + ' , ' + utm33[0].toFixed(0) + 'Ø';

    const utm35 = this._mapService.transformCoordinate(data.projection, 'EPSG:25835', data.coordinate);
    const utm35_formatted = utm35[1].toFixed(0) + 'N' + ' , ' + utm35[0].toFixed(0) + 'Ø';

    const desimalDegree = this._mapService.transformCoordinate(data.projection, 'EPSG:4258', data.coordinate);
    const desimalDegree_formatted = desimalDegree[1].toFixed(5) + '°N' + ' , ' + desimalDegree[0].toFixed(5) + '°Ø';

    const degree_formatted = toStringHDMS(desimalDegree, 1).replace('E', 'Ø');

    const list = [
      {
        name: 'UTM Sone 32',
        value: utm32_formatted,
      },
      {
        name: 'UTM Sone 33',
        value: utm33_formatted,
      },
      {
        name: 'UTM Sone 35',
        value: utm35_formatted,
      },
      {
        name: 'Desimalgrader',
        value: desimalDegree_formatted,
      },
      {
        name: 'Grader (ETRS89)',
        value: degree_formatted,
      },
    ];

    const h = Math.max(0, data.coordinate[2] - 50);
    if (h >= 0) list.push({ name: 'Høyde', value: h.toFixed(0) + ' moh' });

    return list;
  }

  resetAllIframeLoaded(): void {
    this.getFeatureInfo.forEach(f => (f.iframeLoaded = false));
  }

  private _createWMSFeatureInfoArray(wmsFeatures: FeatureInfoInterface[]) {
    if (wmsFeatures.length) {
      this._updateTabsLoading();
    }

    wmsFeatures.map(feat => {
      if (feat.layer && feat.layer instanceof ImageLayer) {
        feat.iframeLoaded = false;
        feat.processing = true;
        feat.visible = true;
        const FEATURE_COUNT = feat.id === 'eksterne_rodlistede_arter' ? '1000' : undefined;

        const url = feat.layer
          ?.getSource()
          ?.getFeatureInfoUrl(
            this.coordinateData.coordinate,
            this.coordinateData.resolution,
            this.coordinateData.projection,
            {
              INFO_FORMAT: this.dialogData.infoFormat,
              FEATURE_COUNT,
            }
          );
        if (url) {
          feat.request = this._httpClient.get(url, { responseType: 'text' });
          feat.url = url;
        }
      }

      return feat;
    });

    // Run all feature info requests in parallell and update each one as they finish
    return combineLatest(
      wmsFeatures.map((feat, featuresIdx) => {
        return feat.request.pipe(
          filter(
            // Filter out invalid responses (Jordsmonn > Eksterne > Vann-nett > * )
            (response: string) => !response.includes('<body></body>')
          ),
          take(1), // First emit is all we need
          finalize(() => {
            // Turn off processing, will typically hide spinner and show content
            feat.processing = false;
            // Recalculate whether we are still loading tabs
            this._updateTabsLoading();
          }),
          tap((response: string) => {
            if (response?.length > 0) {
              // Commented out as part of KILDEN3-483 - instead of passing feat.urlResponse to
              //  the iframe src, we will instead pass feat.url and not "preprocess via Blob".
              //  // Process the returned html and add as string on the Feature
              //  // feat.urlResponse = URL.createObjectURL(new Blob([response], { type: 'text/html' }));

              // Push onto feature infos. This is what makes the tab show in obj info box.
              this.getFeatureInfo.push(feat);
              // Sort tabs right away
              this._sortFeatureInfo();
            }
          }),
          catchError((e: HttpErrorResponse) => {
            feat.processing = false;
            feat.url = URL.createObjectURL(
              new Blob(
                [
                  '<div class="error-notice"><h4>Uventet feil</h4>' +
                    '<div>Noe gikk galt ved henting av dette innholdet, vennligst prøv igjen.</div></div>',
                ],
                { type: 'text/html' }
              )
            );
            this.getFeatureInfo.push(feat);
            this._sortFeatureInfo();
            return throwError(() => e);
          })
        );
      })
    ).pipe(
      // Close the combineLatest stream
      take(1)
    );
  }

  private _createExceptionsData(exceptions: FeatureInfoInterface[]): void {
    exceptions.forEach(ef => {
      this._getFeaturesFromMapPixel(ef, this.coordinateData);

      if (ef.id === AppConfig.IDBOD_FOREST_ROADS_WFS) {
        if (ef.features && ef.features.length > 0) {
          const props = ef.features[0].getProperties();
          if (props['origin'] === 'skogsbilveg' && props['id'].length) {
            this.forestRoad = {
              heading: 'Skogsbilveg',
              id: props['id'],
            };
          }
        }
      }

      if (ef.id === AppConfig.WILDLIFE_POINTSLAYER_ID) {
        if (ef.features && ef.features.length > 0) {
          this.wildlifeInfo = {
            heading: ef.name,
            features: [],
          };

          for (let i = 0, ii = ef.features.length; i < ii; i++) {
            const vProps = ef.features[i].getProperties();

            const mapItem: WildlifeFeatureInfoInterface = {
              name: vProps['id_navn'],
              properties: [],
            };

            if (vProps['symbol'] && vProps['symbol_color']) {
              mapItem.mapSymbol = {
                shape: vProps['symbol'],
                fill: vProps['symbol_color'],
                stroke: vProps['symbol_line'] || '#000',
              };
            }

            mapItem.properties = [
              { label: 'Tidspunkt', value: vProps['Acquisition_time'] },
              { label: 'Halsband ID', value: vProps['collar_id'] },
              { label: 'Farge/nr på halsband', value: vProps['collarfarge'] },
              { label: 'Merkeprosjekt', value: vProps['markproject'] },
              { label: 'Merkekommune', value: vProps['markmunic'] },
              { label: 'Merkelokalitet', value: vProps['lokalitet'] },
              { label: 'Dato merket', value: vProps['markdate'] },
              { label: 'Kalv når merket', value: vProps['calf_when_marked'] },
              { label: 'Anslått alder', value: vProps['markage_guessed'] },
              { label: 'Totalvekt', value: vProps['markweight'] },
            ];

            this.wildlifeInfo.features.push(mapItem);
          }
        }
      }
    });
  }

  private _getFeaturesFromMapPixel(f: FeatureInfoInterface, data: ClickedCoordinateType): void {
    f.features = [];

    const map = this._mapService.getMap();
    if (map) {
      const mapPixel = map.getPixelFromCoordinate(data.coordinate);
      if (mapPixel !== undefined) {
        map.forEachFeatureAtPixel(mapPixel, (mapFeat, mapLayer) => {
          if (mapLayer && mapLayer.get('id') === f.id) {
            // @ts-ignore
            f.features.push(mapFeat);
          }
        });
      }
    }
  }

  private _goToNextTabIndex(tabGroup: MatTabGroup) {
    if (!tabGroup) return;
    const tabindex = tabGroup.selectedIndex || 0;
    tabGroup.selectedIndex = tabindex + 1;
  }

  private _goToPreviousTabIndex(tabGroup: MatTabGroup) {
    if (!tabGroup) return;
    const tabindex = tabGroup.selectedIndex || 0;
    tabGroup.selectedIndex = tabindex - 1;
  }

  private _sortFeatureInfo(): void {
    this.getFeatureInfo.sort((a, b) => a.index - b.index);
  }

  private _lookupCoordinate(): void {
    this.coordinates = this.makeCoordinateArray(this.coordinateData);

    this._objectinfoService.objectArray$
      .pipe(
        concatMap((objectArray: any[]) => {
          // Clear previous results
          this.getFeatureInfo = [];
          this.wildlifeInfo = undefined;

          const features: FeatureInfoInterface[] = objectArray.slice();

          // Construct the API request url for each feature
          this._wmsFeatures = features.filter(feat => {
            return !this._exceptionIds.includes(feat.id);
          });

          const exceptions = features.filter(feat => {
            return this._exceptionIds.includes(feat.id);
          });
          this._createExceptionsData(exceptions);

          return this._createWMSFeatureInfoArray(this._wmsFeatures);
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();
  }

  private _updateTabsLoading(): void {
    this.tabsLoading = this._wmsFeatures.some(feat => feat.processing);
  }
}
