import { Component, OnDestroy, OnInit } from '@angular/core';
import { ZoomInfo } from '@kildencore/models';
import { KildenStateService } from '@kildencore/services/kilden-state.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kilden3-maalestokk',
  templateUrl: './maalestokk.component.html',
  styleUrls: ['./maalestokk.component.css'],
})
export class MaalestokkComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  scale: number = 0;

  constructor(private appState: KildenStateService) {}

  ngOnInit(): void {
    const UNITS_RATIO = 39.37; // inches/meter
    const DPI = 96; // px/inch
    let zoom: ZoomInfo = new ZoomInfo();

    const subscriptionZoom$ = this.appState.zoomChanges$.subscribe(newZoom => {
      if (newZoom.hasResolution()) {
        zoom = newZoom;
        this.scale = Math.round((zoom.resolution as number) * UNITS_RATIO * DPI);
      }
    });

    this.subscriptions.add(subscriptionZoom$);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
