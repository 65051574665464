import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ApiConfig } from '@kildenconfig/api-config';
import { BehaviorSubject, Observable } from 'rxjs';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  private printObj = new BehaviorSubject<any>(null);
  public get printObj$(): Observable<any> {
    return this.printObj.asObservable();
  }

  constructor(private http: HttpClient) {}
  httpHeaders: any = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  credentials: any = 'same-origin';

  /**
   * Get print-configuration from server
   * @return Observable for printdata
   */
  public getPrintObj() {
    return this.http.get<any>(ApiConfig.restPrintServerUrl).subscribe(
      success => this.setNewPrintObj(success),
      err => {
        console.log('Error fetching printconfig', err);
        Swal.fire({
          title: ApiConfig.defaultErrorHeader,
          html: 'Hente informasjon fra printserver feilet: <BR> ' + err.message,
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    );
  }

  public setNewPrintObj(print: any) {
    this.printObj.next(print);
  }

  /**
   * [postPrintSpec ]
   * @param  prop   [json with info about layers, title, scale...]
   * @param  format   [string with info about format, png or pdf]
   * @return      [json with statusurl, downloadurl and ref]
   */
  public postPrintSpec(prop: any, format: string) {
    return this.http.post<any>(ApiConfig.restPrintServerUrlReport + format, prop, {
      headers: this.httpHeaders,
      observe: 'body',
    });
  }

  /**
   *
   *
   * @param {*} url
   * @param {*} interval
   * @param {*} timeout
   * @return {Promise}
   */
  public pollUntilDone(url: any, interval: any, timeout: any) {
    const start = Date.now();
    const mainThis = this;

    /**
     * @ignore
     */
    function run(): any {
      return fetch(url, {
        method: 'GET',
        headers: mainThis.httpHeaders,
        credentials: mainThis.credentials,
      })
        .then(response => mainThis.validateResponse(response))
        .then(response => response.json())
        .then(json => {
          const status = json.status;

          if (status === 'finished') {
            return Promise.resolve(json.downloadURL);
          } else if (status === 'error') {
            return Promise.reject(new Error(`There was an error executing the job: ${json.error}`));
          } else if (status === 'cancelled') {
            return Promise.reject(new Error('The job was cancelled.'));
          } else if (['waiting', 'running'].includes(status)) {
            if (timeout !== 0 && Date.now() - start > timeout) {
              return Promise.reject(new Error('timeout error on pollUntilDone'));
            } else {
              return new Promise(resolve => {
                setTimeout(resolve, interval);
              }).then(run.bind(mainThis));
            }
          }
          return null;
        });
    }

    return run.call(this);
  }

  /**
   * Validates the given HTTP fetch response.
   *
   * @param {Response} response The response to validate.
   *
   * @return {Promise} The resolved or rejected promise.
   */
  validateResponse(response: any) {
    if (response.status >= 200 && response.status < 300) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(
        new Error(`Error while trying to request ` + `${response.url} (${response.status}: ${response.statusText})`)
      );
    }
  }
}
