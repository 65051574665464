<!-- Upload from a basic upload (the input file is hidden by default)-->
<div (dragenter)="preventDrop($event)" (dragover)="preventDrop($event)" (drop)="preventDrop($event)">
  <form>
    <div hidden>
      <label for="fileUpload">Velg fil:</label>
      <input id="fileUpload" type="file" multiple accept=".geojson,.gpx,.json,.kml,.zip" (change)="chosen($event)" />
    </div>

    <!-- Button use to open the file chooser of the input file -->
    <div class="w100">
      <input type="text" readonly value="{{ fileNames }}" placeholder="Ingen fil" />
      <button mat-button type="button" class="kildenBtn" (click)="openFileBrowser()">Velg fil</button>
    </div>
    <button
      mat-button
      class="w100 kildenBtn"
      type="submit"
      *ngIf="progress === 0 || progress === 100"
      [disabled]="!fileNames.length"
      (click)="uploadFilesToMap()">
      Vis i kartet
    </button>
    <!-- Display information about the uploaded file and the upload process -->
    <button mat-button class="w100 kildenBtn" (click)="cancel()" *ngIf="0 < progress && progress < 100">
      <mat-progress-spinner diameter="24" strokeWidth="4" [value]="progress"></mat-progress-spinner>
      Avbryt opplasting
    </button>
    <div class="w100 file-upload-msg">{{ userMessage }}</div>
    <div class="w100 file-upload-error-msg">{{ uploadErrorMessage }}</div>
    <!-- Use for debug purpose / Display the content of the file if upload succeed -->
    <textarea class="w100" readonly hidden>{{ fileContent }}</textarea>
  </form>

  <p>Alternativt, dra og slipp</p>
  <p>
    Dra en fil (av formatet kml, gpx, GeoJSON eller zippet shape) og slipp den over kartet, så vil innholdet automatisk
    bli tegnet opp
  </p>
</div>
