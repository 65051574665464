import { Injectable } from '@angular/core';
import { KildenStateService } from '@kildencore/services/kilden-state.service';
import { ThemeLayersService } from '@kildencore/services/theme-layers.service';
import { FeatureInfoInterface } from '@kildenshared/interfaces/feature-info.interface';
import Layer from 'ol/layer/Layer';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ObjectinfoService {
  objectInfo: FeatureInfoInterface[] = [];
  private objectInfoArray = new BehaviorSubject<any>(this.objectInfo);
  objectArray$ = this.objectInfoArray.asObservable();

  private _dialogPosition: { x: number; y: number } | undefined;
  private _dialogSize: { height?: number; width?: number } | undefined = {
    height: undefined,
    width: undefined,
  };
  private _exceptionIds: string[] = [];
  private _objIndex = 0; //keep track at order for layers

  constructor(
    private readonly _kildenStateService: KildenStateService,
    private readonly _themeLayersService: ThemeLayersService
  ) {
    this._themeLayersService.layersChange$.subscribe(changes => {
      let changesMade = false;
      if (!changes?.length) {
        return;
      }

      changes.forEach(lc => {
        if (lc.change.active !== undefined) {
          changesMade = true;
          if (lc.change.active) {
            this.addLayer(lc.layerid);
          } else {
            this.removeLayer(lc.layerid);
          }
        }
      });

      if (changesMade) {
        this.objectInfoArray.next(this.objectInfo);
      }
    });

    this._kildenStateService.topic$.subscribe(changeTopic => {
      if (changeTopic) {
        this.objectInfo = [];
        this.objectInfoArray.next(this.objectInfo);
      }
    });
  }

  getStoredDialogPosition() {
    return this._dialogPosition;
  }

  getStoredDialogSize() {
    return this._dialogSize;
  }

  makeObject(layer: Layer): FeatureInfoInterface {
    this._objIndex++;
    return {
      id: layer.getProperties()?.['id'],
      name: layer.getSource()?.getProperties()?.['config'].label,
      layer: layer,
      index: this._objIndex,
    } as FeatureInfoInterface;
  }

  resetDialogPosition(): void {
    this._dialogPosition = undefined;
  }

  resetDialogSize(): void {
    this._dialogSize = undefined;
  }

  /**
   * Stores the user positioned dialog coordinates
   */
  setStoredDialogPosition(x: number, y: number) {
    if (!this._dialogPosition) {
      this._dialogPosition = { x: 0, y: 0 };
    }

    this._dialogPosition.x = x;
    this._dialogPosition.y = y;
  }

  /**
   * Stores the objinfo dialog size
   */
  setStoredDialogSize(height: number, width: number): void {
    if (!this._dialogSize) {
      this._dialogSize = { height: undefined, width: undefined };
    }

    this._dialogSize.height = height;
    this._dialogSize.width = width;
  }

  /**
   * Find layer and add object to objectInfoArray
   */
  private addLayer(id: string) {
    if (this._exceptionIds.includes(id)) {
      return;
    }

    const layer = this._themeLayersService.findActiveLayer(id);

    if (!layer) {
      return;
    }

    const isQueryable = layer?.getSource()?.getProperties()?.['config']?.queryable;

    if (!isQueryable) {
      // A layer is queryable if clicking a specific point on the layer can yield more info (object-info)
      return;
    }

    if (this.isLayerInList(id)) {
      return;
    }

    this.createLayer(layer);
  }

  /**
   * Make a standard object for layer
   * and add for objectarray
   * @param layer
   */
  private createLayer(layer: Layer) {
    this.objectInfo.push(this.makeObject(layer));
  }

  /**
   * [isLayerInListe description]
   * @param  {[string]} id [id for one object]
   * @return {[boolean]}  [Check if object id exist already]
   */
  private isLayerInList(id: string): boolean {
    const pos = this.objectInfo.map(e => e.id).indexOf(id);
    return pos > -1;
  }

  /**
   * Remove alle object in objectInfoarray with this id/name
   * @param id
   */
  private removeLayer(id: string) {
    while (this.isLayerInList(id)) {
      const pos = this.objectInfo.map(e => e.id).indexOf(id);
      if (pos > -1) {
        this.objectInfo.splice(pos, 1);
      }
    }
  }
}
