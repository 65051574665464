import { ColorType } from '@kildenshared/types/draw/color.type';

export const ColorsConst: ColorType[] = [
  { name: 'Rød', color: 'rgba(255, 0, 0, 1)', value: 'red' },
  { name: 'Gul', color: 'rgba(255, 255, 0, 1)', value: 'yellow' },
  { name: 'Blå', color: 'rgba(0, 0, 255, 1)', value: 'blue' },
  { name: 'Svart', color: 'rgba(0, 0, 0, 1)', value: 'black' },
  { name: 'Grønn', color: 'rgba(0, 128, 0, 1)', value: 'green' },
  { name: 'Orange', color: 'rgba(255, 165, 0, 1)', value: 'orange' },
  { name: 'Grå', color: 'rgba(128, 128, 128, 1)', value: 'gray' },
  { name: 'Hvit', color: 'rgba(255, 255, 255, 1)', value: 'white' },
];
