import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ReportStatus } from '@kildenshared/types/report/report-status.type';

@Component({
  selector: 'kilden3-report-dialog',
  templateUrl: './report-dialog.component.html',
  styleUrls: ['./report-dialog.component.css'],
})
export class ReportDialogComponent {
  reports: ReportStatus | undefined;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  getCsv() {
    return this.data.reportData.csvDownloadUrl?.replace(/^http:\/\//i, 'https://');
  }
}
