import { Injectable } from '@angular/core';
import { AppConfig } from '@kildenconfig/app.config';
import { CatalogTreeItem } from '@kildenshared/components/catalog-tree/catalog-tree-item';
import { LayerAttributes, LayerChange } from '@kildenshared/interfaces';
import { CatalogLayerTimeOptionsInterface } from '@kildenshared/interfaces/catalog-layer-time-options.interface';
import { CatalogLayerVariantsInterface } from '@kildenshared/interfaces/catalog-layer-variants.interface';
import Feature from 'ol/Feature';
import { Geometry } from 'ol/geom';
import VectorSource from 'ol/source/Vector';

@Injectable({ providedIn: 'root' })
export class LayerHelper {
  /**
   * Apply attribute changes from given LayerChange to the given Layer/Item
   * Does not handle changes to the active-status (in either inputs).
   * Will use default fallback values.
   */
  static applyAttributeChanges(layer: CatalogTreeItem, lc: LayerChange): CatalogTreeItem {
    // Make sure to add idBod in case it's a new layer
    if (!layer || !lc) {
      return layer;
    }

    if (!layer.idBod && lc.layerid) {
      layer.idBod = lc.layerid;
    }

    // Handle layer active/inactive changes
    if (lc.change.active) {
      if (layer.opacity === undefined) {
        // Fallback to a default opacity is important to avoid permalink issues
        layer.opacity = lc.change.opacity ?? layer.opacity ?? layer.config?.opacity ?? AppConfig.DEFAULT_LAYER_OPACITY;
      }
      if (layer.visible === undefined) {
        // Default to visible:true if not specified
        layer.visible = lc.change.visible ?? AppConfig.DEFAULT_LAYER_VISIBILITY;
      }
    }

    // Handle layer opacity changes
    if (lc.change.opacity !== undefined) {
      layer.opacity = lc.change.opacity ?? layer.opacity ?? layer.config?.opacity ?? AppConfig.DEFAULT_LAYER_OPACITY;
    }

    // Handle layer visible/hidden changes
    if (lc.change.visible !== undefined) {
      layer.visible = lc.change.visible;
    }

    // Handle layer version/time changes
    if (lc.change.selectedTimeOption !== undefined) {
      if (layer.timeOptions) {
        layer.timeOptions.selected = lc.change.selectedTimeOption;
      } else {
        layer.timeOptions = {
          selected: lc.change.selectedTimeOption,
        } as CatalogLayerTimeOptionsInterface;
      }
    }

    // Handle layer variant change
    if (lc.change.selectedVariant !== undefined) {
      if (layer.variants) {
        layer.variants.selected = lc.change.selectedVariant;
      } else {
        layer.variants = {
          selected: lc.change.selectedVariant,
        } as CatalogLayerVariantsInterface;
      }
    }

    return layer;
  }

  /**
   * Convert given name of background layer from legacy Kilden
   */
  static convertFromLegacyName(bgLayerName: string): string {
    if (bgLayerName === 'voidLayer') {
      return 'ingen';
    }
    return bgLayerName.split('_')[0];
  }

  /**
   * Convert given background layer name to the equivalent in legacy Kilden
   */
  static convertToLegacyName(bgLayerName: string): string {
    if (bgLayerName === 'ingen') {
      return 'voidLayer';
    }

    if (bgLayerName === 'grunnkart') {
      return bgLayerName;
    }

    let response = bgLayerName + '_cache';

    if (bgLayerName === 'norgeibilder') {
      response += '2';
    }

    return response;
  }

  /**
   * Make layerChange objects that set change[attrib]=value for all given layers
   */
  static generateLayerChanges(
    layersCsv: string,
    attrib: keyof LayerAttributes,
    value: LayerAttributes[keyof LayerAttributes]
  ): LayerChange[] {
    if (layersCsv.trim() === '') {
      return [];
    }
    return layersCsv
      .trim()
      .split(',')
      .map(key => {
        return { layerid: key, change: { [attrib]: value } } as LayerChange;
      });
  }

  /**
   * Make sure new layers have values for necessary fields.
   * Use existing values with default fallbacks.
   */
  static getLayerDefaults(layer: CatalogTreeItem | undefined): CatalogTreeItem {
    const defaults = {
      opacity: AppConfig.DEFAULT_LAYER_OPACITY,
      selectedOpen: true,
      visible: AppConfig.DEFAULT_LAYER_VISIBILITY,
    } as CatalogTreeItem;

    return layer ? Object.assign(layer, defaults) : defaults;
  }

  /**
   * Method to determine if a given layerId is regarded as a user provided layer or not.
   * User provided means user provides the content for the layer, either by uploading files or by drawing etc.
   */
  static isUserProvided(layerId: string): boolean {
    return AppConfig.IMPERSISTENT_LAYERS.includes(layerId) || layerId.startsWith(AppConfig.IDBOD_USER_UPLOADED);
  }

  static removeVectorFeatures(src: VectorSource): void {
    const feats: Feature<Geometry>[] = src.getFeatures();
    feats.forEach(feat => {
      src.removeFeature(feat);
    });
  }
}
