<div *ngIf="attributions.size" id="attributions">
  <a
    *ngIf="attributions.size"
    class="show-mobile"
    href="https://www.geonorge.no/Geodataarbeid/Norge-digitalt"
    target="_blank"
    >&copy; Norge digitalt</a
  >
  <span *ngIf="attributions.size" class="hidden-mobile">&copy; Kartdata:</span>
  <div *ngFor="let attrib of attributions | keyvalue" class="attribution-wrapper hidden-mobile">
    <a [href]="attrib.value" target="_blank">{{ attrib.key }}</a>
    <span class="separator">, </span>
  </div>
</div>
