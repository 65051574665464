import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppConfig } from '@kildenconfig/app.config';
import { ArrayHelper } from '@kildencore/helpers/array.helper';
import { LayerHelper } from '@kildencore/helpers/layer.helper';
import { CatalogTreeService } from '@kildencore/services/data/catalog-tree.service';
import { KildenStateService } from '@kildencore/services/kilden-state.service';
import { PermaLinkService } from '@kildencore/services/perma-link.service';
import { ThemeLayersService } from '@kildencore/services/theme-layers.service';
import { CatalogTreeItem } from '@kildenshared/components/catalog-tree/catalog-tree-item';
import { CatalogTreeRoot } from '@kildenshared/components/catalog-tree/catalog-tree-root';
import { LegendComponent } from '@kildenshared/components/legend/legend.component';
import { DeviceTypesEnum } from '@kildenshared/constants/device-types.enum';
import { LayerChange } from '@kildenshared/interfaces';
import VectorLayer from 'ol/layer/Vector';
import { concatMap, filter, Subject, take, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'kilden3-left',
  templateUrl: './left.component.html',
  styleUrls: ['./left.component.css'],
})
export class LeftComponent implements OnDestroy, OnInit {
  @ViewChild('legendRef', { read: LegendComponent })
  legendRef!: LegendComponent;

  activeLayers: CatalogTreeItem[] = [];
  isMobileScreen: boolean = false;
  layerTabIndex = 0;
  openLeftPanel = false;
  haveLayerSearch: boolean = true;
  topicId = '';

  private readonly _onDestroy$ = new Subject<void>();

  constructor(
    protected readonly kildenStateService: KildenStateService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _catalogTreeService: CatalogTreeService,
    private readonly _permaLinkService: PermaLinkService,
    private readonly _themeLayersService: ThemeLayersService
  ) {}

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  ngOnInit(): void {
    // START initvalues routeprovider from routing.module.ts ref kildenproject
    const config = this._activatedRoute.snapshot.data['config'];
    this.haveLayerSearch = config?.layerSearch;

    this._setupSubscriptions();
  }

  /**
   * Lower given layer one step farther from the user in the Z direction
   */
  activeLayerOrderDown(layer: CatalogTreeItem): void {
    ArrayHelper.moveDown(this.activeLayers, layer);
    this._emitLayersReordered();
  }

  /**
   * Lift given layer one step closer to the user in the Z direction
   */
  activeLayerOrderUp(layer: CatalogTreeItem, dragHandleRef: HTMLDivElement): void {
    ArrayHelper.moveUp(this.activeLayers, layer);

    // Allow the view to update then apply focus to the moved element
    setTimeout(() => {
      dragHandleRef.focus();
    }, 10);

    this._emitLayersReordered();
  }

  /**
   * Callback for active layer dragged n dropped
   */
  activeLayerCdkDropped(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.activeLayers, event.previousIndex, event.currentIndex);
    this._emitLayersReordered();
  }

  /**
   * Dynamic content for title tag of drag handle
   */
  dragHandleTitle(dragHandleRef: HTMLElement): string {
    if (document.activeElement === dragHandleRef) {
      return 'Trykk piltaster for å endre rekkefølge, tabulator for å gå videre.';
    }

    return 'Dra og slipp for å endre rekkefølge';
  }

  preventDrop(e: any) {
    e.preventDefault();
    e.dataTransfer.effectAllowed = 'none';
    e.dataTransfer.dropEffect = 'none';
  }

  toggleSidenav(toggleTo: boolean): void {
    this.openLeftPanel = toggleTo;
    this.legendRef.toggleSidenav();
  }

  private _addCustomLayer(lc: LayerChange): void {
    const existingItemIdx = this.activeLayers.findIndex((al: CatalogTreeItem) => al.idBod === lc.layerid);
    const newOpacity = lc.change.opacity ?? 1;

    if (this.activeLayers.length && existingItemIdx > -1) {
      // If already exists in activeLayers, move it to top as it has now been set back to the static exception Z-index.
      const removedLayers = this.activeLayers.splice(existingItemIdx, 1);
      this.activeLayers.unshift(removedLayers[0]);
      this.activeLayers[0].opacity = newOpacity;
      this.activeLayers[0].visible = true;
      return;
    }

    let layerLabel = lc.layerid === AppConfig.IDBOD_USER_DRAWN ? 'Tegnede objekter' : lc.layerid;
    if (lc.layerid.startsWith(AppConfig.IDBOD_USER_UPLOADED)) {
      layerLabel = lc.change.label?.length ? lc.change.label : 'Opplastet fil';
    }

    // default add customLayer to activeLayers
    this.activeLayers.unshift({
      category: 'layer',
      idBod: lc.layerid,
      label: layerLabel,
      opacity: newOpacity,
      resolutionMessage: '',
      visible: true,
    } as CatalogTreeItem);
  }

  /**
   * Notify services of reordered layers
   */
  private _emitLayersReordered(): void {
    const reorderedLayerList = [...this.activeLayers].reverse();

    // Notify ThemeLayersService to change order of displayed layers
    this._themeLayersService.registerLayersReorder(reorderedLayerList);

    // Update permalink to match changes
    this._permaLinkService.setParamReplaceHistory(
      'layers',
      reorderedLayerList.map(l => l.idBod).join(AppConfig.QP_SEPARATOR_PRIMARY)
    );
  }

  /**
   * Apply incoming changes for each layer
   */
  private _handleLayerChanges(layerChanges: LayerChange[], catalogTree: CatalogTreeRoot): void {
    layerChanges.forEach(lc => {
      // if (AppConfig.ORTHO_EXCEPTIONS.includes(lc.layerid)) {
      //   console.log(`lc`, lc);
      // }

      // Special handling for user provided layers such as user_drawn & user_uploaded*
      if (LayerHelper.isUserProvided(lc.layerid)) {
        if (lc.change.active !== undefined) {
          if (lc.change.active) {
            this._addCustomLayer(lc);
          } else {
            this._removeCustomLayer(lc);
          }
          return;
        }
      }

      // Handle changes to activeLayers
      const alIdx = this.activeLayers.findIndex(al => al.idBod === lc.layerid);
      if (alIdx > -1) {
        if (lc.change.active === false) {
          this.activeLayers.splice(alIdx, 1);
        } else {
          const updatedLayer = this.activeLayers[alIdx];
          LayerHelper.applyAttributeChanges(updatedLayer, lc);
          // This used to reset the JS-ref for each instance, that causes an extra onDestroy+onInit, so instead
          // we assign the changes to original object and make the comp aware changes to e.g. opacity
          this.activeLayers[alIdx] = Object.assign(this.activeLayers[alIdx], updatedLayer);
        }
      }

      // Handle changes to the CatalogTree
      const treeItem: CatalogTreeItem = ArrayHelper.nestedFind(catalogTree.children, lc.layerid, 'idBod', 'children');
      if (treeItem?.category === 'layer') {
        if (lc.change.active !== undefined) {
          // no need to apply changes to layer being removed
          if (!!lc.change?.active) {
            LayerHelper.applyAttributeChanges(treeItem, lc);
          }

          // Handle layer active (enabled) changes
          if (lc.change.active !== undefined) {
            if (lc.change.active) {
              // Read via permalinkService to let permaLink override if exists
              const layerParams = this._permaLinkService.getParamLayerById(lc.layerid);
              treeItem.visible = layerParams?.visible ?? AppConfig.DEFAULT_LAYER_VISIBILITY;
              treeItem.opacity =
                layerParams?.opacity ?? treeItem.opacity ?? treeItem.config?.opacity ?? AppConfig.DEFAULT_LAYER_OPACITY;

              // If not already in list of ActiveLayers, add it
              if (!this.activeLayers.some(al => al.idBod === treeItem.idBod)) {
                // Check what is the current topmost activeLayer
                if (this.activeLayers.length && this.activeLayers[0]?.idBod === AppConfig.IDBOD_USER_DRAWN) {
                  const drawThemeLayer = this._themeLayersService.findActiveLayer(AppConfig.IDBOD_USER_DRAWN);
                  if (drawThemeLayer?.getZIndex() === AppConfig.ZINDEX_USER_DRAWN) {
                    // Respect the exception Z-index of the drawn layer, put new layer beneath/below drawings.
                    const drawCatItem = this.activeLayers.shift();
                    this.activeLayers.unshift(treeItem);
                    this.activeLayers.unshift(drawCatItem as CatalogTreeItem);
                  } else {
                    // User has reordered layers including the drawnCatItem, so eventhough that's on top right now,
                    // it no longer has the exception Z-index. We will therefore treat all activeLayers as equals,
                    // and put the incoming changed layer on top of drawCatItem to match what will happen in the
                    // actual visual map.
                    this.activeLayers.unshift(treeItem);
                  }
                } else {
                  // The current top activeLayer is not the drawing layer, proceed as usual.
                  // Because the ordering of the layers in the permalink is opposite to
                  // the order of layers in the activeLayers tab, use unshift() to add at top, and push() to add bottom
                  if (AppConfig.ORTHO_EXCEPTIONS.includes(treeItem.idBod as string)) {
                    // Ortho layers (satelite or plane photos) default goes below regular themeLayers
                    this.activeLayers.push(treeItem);
                  } else {
                    // All others are added to the top of activeLayers list:
                    this.activeLayers.unshift(treeItem);
                  }
                }
              }
            } else {
              ArrayHelper.removeIfExists(this.activeLayers, treeItem, 'idBod');
            }
          }
        }
      }
    });
  }

  private _removeCustomLayer(lc: LayerChange): void {
    const idx = this.activeLayers.findIndex(al => al.idBod === lc.layerid);
    if (idx > -1) {
      this.activeLayers.splice(idx, 1);
    }
  }

  private _setupSubscriptions(): void {
    this.kildenStateService.deviceType$
      .pipe(
        filter(device => device !== undefined),
        tap(device => {
          this.isMobileScreen = device === DeviceTypesEnum.MOBILE;
          if (this.isMobileScreen) {
            this.openLeftPanel = false;
          }
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();

    this.kildenStateService.sidenavOpen$
      .pipe(
        tap(status => {
          this.openLeftPanel = status;
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();

    // On topic change reset ActiveLayers. If drawings exist, re-add user_drawn, else switch to layerList tab.
    this.kildenStateService.topic$
      .pipe(
        filter(newTopicId => newTopicId?.length > 0),
        tap(newTopicId => {
          if (this.topicId.length > 0 && this.topicId !== newTopicId) {
            this._resetActiveLayers();
          }

          // Assign topic locally to detect next topic change properly
          this.topicId = newTopicId;
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();

    this._themeLayersService.layersReset$
      .pipe(
        tap(() => {
          this._resetActiveLayers();
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();

    // On change in layers we need to update this.activeLayers
    this._themeLayersService.layersChange$
      .pipe(
        concatMap(layerChanges => {
          // Concat the catalogTree to find the correct node via layerchange.layerid
          return this._catalogTreeService.catalogTree$.pipe(
            filter(catalogTree => catalogTree !== undefined && catalogTree !== null),
            take(1),
            tap((catalogTree: CatalogTreeRoot) => {
              this._handleLayerChanges(layerChanges, catalogTree);
            })
          );
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();
  }

  // Resets the ActiveLayers collection, empty except user provided layers
  private _resetActiveLayers() {
    // Empty/clear all layers when changing topic
    this.activeLayers = [];

    const layerChanges: LayerChange[] = [];
    let reAddedLayerCount = 0;

    // If drawings or uploads exist, add them back to ActiveLayers. Removing and re-adding to ActiveLayers like this
    // makes sure all values are up-to-date (else opacity of drawings and slider-value would often be out of sync).
    [...this._themeLayersService.getActiveThemeLayers(), ...(this._themeLayersService.getBankLayers() || [])]
      .filter(l => LayerHelper.isUserProvided(l.get('id')))
      .forEach(l => {
        if (l instanceof VectorLayer) {
          const nrFeatures = l.getSource()?.getFeatures()?.length || 0;
          if (nrFeatures) {
            // console.log(nrFeatures + ' features in ' + l.get('id') + ' label: ' + l.get('label'));

            if (!layerChanges.some(lc => lc.layerid === l.get('id'))) {
              layerChanges.push({
                layerid: l.get('id'),
                change: { active: true, label: l.get('label'), opacity: 1, visible: true },
              });
              reAddedLayerCount++;
            }
          }
        }
      });

    // No active layers to show, switch to LayerList tab
    if (reAddedLayerCount > 0) {
      this._themeLayersService.registerLayerChanges(layerChanges);
    } else {
      this.layerTabIndex = 0;
    }
  }
}
