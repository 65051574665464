import { KeyboardService } from '@kildencore/services/keyboard.service';
import Interaction from 'ol/interaction/Interaction';
import MapBrowserEvent from 'ol/MapBrowserEvent';

export class KeyboardEventInteraction extends Interaction {
  constructor(private _keyboardService: KeyboardService) {
    super();
  }

  override handleEvent(mapBrowserEvent: MapBrowserEvent<KeyboardEvent>) {
    if (mapBrowserEvent.type === 'keydown') {
      this._keyboardService.handleGlobalShortcuts(mapBrowserEvent.originalEvent);
    }
    return true;
  }
}
